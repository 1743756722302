import React, { Component } from 'react';
import axios from 'axios';
import qs from 'qs';

import {getToken,getExtranetToken} from '../helpers/Token';
import {getAppUrl} from '../helpers/Defaults';
import MaskEdit from './MaskEdit';
import CSVDisplay from './CSVDisplay';
import PDFDisplay from './blocks/PDFDisplay';
import VivaGrid2 from './VivaGrid';
import VivaMaskEingabe from './VivaMaskEingabe';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import {Route, Link, Switch } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Drawer from '@material-ui/core/Drawer';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ImageEditorRc from 'react-cropper-image-editor';
import 'cropperjs/dist/cropper.css'; 
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TextField from '@material-ui/core/TextField';
import { Redirect } from 'react-router'
import Tooltip from '@material-ui/core/Tooltip';
import { useSnackbar, withSnackbar } from 'notistack';
import queryString from 'query-string';
import Loader from './blocks/Loader'
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { stringify } from 'querystring';
import { ruRU } from "@material-ui/core/locale";
import {Query, Builder, BasicConfig, Utils as QbUtils} from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import 'react-awesome-query-builder/lib/css/styles.css';



const InitialConfig = MaterialConfig; // or MaterialConfig or MuiConfig or BootstrapConfig or BasicConfig

const localeSettings = {
  locale: {
    short: "en",
    full: "en-IN",
    material: ruRU
  },
  valueLabel: "Value",
  valuePlaceholder: "Value",
  fieldLabel: "Field",
  operatorLabel: "Operator",
  fieldPlaceholder: "Select field",
  operatorPlaceholder: "Select operator",
  deleteLabel: null,
  addGroupLabel: "Add group",
  addRuleLabel: "Add rule1",
  delGroupLabel: null,
  notLabel: "Not",
  valueSourcesPopupTitle: "Select value source",
  removeRuleConfirmOptions: {
    title: "Are you sure delete this rule?",
    okText: "Yes",
    okType: "danger"
  },
  removeGroupConfirmOptions: {
    title: "Are you sure delete this group?",
    okText: "Yes",
    okType: "danger"
  }
};
  
const settings = {
 ...localeSettings
};
  
  
// ...InitialConfig,
const config = {
 ...InitialConfig,
 
  
  fields: {   
    _customer_Produkte__createDateAS400: {
        label: 'ErstellungsDatum AS400',
        type: 'date',
        fieldSettings: {
            min: 0,
        },
        valueSources: ['value'],
        preferWidgets: ["date"]
    },

    _customer_Produkte__Lieferant: {
        label: 'Lieferant',
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: [
			{ value: 'CRAME',	title: 'Crame'},
			{ value: 'EA   ',	title: 'EA'},
			{ value: 'CARBE',	title: 'Carbest'},
			{ value: 'ADRIA',	title: 'Adria Mobil FAHRZEUGE'},
			{ value: 'ALKO',	title: 'AL-KO'},
			{ value: 'ALDE',	title: 'Alde'},
			{ value: 'ARISO',	title: 'Arisol'},
			{ value: 'ATERA',	title: 'Atera'},
			{ value: 'BERKE',	title: 'Berker'},
			{ value: 'BOLON',	title: 'Bolon'},
			{ value: 'BRAND',	title: 'Brand'},
			{ value: 'CADAC',	title: 'Cadac'},
			{ value: 'CAMOS',	title: 'Camos'},
			{ value: 'CAMP4',	title: 'Camp 4'},
			{ value: 'CGAZ ',	title: 'Campingaz'},
			{ value: 'CAN  ',	title: 'Can'},
			{ value: 'CBEST',	title: 'carbest'},
			{ value: 'CBE  ',	title: 'CBE Italien'},
			{ value: 'COMET',	title: 'Comet'},
			{ value: 'CRESP',	title: 'Crespo'},
			{ value: 'DISC',	title: 'DISC-O-BED'},
			{ value: 'WAECO',	title: 'Dometic'},
			{ value: 'DOMET',	title: 'Dometic'},
			{ value: 'SEITZ',	title: 'Dometic Seitz'},
			{ value: 'DYNAX',	title: 'Dynaxo'},
			{ value: 'EBERS',	title: 'Eberspaecher'},
			{ value: 'ELGEN',	title: 'Elgena'},
			{ value: 'EMUK ',	title: 'Emuk'},
			{ value: 'CARRY',	title: 'EuroCarry'},
			{ value: 'FASP ',	title: 'Fasp'},
			{ value: 'FAWO ',	title: 'FAWO'},
			{ value: 'FEUER',	title: 'Feuerdesign'},
			{ value: 'FIAMM',	title: 'Fiamma'},
			{ value: 'FROLI',	title: 'Fromme'},
			{ value: 'GIMEX',	title: 'gimex'},
			{ value: 'GOK  ',	title: 'GOK'},
			{ value: 'GSI  ',	title: 'GSI'},
			{ value: 'HELLA',	title: 'Hella'},
			{ value: 'HEOSO',	title: 'HEOSolution'},
			{ value: 'HERZO',	title: 'Herzog'},
			{ value: 'HIND ',	title: 'Hindermann'},
			{ value: 'HOBBY',	title: 'Hobby'},
			{ value: 'HOLID',	title: 'Holiday Travel'},
			{ value: 'HONDA',	title: 'Honda'},
			{ value: 'HORRE',	title: 'Horrex'},
			{ value: 'IE   ',	title: 'Industrial Electronics'},
			{ value: 'ISABE',	title: 'Isabella'},
			{ value: 'JOKON',	title: 'Jokon'},
			{ value: 'KATAD',	title: 'Katadyn'},
			{ value: 'KATHR',	title: 'Kathrein'},
			{ value: 'KILLI',	title: 'Killian'},
			{ value: 'KOZIO',	title: 'Koziol'},
			{ value: 'LINNE',	title: 'Linnepe'},
			{ value: 'MAXVI',	title: 'Maxview'},
			{ value: 'MCCAM',	title: 'McCamping'},
			{ value: 'MECAT',	title: 'Mecatronic'},
			{ value: 'MEGAS',	title: 'Megasat'},
			{ value: 'MPK  ',	title: 'MPK'},
			{ value: 'NDS  ',	title: 'NDS'},
			{ value: 'NOMAD',	title: 'NomadiQ'},
			{ value: 'REVOL',	title: 'Outdoor Revolution'},
			{ value: 'OUTWE',	title: 'Outwell'},
			{ value: 'PEGGY',	title: 'Peggy Peg'},
			{ value: 'Phoenix',	title: 'Phoenix'},
			{ value: 'PIONE',	title: 'Pioneer'},
			{ value: 'POLYP',	title: 'Polyplastic'},
			{ value: 'PROJE',	title: 'Project 2000'},
			{ value: 'REICH',	title: 'Reich'},
			{ value: 'REIMO',	title: 'REIMO'},
			{ value: 'RTENT',	title: 'Reimo Tent Technology'},
			{ value: 'REMIS',	title: 'Remis'},
			{ value: 'SCHAU',	title: 'Schaudt'},
			{ value: 'SIKA ',	title: 'SIKA'},
			{ value: 'SMEV ',	title: 'SMEV'},
			{ value: 'SOG  ',	title: 'SOG'},
			{ value: 'SPORT',	title: 'Sportscraft'},
			{ value: 'SR ME',	title: 'SR Mecatronic'},
			{ value: 'STAR ',	title: 'Starbrite'},
			{ value: 'SUNLIVING',	title: 'SUNLIVING'},
			{ value: 'TELEC',	title: 'Teleco'},
			{ value: 'THAAF',	title: 'Ten Haaft'},
			{ value: 'THETF',	title: 'Thetford'},
			{ value: 'THITR',	title: 'Thitronik'},
			{ value: 'THULE',	title: 'Thule'},
			{ value: 'TREM',	title: 'TR.EM'},
			{ value: 'TROBO',	title: 'Trobolo'},
			{ value: 'TRUMA',	title: 'Truma'},
			{ value: 'VOTRO',	title: 'Votronic'},
			{ value: 'VWCOL',	title: 'VW Collection'},
			{ value: 'WEBAS',	title: 'Webasto'},
			{ value: 'WESTF',	title: 'Westfield'},
			{ value: 'WINTE',	title: 'Winterhoff'},
			{ value: 'WM AQ',	title: 'WM AQUATEC'},
			{ value: 'XTREM',	title: 'X-Trem Isolator'},
			{ value: 'YACHT',	title: 'Yachticon'},
			{ value: 'ZADI ',	title: 'ZADI'},
			{ value: 'ZWAAR',	title: 'Zwaardvis'}
            
            
          ]
        },        
    },
    _customer_Produkte__isMaster: {
        label: 'Artikel Typ',
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: [
            { value: '1', title: 'Nur Masterartikel anzeigen' },
            { value: '0', title: 'Nur Artikel anzeigen' }
          ],
        }
    },
    _customer_Produkte__verantwortlichkeit: {
        label: 'Verantwortliches Team',
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: [
            { value: '9303199', title: 'Pool' },
            { value: '9303200', title: 'Team: Joey Pforr' },
            { value: '9303201', title: 'Team: Daniela Steudner' },
            { value: '9303202', title: 'Team: Benedikt Schöfer' },
            { value: '11172859', title: 'Team: OEM Katalog' }
          ],
        }
    },
    _customer_Produkte__ListenZuordnung: {
        label: 'Neuheitenliste',
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: [
            { value: '3896', title: 'Neuheiten 2023 Technik' },
            { value: '3897', title: 'Neuheiten 2023 Projekte' },
            { value: '3898', title: 'Neuheiten 2023 Zelte' },
            { value: '3899', title: 'Neuheiten 2023 Möbel' },
            { value: '3900', title: 'Neuheiten 2023 Fenster etc.' },
            { value: '3901', title: 'Neuheiten 2023 Handelsware' }
          ],
        }
    },    
    _customer_Produkte__Klassifizierung: {
        label: 'Klassifizierung',
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: [
            { value: '3649', title: 'A' },
            { value: '3650', title: 'B' },
            { value: '3651', title: 'C' },
            { value: '3652', title: 'D' },
            { value: '3653', title: 'E' },
            { value: '3654', title: 'F' }
          ],
        }
    },    

    _Items__UserCreateIdAS400: {
        label: 'Erstellt (AS400) von',
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: [
			{ value: '34',	title: 'Alexander Behle' },
			{ value: '17',	title: 'Allbytes allbytes' },
			{ value: '3',	title: 'Andreas Krings' },
			{ value: '14',	title: 'Auto Importer' },
			{ value: '52',	title: 'Bayram Karabas' },
			{ value: '21',	title: 'Benedikt Schöfer' },
			{ value: '12',	title: 'Conny Peter' },
			{ value: '19',	title: 'Daniela Steudner' },
			{ value: '35',	title: 'Enrico Peyerl' },
			{ value: '36',	title: 'Fabian Vogt' },
			{ value: '18',	title: 'Felix Holona' },
			{ value: '39',	title: 'Gabriela Sztrum' },
			{ value: '49',	title: 'Gianluca Cravagno' },
			{ value: '27',	title: 'Gvantsa Bleck' },
			{ value: '15',	title: 'Irina Kareth' },
			{ value: '28',	title: 'Jan Schelper' },
			{ value: '50',	title: 'Jenifer Haass' },
			{ value: '1',	title: 'Joe Root' },
			{ value: '46',	title: 'Joey Pforr' },
			{ value: '26',	title: 'Karl-Heinz Lindner' },
			{ value: '11',	title: 'Katharina Tschischkale' },
			{ value: '51',	title: 'Luigi Viaggio' },
			{ value: '54',	title: 'Malgorzata Waloszczyk' },
			{ value: '30',	title: 'Manual Manual' },
			{ value: '2',	title: 'Mirko Görgen' },
			{ value: '37',	title: 'Nadege Tchantchou' },
			{ value: '20',	title: 'Naomi Chodura' },
			{ value: '42',	title: 'Päivi Vastamaeki' },
			{ value: '53',	title: 'Peter Hartmann' },
			{ value: '31',	title: 'Regina Raiman' },
			{ value: '13',	title: 'Rüdiger Oehl' },
			{ value: '16',	title: 'Sabine Oleschkowitz' },
			{ value: '38',	title: 'Sibylle Kahl' },
			{ value: '48',	title: 'Simon Kunz' },
			{ value: '24',	title: 'Thomas Waloszczyk' },
			{ value: '41',	title: 'Thorsten Hoppe' },
			{ value: '40',	title: 'Tobias Fleckenstein' },
			{ value: '22',	title: 'Zaneta Prodanova' }
          ],
        }
    },    
    _Items__UserID: {
        label: 'Zuletzt geändert von',
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: [
			{ value: '34',	title: 'Alexander Behle' },
			{ value: '17',	title: 'Allbytes allbytes' },
			{ value: '3',	title: 'Andreas Krings' },
			{ value: '14',	title: 'Auto Importer' },
			{ value: '52',	title: 'Bayram Karabas' },
			{ value: '21',	title: 'Benedikt Schöfer' },
			{ value: '12',	title: 'Conny Peter' },
			{ value: '19',	title: 'Daniela Steudner' },
			{ value: '35',	title: 'Enrico Peyerl' },
			{ value: '36',	title: 'Fabian Vogt' },
			{ value: '18',	title: 'Felix Holona' },
			{ value: '39',	title: 'Gabriela Sztrum' },
			{ value: '49',	title: 'Gianluca Cravagno' },
			{ value: '27',	title: 'Gvantsa Bleck' },
			{ value: '15',	title: 'Irina Kareth' },
			{ value: '28',	title: 'Jan Schelper' },
			{ value: '50',	title: 'Jenifer Haass' },
			{ value: '1',	title: 'Joe Root' },
			{ value: '46',	title: 'Joey Pforr' },
			{ value: '26',	title: 'Karl-Heinz Lindner' },
			{ value: '11',	title: 'Katharina Tschischkale' },
			{ value: '51',	title: 'Luigi Viaggio' },
			{ value: '54',	title: 'Malgorzata Waloszczyk' },
			{ value: '30',	title: 'Manual Manual' },
			{ value: '2',	title: 'Mirko Görgen' },
			{ value: '37',	title: 'Nadege Tchantchou' },
			{ value: '20',	title: 'Naomi Chodura' },
			{ value: '42',	title: 'Päivi Vastamaeki' },
			{ value: '53',	title: 'Peter Hartmann' },
			{ value: '31',	title: 'Regina Raiman' },
			{ value: '13',	title: 'Rüdiger Oehl' },
			{ value: '16',	title: 'Sabine Oleschkowitz' },
			{ value: '38',	title: 'Sibylle Kahl' },
			{ value: '48',	title: 'Simon Kunz' },
			{ value: '24',	title: 'Thomas Waloszczyk' },
			{ value: '41',	title: 'Thorsten Hoppe' },
			{ value: '40',	title: 'Tobias Fleckenstein' },
			{ value: '22',	title: 'Zaneta Prodanova' }
          ],
        }
    },
    _customer_Produkte__ArtikelTyp: {
        label: 'ArtikelKlasse',
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: [
			{ value: '150',	title: 'Adaptersatz Autolift' },
			{ value: '153',	title: 'Adaptersatz für Autolift' },
			{ value: '2',	title: 'Artikel' },
			{ value: '75',	title: 'Aufsteller Hubdach' },
			{ value: '35',	title: 'Auszug' },
			{ value: '151',	title: 'Autolift' },
			{ value: '123',	title: 'Backofen' },
			{ value: '3',	title: 'Batterie' },
			{ value: '62',	title: 'Batterieklemmen' },
			{ value: '115',	title: 'Batteriewächter' },
			{ value: '80',	title: 'Befestigungsklammern' },
			{ value: '114',	title: 'Besteck' },
			{ value: '147',	title: 'Bett' },
			{ value: '22',	title: 'Bodenplatte' },
			{ value: '46',	title: 'Bodenschürzen' },
			{ value: '100',	title: 'Box/Dose' },
			{ value: '109',	title: 'Buch' },
			{ value: '152',	title: 'Dachdurchführung' },
			{ value: '140',	title: 'Dachhaube' },
			{ value: '78',	title: 'Dichtgummi' },
			{ value: '79',	title: 'Dichtrahmen' },
			{ value: '122',	title: 'Drehkonsole' },
			{ value: '160',	title: 'Einbaurahmen' },
			{ value: '29',	title: 'Einbaurahmen Dacheinbau' },
			{ value: '33',	title: 'Einbruchschutz Fenster ' },
			{ value: '132',	title: 'Einstiegsstufen' },
			{ value: '47',	title: 'Endkappen für Markise' },
			{ value: '9',	title: 'Ersatzscheibe' },
			{ value: '87',	title: 'Fahrrad-/Motorrad-Einzug' },
			{ value: '85',	title: 'Fahrradbefestigung' },
			{ value: '92',	title: 'Fahrradträger' },
			{ value: '93',	title: 'Felge' },
			{ value: '13',	title: 'Fenster' },
			{ value: '4',	title: 'Fenster-Innenrahmen' },
			{ value: '7',	title: 'Fensteraussteller' },
			{ value: '101',	title: 'Fenstergriff' },
			{ value: '157',	title: 'Filz' },
			{ value: '77',	title: 'Fliegenschutzrollo' },
			{ value: '52',	title: 'Fußmatte' },
			{ value: '28',	title: 'Gardinen' },
			{ value: '76',	title: 'Gasfederhalter' },
			{ value: '38',	title: 'Gasfedern Aufstelldach' },
			{ value: '128',	title: 'Gasschlauch' },
			{ value: '144',	title: 'Gaszubehör' },
			{ value: '145',	title: 'Geschirr' },
			{ value: '126',	title: 'Glas' },
			{ value: '58',	title: 'Gleitschienen' },
			{ value: '131',	title: 'Grill' },
			{ value: '97',	title: 'Halterung' },
			{ value: '159',	title: 'Heizung' },
			{ value: '158',	title: 'Innenverkleidung' },
			{ value: '30',	title: 'Innenverkleidung Hochdach' },
			{ value: '161',	title: 'Isolator' },
			{ value: '40',	title: 'Kabel/Stecker' },
			{ value: '125',	title: 'Klimaanlage' },
			{ value: '127',	title: 'Kocher' },
			{ value: '112',	title: 'Kochtopf/-pfanne' },
			{ value: '139',	title: 'Kofferklappen' },
			{ value: '102',	title: 'Kühlbox/Kühlschrank' },
			{ value: '149',	title: 'Ladebooster' },
			{ value: '60',	title: 'Ladegeräte' },
			{ value: '56',	title: 'Lampen' },
			{ value: '50',	title: 'Leuchten' },
			{ value: '108',	title: 'Liegen/Matten' },
			{ value: '49',	title: 'Luftfedern/andere Federn' },
			{ value: '34',	title: 'Lüftungsgitter/Windabweiser' },
			{ value: '1',	title: 'Markise' },
			{ value: '27',	title: 'Markisen Zubehör' },
			{ value: '143',	title: 'Markisen-Vorzelt' },
			{ value: '17',	title: 'Markise_mit_Vorzelt' },
			{ value: '99',	title: 'Montagesatz Fenster' },
			{ value: '53',	title: 'Montageset Heki' },
			{ value: '24',	title: 'Moskitonetz' },
			{ value: '95',	title: 'Multirail' },
			{ value: '106',	title: 'Polsterstoffe' },
			{ value: '67',	title: 'Rangiersystem' },
			{ value: '124',	title: 'Reifenchutzhülle' },
			{ value: '103',	title: 'Reinigung/Imprägnierung' },
			{ value: '113',	title: 'Rohr/Schlauch' },
			{ value: '137',	title: 'Rollo' },
			{ value: '155',	title: 'Rückfahr-Kamera' },
			{ value: '90',	title: 'Sat-Antenne' },
			{ value: '64',	title: 'Schalter' },
			{ value: '105',	title: 'Scharnier' },
			{ value: '31',	title: 'Schlafdach' },
			{ value: '32',	title: 'Schlafdach-Bett' },
			{ value: '107',	title: 'Schlafsack' },
			{ value: '21',	title: 'Schlafsitzbank' },
			{ value: '111',	title: 'Schloss' },
			{ value: '39',	title: 'Schrauben' },
			{ value: '43',	title: 'Schutzdach' },
			{ value: '81',	title: 'Schutzhülle ' },
			{ value: '44',	title: 'Shirts' },
			{ value: '61',	title: 'Sicherungen' },
			{ value: '121',	title: 'Sitze' },
			{ value: '41',	title: 'Solaranlage/-modul' },
			{ value: '148',	title: 'Solarregler' },
			{ value: '68',	title: 'Sonnendach/-segel' },
			{ value: '86',	title: 'Spanngurte' },
			{ value: '51',	title: 'Spiegel' },
			{ value: '8',	title: 'Steckdose' },
			{ value: '6',	title: 'Steckdosen-/Schalter-Rahmen' },
			{ value: '26',	title: 'Stühle' },
			{ value: '141',	title: 'Stürzräder' },
			{ value: '142',	title: 'Stützräder' },
			{ value: '70',	title: 'Taschen' },
			{ value: '5',	title: 'Thermoabdeckung' },
			{ value: '91',	title: 'Tisch' },
			{ value: '36',	title: 'Tischfuß/Tischbein' },
			{ value: '118',	title: 'Toilette' },
			{ value: '129',	title: 'Trinkwasserkonservierung' },
			{ value: '54',	title: 'TV' },
			{ value: '89',	title: 'TV-Set' },
			{ value: '10',	title: 'Verdunkelungsrollo' },
			{ value: '138',	title: 'Verdunklung Fahrerhaus' },
			{ value: '25',	title: 'Vorzelt' },
			{ value: '55',	title: 'Vorzelt Caravan' },
			{ value: '84',	title: 'Wandhalter' },
			{ value: '156',	title: 'Waschbecken' },
			{ value: '120',	title: 'Wasserhahn' },
			{ value: '37',	title: 'Wasserkanister/-tank' },
			{ value: '130',	title: 'Wasserpumpen' },
			{ value: '59',	title: 'WC-Entlüftung' },
			{ value: '135',	title: 'Wechselrichter' },
			{ value: '94',	title: 'Zelt' },
			{ value: '96',	title: 'Zeltbalg' },
			{ value: '12',	title: 'Zeltteppich' },
			{ value: '134',	title: 'Zeltzubehör' }
          ],
        }
    },       
    
    _Items__statusUser: {
        label: 'Benutzer Status',
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: [
            { value: '1', title: 'in Bearbeitung' },
            { value: '2', title: 'Texte überabeiten' },
            { value: '3', title: 'Texte freigeben' },
            { value: '4', title: 'Daten überarbeiten' },
            { value: '5', title: 'Daten freigeben' },
            { value: '6', title: 'Bilder überarbeiten' },
            { value: '7', title: 'Bilder freigeben' },
            { value: '8', title: 'Artikel freigeben' },            
            { value: '9', title: 'Artikel fertig' }            
          ],
        }
    },
    _Items__statusSystem: {
        label: 'System Status',
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: [
            { value: '10', title: 'ungeprüft' },
            { value: '11', title: 'Fehlerhafte Kategorie' },
            { value: '12', title: 'keine Bilder' },
            { value: '13', title: 'Keine Selektion' },
            { value: '14', title: 'Allgemeiner Fehler' },
            { value: '15', title: 'Fehlerfrei' },
            { value: '16', title: 'Fehlende DetailArtikel' },
            { value: '17', title: 'Kein Artikelname' },             
            { value: '18', title: 'Keine Beschreibung' },             
            { value: '19', title: 'HTML Syntax Error' }        
          ],
        }
    },
  }
};

// You can load query value from your backend storage (for saving see `Query.onChange()`)
const queryValue = {"id": QbUtils.uuid(), "type": "group"};


class VivaGrid extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            rowDisplay: 26,
            select: [],
            fields: {},
            suche: "",
            show: false,
            offset: 0,
            isLoaded : false,
            productData: [],
            orderField: "",
            maskHeaderClass: undefined,
            searchfield: "",
			anchorElGridMenue: null,
            confirmDialog: false,
            showExportOptions: false,
			rightItemId: 0,
			leftItemId: 0,
			confirmDialogText: "",
			prevImage: false,
			exportDialog: false,
			pdfDialog: false,
			imageDialog: false,
			notification: false,
            exportStatus: 0,
            cropLeft: 0,
            croptTop: 0,
            cropWidth: 0,
            cropHeight: 0,
            cropData: "",
            cropFile: "",
            cropImageUrl: "",
            redirect: false,
            advice: false,
            isResizing: false,
            confirmDialogLoader: false,
            lastDownX: 0,
            currentMaskWidthLink: 1200,
            currentHighlightItemId: -1,
            currentItemByUser: {},
            newWidthLinkItems: {},
            currentSelection: [],
            lastChecked: null,
            bookmarklabel: "",
			bookmarkLinks: [],
            bookmarkfolderId: 0,
            downloadD: false,  
			anchorElFlag: null,
			anchorElFilter: null,
			itemLanguage: "de",
            itemLanguages: [],
            exportImageQuality: "lowRes",
            exportOpen: false,
			profillabel: "allbytes",
            profillanguage: "de",
            filter: 0,
			shopProfiles: [],
			articleCheck: false,
			tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
      		config: config,
      		filterSQL: "",
      		showFilter: false,
      		filterDialogShow: false,
      		planingFirstOpen: false               
        };

		this.setFilter = this.setFilter.bind(this);
		this.addBookmark = this.addBookmark.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleMaskButtonClick = this.handleMaskButtonClick.bind(this);
        this.change = this.change.bind(this);
        this.catchReturn = this.catchReturn.bind(this);
        this.handleClickGridMenue = this.handleClickGridMenue.bind(this);
        this.handleCloseGridMenue = this.handleCloseGridMenue.bind(this);        
        this.reload = this.reload.bind(this);
		this.handleConfirmDialog = this.handleConfirmDialog.bind(this);
		this.changeField = this.changeField.bind(this);
		this.renderCellValue = this.renderCellValue.bind(this);
		this.showPreview = this.showPreview.bind(this);
        this.handleexportDialog = this.handleexportDialog.bind(this);
        this.handlepdfDialog = this.handlepdfDialog.bind(this);
        this.handleImageDialog = this.handleImageDialog.bind(this);
        this.handleNotificationClick = this.handleNotificationClick.bind(this);
        this.getC = this.getC.bind(this);
        this.handleAdvice = this.handleAdvice.bind(this);
        this.checkEditLink = this.checkEditLink.bind(this);
        this.handleMousemove.bind(this);
        this.handleGridAction = this.handleGridAction.bind(this);
        this.doneResizing = this.doneResizing.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
		this.handleClickFlag = this.handleClickFlag.bind(this);
        this.handleCloseFlag = this.handleCloseFlag.bind(this);
		this.handleClickFilter = this.handleClickFilter.bind(this);
        this.handleCloseFilter = this.handleCloseFilter.bind(this);        
        this.setCropData = this.setCropData.bind(this);
        this.cropperReady = this.cropperReady.bind(this);
        this.handleUseFilter = this.handleUseFilter.bind(this);
        this.filterDialog = this.filterDialog.bind(this);
        this.filterDialogShow = this.filterDialogShow.bind(this);
        
        config.settings.addRuleLabel = "Regel";
        config.settings.fieldPlaceholder = "Feld auswählen";
        console.log("Config");
        console.log(config);

    }
    
    handleUseFilter(e) {
		this.reload("reload");
	}
	
	handleShowQueryFilter() {
		if(this.state.showFilter === true) {
			this.setState({
				showFilter: false
			})
		}
		else {
			this.setState({
				showFilter: true
			})
		}
    }

    cropperReady(e, json) {
        if(this.state.cropArea != "") {
            var myobj = JSON.parse(atob(this.state.cropArea));
            this.refs.cropper.setData(myobj);
        }
    }

    setCropData(e) {
        var myobj = JSON.parse(e.target.value);
        this.refs.cropper.setData(myobj);
    }

	changeItemLanguage(language) {
		this.setState({
			itemLanguage: language,
			anchorElFlag: null
		})
	}
	setFilter(event) {
		this.setState({
			filter: event,anchorElFilter: null
        }, ()=>this.reload("reload"));
        
	}	
	handleCloseFlag() {
		this.setState({
			anchorElFlag: null
		})
    }	
    
    handleCloseFilter() {
		this.setState({
			anchorElFilter: null
		})
	}	
	
	handleClickFlag(event) {
		this.setState({
			anchorElFlag: event.currentTarget,
		})
    }
    handleClickFilter(event) {
		this.setState({
			anchorElFilter: event.currentTarget,
		})
	}	

    downloadFile(encoded, filename) {
		var fileDownload = require('js-file-download');
		fileDownload(encoded, filename);
    }

    handleDownload() {
        this.setState({downloadD: false});
    }

    filterDialogShow(kind) {
		this.setState({
				filterDialogShow: true
		})  
	}
		
    filterDialog(kind) {
		if(kind === 'add') {
			
			let obj = {};
			obj.label = this.state.filterlabel;
			obj.tree = btoa(JSON.stringify(this.state.jsonTree));
			obj.sql = this.state.filterSQL;
			
			axios.post(getAppUrl() + '/gatebackend/rest/content/saveFilter', 
               obj
            ,
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }
            ).then(repsonse => {
				this.setState({
						filterDialogShow: false
				})  

                this.props.enqueueSnackbar(this.props.t('Filter gespeichert'), { variant: 'info' });
            }
                
            )
            .catch((error) => {
                console.log(error);
            });			

			
		}
		else if(kind === 'false') {
			this.setState({
					filterDialogShow: false
			})  
		}
		
	}
	
    addBookmark(kind) {
		var k = true;
		if(kind === 'true') {
			axios.get(getAppUrl() + '/gatebackend/rest/settings/bookmarks',
			{
				headers: { 
					"Authorization": " Bearer " + getToken()
				}
			}).then(res2 => {
				this.setState({
					bookmarkOpen: kind,
					bookmarkLinks: res2.data
				})  
			}).catch((error) => {
				
			});
		}
		else {
			if(kind === 'false')
				k = false;
			if(kind === 'add') {
                let tableID = this.props.tableId ;
                let maskID = this.props.maskId ;
                let bookmark = {};
                bookmark.label = this.state.bookmarklabel;
                bookmark.link = "/maske/"+ tableID +"/"+ maskID +"?query=" + this.state.searchfield;
				bookmark.itemId = 0;
				bookmark.folder = parseInt(this.state.bookmarkfolderId);
				axios.post(getAppUrl() + '/gatebackend/rest/settings/bookmarks/add', 
					bookmark
				,
				{
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}
				).then(repsonse => {
				this.props.enqueueSnackbar(this.props.t('Bookmark hinzugefügt'), { variant: 'info' });
				}
					
				)
				.catch((error) => {
					console.log(error);
					alert("error");
				});
				k = false;
			}
			this.setState({
				bookmarkOpen : k
			})  

		}
	}

    handleGridAction(action) {
        let x = [];
        if(action === "clearSelection") {     
            this.setState({ select: x });
            this.props.enqueueSnackbar(this.props.t('Auswahl aufgehoben'), { variant: 'info' });

        }
        if(action === "selectAll") {  
            let newSelect = this.state.select.slice();
            this.state.posts.contentResult.gateContent.map(function(gc) {
                newSelect[gc.dbId] = "true";
            });

            this.setState({ select: newSelect });
            this.props.enqueueSnackbar(this.props.t('Items auf der Seite markiert'), { variant: 'info' });

        }        
        this.handleCloseGridMenue();
    }

    handleMousedown = e => {
        this.setState({ isResizing: true, lastDownX: e.clientX });
    };

    handleMousemove = e => {
        // we don't want to do anything if we aren't resizing.
        if (!this.state.isResizing) {
            return;
        }

        let offsetRight =
        document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        let minWidth = 400;
        let maxWidth = 1400;
        if (offsetRight > minWidth && offsetRight < maxWidth) {
        this.setState({ newWidthLinkItems: { width: offsetRight },
                        currentMaskWidthLink: offsetRight });
        }
    };
    handleMouseup = e => {
        this.setState({ isResizing: false });
    };


    componentWillReceiveProps(props) {
        if(this.props.maskWidth != undefined)
           this.updateDimensions();
        else
            this.setState({ currentHighlightItemId: props.currentHighlightItemId })
    }

    handleAdvice(e) {
        this.setState({advice: false});
    }

    checkEditLink(e, item, link) {

        axios.get(getAppUrl() + '/gatebackend/rest/content/item/' + item, 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                if(res.data.show === true)
                    this.setState({redirect: true, redirectLink: link});
                else    
                    this.setState({advice: true,
                                   currentItemByUser: res.data.user});
        }); 

    }

    getC() {
        let image = this.refs.cropper.getImageData();
        let data = this.refs.cropper.getData();
        data.naturalHeight = image.naturalHeight;
        data.naturalWidth = image.naturalWidth;
        var m = this.refs.cropper.getCropBoxData();
        let imgUrl = this.refs.cropper.getCroppedCanvas();
        this.setState({
            cropLeft: m.left,
            cropTop: m.top,
            cropWidth: m.width,
            cropHeight: m.height,
            cropImageUrl: imgUrl,
            cropData: JSON.stringify(data),
            cropDataUrl: imgUrl.toDataURL()
        }) 
    }

	handleNotificationClick(kind) {
        this.setState({
            notification: kind
        })       
    }

    handleexportDialog(kind, e) {
		if(kind === true) {
			this.setState({
				exportDialog: kind,
				leftItemId: e.target.dataset.leftitemid,
				nextMask: e.target.dataset.nextmask,
				exportStatus: 1
			})
		}
		else {
			this.setState({
				exportDialog: kind,
				exportStatus: 0
			})
		}
    }	

    handlepdfDialog(kind, e) {
		if(kind === true) {
			this.setState({
				pdfDialog: kind,
                plItemId: e.target.dataset.plitemid,
                nextMask: e.target.dataset.nextmask,
                planingFirstOpen: false
			})
		}
		else {
			this.setState({
				pdfDialog: kind,
			})
		}
    }  



    handleImageDialog(kind, e, id, fid, cropData, cropDataUrl) {
            if(kind === true) {
                this.setState({
                    imageDialog: kind,
                    cropFile: e.target.dataset.file,
                    cropItemId: e.target.dataset.itemid,
                    cropElementvalue: e.target.dataset.elementvalue                    
                });

            }
            else if(kind == "ok") {
                
                    id = parseInt(id);
                    fid = parseInt(fid);
                    
                    let posts = this.state.posts;
                    posts.contentResult.gateContent.map(function(gc) {

                        if(gc.itemID === fid) {
                            gc.contentFields.map(function(cF) {
                                if(parseInt(id) === cF.fieldId) {
                                        cF.dataURL = cropDataUrl;
                                        cF.content = cropData;
                                        cF.sticky = 1;
                                        
                                    
                                }
                            });
                        }
                    });
                    this.setState({
                        posts: posts
                    });

                this.setState({
                    imageDialog: false,
                })       
            }
            else {
                this.setState({
                    imageDialog: kind,
                })
            }
    }    

	showPreview(event) {
            this.setState({
				prevImage: true,
				prevSrc: event.target.dataset.src
            })
	}

    handleClickGridMenue(event) {
            this.setState({
                anchorElGridMenue: event.currentTarget
            })
	}
	
    handleMaskButtonClick(e, maskid, tableid, text, func, leftItemId) {
		if(func === "4") {
			this.setState({
                leftItemId: leftItemId,
                rightItemId: 0,
                confirmDialog: true,
                confirmAction: 'deleteItems',
                confirmField: "array",
				confirmDialogText: this.props.t("Möchten Sie die markierten Datensätze löschen?")
			})
        }
       
		else if(func === "13") {
        	axios.post(getAppUrl() + '/gatebackend/rest/content/savecontent', 
                this.state.posts
            ,
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }
            ).then(repsonse => {
                this.reload("reload", "");
                if (typeof this.props.action === 'function') {
                    this.props.action();
                }
                this.props.enqueueSnackbar(this.props.t('Änderungen gespeichert'), { variant: 'info' });
            }
                
            )
            .catch((error) => {
                console.log(error);
                alert("error");
            });
		}
		else if(func === "11") {
            let nw = {width: 1200};
			this.setState({
                newWidthLinkItems: nw,
				notification: true,
			})
        }
		else if(func === "30") {  // Selektion übernehmen
			this.setState({
                leftItemId: leftItemId,
                rightItemId: 0,
                confirmDialog: true,
                showExportOptions: false,
                confirmAction: 'addToPlaning',
				confirmDialogText: this.props.t("Möchten Sie die markierten Datensätze zur Planung hinzufügen?")
			})
		}        
		else if(func === "31") {  // Selektion übernehmen
			this.setState({
                leftItemId: leftItemId,
                rightItemId: 0,
                confirmDialog: true,
                showExportOptions: false,
                confirmAction: 'deleteFromPlaning',
				confirmDialogText: this.props.t("Möchten Sie die markierten Datensätze aus der Planung löschen?")
			})
        } 
		else if(func === "32") {  // Selektion übernehmen
			this.setState({
                leftItemId: leftItemId,
                rightItemId: 0,
                confirmDialog: true,
                showExportOptions: false,
                confirmAction: 'deleteFromLinks',
				confirmDialogText: this.props.t("Möchten Sie die markierten Verlinkungen löschen?")
			})
        }     
		else if(func === "33" || func === "7") {  // Selektion übernehmen
			this.setState({
                leftItemId: leftItemId,
                rightItemId: 0,
                confirmDialog: true,
                showExportOptions: false,
                confirmAction: 'addLinks',
				confirmDialogText: this.props.t("Möchten Sie die markierten Datensätze linken?")
			})
        }     
        else if(func === "34") {
            this.setState({redirect: true, 
                           redirectLink: "/maske/"+tableid+"/"+maskid+"/-2"});        
        }
        else if(func === "35") {
			this.setState({
                leftItemId: leftItemId,
                rightItemId: 0,
                nextMask: maskid,
                nextMaskTableId: tableid,
                confirmDialog: true,
                showExportOptions: false,
                confirmAction: 'multiEdit',
				confirmDialogText: this.props.t("Möchten Sie die markierten Datensätze editieren?")
			})        
        }
        else if(func === "38") {
			this.setState({
                leftItemId: leftItemId,
                rightItemId: 0,
                nextMask: maskid,
                nextMaskTableId: tableid,
                confirmDialog: true,
                showExportOptions: true,
                confirmAction: 'exportImages',
				confirmDialogText: this.props.t("Möchten Sie zu den markierten Datensätze die Bilder exportieren?")
			})        
        }
        else if(func === "40") {
			this.doShowExport();    
        }                 
		else {
	        alert("click" + e.target.dataset.function);
		}
    }	

    async doShowExport() {
        this.setState({chooseArticle: false });
        const getEToken = async ()  => {
            let x = await getExtranetToken();
            return x;
        }        
        let token =  await getEToken();
        axios.get(getAppUrl() + '/extranet/rest/shop/profiles', 
            {
                headers: { 
                    "Authorization": " Bearer " + token,
                }
            }).then(res => {
				this.setState({ exportOpen: true,
								shopProfiles:  res.data});
            }).catch((error) => {
                console.log(error);
                alert("error");
			});
    }	
	exportClose(action) {
		if(action == "do")
			this.exportArticle();
		else {
			this.setState({
				exportOpen: false,
				articleCheck: false
			});	
		}
	}
    async exportArticle() {
        let selections = [];
        for (var key in this.state.select) { 
            if(this.state.select[key] === "true") {
                let select = {};
                select.selected = parseInt(key);
                this.state.posts.contentResult.gateContent.map(function(gc) {
                    if(gc.itemID == key) {
                        gc.contentFields.map(function(contentField) {
                            if(contentField.fieldId == 98 ) {
                                selections.push(contentField.plainContent);
                                 //this._exportArticle(contentField.plainContent);
                            }
                        });
                    }
                });
            }
        }
        for (const x of selections) { 
            this._exportArticle(x); }
        
        
        
    }

	async _exportArticle(itemID) {
        
		this.setState(
    {
			articleCheck: true
		});	
       
        
			
        const getEToken = async ()  => {
            let x = await getExtranetToken();
            return x;
        }        
		let token =  await getEToken();
		const data = {
            profil: this.state.profillabel
		};

		axios.get(getAppUrl() + '/extranet/rest/articles/check/'+itemID+'?language='+this.state.profillanguage+'&country='+this.state.profillanguage, 
			
            {
                headers: { 
					"Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": " Bearer " + token,
                }
            }).then(res => {
				if(res.data.result === true) {
					this.props.enqueueSnackbar(this.props.t('Check erfolgreich abgeschlossen'), { variant: 'info' });
					axios.post(getAppUrl() + '/extranet/rest/shop/'+itemID+'?language='+this.state.profillanguage+'&country='+this.state.profillanguage, 
					qs.stringify(data),
					{
						headers: { 
							"Content-Type": "application/x-www-form-urlencoded",
							"Authorization": " Bearer " + token,
						}
					}).then(res1 => {
						this.props.enqueueSnackbar(this.props.t('Artikelexport eingetragen'), { variant: 'info' });
					}).catch((error) => {
						console.log(error);
						alert("error");
					});

				}
				else {
					this.props.enqueueSnackbar(this.props.t('Check fehlgeschlagen'), { variant: 'info' });
					let base64 = require('base-64');
					let utf8 = require('utf8');
					let bytes = base64.decode(res.data.bdata);
					let encoded = utf8.decode(bytes);
					this.downloadFile(encoded, 'report.csv');
				}
				this.setState({
					exportOpen: false,
					articleCheck: false
				});				
            }).catch((error) => {
                console.log(error);
                alert("error");
		});

    }   
    handleConfirmDialog(state, e, leftItemId, rightItemId, confirmAction) {
		if(state === "confirm") {
            this.setState({
                confirmDialogLoader: true,
            });

            if(confirmAction === "addToPlaning") {
                let selections = [];
                let planingElementId = this.props.linkToPlanungElementId;
                for (var key in this.state.select) { 
                    if(this.state.select[key] === "true") {
                        let select = {};
                        select.planungsElement = parseInt(planingElementId);
                        select.selected = parseInt(key);
                        selections.push(select);
                    }
                }
                let getURL = getAppUrl() + '/gatebackend/rest/content/action?operator='+confirmAction;		
                    axios.post(getURL, 
                    selections,
                    {
                        headers: { 
                            "Authorization": " Bearer " + getToken()
                        }
                    }).then(res => {
                        this.setState({
                            confirmDialog: false,
                            confirmDialogLoader: false,
                        });
                        if (typeof this.props.action === 'function') {
                            this.props.action();
                        }
                        if (typeof this.props.actionOnClose === 'function') {
                            this.props.actionOnClose();
                        }                        
                        this.props.enqueueSnackbar(this.props.t('Items hinzugefügt'), { variant: 'info' });

                    })
                    .catch((error) => {
                        console.log(error);

                    });
            }
            if(confirmAction === "deleteFromPlaning") {
                let selections = [];
                let planingElementId = this.props.planingElementId;
                for (var key in this.state.select) { 
                    if(this.state.select[key] === "true") {
                        let select = {};
                        select.planungsElement = parseInt(planingElementId);
                        select.selected = parseInt(key);
                        selections.push(select);
                    }
                }
                let getURL = getAppUrl() + '/gatebackend/rest/content/action?operator='+confirmAction;		
                    axios.post(getURL, 
                    selections,
                    {
                        headers: { 
                            "Authorization": " Bearer " + getToken()
                        }
                    }).then(res => {
                        this.setState({
                            confirmDialog: false,
                            confirmDialogLoader: false,
                        });
                        this.reload("reload", "");
                        if (typeof this.props.action === 'function') {
                            this.props.action();
                        }
                        this.props.enqueueSnackbar(this.props.t('Items entfernt'), { variant: 'info' });

                    })
                    .catch((error) => {
                        console.log(error);

                    });
            }
            if(confirmAction === "addLinks") {
                let selections = [];
                let leftItemId = this.state.leftItemId;
                for (var key in this.state.select) { 
                    if(this.state.select[key] === "true") {
                        let select = {};
                        select.leftItemId = parseInt(leftItemId);
                        select.selected = parseInt(key);
                        selections.push(select);
                    }
                }
                let getURL = getAppUrl() + '/gatebackend/rest/content/action?operator='+confirmAction;		
                    axios.post(getURL, 
                    selections,
                    {
                        headers: { 
                            "Authorization": " Bearer " + getToken()
                        }
                    }).then(res => {
                        this.setState({
                            confirmDialogLoader: false,
                            confirmDialog: false,

                        });
                        this.reload("reload", "");
                        if (typeof this.props.action === 'function') {
                            this.props.action();
                        }
                        this.props.enqueueSnackbar(this.props.t('Links hinzugefügt'), { variant: 'info' });

                    })
                    .catch((error) => {
                        console.log(error);

                    });
            }  
            if(confirmAction === "deleteFromLinks") {
                let selections = [];
                let planingElementId = this.props.linkToPlanungElementId;
                for (var key in this.state.select) { 
                    if(this.state.select[key] === "true") {
                        let select = {};
                        select.selected = parseInt(key);
                        selections.push(select);
                    }
                }
                let getURL = getAppUrl() + '/gatebackend/rest/content/action?operator='+confirmAction;		
                    axios.post(getURL, 
                    selections,
                    {
                        headers: { 
                            "Authorization": " Bearer " + getToken()
                        }
                    }).then(res => {
                        this.setState({
                            confirmDialog: false,
                            confirmDialogLoader: false,
                        });
                        this.reload("reload", "");
                        if (typeof this.props.action === 'function') {
                            this.props.action();
                        }
                        this.props.enqueueSnackbar(this.props.t('Links entfernt'), { variant: 'info' });

                    })
                    .catch((error) => {
                        console.log(error);

                    });
            }                      
            if(confirmAction === "deleteLink") {
                let selections = [];
                let planingElementId = this.props.linkToPlanungElementId;
                let select = {};
                select.selected = parseInt(rightItemId);                
                selections.push(select);
                let getURL = getAppUrl() + '/gatebackend/rest/content/action?operator=deleteFromLinks';		
                    axios.post(getURL, 
                    selections,
                    {
                        headers: { 
                            "Authorization": " Bearer " + getToken()
                        }
                    }).then(res => {
                        this.setState({
                            confirmDialog: false,
                            confirmDialogLoader: false,
                        });
                        this.reload("reload", "");
                        if (typeof this.props.action === 'function') {
                            this.props.action();
                        }
                        this.props.enqueueSnackbar(this.props.t('Links entfernt'), { variant: 'info' });

                    })
                    .catch((error) => {
                        console.log(error);

                    });
            }
            if(confirmAction === "deleteItems") {
                let selections = [];
                for (var key in this.state.select) { 
                    if(this.state.select[key] === "true") {
                        let select = {};
                        select.selected = parseInt(key);
                        selections.push(select);
                    }
                }
                let getURL = getAppUrl() + '/gatebackend/rest/content/action?operator='+confirmAction;		
                    axios.post(getURL, 
                    selections,
                    {
                        headers: { 
                            "Authorization": " Bearer " + getToken()
                        }
                    }).then(res => {
                        this.setState({
                            confirmDialog: false,
                            confirmDialogLoader: false,
                        });
                        this.reload("reload", "");
                        if (typeof this.props.action === 'function') {
                            this.props.action();
                        }
                        this.props.enqueueSnackbar(this.props.t('Items gelöscht'), { variant: 'info' });

                    })
                    .catch((error) => {
                        console.log(error);

                    });
            }
            if(confirmAction === "multiEdit") {
                let selections = [];
                let nextMask = this.state.nextMask;
                let nextMaskTableId = this.state.nextMaskTableId;
                for (var key in this.state.select) { 
                    if(this.state.select[key] === "true") {
                        let select = {};
                        select.selected = parseInt(key);
                        selections.push(select);
                    }
                }

                this.setState({ redirect: true, 
                                redirectLink: "/maske/"+nextMaskTableId+"/"+nextMask+"/-3",
                                currentSelection: selections
                                });
            }
        }
        if(confirmAction === "exportImages") {
            let selections = [];
            for (var key in this.state.select) { 
                if(this.state.select[key] === "true") {
                    let select = {};
                    select.selected = parseInt(key);
                    selections.push(select);
                }
            }
            let getURL = getAppUrl() + '/gatebackend/rest/content/action?operator='+confirmAction+"&level="+this.state.exportImageQuality;		
                axios.post(getURL, 
                selections,
                {
                    headers: { 
                        "Authorization": " Bearer " + getToken()
                    }
                }).then(res => {
                    this.setState({
                        confirmDialog: false,
                        confirmDialogLoader: false,
                        showExportOptions: false,
                        downloadD: true
                    });

                    if (typeof this.props.action === 'function') {
                        this.props.action();
                    }
                    this.props.enqueueSnackbar(this.props.t('Images exportiert'), { variant: 'info' });

                })
                .catch((error) => {
                    console.log(error);

                });
        }        


		if(state === true) {
			this.setState({
                confirmDialog: state,
                confirmField: "field",
				leftItemId: e.target.dataset.leftitemid,
				rightItemId: e.target.dataset.rightitemid,
                confirmDialogText: e.target.dataset.confirmdialogtext,
                confirmAction: e.target.dataset.confirmaction
			})
		}
		else {
			this.setState({
				confirmDialog: state
			})
		}
    }

    handleCloseGridMenue() {

			this.setState({
				anchorElGridMenue: null
			})

    }

    handleStateChange(mask){
        this.setState({
            mask : mask,
        })
    }
    
    componentWillUnmount() {
        console.log("verlassen");
    }

    componentDidUpdate(prevProps) {
        if(this.props.tableId !== prevProps.tableId || this.props.planingElementId !== prevProps.planingElementId) {
            this.setState({offset: 0, orderField: "", searchfield: ""});
 //           this.reload("reload", "")
        }
    }

    updateDimensions() {
        var elem = document.getElementsByClassName('applicationAction')[0];
        if(document.getElementsByClassName('MuiDrawer-paper').length > 0)
	        elem = document.getElementsByClassName('MuiDrawer-paper')[0];
		if(this.props.anchor != undefined) {
            elem = document.getElementById(this.props.anchor);
                console.log("uD byID:" + this.props.anchor);
        	
        }
        if(elem !== undefined) {
            try {
                let width = elem.offsetWidth;
                console.log("uD width:" + width);
                if(this.props.maskWidth !== undefined) {
                    width = this.props.maskWidth;
                }
                this.setState({
                    width : width,
                })        
            }
            catch (e) {

            }
        }
    }

    doneResizing(){
        //newthis.reload("reload", "");
    }

   componentDidMount() {       
        this.setState({orderField: "", searchfield: ""});
        this.reload("reload", "");
        var resizeId;
        let self = this;
        window.addEventListener('resize', function() {
            clearTimeout(resizeId);
            resizeId = setTimeout(self.doneResizing, 500);
        });

        
        window.addEventListener("resize", this.updateDimensions);
        document.addEventListener("mousemove", e => this.handleMousemove(e));
        document.addEventListener("mouseup", e => this.handleMouseup(e));        
        this.updateDimensions();
    }

   reload(e, orderField, event) {  
        let getRows = Math.trunc((window.innerHeight - 249 - 170) / 35);
        if(this.props.maskModus == 6)
            getRows = Math.trunc((window.innerHeight - 249 - 400) / 35);
     //   let getRows = this.state.rowDisplay;    
        var searchTerm = "";
        if(this.state.searchfield != undefined)
            searchTerm = this.state.searchfield;
        var searchTerm2 = "";

       
        const value=queryString.parse(window.location.search);
        if(value.query)
            searchTerm2 = value.query;

        var offset = this.state.offset;
        if(orderField == undefined)
            orderField = this.state.orderField;
        else if(orderField != "") {
            var oldOrderField = this.state.orderField;
            var maskHeaderClass = this.state.maskHeaderClass;

            for (var key in maskHeaderClass) {
                maskHeaderClass[key] = "fa_alpha_asc showLink";
            }

            maskHeaderClass[event.target.dataset.maskid] = "fa_alpha_asc showLink fa-actice";
            if(oldOrderField == orderField) {
                orderField = orderField + " DESC ";
                maskHeaderClass[event.target.dataset.maskid] = "fa_alpha_desc  showLink fa-actice";
            }
            else if(oldOrderField ==  orderField + " DESC ") {
                maskHeaderClass[event.target.dataset.maskid] = "fa_alpha_asc  showLink";
                orderField = "";

            }
            this.setState({orderField: orderField,
                          maskHeaderClass: maskHeaderClass});
        }
        if(e === "step-backward") {
            offset = 0;
        }
        if(e === "backward") {
            offset = offset - getRows;
            if(offset < 0)
                offset = 0;
        }
        if(e === "forward") {
            offset = offset + getRows;
            if(offset > this.state.posts.contentResult.meta.total -getRows)
                offset = this.state.posts.contentResult.meta.total -getRows;
        }
        if(e === "step-forward") {
            offset = this.state.posts.contentResult.meta.total -getRows;
            if(offset < 0)
                offset = 0;
        }

        this.setState({
            isLoaded : false,
            posts: []
        });
        var tableID = this.props.tableId;
        var maskID = this.props.maskId;
        var itemID = this.props.itemId;
        var modus = this.props.maskModus;
        var nextQuery = this.props.nextQuery;
        let language = this.state.itemLanguage;
        let filterSQL = this.state.filterSQL;
        if(filterSQL == "" && this.props.filterSQL != null && this.props.filterSQL != "") {
	        filterSQL = encodeURIComponent(this.props.filterSQL);
	     	let y = atob(this.props.tree);
			this.setState({
				showFilter: true,
				tree: QbUtils.checkTree(QbUtils.loadTree(y), config)
			})	     
	     
	     }
        if(nextQuery === undefined)
            nextQuery = 0;
        if(this.state.filter > 0)
            nextQuery = this.state.filter;
		var planingElementId = this.props.planingElementId;
		
		var getURL = getAppUrl() + '/gatebackend/rest/content/'+ tableID +'/'+ maskID +'/leftitem/' + itemID +'?offset='+ offset +'&count='+getRows+'&orderField='+orderField+'&searchTerm='+searchTerm+'&modus='+modus+'&searchTerm2='+searchTerm2+'&nextQuery='+nextQuery+'&language='+language+'&filterSQL='+filterSQL;
		if(itemID == -1)
			getURL = getAppUrl() + '/gatebackend/rest/content/mask/'+ maskID +'?offset='+ offset +'&count='+getRows+'&orderField='+orderField+'&searchTerm='+searchTerm+'&modus='+modus+'&searchTerm2='+searchTerm2+'&nextQuery='+nextQuery+'&filterSQL='+filterSQL;
		if(planingElementId !== undefined)
			getURL = getAppUrl() + '/gatebackend/rest/content/mask/'+ maskID +'/planingElement/' + planingElementId +'?offset='+ offset +'&count='+getRows+'&orderField='+orderField+'&searchTerm='+searchTerm+'&modus='+modus+'&searchTerm2='+searchTerm2+'&nextQuery='+nextQuery+'&filterSQL='+filterSQL;		
       
       
        axios.get(getAppUrl() + '/gatebackend/rest/content/languages',  {
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}).then(res1 => {	

                    axios.get(getURL, 
                    {
                        headers: { 
                            "Authorization": " Bearer " + getToken()
                        }
                    }).then(res => {
                        var width = 0;
                        var inlineFieldsStatus = [];
                        var inlineFieldsStatic = [];
                        var inlineFieldsEdit = [];
                        
                        res.data.contentResult.gateContent.map(function(gc) {
                            res.data.mask.maskfields.map(function(mask) {
                                inlineFieldsStatus[gc.itemID + "_" + mask.fieldID] = "static";		
                                inlineFieldsStatic[gc.itemID + "_" + mask.fieldID] = "displayThis";
                                inlineFieldsEdit[gc.itemID + "_" + mask.fieldID] = "hideThis";
                            });
                        });
                        

                        var maskHeaderClass = [];
                        res.data.mask.maskfields.map(function(mask) {
                            if(mask.type !== "submit" && mask.type != "constant") {
                                width += mask.width;
                            }
                            maskHeaderClass[mask.elementValue] = "fa_alpha_asc showLink";
                        });               
                        if(this.state.maskHeaderClass == undefined) {
                            this.setState({
                                currentMaskWidth: width,
                                offset: offset,
                                isLoaded : true,
                                posts: res.data,
                                maskHeaderClass: maskHeaderClass,
                                inlineFieldsStatus: inlineFieldsStatus,
                                inlineFieldsStatic: inlineFieldsStatic,
                                inlineFieldsEdit: inlineFieldsEdit,
                                rowDisplay: getRows,
                                itemLanguages: res1.data
                            }) 
                        }
                        else {

                            this.setState({
                                currentMaskWidth: width,
                                offset: offset,
                                isLoaded : true,
                                posts: res.data,
                                confirmDialog: false,
                                inlineFieldsStatus: inlineFieldsStatus,
                                inlineFieldsStatic: inlineFieldsStatic,
                                inlineFieldsEdit: inlineFieldsEdit,
                                itemLanguages: res1.data					
                            }) 
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    });
				})
				.catch((error) => {
				});


    }

    getCellClasses(itemId) {
        if(itemId === this.state.currentHighlightItemId)
            return "grid-Cell highlightGridCell";
        return "grid-Cell";
    }

    calculateCellWidth(x) {
        var currentWidth = this.state.width - 25 - 48;
        var currentMaskWidth = this.state.currentMaskWidth;
        var restWidth = currentWidth - currentMaskWidth;
        var addionalWidth = restWidth / currentMaskWidth * x;
        
        console.log("x: " + x);
        console.log("currentWidth: " + currentWidth);
        console.log("currentMaskWidth: " + currentMaskWidth);
        
        
        
        return (x + addionalWidth) + "px";
    }

    change(e) { 
        var select = this.state.select;
        var id = e.target.name.split("_");
        if(e.target.name === "profillanguage") {
            this.setState({
                profillanguage : e.target.value,
            })
		}
        if(e.target.name === "profillabel") {
            this.setState({
                profillabel : e.target.value,
            })
		}        
		if(e.target.name === "filterlabel") {
            this.setState({
                filterlabel : e.target.value,
            })
		}
        if(e.target.name === "exportImageQuality") {
            this.setState({
                exportImageQuality : e.target.value,
            })
        }
        if(e.target.name === "bookmarklabel") {
            this.setState({
                bookmarklabel : e.target.value,
            })
        }
        if(e.target.name === "bookmarkfolder") {
            this.setState({
                bookmarkfolderId :e.target.value,
            })
        }	        
        if(e.target.name.startsWith("select")) {
            var lastChecked = this.state.lastChecked;
            if(lastChecked === null)
                lastChecked = id[1];

            if (e.shiftKey) {
                var start = lastChecked;
                var end = id[1];

                let mark = false;;
			    this.state.posts.contentResult.gateContent.map(function(gc) {
				    if(parseInt(gc.dbId) === parseInt(start))
                        mark = true;
				    if(parseInt(gc.dbId) === parseInt(end)) 
                        mark = false;
                    if(mark === true)
                        select[gc.dbId] = e.target.value;
                });

            }
            if(select[id[1]] === "true")
                select[id[1]] = undefined; 
            else
                select[id[1]] = e.target.value;        

            this.setState({
                select : select,
                lastChecked: lastChecked
            })
        }
        if(e.target.name === "searchfield") {
            this.setState({
                searchfield : e.target.value,
            })
        } 
               
	}  
    
    catchReturn(ev) {
        if (ev.key === 'Enter') {
            this.reload("reload");
            ev.preventDefault();
        }
    }


	changeField(e) {
		alert("hier");
	}

    

    renderCellValue(mask, row) {
		console.log(row);
		
        const itemLanguage = this.state.itemLanguage;
		const changeValue = idx => event => {
			var i = idx.split("_");
			var id = parseInt(i[0]);
			var fid = parseInt(i[1]);
            
			const posts = this.state.posts;
			posts.contentResult.gateContent.map(function(gc) {
				if(gc.itemID === fid) {
					gc.contentFields.map(function(cF) {
						if(parseInt(id) === cF.fieldId) {
                            if(cF.content !== event.target.value) {
    							cF.content = event.target.value;
                                cF.sticky = 1;
                                cF.color = event.currentTarget.dataset.color;
                            }
						}
					});
				}
			});
			this.setState({
				posts: posts
			});
		};
		
		
		const getValue = contentField => {
			var value = contentField.content;
			this.state.posts.contentResult.fields.map(function(f) {
				console.log(f.type);
				if(f.id === contentField.fieldId) {
					if(f.type === "singlechoice" || f.type === "singlechoiceDB" || f.type === "form" || f.type === "behavior") {
						f.selectValues.map(function(selectValue) {
							if(selectValue.id == value) {
								value = selectValue.name;
								return;
							}
						});
                    }
					else if(f.type === "status") {
						f.selectValues.map(function(selectValue) {
							if(selectValue.id == value) {
								value = selectValue.name;
								return;
							}
						});
                    }

                    else if(f.type === "json") {
                        let tJ = JSON.parse(contentField.content);
						value = tJ[itemLanguage].content;
                    }
				}
			});

			console.log(value);
			return value;
		};		
            
        
    const  clearCropData = idx  => event => {
            let i = idx.split("_");
            let id = parseInt(i[1]);
            let fid = parseInt(i[0]);
            
            let posts = this.state.posts;
            posts.contentResult.gateContent.map(function(gc) {
                if(gc.itemID === fid) {
                    gc.contentFields.map(function(cF) {

                        if(parseInt(id) === cF.fieldId) {
                                cF.dataURL = "";
                                cF.content = "";
                                cF.sticky = 1;
                        }
                    });
                }
            });
            this.setState({
                posts: posts
            });
        };


		const changeFieldInput = id => event => {
			const inlineFieldsStatic = this.state.inlineFieldsStatic;
			const inlineFieldsStatus = this.state.inlineFieldsStatus;			
			const inlineFieldsEdit = this.state.inlineFieldsEdit;	
			const posts = this.state.posts;

			posts.contentResult.gateContent.map(function(gc) {
				posts.mask.maskfields.map(function(mask) {
					if(id !== gc.itemID + "_" + mask.fieldID) {
						inlineFieldsStatus[gc.itemID + "_" + mask.fieldID] = "static";		
						inlineFieldsStatic[gc.itemID + "_" + mask.fieldID] = "displayThis";
						inlineFieldsEdit[gc.itemID + "_" + mask.fieldID] = "hideThis";
					}
				});
			});

			if(inlineFieldsStatus[id] == "static") {
				inlineFieldsStatus[id] = "edit";
				inlineFieldsEdit[id] = "displayThis";
				inlineFieldsStatic[id] = "hideThis";
			}
			else if(inlineFieldsStatus[id] == "edit") {
				inlineFieldsStatus[id] = "static";
				inlineFieldsEdit[id] = "hideThis";
				inlineFieldsStatic[id] = "displayThis";
			}
			this.setState({
				inlineFieldsStatus: inlineFieldsStatus,
				inlineFieldsStatic: inlineFieldsStatic,
				inlineFieldsEdit: inlineFieldsEdit
			}) 
		};

		const getFilepath = value => {
			var val = value.split(";");
			var md5 = require('md5');
			var path = md5(val[0]);
			return "/" + path[0] + path[1] + "/" + path[2] + path[3] + "/" + val[0];
		};

		const getFilename = value => {
			var val = value.split(";");
			return val[0];
		};

		const inlineFieldsStatic = this.state.inlineFieldsStatic;
		const inlineFieldsEdit = this.state.inlineFieldsEdit;
		const posts = this.state.posts;
        const self = this;
        
        const handleImageDialog = kind => event => {
            if(kind === true) {
                this.setState({
                    imageDialog: kind,
                    cropFile: event.target.dataset.file,
                    cropArea: event.target.dataset.content,
                    cropItemId: event.target.dataset.itemid,
                    cropElementvalue: event.target.dataset.elementvalue

                })
            }
            else {
                this.setState({
                    imageDialog: kind,
                })
            }
        };

        if(mask.type === "field") {

            return row.contentFields.map(function(contentField) {
				
                if(contentField.fieldId == mask.elementValue && contentField.content !== undefined ) {
                    if(contentField.tableFieldTyp === "status" && contentField.access === "readOnly") {
						return <div style={{backgroundColor: contentField.color, marginTop: "5px", borderRadius: "5px", textAlign: 'center'}}>{getValue(contentField)}</div>
					}
                    else if(contentField.access === "readOnly") {
                        return <div>
                                {contentField.tableFieldTyp === "user" ? ( 
									<span dangerouslySetInnerHTML={{__html: contentField.plainContent}}></span>
                                    ): (<>
                                    		{contentField.plainContent}
                                    	</>)
                                }
                                
                                </div>
                    }
                    
                    if(contentField.access === "forbidden") {
                        return <div style={{color: "red"}}>
                                    kein Zugriff
                                </div>
                    }

					if(mask.displayType === "edittext") {
						return <div>
								<span className={inlineFieldsStatic[row.itemID + "_" + mask.fieldID]}  onClick={changeFieldInput(row.itemID + "_" + mask.fieldID)}>{contentField.content}</span>
								<span className={inlineFieldsEdit[row.itemID + "_" + mask.fieldID]} > <input value={contentField.content} onChange={changeValue(mask.elementValue+ "_" + row.itemID)} onBlur={changeFieldInput(row.itemID + "_" + mask.fieldID)}/></span>
								</div>
                    }
                    else if(mask.displayType === "cropper") {
                        return  <div>
                                    <>                                    
                                    {contentField.content == "" || contentField.content == undefined ? (<></>) : (
                                     <> 
                                     {contentField.dataURL == undefined || contentField.dataURL == ""  ? (
                                        <>
                                            <img className="previewSmall" src={"/imagepool/thumbnails/" + row.dbId + ".png?" + Math.floor(Date.now() / 1000)}/>
                                        </>
                                        ) : (
                                            <img className="previewSmall" src={contentField.dataURL}/>                    
                                        )}
                                        <br/>
                                        <span className="fa fa-trash-o" data-itemid={row.itemID} data-elementvalue={mask.elementValue}  onClick={clearCropData(row.itemID + "_" + mask.elementValue)}>
                                        </span>
                                        &nbsp;
                                     </>
                                    )}

                                    </>
                                    <span className="fa fa-crop" data-itemid={row.itemID} data-elementvalue={mask.elementValue} data-content={btoa(contentField.content)} data-file={"/imagepool" + getFilepath(getFilename(row.file))} onClick={handleImageDialog(true)}></span>
                                </div>

                    }
                    else if(mask.displayType === "checkbox") {
                        return <div>
	                                {contentField.plainContent == 1 ? ( <>Ja</>
	                                ): (<>Nein</>)
	                                }                                
                               </div>
                    }                     
                    else if(mask.displayType === "boolIcon") {
                        return <div>
	                                {getValue(contentField) == 1 ? ( 
										<span className={"redComment " + mask.iconName} ></span>
	                                ): (
										<></>)
	                                }                                
                               </div>

                    } 
                    else if(mask.displayType === "filedata") {
                        return <div>{mask.displayType}</div>

                    }                    
					else if(mask.displayType === "popup") {
						return <div>
								
                                {contentField.color != undefined  ? (
									<span style={{backgroundColor: contentField.color, marginTop: "5px", borderRadius: "5px", textAlign: 'center'}} className={inlineFieldsStatic[row.itemID + "_" + mask.fieldID]}  onClick={changeFieldInput(row.itemID + "_" + mask.fieldID)}>{getValue(contentField)}</span>								
								) : (									
									<span  className={inlineFieldsStatic[row.itemID + "_" + mask.fieldID]}  onClick={changeFieldInput(row.itemID + "_" + mask.fieldID)}>{getValue(contentField)}</span>
								)  }
								<span className={inlineFieldsEdit[row.itemID + "_" + mask.fieldID]} > 
									<Select
                                        value={contentField.content}
                                        style={{backgroundColor: contentField.color}}
                                        onChange={changeValue(mask.elementValue+ "_" + row.itemID)}
                                        input={<Input name="age" id="age-label-placeholder" />}
                                    >
										{  posts.contentResult.fields.map(function(datafield) {
			  			 					if(datafield.id == mask.elementValue ) {
													return datafield.selectValues.map(function(selectValue) {
														let color = "#ffffff";
														if(selectValue.color != undefined)
															color =selectValue.color;
														return <MenuItem style={{backgroundColor: color}} data-color={color} value={selectValue.id}>{selectValue.name}</MenuItem>
																
																
											})
										   }
                                           }) 
                                        }
									</Select>
								</span>
								</div>
					}
					else if(mask.displayType === "filepreview") {
						return 	<div className="">
                                    <div>
                                        <img className="previewSmall" src={"/imagepool" + getFilepath(getFilename(contentField.content)) + "?" + Math.floor(Date.now() / 1000)}/><br/>
                                        <span className="fa fa-search" data-src={"/imagepool" + getFilepath(getFilename(contentField.content))  + "?" + Math.floor(Date.now() / 1000)} onClick={e => self.showPreview(e)}></span>
                                    </div>
                                </div>
					}
					else if(contentField.tableFieldTyp === "singlechoiceDB") {
						return <div style={{marginTop: "5px", borderRadius: "5px"}}>{getValue(contentField)}</div>
					}
					else {
						return <div>
								    <span>{getValue(contentField)}</span>								
								</div>
					}					
                }
                
            })   
            
        }
        else if(mask.type === "link") {
// clear Link  {row.itemID} - {this.props.itemId}
			if(mask.elementValue == 9)   
                return <span data-confirmdialogtext="Möchten Sie diesen Link löschen?" data-confirmaction="deleteLink" data-field="item" data-leftitemid={this.props.itemId} data-rightitemid={row.dbId} className={"buttonIcon " + mask.iconName} onClick={e => this.handleConfirmDialog(true, e)}></span>
// edit Item
			else if(mask.elementValue == 10) {
                return <span onClick={e => self.checkEditLink(e, row.itemID, "/maske/"+ mask.tableId + "/"+ mask.nextMask +"/" + row.itemID)} data-link={"/maske/"+ mask.tableId + "/"+ mask.nextMask +"/" + row.itemID}   ><span className={mask.iconName} /></span>
			}
			else if(mask.elementValue == 20) {
                return <span className={mask.iconName} data-nextmask={mask.nextMask} data-leftitemid={row.planungsItemId} data-plitemid={row.planungsItemId} onClick={e => this.handleexportDialog(true,e)}></span>
			}
			else if(mask.elementValue == 21) {
				var plItem = 0;
				row.contentFields.map(function(contentField) {   
		        	if(contentField.fieldId ==  881) {
						plItem = contentField.content;
						
					}
					
				});
                return <span className={mask.iconName} data-nextmask={mask.nextMask} data-plitemid={plItem} data-leftitemid={row.itemID} onClick={e => this.handlepdfDialog(true,e)}></span>
			}
			else if(mask.elementValue == 57) {
				let _HeliosPicFilename = "";
				let _HeliosPicFilepath = "";
				row.contentFields.map(function(contentField) {
 	               if(contentField.fieldId == 417 && contentField.content !== undefined ) 
						_HeliosPicFilename = contentField.content;
 	               if(contentField.fieldId == 418 && contentField.content !== undefined ) 
						_HeliosPicFilepath = contentField.content;
				})
				
				return 	<div className="">
                            <div>
                                <img className="previewSmall" src={"/imagepool" + _HeliosPicFilepath + "/"+ _HeliosPicFilename + "?" + Math.floor(Date.now() / 1000)}/><br/>
                                <span className="fa fa-search" data-src={"/imagepool" + _HeliosPicFilepath + "/"+ _HeliosPicFilename + "?" + Math.floor(Date.now() / 1000)} onClick={e => self.showPreview(e)}></span>
                            </div>
                        </div>
            }
            else
                return <span className={mask.iconName} ></span>

        }
        else if(mask.type === "select") {
            return <input type='checkbox'  name={"select_" + row.itemID} onChange={e => this.change(e)} checked={this.state.select[row.itemID]}/>
        }
       
    }

    renderCellHeader1(field, mask, width) {
        var maskHeaderClass = this.state.maskHeaderClass;
        if(field.id == mask.elementValue)
        return <div className="grid-Cell grid-Cell-Header"  style={{width : width}}>
            <div className="grid-Cell__header" > 
                <nobr><span data-maskid={mask.elementValue} className={maskHeaderClass[mask.elementValue]}  onClick={event => this.reload("reload",field.table+"."+field.name, event)}>&nbsp;{this.props.t(mask.name)}</span></nobr>
            </div>
        </div> 
    }

    renderCellHeader(mask, fields) {
        if(mask.type !== "submit")
            if(mask.type === "field") {
                var width = this.calculateCellWidth(mask.width)
                return fields.contentResult.fields.map(field => (
                    this.renderCellHeader1(field, mask, width)
                ))

            }
            else if(mask.type === "link" || mask.type === "select" )
                return <div className="grid-Cell grid-Cell-Header" style={{width : this.calculateCellWidth(mask.width)}}>
                    <div className="grid-Cell__header" > 
                        <span>{this.props.t(mask.name)}</span>
                    </div>
                </div>             
    }

    renderBuilder = (props) => (
      <div className="query-builder-container" style={{padding: '10px'}}>
        <div className="query-builder qb-lite">
            <Builder {...props} />
        </div>
      </div>
    )
    
    onChangeQB = (immutableTree, config) => {
      // Tip: for better performance you can apply `throttle` - see `examples/demo`
      	this.setState({tree: immutableTree, config: config});

      	const jsonTree = QbUtils.getTree(immutableTree);
      	console.log(jsonTree);
      	let sql = JSON.stringify(QbUtils.sqlFormat(immutableTree, config))
      	if(sql != undefined) {
		      this.setState({
		      	filterSQL : encodeURIComponent(sql),
		      	jsonTree: jsonTree
		      }) 	
		}
      	else {
		      this.setState({
		      	filterSQL : "",
		      }) 	
		}
      // `jsonTree` can be saved to backend, and later loaded to `queryValue`
    }


    render() {
        const {offset, isLoaded, posts, prevImage, redirect, redirectLink} = this.state;
        const language = this.state.itemLanguage;
        if (redirect) {
            return <Redirect to={{
            pathname: redirectLink,
            state: { selections: this.state.currentSelection }
        }}
        />;
        }
        if(!isLoaded) {
            return (<div><Loader/></div>)
        }
        else {

            return (
                <div>                   
                    <div className="Vivacard">
                        <div className="Vivacardheader">
                            <div className="row">
                                <div className="col-sm">
                                    {posts.mask.displayName}
                                </div>
                                {this.props.maskModus == "11" ? (
                                    
                                    <div className="col-sm ">
                                        <span className="pull-right"> 
                                            <MaskEdit maskid={this.props.maskId} handleStateChange={this.handleStateChange} reload={this.reload}></MaskEdit>
                                        </span> 
                                    </div>
                                ) : (
                                <div className="col-sm ">
                                    <span className="pull-right"> 
                                    	<MaskEdit maskid={this.props.maskId} handleStateChange={this.handleStateChange} reload={this.reload}></MaskEdit>
                                    </span>             
                                    <span className="pull-right">
                                        {posts.mask.showFilter == "1" ? (
                                            <>
                                                <span class="icon-stack" onClick={e => this.handleShowQueryFilter(e)}>
												   <i class="fa fa-filter icon-stack-3x"></i>
												   <i class="fa fa-plus icon-stack-1x"></i>
												</span>
                                            
                                            
                                            
                                            <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.handleClickFilter(e)}>              
                                                <span className="fa fa-filter"></span>
                                            </Button>
                                            <Menu	id="simple-menuFlag "
                                                    anchorEl={this.state.anchorElFilter}
                                                    keepMounted
                                                    open={Boolean(this.state.anchorElFilter)}
                                                    onClose={this.handleCloseFilter}
                                                >	
                                                {posts.mask.filter.map(iT => (
                                                    <>
                                                    <MenuItem onClick={e => this.setFilter(iT.id)}><span className={iT.id == this.state.filter ? "fa fa-check" : ""}></span>&nbsp;{iT.label}</MenuItem>
                                                    </>
                                                ))}										
                                    

                                            </Menu>
                                            </>
                                        ) : (<></>)}

                                        <TextField 
                                            className="defaultSearch"
                                            name="searchfield"
                                            onChange={e => this.change(e)} 
                                            onKeyPress={e => this.catchReturn(e)}
                                            value={this.state.searchfield}
                                            id="standard-basic" 
                                            label="Suche" />                                

                                        <span  className="fa fa-search"  onClick={e => this.reload("reload")}>&nbsp;</span>
                                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.addBookmark('true'))}>
                                            <span className="fa fa-bookmark-o"></span>
                                        </Button>
                                                                                          

                                   
										<Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.handleClickFlag(e)}>              
											<span className="fa fa-flag"></span>
										</Button>
										<Menu	id="simple-menuFlag "
                                                anchorEl={this.state.anchorElFlag}
                                                keepMounted
                                                open={Boolean(this.state.anchorElFlag)}
                                                onClose={this.handleCloseFlag}
                                            >											
											{this.state.itemLanguages.map(iT => (
												<>
												<MenuItem onClick={e => this.changeItemLanguage(iT.iso)}><span className={iT.iso == language ? "fa fa-check" : ""}></span>&nbsp;{iT.label}</MenuItem>
												</>
											))}
                                        </Menu>										
                                    	
                                        
                                    </span>
                                </div>
                                )}
                            </div>
                            {this.state.showFilter === true ? ( 
	                          <div>                          
						        <Query
						            {...config} 
						            value={this.state.tree}
						            onChange={this.onChangeQB}
						            renderBuilder={this.renderBuilder}
						        />
						        <div style={{float: "right"}}>
							        <Button variant="contained" 
							        		color="success"
							        		onClick={this.filterDialogShow}>
									  Filter speichern
									</Button>
									<Button variant="contained" 
							        		color="success"
							        		onClick={this.handleUseFilter}>
									  Filter übernehmen
									</Button>
						      	</div>
						      </div>
						    ) :(<></>) }
                        </div>
                        <div className="Vivacardbody">
                            <div className="vivaGrid" style={{height: "calc(100vh - 249px)"}}>
                                
                                <div className="row clearmargin"  align="start">
                                    <div className="grid-Cell grid-Cell-Header" style={{width : "40px"}}>
                                        <div className="grid-Cell__header" > 
                                            <span className="pull-right">
                                                    <Button className="buttonIcon fontColorWhite" aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClickGridMenue}>
                                                        <span className="fa fa-ellipsis-v "></span>
                                                    </Button>
                                                    <Menu
                                                        id="simple-menu"
                                                        anchorEl={this.state.anchorElGridMenue}
                                                        keepMounted
                                                        open={Boolean(this.state.anchorElGridMenue)}
                                                        onClose={this.handleCloseGridMenue}
                                                    >
                                                        <MenuItem onClick={((e) => this.handleGridAction("selectAll"))}>{this.props.t('Alles markieren')}</MenuItem>
                                                        <MenuItem onClick={((e) => this.handleGridAction("clearSelection"))}>{this.props.t('Alle Markierungen aufheben')}</MenuItem>
                                                    </Menu>
                                            </span>                                                
                                        </div>
                                    </div>                                     
                                    {
                                        posts.mask.maskfields.map(mask => (                                           
                                            this.renderCellHeader(mask, posts)
                                    ))}
                                    </div>
                                    <div className="vivaGridBody hier"
                                         style={this.props.isSmallMask ? {maxHeight: "200px",minHeight: "200px", overflow: "scroll"} : {}}
                                    >
                                    {
                                        posts.contentResult.gateContent.map(row => (
                                            <div className="row clearmargin grid-Row" >
                                            <div className="grid-Cell grid-Cell-Left" style={{width : "40px"}}>
                                                <div className="grid-Cell__header" > 
                                                {this.state.select[row.dbId] ? (
                                                    <>
                                                        <Checkbox
                                                        name={"select_" + row.dbId}
                                                        onClick={this.change}
                                                        className="selectSmall"
                                                        checked={this.state.select[row.dbId]}
                                                        value="true"
                                                        color="primary"
                                                    />
                                                    </>
                                                    ) : (
                                                    <><Checkbox
                                                        name={"select_" + row.dbId}
                                                        onClick={this.change}
                                                        className="selectSmall"
                                                        checked={false}
                                                        value="true"
                                                        color="primary"
                                                    />
                                                    </>
                                                    )
                                                }
                                                </div>
                                            </div>                                             
                                            {
                                                posts.mask.maskfields.map(mask => (
                                                    (mask.type !== "submit")  &&
                                                    <div className={this.getCellClasses(row.itemID)} style={{width : this.calculateCellWidth(mask.width)}}>
                                                        <div className="grid-Cell__value" >
                                                        {
                                                            this.renderCellValue(mask, row)
                                                        }
                                                        </div>
                                                    </div>
                                            ))}                                                

                                            </div>
                                        ))
                                    }
                                    </div>
                                    <div className=" clearmargin gridBottom">
                                        <span>
                                            {
                                            posts.mask.maskfields.map(mask => (
                                                (mask.type === "submit")  &&
                                                <Button data-field="array" 
                                                        
                                                        data-leftitemid={this.props.leftItemId} 
                                                        data-function={mask.elementValue} 
                                                        id={mask.elementValue} 
                                                        data-nextmask={mask.nextMask} 
                                                        data-tableid={mask.tableId} 
                                                        color="primary"
                                                        onClick={((e) => this.handleMaskButtonClick(e, mask.nextMask, mask.tableId, mask.name, mask.elementValue, this.props.leftItemId))}>
                                                        {this.props.t(mask.name)}
                                                </Button>
                                            ))}
                                        </span>
                                        <span className="pull-right">
										    <Button style={{marginTop: "-4px"}} className="buttonIcon navMuiButton-root naviGridButton" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.reload("step-backward")}>
                                                <span className="fa fa-step-backward"></span>
                                            </Button>
										    <Button style={{marginTop: "-4px"}} className="buttonIcon navMuiButton-root naviGridButton" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.reload("backward")}>
                                                <span className="fa fa-backward"></span>
                                            </Button>
                                            {offset} - {(offset + this.state.rowDisplay)} / {posts.contentResult.meta.total}
										    <Button style={{marginTop: "-4px"}} className="buttonIcon navMuiButton-root naviGridButton" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.reload("forward")}>
                                                <span className="fa fa-forward"></span>
                                            </Button>
										    <Button style={{marginTop: "-4px"}} className="buttonIcon navMuiButton-root naviGridButton" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.reload("step-forward")}>
                                                <span className="fa fa-step-forward"></span>
                                            </Button>
                                        </span>
                                    </div>
                               
                            </div>
                        </div>
                    </div>
					{prevImage && (
					<Lightbox
						mainSrc={this.state.prevSrc}
						onCloseRequest={() => this.setState({ prevImage: false })}
					/>
					)}
                    <Dialog
                            open={this.state.confirmDialog}
                            onClose={((e) => this.handleConfirmDialog(false))}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">Bestätigen</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {this.state.confirmDialogText}
                            </DialogContentText>
                            {this.state.showExportOptions ? (
                                <FormControl  style={{width: "100%"}}>
                                    <InputLabel htmlFor="age-native-helper">Qualität der Bilder</InputLabel>
                                    <NativeSelect
                                        value={this.state.exportImageQuality}
                                        onChange={e => this.change(e)}
                                        inputProps={{
                                            name: 'exportImageQuality',
                                            id: 'age-native-helper',
                                        }}
                                    >
                                        <option value="fine">keine Konvertierung</option>
                                        <option value="lowRes">LowRes</option>
                                        <option value="highRes">HighRes</option>
                                    </NativeSelect>
                                    <FormHelperText>LowRes ca 1MB,  HighRes ca 4MB pro Bild</FormHelperText>
                                </FormControl> 
                            ) : (<></>)}
                            {this.state.confirmDialogLoader === false ? (
                                <>                                
                                </>
                                )
                                :(<div><Loader/></div>)
                            }
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={((e) => this.handleConfirmDialog(false))} color="primary">
                            Cancel
                        </Button>
                        <Button data-leftitemid={this.state.leftItemId} data-rightitemid={this.state.rightItemId} onClick={((e) => this.handleConfirmDialog("confirm", e, this.state.leftItemId, this.state.rightItemId, this.state.confirmAction))} color="primary" autoFocus>
                            Ok
                        </Button>
                        </DialogActions>
                    </Dialog> 
						
                    <Dialog open={this.state.exportOpen} 

							onClose={((e) => this.exportClose("false"))} 
							aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.props.t('Hinweis')}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									{this.props.t('Exportieren Sie diesen Artikel')}
								</DialogContentText>
								{this.state.articleCheck === false ? (
									<>
									<FormControl  style={{width: "100%"}}>
										<InputLabel htmlFor="age-native-helper">Sprache</InputLabel>
										<NativeSelect
											value={this.state.profillanguage}
											onChange={e => this.change(e)}
											inputProps={{
												name: 'profillanguage',
												id: 'age-native-helper',
											}}
										>
										{this.state.itemLanguages.map(iT => (
											<option value={iT.iso}>{iT.label}</option>
										))}
										</NativeSelect>
									</FormControl>		
									<FormControl style={{width: "100%"}}>
										<InputLabel htmlFor="age-native-helper">ShopwareProfile</InputLabel>
										<NativeSelect
											value={this.state.profillabel}
											onChange={e => this.change(e)}
											inputProps={{
												name: 'profillabel',
												id: 'age-native-helper',
											}}
										>
										{this.state.shopProfiles.map(iT => (
											<option value={iT.name}>{iT.name}</option>
										))}
										</NativeSelect>
									</FormControl>		
								</>) : (
									<>
									Check {this.state.noticeMsg}
									</>
								)}
								
							</DialogContent>
							<DialogActions>
								<Button onClick={((e) => this.exportClose("false"))} color="primary">
									{this.props.t('Abbrechen')}
								</Button>
								<Button onClick={((e) => this.exportClose("do"))} color="primary">
									{this.props.t('OK')}
								</Button>
								</DialogActions>
						</Dialog>
					<Dialog
                            fullWidth="md"
                            maxWidth="md"
                            open={this.state.imageDialog}
                            onClose={((e) => this.handleImageDialog(false,e))}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">{this.props.t('Image Editor')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            <div className="row clearmargin"  align="start">
                                <div className="grid">
                                    <ImageEditorRc
                                        ref='cropper'
                                        crossOrigin='true' 
                                        src={this.state.cropFile}
                                        style={{height: 460, width: 500}}
                                        ready={e => this.cropperReady(e, this.state.cropArea)}
                                        rotatable={true}
                                        imageName='image name with extension to download'                                        
                                        responseType='blob/base64'
                                        viewMode = {1}
                                        crop={this.getC}
                                        guides={true}/>                                
                                </div>
                                <div className="grid" style={{marginLeft: '10px'}}>
                                    <div className="previewBorder" style={{marginTop: '0px'}}>
										<div>
										    <img id="previewCropper" className="previewBig" src={this.state.cropImageUrl}/>
										</div>
									</div>                                
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <i className="fa fa-long-arrow-right"></i>
                                        </span>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="username" 
                                            value={this.state.cropLeft} 
                                        />
                                    </div>
                                    <br/>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <i className="fa  fa-long-arrow-down"></i>
                                        </span>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="username" 
                                            value={this.state.cropTop} 
                                        />
                                    </div>
                                    <br/>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <i className="fa  fa-arrows-h"></i>
                                        </span>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="username" 
                                            value={this.state.cropWidth} 
                                        />
                                    </div>
                                    <br/>                                    
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <i className="fa fa-arrows-v"></i>
                                        </span>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="username" 
                                            value={this.state.cropHeight} 
                                        />
                                    </div>
                                    <br/>                                    
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            GD
                                        </span>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="username" 
                                            value={this.state.cropData} 
                                        />
                                    </div>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            SD
                                        </span>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="username" 
                                            onChange={e => this.setCropData(e)}
                                            value={this.state.cropSetData} 
                                        />
                                    </div>                                                                        
                                </div> 
                                
                            </div>
                            
                                
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={((e) => this.handleImageDialog(false,e))} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={((e) => this.handleImageDialog("ok",e, this.state.cropElementvalue, this.state.cropItemId, this.state.cropData, this.state.cropDataUrl ))} color="primary">
                            Ok
                        </Button>
                        </DialogActions>
                    </Dialog> 


					<Dialog
                            fullWidth="md"
                            maxWidth="md"
                            open={this.state.pdfDialog}
                            onClose={((e) => this.handlepdfDialog(false,e))}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">{this.props.t('PDF')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            	<PDFDisplay nextMask="619" planingFirstOpen={this.state.planingFirstOpen} cleanForceRefresh="true" forceRefresh="true" preview="" language="de" items="-1" itemId={this.state.plItemId} />	                            
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={((e) => this.handlepdfDialog(false,e))} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={((e) => this.handlepdfDialog(false,e))} color="primary">
                            Ok
                        </Button>
                        </DialogActions>
                    </Dialog> 

					<Dialog
                            fullWidth="md"
                            maxWidth="md"
                            open={this.state.exportDialog}
                            onClose={((e) => this.handleexportDialog(false,e))}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">{this.props.t('Export')}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {this.state.exportStatus === 0 && 
							  <span>Export läuft</span>
						  }
                          {this.state.exportStatus === 1 && 
                          		<CSVDisplay language="de" itemId="100498" items="-1" nextMask="459"/>

						  }
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={((e) => this.handleexportDialog(false,e))} color="primary">
                            {this.props.t('Abbruch')}
                        </Button>
                        <Button onClick={((e) => this.handleexportDialog(false,e))} color="primary">
                            Ok
                        </Button>
                        </DialogActions>
                    </Dialog> 

                    <Dialog open={this.state.bookmarkOpen} onClose={((e) => this.addBookmark('false'))} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{this.props.t('Bookmark anlegen')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.props.t('Vergeben Sie einen Namen für ihren Bookmark')}
                            </DialogContentText>
                            <FormControl  style={{width: "100%"}}>
                                <InputLabel htmlFor="age-native-helper">Ordner Auswahl</InputLabel>
                                <NativeSelect
                                    value={this.state.bookmarkfolderId}
                                    onChange={e => this.change(e)}
                                    inputProps={{
                                        name: 'bookmarkfolder',
                                        id: 'age-native-helper',
                                    }}
                                >
                                {this.state.bookmarkLinks.length && this.state.bookmarkLinks.map(folder => (
                                    <option value={folder.id}>{folder.label}</option>
                                ))}
                                </NativeSelect>
                                <FormHelperText>wählen Sie den Ordner für den Bookmark aus.</FormHelperText>
                            </FormControl>                            
                            <TextField
                                autoFocus
                                margin="dense"
                                name="bookmarklabel"
                                label="Bookmarkname"
                                type="email"
                                onChange={e => this.change(e)}
                                value={this.state.bookmarklabel}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={((e) => this.addBookmark('false'))} color="primary">
                                {this.props.t('Abbruch')}
                            </Button>
                            <Button onClick={((e) => this.addBookmark('add'))} color="primary">
                                {this.props.t('OK')}
                            </Button>
                            </DialogActions>
                    </Dialog>                    

					<Dialog open={this.state.filterDialogShow} onClose={((e) => this.addBookmark('false'))} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{this.props.t('Filter anlegen')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.props.t('Vergeben Sie einen Namen für ihren Filter')}
                            </DialogContentText>
                          
                            <TextField
                                autoFocus
                                margin="dense"
                                name="filterlabel"
                                label="Filtername"
                                type="email"
                                onChange={e => this.change(e)}
                                value={this.state.filterlabel}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={((e) => this.filterDialog('false'))} color="primary">
                                {this.props.t('Abbruch')}
                            </Button>
                            <Button onClick={((e) => this.filterDialog('add'))} color="primary">
                                {this.props.t('OK')}
                            </Button>
                            </DialogActions>
                    </Dialog>  




                    <Dialog onClose={((e) => this.handleAdvice(e))} 
                            aria-labelledby="customized-dialog-title" open={this.state.advice}
                            fullWidth="md"
                            maxWidth="md"
                            >
                        <DialogTitle id="customized-dialog-title" onClose={((e) => this.handleAdvice(e))}>
                        Hinweis
                        </DialogTitle>
                        <DialogContent dividers>
                            Der Datensatz ist aktuell in Benutzung durch Benutzer: 
                            &nbsp;{this.state.currentItemByUser.firstname} {this.state.currentItemByUser.lastname} 
                        </DialogContent>
                        <DialogActions>
                        <Button autoFocus onClick={((e) => this.handleAdvice(e))} color="primary">
                            {this.props.t('OK')}
                        </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog onClose={((e) => this.handleDownload(e))} 
                            aria-labelledby="customized-dialog-title" open={this.state.downloadD}
                            fullWidth="md"
                            maxWidth="md"
                            >
                        <DialogTitle id="customized-dialog-title" onClose={((e) => this.handleDownload(e))}>
                        Hinweis
                        </DialogTitle>
                        <DialogContent dividers>
                            <a href={"/download/download.zip?" + new Date().getTime()}>Download Archiv</a> 
                        </DialogContent>
                        <DialogActions>
                        <Button autoFocus onClick={((e) => this.handleDownload(e))} color="primary">
                            {this.props.t('OK')}
                        </Button>
                        </DialogActions>
                    </Dialog>



                    <Drawer anchor="right" 
                            open={this.state.notification} 
                            onClose={((e) => this.handleNotificationClick(false))} 
                            PaperProps={{ style: this.state.newWidthLinkItems }}>
                        <div
                            id="dragger"
                            onMouseDown={event => {
                                this.handleMousedown(event);
                            }}
                            className="drawerDragger"
                        />
                        <div className="notificationFrame" style={{marginLeft: "5px"}}>
                            <VivaGrid2  maskId="67" 
                                        tableId="2" 
                                        itemId="-1" 
                                        maskModus="6" 
                                        linkToPlanungElementId={this.props.planingElementId}
                                        maskWidth={this.state.currentMaskWidthLink}
                                        actionOnClose={this.reload}
                                        action={this.props.action}/>
                            
                        </div>
                    </Drawer>
                </div> 
              
            );
       }
    }
}
export default withTranslation()(withSnackbar(VivaGrid)) ;

