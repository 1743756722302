import React from "react";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

function insertInternalLink(quillEditor, props) {
	console.log(quillEditor);

	props.internLinks(quillEditor);
}

class Editor extends React.Component {
    constructor(props) {
		super(props);
		this.reactQuillRef = null;
		this.state = {
			linkOpen: false,
		}
		this.addLinks = this.addLinks.bind(this);

	}

	insertStar() {
		alert("iSS1");
	}

	addLinks() {
		this.setState({
			linkOpen: false,
		})
	}

 modules = {
    toolbar: {
        container:
        [
            [{ 'placeholder': ['[GuestName]', 'tag'] }], 
            ['bold', 'italic', 'underline'],        
            ['blockquote','insertStar'],

            [{ 'header': 1 }, { 'header': 2 }],               
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],

            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }]                     
        ],
        handlers: {
            "placeholder": function (value) { 
                if (value) {	
                    const cursorPosition = this.quill.getSelection().index;
                    this.quill.insertText(cursorPosition, value);
					this.quill.setSelection(cursorPosition + value.length);
                }
			},
			"insertStar": () => insertInternalLink(this.reactQuillRef.getEditor(),
												   this.props
          	)
        }
    }
}

    componentDidMount() {
        const placeholderPickerItems = Array.prototype.slice.call(document.querySelectorAll('.ql-placeholder .ql-picker-item'));

        placeholderPickerItems.forEach(item => item.textContent = item.dataset.value);
        document.querySelector('.ql-placeholder .ql-picker-label').innerHTML
			= 'Insert Link ' + document.querySelector('.ql-placeholder .ql-picker-label').innerHTML;
        document.querySelector('.ql-insertStar').innerHTML
			= 'is ' + document.querySelector('.ql-insertStar').innerHTML;

	}
	
    render() {

        return (
			<>
            <div className="text-editor">
				<ReactQuill style={{height: "300px"}}
					ref={(el) => { this.reactQuillRef = el }}
                    theme={'snow'}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    modules={this.modules}
                />
            </div>
			<Dialog open={this.state.linkOpen} onClose={((e) => this.addLinks('false'))} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Link anlegen</DialogTitle>
				<DialogContent>
					<DialogContentText>
						xy
					</DialogContentText>
					<FormControl  style={{width: "100%"}}>
						<InputLabel htmlFor="age-native-helper">Link Auswahl</InputLabel>

					</FormControl>								

				</DialogContent>
				<DialogActions>
					<Button onClick={((e) => this.addLinks('false'))} color="primary">
						Abbruch
					</Button>
					<Button onClick={((e) => this.addLinks('add'))} color="primary">
						OK
					</Button>
				</DialogActions>
			</Dialog>
			</>
        )
    }
}


/* 
 * Quill modules to attach to editor
 * See http://quilljs.com/docs/modules/ for complete options
 */




/* 
 * PropType validation
 */
Editor.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string
}

export default Editor;