import React, { Component } from 'react';
import axios from 'axios';
import Fields from './Fields';
import {getToken} from '../helpers/Token';
import {getAppUrl} from '../helpers/Defaults';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { useSnackbar, withSnackbar } from 'notistack';

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            currentTableId: 0,
            offset: 0,
            isLoaded : false,
            productData: [],
            tabValue: 0,
            user: {},
            groups: []
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.changeProfil = this.changeProfil.bind(this);
        this.handleNew = this.handleNew.bind(this);

    }
    handleTabChange(event, newValue) {
        this.setState({
            tabValue : newValue,
        })
    }
    changeProfil(e) {
        let user = this.state.user;
        if(e.target.name === "username") {
            user.username = e.target.value;
        }            
        if(e.target.name === "firstname") {
            user.firstname = e.target.value;
        }
        if(e.target.name === "lastname") {
            user.lastname = e.target.value;
        }
        if(e.target.name === "email") {
            user.email = e.target.value;
        }
        if(e.target.name === "groupId") {
            user.groupId = e.target.value;
        }        
        if(e.target.name === "roleId") {
            user.roleId = e.target.value;
        }
        this.setState({
            user: user
        });
    }

    componentDidMount() {
        axios.get(getAppUrl() + '/gatebackend/rest/settings/users', 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.setState({
                    isLoaded : true,
                    posts: res.data
                })   
            });
    }

    handleChange(which, event) {

    }

    handleProfil(kind) {
            if(kind === "save") {
                let user = this.state.user;
                axios.post(getAppUrl() + '/gatebackend/rest/settings/users/change', 
                    user
                ,
                {
                    headers: { 
                        "Authorization": " Bearer " + getToken()
                    }
                }
                ).then(repsonse => {
                    this.props.enqueueSnackbar(this.props.t('Daten geändert'), { variant: 'info' });

                    axios.get(getAppUrl() + '/gatebackend/rest/settings/users', 
                        {
                            headers: { 
                                "Authorization": " Bearer " + getToken()
                            }
                        }).then(res1 => {
                            this.setState({
                                isLoaded : true,
                                posts: res1.data,
                                show: false,
                            })   
                        });                    

                }
                    
                )
                .catch((error) => {
                    console.log(error);
                    alert("error");
                });
                
            }
            else {
                this.setState({
                    show: false,
                })
            }
        }
    

    handleClose() {
        this.setState({ show: false });
    }

    handleShow(e) {
        var userId = e.currentTarget.dataset.id;
        axios.get(getAppUrl() + '/gatebackend/rest/settings/users/' + userId,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }).then(res => {
        axios.get(getAppUrl() + '/gatebackend/rest/settings/groups', 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res1 => {
                this.setState({
                    userId: userId,
                    show: true,
                    user: res.data, 
                    profillabel: "Profil ändern",
                    groups: res1.data
                })   
            });
 
        }).catch((error) => {
            
        });
    }


    handleNew(e) {
        let user = {};
        user.id = -1;
        user.username = "";
        user.firstname = "";
        user.lastname = "";
        user.roleId = 2;
        user.groupId = 1;
        var userId = -1;

        axios.get(getAppUrl() + '/gatebackend/rest/settings/groups', 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.setState({
                    userId: userId,
                    show: true,
                    user: user,
                    profillabel: "Profil hinzufügen",
                    groups: res.data
                })   
            });
    }    

    render() {
        const user = this.state.user;
        const {isLoaded, posts} = this.state;
        if(!isLoaded) {
            return (<div>loading</div>)
        }
        else {
            let elHeight = document.getElementById('actionFrame').clientHeight -100;
            var height = {
                'max-height': elHeight + 'px',
            };
            return (
                <div>
                    <div class="Vivacard" style={height}>
                        <div class="Vivacardheader">                           
                            {this.props.t('Benutzer')}
                            <span class="pull-right">
                                <Button className="buttonIcon" aria-controls="simple-menuFlag" aria-haspopup="true" onClick={((e) => this.handleNew(e))}>
                                    <span class="fa fa-ellipsis-v pull-right"></span>
                                </Button>
                            </span>
                        </div>
                        <div class="Vivacardbody">
                            <div class="border">
                                <div class="row clearmargin"  align="start">
                                    <div class="grid-Cell grid-Cell-Header col-sm-1">
                                        <div class="grid-Cell__header "> 
                                            ID
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            UserName
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            Vorname
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm">
                                        <div class="grid-Cell__header"> 
                                            Nachname
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            löschen
                                        </div>
                                    </div>
                                </div>
                                {
                                    posts.map(user => (
                                        <div class="row clearmargin grid-Row" data-id={user.id} data-tablename={user.name}  onDoubleClick={((e) => this.handleShow(e))} align="start">
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value" > 
                                                    {user.id}
                                                </div>
                                            </div>
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value"> 
                                                    {user.username}
                                                </div>
                                            </div>    
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value"> 
                                                    {user.firstname}
                                                </div>
                                            </div>
                                            <div class="grid-Cell col-sm"> 
                                                <div class="grid-Cell__value"> 
                                                    {user.lastname}
                                                </div>
                                            </div> 
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value"> 
                                                    <ul>  
                                                        <li class="fa fa-trash-o gridIcon">

                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>                                                                                    
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <Dialog
                            fullWidth="md"
                            maxWidth="md"
                            open={this.state.show}
                            onClose={((e) => this.handleClose(e))}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">{this.state.profillabel}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                           <div className="notificationHeader">
                            <AppBar position="static" color="default">
                                    <Tabs
                                    className="grid-Cell-Header"
                                    value={this.state.tabValue}
                                    onChange={this.handleTabChange}
                                    indicatorColor="secondary"
                                    textColor="white"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    >
										<Tab label="Allgemein" />
										<Tab label="Privat" />
										<Tab label="Benachrichtigungen" />

                                    </Tabs>
                            </AppBar>
							<div className="profilHeight">
								{this.state.tabValue === 0 && 
									<div className="row ">
										<div className="col-md-5 col-lg-5 col-xl-5 disablePaddingRight">
											<div>
												<TextField
                                                    name="username"
													label={this.props.t('Benutzername')}											
													value={user.username}
													onChange={e => this.changeProfil(e)}
													margin="normal"
												/>
											</div>
											<div>
												<TextField
                                                    name="email"
													label={this.props.t('Email')}											
													value={user.email}
													onChange={e => this.changeProfil(e)}
													margin="normal"
												/>
											</div>

										</div>
										<div className="col-md-5 col-lg-5 col-xl-5 disablePaddingRight">
											<div style={{marginTop: "15px"}}>
                                                <FormControl >
                                                        <InputLabel id="demo-simple-select-label">{this.props.t('Rolle')}</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            name="roleId"
                                                            value={user.roleId}
                                                            onChange={e => this.changeProfil(e)}
                                                        >
                                                        <MenuItem value={1}>Admin</MenuItem>
                                                        <MenuItem value={2}>Manager</MenuItem>
                                                        </Select>
                                                </FormControl>
											</div>											
											<div style={{marginTop: "15px"}}>
                                                <FormControl >
                                                        <InputLabel id="demo-simple-select-label">{this.props.t('Gruppe')}</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            name="groupId"
                                                            value={user.groupId}
                                                            onChange={e => this.changeProfil(e)}
                                                        >
                                                        {this.state.groups.map(group => (
                                                            <MenuItem value={group.id}>{group.name}</MenuItem>
                                                        ))}
                                                        </Select>
                                                </FormControl>
											</div>
										</div>
								</div>
								}
								{this.state.tabValue === 1 && 
									<div className="row ">
										<div className="col-md-5 col-lg-5 col-xl-5 disablePaddingRight">
                                            <div>
												<TextField
                                                    name="firstname"
													label={this.props.t('Vorname')}											
													value={user.firstname}
													onChange={e => this.changeProfil(e)}
													margin="normal"
												/>
											</div>
											<div>
												<TextField
                                                    name="lastname"
													label={this.props.t('Nachname')}											
													value={user.lastname}
													onChange={e => this.changeProfil(e)}
													margin="normal"
												/>
											</div>
										</div>
										<div className="col-md-5 col-lg-5 col-xl-5 disablePaddingRight">
											
										</div>                                        
									</div>
								}											
							</div>
						   </div>
						
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={((e) => this.handleClose(e))} color="primary">
                            {this.props.t('Abbruch')}
                        </Button>
                        <Button onClick={((e) => this.handleProfil("save"))} color="primary" autoFocus>
							{this.props.t('Speichern')}
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div> 
            );
       }
    }
}
export default withTranslation()(withSnackbar(Users)) ;

