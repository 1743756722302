import React, { Component } from 'react';
import axios from 'axios';
import {getAppUrl} from '../helpers/Defaults';
import {getToken} from '../helpers/Token';
import {Tabs, Tab} from 'react-bootstrap';
import {Modal} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { useSnackbar, withSnackbar } from 'notistack';
import CreateNew from './blocks/CreateNew';
import Loader from './blocks/Loader'

	function transferAffix() {
		
		return "hier";
	}


class ClassColumns extends Component {



    constructor(props) {
        super(props);
        this.state = {
            show: false,
            currentTableId: 0,
            offset: 0,
            newCounter: -1,
            chooseList: [],
            currentSearch: "",
            allColumns: []
        };

        this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleStateChange = this.handleStateChange.bind(this);
        this.reload = this.reload.bind(this);
        this.createNew = this.createNew.bind(this); 
        this.change = this.change.bind(this);    
        this.handleCloseOK = this.handleCloseOK.bind(this);  
        this.addNewEntry = this.addNewEntry.bind(this); 
        this.searchChange = this.searchChange.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);

    }

	deleteEntry(linkid) {
        let cID = this.state.currentTableId;
        let columnList = [];
        this.state.posts.map(function(post) {
            if(post.id == cID) {
                columnList  = post.columns;
            }
        });
        
        this.state.columnList.map(function(cl) {
            if(cl.linkid == linkid) {
                cl.markAsDelete = "true";
            }
        });

        this.setState({ columnList: columnList});        
	}    

    searchChange(e) {
        this.setState({
			currentSearch: e
		});
    }

    createNew(newName) {
        let chooseList = {};
        chooseList.id = -1;
        chooseList.name = newName;
        axios.post(getAppUrl() + '/gatebackend/rest/settings/articleClassColumn/add', 
            chooseList
        ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
            this.props.enqueueSnackbar(this.props.t('Column hinzugefügt'), { variant: 'info' });
         
            this.reload();
        }
            
        )
        .catch((error) => {
            console.log(error);
            alert("error");
        });

    }

    change(e) {
        if(e.target.name === "columnName") {
            this.setState({
                currentTableName : e.target.value
            })
        }        
        else if(e.target.name === "DefaultValue1") {
			let def = [];
			if(e.target.value != 0)
				def.push("{" + e.target.value + "}");				
			if(this.state.currentDefaultValue2 != 0)
				def.push("{" +this.state.currentDefaultValue2 + "}");				
			if(this.state.currentDefaultValue3 != 0)
				def.push("{" +this.state.currentDefaultValue3 + "}");				
			let d = "# " + def.join(" x ");
	
            this.setState({
                currentDefaultValue1 : e.target.value,
                currentDefaultValue: d
            })
        }        
        else if(e.target.name === "DefaultValue2") {
			let def = [];
			if(this.state.currentDefaultValue1 != 0)
				def.push("{" +this.state.currentDefaultValue1 + "}");				
			if(e.target.value != 0)
				def.push("{" + e.target.value + "}");				
			if(this.state.currentDefaultValue3 != 0)
				def.push("{" +this.state.currentDefaultValue3 + "}");				
			let d = "# " + def.join(" x ");	
            this.setState({
                currentDefaultValue2 : e.target.value,
                currentDefaultValue: d
            })
        }
        else if(e.target.name === "DefaultValue3") {
			let def = [];
			if(this.state.currentDefaultValue1 != 0)
				def.push("{" +this.state.currentDefaultValue1 + "}");				
			if(this.state.currentDefaultValue2 != 0)
				def.push("{" +this.state.currentDefaultValue2 + "}");				
			if(e.target.value != 0)
				def.push("{" + e.target.value + "}");				
			let d = "# " + def.join(" x ");	
			this.setState({
                currentDefaultValue3 : e.target.value,
                currentDefaultValue: d
            })
        }
        
        
        
        else if(e.target.name === "pattern") {
            this.setState({
                currentPattern : e.target.value
            })
        }        
        
        else if(e.target.name === "inputWidget") {
            this.setState({
                currentinputWidget : e.target.value
            })
        }        
        else if(e.target.name === "displayWidget") {
            this.setState({
                currentdisplayWidget : e.target.value
            })
        }
        else if(e.target.name === "currentPostfix") {
            this.setState({
                currentPostfix : e.target.value
            })
        }        
        else if(e.target.name === "currentPrefix") {
            this.setState({
                currentPrefix : e.target.value
            })
        }        
        else {
            var columnList = this.state.columnList;
            var id = e.target.name.split("_");

            columnList.map(function(colList, j) {
                if(colList.linkid ===  parseInt(id[1], 10)) {
                    if(e.target.name.startsWith("sortId")) 
                        colList.sortId = e.target.value;
                    if(e.target.name.startsWith("text")) 
                        colList.type = e.target.value;

                }
            }
            );
            this.setState({
                columnList : columnList
            })
        }
    }

	reload() {
		axios.get(getAppUrl() + '/gatebackend/rest/content/columns', 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res1 => {
	
				axios.get(getAppUrl() + '/gatebackend/rest/content/affixe', 
		            {
		                headers: { 
		                    "Authorization": " Bearer " + getToken()
		                }
		            }).then(res2 => {
			            console.log( res1.data);

		                this.setState({
		                    isLoaded : true,
		                    posts: res1.data,
		                    affixe: res2.data,
		                    show: false 
		                })                                       
	            }).catch((error) => {
	                console.log(error);
	            });
            }).catch((error) => {
                console.log(error);
            });
	}

	handleStateChange() {
		;
	}

   componentDidMount() {
        this.reload();
    }

    handleCloseOK() {

        let column = {};
        column.id = parseInt(this.state.currentTableId);
        column.name = this.state.currentTableName;
        column.inputWidget = this.state.currentinputWidget;
        column.pattern = this.state.currentPattern;



        column.displayWidget = this.state.currentdisplayWidget;
        column.postfix = parseInt(this.state.currentPostfix);
        column.prefix = parseInt(this.state.currentPrefix);
        
        axios.post(getAppUrl() + '/gatebackend/rest/settings/articleColumn/change', 
            column
        ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
            this.props.enqueueSnackbar(this.props.t('Einträge gespeichert'), { variant: 'info' });
            this.reload();
        }
            
        )
        .catch((error) => {
            console.log(error);
        });
    }

    handleClose() {
        this.setState({ show: false });
    }

    addNewEntry(e) {
        let columnList = this.state.columnList;
        let counter = this.state.newCounter;
        let cLE = {};
        cLE.linkid = counter --;
        cLE.sortId = 0;
        cLE.text = "";
        cLE.markAsDelete = "false";
        columnList.push(cLE);

        this.setState({ columnList: columnList,
                        newCounter: counter});
    }

    handleShow(e) {
        let currentPrefix = e.currentTarget.dataset.prefix;
        let currentPostfix = e.currentTarget.dataset.postfix;
        let currentTableId = e.currentTarget.dataset.id;
        let currentTableName = e.currentTarget.dataset.tablename;
        let currentinputWidget = e.currentTarget.dataset.inputwidget;
        let currentPattern = e.currentTarget.dataset.pattern;        
        let currentdisplayWidget = e.currentTarget.dataset.displaywidget;
        let currentDefaultValue = e.currentTarget.dataset.defaultvalue;
        
        let currentDefaultValue1 = 0;
        let currentDefaultValue2 = 0;
        let currentDefaultValue3 = 0;
        
        if(currentDefaultValue.startsWith('#')) {
			let x = currentDefaultValue.split("}");
			for(let i = 0; i < x.length; i++) {
				let o = x[i].replace(/[^\d]/g, "");
				if(i == 0)
					currentDefaultValue1 = o;
				if(i == 1)
					currentDefaultValue2 = o;
				if(i == 2)
					currentDefaultValue3 = o;

			}
		}
        
        
        
        let columnList = [];
        this.state.posts.map(function(post) {
            if(post.id == currentTableId) {
                columnList  = post.columns;
            }
        });

        this.setState({
            show : true,
            columnList: columnList,
            currentPrefix: currentPrefix,
            currentPostfix: currentPostfix,
            currentTableId: currentTableId,
            currentTableName: currentTableName,
            currentinputWidget: currentinputWidget,
            currentPattern: currentPattern,
            currentdisplayWidget: currentdisplayWidget,
            currentDefaultValue: currentDefaultValue,
            currentDefaultValue1: currentDefaultValue1,
            currentDefaultValue2: currentDefaultValue2,
            currentDefaultValue3: currentDefaultValue3
        })   
    }

    render() {
        const {isLoaded, posts} = this.state;
        if(!isLoaded) {
            return (<div><Loader/></div>)
        }
        else {
            let elHeight = document.getElementById('actionFrame').clientHeight -100;
            var height = {
                'max-height': elHeight + 'px',
            };
            return (
                <div>
                    <div class="Vivacard" style={height}>
                        <div class="Vivacardheader">
                           Artikelklassen Columns
                           <CreateNew searchChange={this.searchChange} actionNew={this.createNew} title="Neue Colummn"/>
                        </div>
                        <div class="Vivacardbody">
                            <div class="border">
                                <div class="row clearmargin"  align="start">
                                    <div class="grid-Cell grid-Cell-Header col-sm-1">
                                        <div class="grid-Cell__header "> 
                                            ID
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm">
                                        <div class="grid-Cell__header"> 
                                            Name
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm">
                                        <div class="grid-Cell__header"> 
                                            InternName
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm">
                                        <div class="grid-Cell__header"> 
                                            Default Value
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm">
                                        <div class="grid-Cell__header"> 
                                            Prefix
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm">
                                        <div class="grid-Cell__header"> 
                                            Postfix
                                        </div>
                                    </div>                                    

                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            Bearbeiten
                                        </div>
                                    </div>
                                </div>
                                {
                                    posts.map(post => (
                                        <>
                                        {this.state.currentSearch === "" || ((post.id).toString().includes(this.state.currentSearch) ||  post.label.toLowerCase().includes((this.state.currentSearch).toLowerCase())) ? (                                        
                                        <div class="row clearmargin grid-Row" data-id={post.id} data-tablename={post.name}  onXYDoubleClick={((e) => this.handleShow(e))} align="start">
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value" > 
                                                    {post.id}
                                                </div>
                                            </div>
                                            <div class="grid-Cell col-sm"> 
                                                <div class="grid-Cell__value"> 
                                                    {post.label} 
                                                </div>
                                            </div> 
                                            <div class="grid-Cell col-sm"> 
                                                <div class="grid-Cell__value"> 
                                                    {post.type}
                                                </div>
                                            </div>
                                            <div class="grid-Cell col-sm"> 
                                                <div class="grid-Cell__value"> 
                                                    {post.defaultValue}
                                                </div>
                                            </div>                                                                                            
                                            <div class="grid-Cell col-sm"> 
                                                <div class="grid-Cell__value"> 
                                                     {this.state.affixe[post.prefix].name} <em>{this.state.affixe[post.prefix].output}</em>
                                                </div>
                                            </div>                                             
                                            <div class="grid-Cell col-sm"> 
                                                <div class="grid-Cell__value"> 
                                                    {this.state.affixe[post.postfix].name} <em>{this.state.affixe[post.postfix].output}</em>
                                                </div>
                                            </div>                                              
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value"> 
                                                    <ul>  
														<li data-id={post.id} data-inputwidget={post.inputWidget} data-defaultvalue={post.defaultValue} data-displaywidget={post.displayWidget} data-tablename={post.label} data-prefix={post.prefix} data-postfix={post.postfix} class="fa fa-pencil gridIcon" onClick={((e) => this.handleShow(e))}>

                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>                                                                                    
                                        </div>
                                        ) : (<></>)}
                                        </>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Column: </Modal.Title>
                        </Modal.Header>
                        <Modal.Body class="modalBody">

                            <div class="Vivacard">
                                <div class="Vivacardheader">
                                    Allgemein                                
                                </div>
                                <div class="border">                               
                                    <div className="row clearmargin "  align="start">
                                        <div className="col-sm-2 ">
                                            ID: 
                                        </div>
                                        <div className="col-sm">
                                            {this.state.currentTableId}
                                        </div>
                                    </div>
                                    <div className="row clearmargin "  align="start">
                                        <div className="col-sm-2 ">
                                            Name: 
                                        </div>
                                        <div className="col-sm">
                                            <input maxlength="30" 
                                                    size="30" 
                                                    type="text" 
                                                    name="columnName" 
                                                    onChange={e => this.change(e)} 
                                                    value={this.state.currentTableName} />
                                            
                                        </div>
                                    </div>
                                    <div className="row clearmargin "  align="start">
                                        <div className="col-sm-2 ">
                                            Prefix: 
                                        </div>
                                        <div className="col-sm">
                                            <select style={{width: '220px'}}
                                                        		name="currentPrefix"
                                                                onChange={e => this.change(e)} 
                                                                value={this.state.currentPrefix}
                                                                >
                                                            {
                                                                this.state.affixe.map(af => (
                                                                    <option value={af.id}>{af.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                            
                                        </div>
                                    </div>
                                    <div className="row clearmargin "  align="start">
                                        <div className="col-sm-2 ">
                                            Postfix:
                                        </div>
                                        <div className="col-sm">
                                            <select style={{width: '220px'}}
                                                                name="currentPostfix"                                    		
                                                                onChange={e => this.change(e)} 
                                                                value={this.state.currentPostfix}
                                                                >
                                                            {
                                                                this.state.affixe.map(af => (
                                                                    <option value={af.id}>{af.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                            
                                        </div>
                                    </div>
									<div className="row clearmargin "  align="start">
                                        <div className="col-sm-2 ">
                                            Eingabe:
                                        </div>
                                        <div className="col-sm">
                                            <select style={{width: '220px'}}
                                                                name="inputWidget"                                    		
                                                                onChange={e => this.change(e)} 
                                                                value={this.state.currentinputWidget}
                                                                >
                                                            		<option value="dropdown">DropDown mit Eingabe</option>
                                                            		<option value="dropdownMulti">DropDown Multiselect</option>
                                                            		<option value="dropdownSelectOnly">DropDown</option>
                                                            		<option value="Icon">Text/Icon Eingabe</option>
                                                            		<option value="float">Fließkommazahl</option>
                                                            		<option value="2-float">Fließkommazahl Range</option>
                                                            
                                                        </select>
                                            
                                        </div>
                                    </div>                                    
									<div className="row clearmargin "  align="start">
                                        <div className="col-sm-2 ">
                                            Pattern:
                                        </div>
                                        <div className="col-sm">
                                            <select style={{width: '220px'}}
                                                                name="pattern"                                    		
                                                                onChange={e => this.change(e)} 
                                                                value={this.state.currentPattern}
                                                                >
                                                            		<option value="">Keine</option>
                                                            		<option value="2digits">Fließkommazahl 2</option>
                                                            
                                             </select>
                                            
                                        </div>
                                    </div>

									<div className="row clearmargin "  align="start">
                                        <div className="col-sm-2 ">
                                            Ausgabe:
                                        </div>
                                        <div className="col-sm">
                                            <select style={{width: '220px'}}
                                                                name="displayWidget"                                    		
                                                                onChange={e => this.change(e)} 
                                                                value={this.state.currentdisplayWidget}
                                                                >
                                                            		<option value="unkown">Unbekannt</option>
                                                            		<option value="int">Zahlen Eingabe</option>
                                                            
                                                        </select>
                                            
                                        </div>
                                    </div>    
									<div className="row clearmargin "  align="start">
                                        <div className="col-sm-2 ">
                                            Default Value:
                                        </div>
                                        <div className="col-sm">
                                            <select style={{width: '200px'}}
                                                    name="DefaultValue1"                                    		
                                                    onChange={e => this.change(e)} 
                                                    value={this.state.currentDefaultValue1}
                                                    >
                                                    <option value="0"></option>
                                                    {posts.map(post => (
                                                		<option value={post.id}>{post.label}{this.state.affixe[post.postfix].output}</option>
                                                	))}
                                                
                                            </select>
                                            <select style={{width: '200px'}}
                                                    name="DefaultValue2"                                    		
                                                    onChange={e => this.change(e)} 
                                                    value={this.state.currentDefaultValue2}
                                                    >
                                                    <option value="0"></option>
                                                    {posts.map(post => (
                                                		<option value={post.id}>{post.label}{this.state.affixe[post.postfix].output}</option>
                                                	))}
                                                
                                            </select>
                                            <select style={{width: '200px'}}
                                                    name="DefaultValue3"                                    		
                                                    onChange={e => this.change(e)} 
                                                    value={this.state.currentDefaultValue3}
                                                    >
                                                    <option value="0"></option>
                                                    {posts.map(post => (
                                                		<option value={post.id}>{post.label}{this.state.affixe[post.postfix].output}</option>
                                                	))}
                                                
                                            </select>
                                            
                                            
                                            <br/>
                                            <input maxlength="30" 
                                                    size="30" 
                                                    type="text" 
                                                    name="defaultValue" 
                                                    onChange={e => this.change(e)} 
                                                    value={this.state.currentDefaultValue} />
                                            
                                        </div>
                                    </div>                                                                       
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                            Abbruch
                            </Button>
                            <Button variant="primary" onClick={this.handleCloseOK}>
                            Speichern
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div> 
            );
       }
    }
}

export default withTranslation()(withSnackbar(ClassColumns)) ;
