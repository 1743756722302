import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Link} from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';

class ConfirmAction extends Component {

    constructor(props) {

        super(props);
        this.state = {
            posts: [],
            anchorElFlag: null,
            setAnchorElFlag: null,
            newValue: "",
            open : false
        };
        this.handleClickFlag = this.handleClickFlag.bind(this);
        this.handleCloseFlag = this.handleCloseFlag.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.actionConfirm = this.actionConfirm.bind(this);
        this.change = this.change.bind(this);
    }

    change(e) {
        this.setState({
            newValue: e.target.value
        });          
    }

    actionConfirm(e) {
        this.setState({
            open: null
        });        
        if (typeof this.props.actionConfirm === 'function') {
            this.props.actionConfirm();
        }

    }

    handleClickFlag(event) {
        this.setState({
            anchorElFlag: event.currentTarget,
        })
    }

    handleCloseFlag() {
        this.setState({
            anchorElFlag: null
        })
    }

    handleClose() {
        this.setState({
            open: null
        })
    }

    handleNew(kind) {
        this.setState({
            anchorElFlag: null,
            open: true
        });
    }

    render() {
        const {isLoaded, posts} = this.state;
        return (
            <>          
                <span class="pull-right">
                    <Button className="buttonIcon" aria-controls="simple-menuFlag" aria-haspopup="true" onClick={((e) => this.handleNew(true))}>
                        <span class="fa fa-trash-o pull-right"></span>
                    </Button>

                </span>
                <Dialog open={this.state.open} onClose={(e) => this.handleClose(e)} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.props.description}
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(e) => this.handleClose(e)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={(e) => this.actionConfirm(e)} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>  
            </>          
        );
    
    }
}

export default withTranslation()(ConfirmAction) ;