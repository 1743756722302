import React, { Component, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import {getToken} from '../../helpers/Token';
import {getAppUrl} from '../../helpers/Defaults';
import { Document, Page } from "react-pdf";
import { pdfjs } from 'react-pdf';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {getWSUrl} from '../../helpers/Defaults';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';

import Loader from './Loader'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PDFDisplay extends Component {


    constructor(props) {

        super(props);
        this.state = {
            posts: [],
            isLoaded : false,
            numPages: null,
            pageNumber: 1,
            templateId: -1,
            zoom: 1.0,
            items: props.items,
            itemId: props.itemId,
            language: props.language,
            showLOG: false,
            showAdvice: false,
            nextMask: props.nextMask,
            timestamp: this.props.timestamp,
            logTXT: "",
            preview:"",
            planingFirstOpen: undefined,
            quality: "LowRes"
        };
        this.refresh = this.refresh.bind(this);
        this.back = this.back.bind(this);
        this.forward = this.forward.bind(this);
        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.createPDF = this.createPDF.bind(this);
        this.handleCloseLog = this.handleCloseLog.bind(this);
        this.handleCloseAdvise = this.handleCloseAdvise.bind(this);
        this.cleanForceRefresh = this.cleanForceRefresh.bind(this);
    }
    
    cleanForceRefresh(){
        this.props.cleanForceRefresh(false);

    }
    
	handleCloseAdvise() {      
		this.setState({
			showAdvice: false,
		});
	}
	
	handleCloseLog() {      
		this.setState({
			showLOG: false,
		});
	}
	
    zoomOut(e) {
        let zoom = parseFloat(this.state.zoom);
        if(zoom > parseFloat(0.5))
            zoom = parseFloat(zoom) - parseFloat(0.5);
        try {
            zoom = zoom.toFixed(2);
        }
        catch (e) {
            console.log("zoomOut error:" + zoom);

            zoom = 1.0;
        }
        this.setState({
            zoom: zoom
        })
        
    }
    zoomIn(e) {
        let zoom = parseFloat(this.state.zoom);
        if(zoom < parseFloat(5))
            zoom = parseFloat(zoom) + parseFloat(0.5);
        try {
            zoom = zoom.toFixed(2);
        }
        catch (e) {
            console.log("zoomIn error:" + zoom);
            zoom = 1.0;
        }            
        this.setState({
            zoom: zoom
        })
    }    

    refresh(e) {
        this.createPDF(this.props.itemId, this.props.items, this.props.language, this.props.templateId, this.props.quality);
    }    
    
    back(e) {
        var pageNumber = this.state.pageNumber;
        if(pageNumber > 1)
            pageNumber--;
        this.setState({
            pageNumber: pageNumber
        })
    }

    forward(e) {
        var pageNumber = this.state.pageNumber;
        if(pageNumber < this.state.numPages)
            pageNumber++;
        this.setState({
            pageNumber: pageNumber
        })
    }

    componentDidUpdate() {


		let showAdvice = this.state.showAdvice;
		if(this.state.isLoaded !== true || this.props.planingFirstOpen === false) {
			if(this.props.forceRefresh === true) {
				showAdvice = true;
			}
	        if(this.props.quality != this.state.quality || this.props.planingFirstOpen != this.state.planingFirstOpen || this.props.items != this.state.items || this.props.itemId != this.state.itemId || this.props.language != this.state.language || this.props.templateId != this.state.templateId) {
		        if(this.props.preview != undefined && this.props.preview != "" && this.props.templateId == 1) {
			        let obj = {};
			        obj.url = "data:application/pdf;base64," + this.props.preview;
			        obj.file= "data:application/pdf;base64," + this.props.preview;
			        obj.result = "success";
			       	obj.filename = "preview.pdf";
			        this.setState({
			            isLoaded : true,
			            posts: obj,
			            preview: this.props.preview,
			            quality: this.props.quality,
			          	itemId: this.props.itemId,
	            		items: this.props.items,
	            		pageNumber: 1,
	            		templateId: this.props.templateId,
	            		language: this.props.language,
	            		showAdvice: showAdvice,
	            		planingFirstOpen: this.props.planingFirstOpen
			        });        
				}
				else if(this.state.planingFirstOpen === false) {
					this.createPDF(this.props.itemId, this.props.items, this.props.language, this.props.templateId, this.props.quality);
				}
				else {
					this.setState({
						planingFirstOpen: this.props.planingFirstOpen,
				        isLoaded : true,
				 	}); 				
				}
			}			
		}
		else if(this.props.forceRefresh === true && this.state.forceRefresh === false) {
			this.setState({
		            isLoaded : true,
				showAdvice: showAdvice,
				planingFirstOpen: this.props.planingFirstOpen
		 	}); 
		}
    }

    componentWillMount() {
        let client = new W3CWebSocket(getWSUrl() + '/gatebackend/websocketendpoint');
        client.onopen = () => {
            console.log("connect WS here");
        };
        client.onmessage = (message) => {
            let obj = JSON.parse(message.data);
            if(obj.type === "renderPDFUpdate") {
                console.log("Msg:" +  obj.message);
                this.setState({
                    noticeMsg : obj.message ,
                })     
            }

        };
        if(this.props.preview != undefined && this.props.preview != "" && this.state.planingFirstOpen == undefined) {
            console.log("step1");

	        let obj = {};
	        obj.url = "data:application/pdf;base64," + this.props.preview;
	        obj.file= "data:application/pdf;base64," + this.props.preview;
	        obj.filename = "preview.pdf";
	        obj.result = "success";
	        this.setState({
	            isLoaded : true,
	            posts: obj,
	            pageNumber: 1,
	           	preview: this.props.preview,
				timestamp: this.props.timestamp,
				timestamp_current: this.props.timestamp,
				planingFirstOpen: this.props.planingFirstOpen,  				         	
	        });        
		}
		else if(this.props.planingFirstOpen == false) {
            console.log("step2");
	        this.setState({
	            isLoaded: false,
				
	        });        
			
        	this.createPDF(this.state.itemId, this.state.items, this.state.language, this.props.templateId, this.props.quality);
        }
    }
    
    createPDF(itemId, items, language, templateId, quality) {
		
        this.setState({
			showAdvice: false,
			pageNumber: 1,
            isLoaded : false,
            quality: quality,
            itemId: itemId,
            items: items,
            language: language,
            templateId: templateId,
            timestamp: this.props.timestamp
        });
		if(templateId == undefined )
        	templateId = 1;
        if(items == undefined )
        	items = -1;
        if(language == undefined )
        	language = "de";
        let sItemId = "?itemId="+itemId ;
        console.log("createPDF" + getAppUrl() + '/gatebackend/rest/content/createPDF/'+this.state.nextMask + sItemId+ "&language="+language+ "&items="+items+ "&templateId="+templateId);
        
        axios.get(getAppUrl() + '/gatebackend/rest/content/createPDF/'+this.state.nextMask + sItemId+ "&language="+language+ "&items="+items+ "&templateId="+templateId+ "&quality="+quality, 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
				console.log(res.data);
				if(res.data.result == "failed")
					alert("fehler");
                this.setState({
                    isLoaded : true,
                    posts: res.data,
                    itemId: itemId
                }) 
                this.cleanForceRefresh();
            })
            .catch((error) => {
				console.log("error");
//      			alert("fehler");
            });  
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }
    
    downloadCSV(e) {
        const linkSource = "data:application/csv;base64,"+ this.state.posts.contentCSV;
        const downloadLink = document.createElement("a");
        const fileName = "database.csv";
		downloadLink.setAttribute('target', '_blank');
        downloadLink.href = this.state.posts.contentCSV;
        downloadLink.download = fileName;
        downloadLink.click();
    }    
    
    downloadPDF(e) {
        const linkSource = "data:application/pdf;base64,"+ this.state.posts.url;
        const downloadLink = document.createElement("a");
        const fileName = this.state.posts.filename;
        downloadLink.href = this.state.posts.url;
        downloadLink.download = fileName;
        downloadLink.click();
    }    
    
    downloadTXT(e) {
		console.log(this.state.posts.messageTxt);
		if(this.state.posts.messageTxt != undefined && this.state.posts.messageTxt != "") {
	        fetch(this.state.posts.messageTxt)
	          .then(response2 => response2.text())
	          .then(response2 => {
	            this.setState({
	                showLOG: true,
	                logTXT: response2
	            })
	          })
		}
		else {
			this.setState({
	                showLOG: true,
	                logTXT: "keine Log Datei vorhanden"
	        })

		}
    }

    downloadnNWPD(e) {
        const linkSource = "data:application/x-binary;base64,"+ this.state.posts.filenameNWPD;
        const downloadLink = document.createElement("a");
        const fileName = "document.nwpd";

        downloadLink.href = this.state.posts.filenameNWPD;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    
    render() {
        const {isLoaded, posts} = this.state;
        const { pageNumber, numPages, zoom } = this.state;


        if(!isLoaded) {
            return (<>
                    <div class="Vivacardheader"></div>
                    <div>    
                        <div style={{width: "50%", margin: "0 auto"}}><Loader/><br/>{this.state.noticeMsg}</div>
                    </div>
                    </>)
        }
        else if(this.state.planingFirstOpen) {
			return (
                <>     
                    <div class="Vivacardheader">
					</div>	
                    <div>    
                        <div style={{width: "50%", margin: "0 auto"}}>
                            First open Planing<br/>
                            
                        </div>
                    </div> 					
				</>
			)
		}
        else {
            return (
                <>     
                    <div class="Vivacardheader">
                        <Button
                            type="button"
                            className="buttonIcon"
                            onClick={((e) => this.refresh(e))}
                            style={{
                                verticalAlign: 'middle',
                            }}
                            >
                            <span className="fa fa-refresh"></span>
                        </Button>	
                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.back(e))}>
                            <span className="fa fa-backward"></span>
                        </Button> 
                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.forward(e))}>
                            <span className="fa fa-forward"></span>
                        </Button>                        
                            Seite {pageNumber} von {numPages} 
                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.zoomOut(e))}>
                            <span className="fa fa-search-minus"></span>
                        </Button> 
                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.zoomIn(e))}>
                            <span className="fa fa-search-plus"></span>
                        </Button>   
                        <span className="fa fa-search-plu">{zoom}</span>    
                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.downloadTXT(e)}>
                            <span className="fa fa-file-text"></span>
                        </Button>   
                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.downloadCSV(e)}>
                            <span className="fa fa-database"></span>
                        </Button>                           
                        {posts.result === "success" ? (
                            <>
	                            <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.downloadPDF(e)}>
	                                <span className="fa fa-file-pdf-o"></span>
	                            </Button>   
	                            {posts.filenameNWPD != undefined ? (
		                            <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.downloadnNWPD(e)}>
		                                <span className="fa fa-download"></span>
		                            </Button>     
		                         ): (
			                            <></>
			                        )}
                            </>                    
                        ) : (
                            <></>
                        )}
                    </div>   
                    {posts.result === "success" ? (                        
                        <Paper>
                            <div style={{maxHeight: "800px", overflow: "scroll"}}>
                                <Document
                                    file={posts.url}
                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                >
                                    <Page pageNumber={pageNumber} scale={zoom} width={800}  />
                                </Document>
                            </div>
                        </Paper>
                    ) : (posts.result === "failed" ? (
	                        <>
	                            <div>    
				                    <div class="Vivacardheader"></div>
				                    <div>    
				                        <div style={{width: "50%", margin: "0 auto"}}>Fehler<br/>{this.state.noticeMsg}</div>
				                    </div>
	                            </div>                        
	                        </>
	                       ) : (
	                            <div>    
				                    <div class="Vivacardheader"></div>
				                    <div>    
				                        <div style={{width: "50%", margin: "0 auto"}}><Loader/><br/>{this.state.noticeMsg}</div>
				                    </div>
	                            </div>                        
		
							)
                    )}
                    
                	<Dialog open={this.state.showAdvice} 
                            onClose={this.handleCloseAdvise} 
                            smallWidth
                            maxWidth='lg'
                            aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.props.t('Hinweis')}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									PDF nicht mehr aktuell.<br/>
									<Button
			                            type="button"
			                            className="buttonIcon"
			                            onClick={((e) => this.refresh(e))}
			                            style={{
			                                verticalAlign: 'middle',
			                            }}
			                            >
			                            <span className="fa fa-refresh">aktualisieren</span>
			                        </Button>
								</DialogContentText>

							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleCloseAdvise} color="primary">
									{this.props.t('OK')}
								</Button>
							</DialogActions>
					</Dialog>

                    <Dialog open={this.state.showLOG} 
                            onClose={this.handleCloseLog} 
                            fullWidth
                            maxWidth='lg'
                            aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.props.t('LogFile VivaNWP')}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									{this.props.t('Ausgabe')}
								</DialogContentText>
							    <textarea style={{ width: "100%", height: "300px" }}>
                                    {this.state.logTXT}
                                </textarea>			

							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleCloseLog} color="primary">
									{this.props.t('OK')}
								</Button>
							</DialogActions>
					</Dialog>
                </>
        );

    }
    }
}


export default withTranslation() (PDFDisplay);