import React, { Component } from 'react';
import axios from 'axios';
import {getAppUrl} from '../helpers/Defaults';
import {getToken} from '../helpers/Token';
import {Tabs, Tab} from 'react-bootstrap';
import {Modal} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { useSnackbar, withSnackbar } from 'notistack';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import CreateNew from './blocks/CreateNew';

class Groups extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            show: false,
            currentTableId: 0,
            offset: 0,
            isLoaded : false,
            productData: [],
            currentSearch: ""
        };
        this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleStateChange = this.handleStateChange.bind(this);
        this.reload = this.reload.bind(this);
        this.createNew = this.createNew.bind(this);
        this.addNewEntry = this.addNewEntry.bind(this);
        this.handleCloseUser = this.handleCloseUser.bind(this);
        this.change = this.change.bind(this);
        this.handleCloseOK = this.handleCloseOK.bind(this);
        this.remove = this.remove.bind(this);
        this.searchChange = this.searchChange.bind(this);

    }

    searchChange(e) {
        this.setState({
			currentSearch: e
		});
    }        


    remove(id) {
        let group = this.state.group;
        for( var i = 0; i < group.users.length; i++){ 
            if ( group.users[i].id === id) {
                group.users.splice(i, 1); 
                i--;
            }
        }
        this.setState({
            group : group
        })
    }

    createNew(newName) {
        let group = {};
        group.id = -1;
        group.name = newName;
        axios.post(getAppUrl() + '/gatebackend/rest/settings/groups/add', 
            group
        ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
            this.props.enqueueSnackbar(this.props.t('Gruppe hinzugefügt'), { variant: 'info' });
            this.reload();
        }
            
        )
        .catch((error) => {
            console.log(error);
            alert("error");
        });        

    }

    handleCloseOK() {
        let group = this.state.group;
        axios.post(getAppUrl() + '/gatebackend/rest/settings/groups/change', 
            group
        ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
            this.props.enqueueSnackbar(this.props.t('Daten geändert'), { variant: 'info' });
            this.reload();
        }
            
        )
        .catch((error) => {
            console.log(error);
            alert("error");
        });      
    }

	reload() {
        axios.get(getAppUrl() + '/gatebackend/rest/settings/groups', 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.setState({
                    isLoaded : true,
                    show: false,
                    open: false,
                    posts: res.data
                })   
            });
	}

	handleStateChange() {
		;
    }
    
    change(e) {
        var group = this.state.group;
        group.name = e.target.value;
        this.setState({
            group : group
        })
    }

    componentDidMount() {
        this.reload();
    }

    handleListItemClick(value) {
        let group = this.state.group;
        let user = {};
        user.id = value.id;
        user.username = value.username;
        user.firstname = value.firstname;
        user.lastname = value.lastname;
        group.users.push(user);

        this.setState({ open:false });
    }

    handleCloseUser() {
        this.setState({ open: false });
    }

    addNewEntry() {
        this.setState({ open: true });
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow(e) {
        let currentTableId = e.currentTarget.dataset.id;
        let currentTableName = e.currentTarget.dataset.tablename;
        axios.get( getAppUrl() + '/gatebackend/rest/settings/groups/' + currentTableId, 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                console.log(res.data);
                this.setState({
                    show: true,
                    currentTableId: currentTableId,
                    currentTableName: currentTableName,
                    group: res.data
                })   
        });
    }

    render() {
        const {isLoaded, posts} = this.state;
        if(!isLoaded) {
            return (<div>loading</div>)
        }
        else {
            let elHeight = document.getElementById('actionFrame').clientHeight -100;
            var height = {
                'max-height': elHeight + 'px',
            };
            return (
                <div>
                    <div class="Vivacard" style={height}>
                        <div class="Vivacardheader">
                           Benutzergruppen
                           <CreateNew searchChange={this.searchChange} actionNew={this.createNew} title="Neuer Gruppenname"/>

                        </div>
                        <div class="Vivacardbody">
                            <div class="border">
                                <div class="row clearmargin"  align="start">
                                    <div class="grid-Cell grid-Cell-Header col-sm-1">
                                        <div class="grid-Cell__header "> 
                                            ID
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm">
                                        <div class="grid-Cell__header"> 
                                            Name
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            Bearbeiten
                                        </div>
                                    </div>
                                </div>
                                {
                                    posts.map(post => (
                                        <>
                                        {this.state.currentSearch === "" || ((post.id).toString().includes(this.state.currentSearch) ||  post.name.toLowerCase().includes((this.state.currentSearch).toLowerCase())) ? (

                                        <div class="row clearmargin grid-Row" data-id={post.id} data-tablename={post.name}  onXYDoubleClick={((e) => this.handleShow(e))} align="start">
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value" > 
                                                    {post.id}
                                                </div>
                                            </div>
                                            <div class="grid-Cell col-sm"> 
                                                <div class="grid-Cell__value"> 
                                                    {post.name}
                                                </div>
                                            </div> 
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value"> 
                                                    <ul>  
														<li data-id={post.id} data-tablename={post.name} class="fa fa-pencil gridIcon" onClick={((e) => this.handleShow(e))}>

                                                        </li>                                                    
                                                    </ul>
                                                </div>
                                            </div>                                                                                    
                                        </div>
                                        ) : (<></>)}
                                        </>                                        
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Gruppe: {this.state.currentTableName}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body class="modalBody">
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={this.state.key}
                                onSelect={key => this.setState({ key })}
                            >
                                <Tab eventKey="fields" title={this.props.t('Einträge')}>

                                    <div className="maskfieldsArea">
                                        <div className="row clearmargin maskFieldRow"  align="start">
                                            <div className="grid-Cell grid-Cell-Header maskField_200" >
                                                <div className="grid-Cell__header "> 
                                                    {this.props.t('Username')}
                                                </div>
                                            </div>
                                            <div className="grid-Cell grid-Cell-Header maskField_250">
                                                <div className="grid-Cell__header"> 
                                                    {this.props.t('Vorname')}
                                                </div>
                                            </div>									
                                            <div className="grid-Cell grid-Cell-Header maskField_250">
                                                <div className="grid-Cell__header"> 
                                                    {this.props.t('Nachname')}
                                                </div>
                                            </div>									
                                            <div className="grid-Cell grid-Cell-Header maskField_100">
                                                <div className="grid-Cell__header"> 
                                                    {this.props.t('Bearbeiten')}
                                                </div>
                                            </div>									
                                        </div>
                                    </div>
                                    {this.state.group && this.state.group.users ? (
                                        <>
                                    { this.state.group.users.map(user => (                                    
                                        <div className="row clearmargin maskFieldRow"  align="start">
                                            <div className="grid-Cell  maskField_200" >
                                                <div className="grid-Cell__header "> 
                                                    {user.username}
                                                </div>
                                            </div>
                                            <div className="grid-Cell  maskField_250">
                                                <div className="grid-Cell__header"> 
                                                    {user.firstname}
                                                </div>
                                            </div>									
                                            <div className="grid-Cell  maskField_250">
                                                <div className="grid-Cell__header"> 
                                                    {user.lastname}
                                                </div>
                                            </div>									
                                            <div className="grid-Cell  maskField_100">
                                                <div className="grid-Cell__header"> 
                                                    <i class="fa fa-trash-o" onClick={e => this.remove(user.id)}></i>
                                                </div>
                                            </div>
                                        </div>
                                        ))
                                    }
                                    </>) : (<></>)
                                    }
                                    <Button variant="secondary" onClick={this.addNewEntry} style={{border: "1px solid #a0a0a0", margin: "10px"}}>
                                        <i class="fa fa-plus-circle"></i>&nbsp;
                                        {this.props.t('hinzufügen')}
                                    </Button>
                                </Tab>
                                <Tab eventKey="settings" title="Einstellungen">
                                    <div class="Vivacard">
                                        <div class="Vivacardheader">
                                            Allgemein                                
                                        </div>
                                        <div class="border">                               
                                            <div className="row clearmargin "  align="start">
                                                <div className="col-sm-2 ">
                                                    ID: 
                                                </div>
                                                <div className="col-sm">
                                                    {this.state.currentTableId}
                                                </div>
                                            </div>
                                            <div className="row clearmargin "  align="start">
                                                <div className="col-sm-2 ">
                                                    Name: 
                                                </div>
                                                <div className="col-sm">
                                                {this.state.group ? (
                                                    <>
                                                        <input maxlength="30" 
                                                                size="30" 
                                                                type="text" 
                                                                name="groupName" 
                                                                onChange={e => this.change(e)} 
                                                                value={this.state.group.name} />
                                                    </>) : (<></>)
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>

                            </Tabs>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                            Abbruch
                            </Button>
                            <Button variant="primary" onClick={this.handleCloseOK}>
                            Speichern
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Dialog onClose={this.handleCloseUser} 
                            aria-labelledby="simple-dialog-title" 
                            open={this.state.open}
                            style={{zIndex: "4000"}}>
                        <DialogTitle id="simple-dialog-title">Benutzer auswählen</DialogTitle>
                        <List>
                            {this.state.group && this.state.group.musers ? (
                                <>
                            { this.state.group.musers.map(user => (
                            <ListItem button onClick={() => this.handleListItemClick(user)} key={user}>
                                <ListItemAvatar>
                                <Avatar className="classesAvatar">
                                    <PersonIcon />
                                </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={user.firstname + " " + user.lastname} />
                            </ListItem>
                            ))}
                            </>) : (<></>)
                            }

                        </List>
                        </Dialog>


                </div> 
            );
       }
    }
}

export default withTranslation()(withSnackbar(Groups)) ;
