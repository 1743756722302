import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Link} from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import axios from 'axios';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {getAppUrl} from '../../helpers/Defaults';
import {getToken} from '../../helpers/Token';
import { Dropdown } from 'react-bootstrap';
class Access extends Component {


    constructor(props) {

        super(props);
        this.state = {
            posts: [],
            anchorElFlag: null,
            setAnchorElFlag: null,
            newValue: "",
            open : false
        };
        this.handleClickFlag = this.handleClickFlag.bind(this);
        this.handleCloseFlag = this.handleCloseFlag.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.createNew = this.createNew.bind(this);
        this.change = this.change.bind(this);
    }

    change(id, value) {
        var access = this.state.posts;

        if(value === "forbidden")
            value = "readOnly";
        else if(value === "readOnly")
            value = "write";
        else if(value === "write")
            value = "inherit";
        else if(value === "inherit")
            value = "forbidden";
        access.map(function(ac, j) {
            if(ac.id ===  parseInt(id, 10)) {
                ac.right = value;
            }
        }
        );
        this.setState({
            posts : access
        })  
                
    }

    createNew(e) {
        let access = this.state.posts;

        axios.post(getAppUrl() + '/gatebackend/rest/settings/access/'+ this.props.type +'/' + this.props.id, 
            access
        ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
            this.props.enqueueSnackbar(this.props.t('Rechte angepasst'), { variant: 'info' });
            this.setState({
                open: null
            })
        }
            
        )
        .catch((error) => {
            console.log(error);
            alert("error");
        });

    }

   componentDidMount() {
        axios.get(getAppUrl() + '/gatebackend/rest/settings/access/'+ this.props.type +'/' + this.props.id, 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.setState({
                    isLoaded : true,
                    posts: res.data
                })   
            });
    }

    handleClickFlag(event) {
        this.setState({
            anchorElFlag: event.currentTarget,
        })
    }

    handleCloseFlag() {
        this.setState({
            anchorElFlag: null
        })
    }

    handleClose() {
        this.setState({
            open: null
        })
    }

    handleNew(kind) {
        this.setState({
            anchorElFlag: null,
            open: true
        });
    }

    render() {
        const {isLoaded, posts} = this.state;
        return (
            <>          
                <span class="">
                    <Button className="buttonIcon" aria-controls="simple-menuFlag" aria-haspopup="true" onClick={this.handleNew}>
                        <span class="fa fa-unlock-alt"></span>
                    </Button>
                </span>
                <Dialog fullWidth="sm"
                        maxWidth="sm" 
                        className="zInd" 
                        open={this.state.open} 
                        onClose={(e) => this.handleClose(e)} 
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        {this.props.t('BenutzerRechte')} {this.props.label}
                    </DialogTitle>
                    <DialogContent style={{minHeight: "360px", minWidth: "240px"}}>
                        <div class="row clearmargin"  align="start">
                            <div class="grid-Cell grid-Cell-Header maskField_300">
                                <div class="grid-Cell__header "> 
                                    {this.props.t('Gruppe')}
                                </div>
                            </div>
                            <div class="grid-Cell grid-Cell-Header maskField_250">
                                <div class="grid-Cell__header"> 
                                    {this.props.t('Zugriff')}
                                </div>
                            </div>
                        </div>
                        {posts.map(post => (
                                <div class="row clearmargin grid-Row" data-id={post.id} data-tablename={post.name}  onXYDoubleClick={((e) => this.handleShow(e))} align="start">
                                    <div class="grid-Cell maskField_300"> 
                                        <div class="grid-Cell__value" > 
                                            {post.name}
                                        </div>
                                    </div>
                                    <div class="grid-Cell maskField_250"> 
                                        <div class="grid-Cell__value"> 
                                            <Button onClick={(e) => this.change(post.id, post.right)} 
                                                    color="primary"
                                                    style={{border: "1px solid rgb(160, 160, 160)", margin: "3px"}}>
                                                {post.right}
                                            </Button>
                                        </div>
                                    </div> 
                                                                            
                                </div>
                            ))
                        }


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(e) => this.handleClose(e)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={(e) => this.createNew(e)} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>  
            </>          
        );
    
    }
}


export default withTranslation()(withSnackbar(Access)) ;