import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import {getToken} from '../../helpers/Token';
import {getAppUrl} from '../../helpers/Defaults';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class MessageBlock extends Component {

constructor(props) {
        super(props);
        this.state = {
            posts: [],
            isLoaded : false,

        };
    }
    componentWillMount() {
        axios.get(getAppUrl() + '/gatebackend/rest/content/getMessages', 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.setState({
                    isLoaded : true,
                    posts: res.data
                }) 

            })
            .catch((error) => {
       //         localStorage.removeItem("app-token");
         //       this.props.history.push("/login");
            });
    }

    render() {
        const {isLoaded, posts} = this.state;
        if(!isLoaded) {
            return (<div>loading</div>)
        }
        else {
            console.log(posts);
            return (
                <Paper>
                    <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Tabelle Produkte</Typography>
                            </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {posts.map(post => (
                                <Typography>
                                    {post.type} 
                                </Typography>                        
                            ))}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                </Paper>
        );
    }
    }
}


export default MessageBlock;