import React, { Component } from 'react';
import axios from 'axios';
import AppBar from '@material-ui/core/AppBar';
import {Tabs, Tab} from 'react-bootstrap';
import {getAppUrl} from '../helpers/Defaults';
import {getToken} from '../helpers/Token';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import VivaGrid from './VivaGrid'
import {Modal} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import CSVDisplay from './CSVDisplay';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { useSnackbar, withSnackbar } from 'notistack';
import PDFDisplay from './blocks/PDFDisplay';
import ExportFields from './ExportFields.js';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import QueryDetail from './QueryDetail.js';
import Loader from './blocks/Loader'
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import VivaMaskEingabe from './VivaMaskEingabe';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import SortableTree, { addNodeUnderParent, removeNodeAtPath, changeNodeAtPath, getNodeAtPath, toggleExpandedForAll, defaultSearchMethod, map as mapTree } from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import CustomNodeContentRenderer from './blocks/CustomNode/CustomNodeContentRenderer';
import VivaGrid2 from './VivaGrid';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/core/Menu';
import {Route, Link } from "react-router-dom";


const contentShow = [
  {
	"id":"showAll",
  	"label":"Alles anzeigen"
  },
  {
	"id":"showDescription",
  	"label":"Beschreibung anzeigen"
  },
  {
	"id":"showAdvPoints",
  	"label":"Vorteilspunkte anzeigen"
  },
  {
	"id":"showPictures",
  	"label":"Bilder anzeigen"
  },
  {
	"id":"showTechnicalDetails",
  	"label":"Technische Details anzeigen"
  },
  {
	"id":"showPrice",
  	"label":"Preise anzeigen"
  }
  
];

function generateSmallNodeMask(contentResultNodeMask, itemId, location, withName) {
	let sResult = "";
	contentResultNodeMask.mask.maskfields.map(function(mField) {
		if(mField.gravity === location) {
			if(withName)
				sResult += mField.name + ":";
			contentResultNodeMask.contentResult.gateContent.map(function(gC) {
				if(gC.itemID === itemId) {
					gC.contentFields.map(function(fC) {
						if(mField.elementValue == fC.fieldId) {
							if(mField.gravity === "bottom")
								sResult += fC.plainContent + " | ";
							else
								sResult += fC.plainContent;
						}
					})
				}
			});
		}
	});
	return sResult;
}

function searchItemValue(contentResultNodeMask, itemId, field) {
	let sResult = "";
	contentResultNodeMask.mask.maskfields.map(function(mField) {
		contentResultNodeMask.contentResult.gateContent.map(function(gC) {
			if(gC.itemID === itemId) {
				gC.contentFields.map(function(fC) {
					if(field == fC.fieldId) {
						sResult = fC.plainContent;
					}
				})
			}
		});
		
	});
	return sResult;
}

function getFormDetail(forms,fname) {
	let e = {};
	forms.map(function(form) {
		if(form.name == fname) {
			e.width = form.width;
			e.height = form.height;
		}
	})
	return e;
}

function generatePlanningMap(treeData, forms) {
	let page = {};
	let planningMap = [];
	let pageHeight = 0;
	let pageWidth = 0;
	let pageSide = "left";
	treeData.map(function(node) {
		if(node.type == "sub") {
			node.children.map(function(node1) {
				if(node1.type == "item") {
					let items = [];
					let elem = {};
					node1.children.map(function(node2) {
						if(node2.type == "plItem") {
							elem = {};
							elem.form = node2.viva_form;
							let fD = getFormDetail(forms, node2.viva_form);
							elem.width = fD.width;
							elem.height = fD.height;
							elem.dbId = node2.dbId;
							let a = [];
							if(node2.viva_form != null && node2.viva_form != "")
								a = node2.viva_form.split(" ");
							let b = "";
							let v = "";
							if(a.length == 2) {
								b = a[0];
								v = a[1];
							}	
							elem.formVersion = v;						
							if(pageHeight == 0) {
								items.push(elem);
								pageHeight += fD.height;
							}							
							else if(pageHeight + elem.height < 100) {
								items.push(elem);
								pageHeight += fD.height;
							}
							else if (pageHeight + elem.height > 100) {
								page.side = pageSide;
								page.header = node1.subtitle;
								page.width = 240;
								page.height = 320;
								page.items = items;
								planningMap.push(page);	
								if(pageSide == "left")
									pageSide = "right";
								else	
									pageSide = "left";
								page = {};	
								items = [];
								pageHeight = elem.height;	
								items.push(elem);				
							}

						}
					})
					if(items.length > 0) {
						items.push(elem);
						page.side = pageSide;
						page.header = node1.subtitle;
						page.width = 240;
						page.height = 320;
						page.items = items;
						planningMap.push(page);	
						if(pageSide == "left")
							pageSide = "right";
						else	
							pageSide = "left";
						page = {};	
						items = [];
						pageHeight = 0;	
					}					
				
				}
			})
			
		}
	})
	return planningMap;
}

function setconvertJSON(c, t, l) {
	let tJ = JSON.parse(c);
	tJ[l].content = t;
	return JSON.stringify(tJ);
}

function convertJSON(c, l)  {
	let tJ = JSON.parse(c);
	return  tJ[l].content;
}

function getTdt() {
	var currentdate = new Date(); 
	var datetime = "Time: " + currentdate.getDate() + "/"
 
                + currentdate.getHours() + ":"  
                + currentdate.getMinutes() + ":" 
				+ currentdate.getSeconds();
				return datetime;
}

function generateTree(plElements, showSubPlaning, forceRefresh) {
			let treeData = [];
			let  firstexpand= true;
			if(plElements.planingElemets !== undefined)
				plElements.plElemente = plElements.planingElemets;

			plElements.plElemente.map(function(plElement) {
				let elem = {};
				elem.isSelected = false;
				elem.expanded = firstexpand;
				elem.link= "";
				elem.merge= plElement.merge;
				elem.type= plElement.type;
				elem.planungsTypId= plElement.planungsTypId;
				elem.class=plElement.type;
				elem.subPlaning = plElement.subPlaning;
				elem.subPlaningDetail = plElement.subPlaningDetail;
				elem.export = plElement.export.id;
				elem.title= plElement.subPlaningLabel;
				elem.plId = plElement.id;
				elem.startPage = plElement.startPage;
				elem.endPage = plElement.endPage;
				elem.maskId = plElement.maskId;
				elem.nodeMaskId = plElement.nodeMaskId;
				elem.preview = plElement.preview;
				elem.queryId = plElement.query;
				elem.forceRefresh = forceRefresh;
				elem.subTitle= plElement.type + plElement.id;
				firstexpand = false;
				if(plElement.type === "sub")
					elem.children = generateTree(plElement, showSubPlaning, forceRefresh);
				else if(plElement.type === "query") {
					
					elem.children = generateTree(plElement, showSubPlaning, forceRefresh);
				}
				else if(plElement.type === "item" || plElement.type === "table") {
					

					let childs = [];
					if(plElement.subPlaningDetail !== undefined && showSubPlaning === true) {
						let elem1 = {};
						elem1.expanded = false;
						elem1.isSelected = false;
						elem1.link= "";
						elem1.merge= plElement.subPlaningDetail.merge;
						elem1.subPlaning = plElement.subPlaning;
						elem1.type = "sub";
						elem1.class = "";
						elem1.export = plElement.export.id;
						elem1.title= plElement.subPlaningDetail.label;
						elem1.subTitle= "";
						elem1.forceRefresh = forceRefresh;
						elem1.children = generateTree(plElement.subPlaningDetail, showSubPlaning, forceRefresh);
						childs.push(elem1);
					}
					else {
						elem.subtitle =  convertJSON(plElement.zusatz1, "de");
						elem.sublabel =  convertJSON(plElement.zusatz1, "de");
						elem.sublabelJson =  plElement.zusatz1;
						elem.plElementId = plElement.id;
						elem.zusatz2 = plElement.zusatz2;
						elem.zusatz3 = plElement.zusatz3;
						elem.preview = plElement.preview;
						elem.forceRefresh = forceRefresh;


					}
					let prChilds = [];
					plElement.contentResultMask.contentResult.gateContent.map(function(result) {
						let refId = searchItemValue(plElement.contentResultNodeMask, result.itemID,840);
						if(refId == 0)
							prChilds = [];
						let elem1 = {};
						elem1.isSelected = false;
						elem1.link= "";
						elem1.type = "plItem";
						elem1.refId = refId;
						elem1.itemID = result.itemID; 
						elem1.classColumns = result.classColumns;
//						if(result.classColumnsSelect) {
							
							elem1.classColumnsSelect = result.classColumnsSelect;
							elem1.preview = undefined;
							elem1.artnr= searchItemValue(plElement.contentResultNodeMask, result.itemID,98);
	
	//						elem1.title= result.dbId + generateSmallNodeMask(plElement.contentResultNodeMask, result.itemID, "top", false);
							elem1.title= generateSmallNodeMask(plElement.contentResultNodeMask, result.itemID, "top", false);
							elem1.viva_form = searchItemValue(plElement.contentResultNodeMask, result.itemID,823);
							elem1.viva_formz1 = searchItemValue(plElement.contentResultNodeMask, result.itemID,837);
							elem1.viva_formz2 = searchItemValue(plElement.contentResultNodeMask, result.itemID,839);
							elem1.viva_formz3 = searchItemValue(plElement.contentResultNodeMask, result.itemID,866);
							elem1.viva_formz4 = searchItemValue(plElement.contentResultNodeMask, result.itemID,867);
							elem1.viva_layout = searchItemValue(plElement.contentResultNodeMask, result.itemID,842);
							elem1.viva_relationship = searchItemValue(plElement.contentResultNodeMask, result.itemID,841);
							elem1.viva_formst_preis = searchItemValue(plElement.contentResultNodeMask, result.itemID,843);
							elem1.viva_markeLogo = searchItemValue(plElement.contentResultNodeMask, result.itemID,874);
							
							elem1.viva_formst_neu = searchItemValue(plElement.contentResultNodeMask, result.itemID,844);
							elem1.viva_formst_text1 = searchItemValue(plElement.contentResultNodeMask, result.itemID,845);
							elem1.viva_formst_text2 = searchItemValue(plElement.contentResultNodeMask, result.itemID,846);
							elem1.viva_formst_text3 = searchItemValue(plElement.contentResultNodeMask, result.itemID,847);
							elem1.viva_formst_seitenmotiv = searchItemValue(plElement.contentResultNodeMask, result.itemID,900);
							elem1.viva_anzahl_bilder = searchItemValue(plElement.contentResultNodeMask, result.itemID,901);
	
							elem1.viva_formst_textValue1 = searchItemValue(plElement.contentResultNodeMask, result.itemID,868);
							elem1.viva_formst_textValue2 = searchItemValue(plElement.contentResultNodeMask, result.itemID,869);
							elem1.viva_formst_textValue3 = searchItemValue(plElement.contentResultNodeMask, result.itemID,870);	
	
							elem1.viva_formst_textForm1 = searchItemValue(plElement.contentResultNodeMask, result.itemID,871);
							elem1.viva_formst_textForm2 = searchItemValue(plElement.contentResultNodeMask, result.itemID,872);
							elem1.viva_formst_textForm3 = searchItemValue(plElement.contentResultNodeMask, result.itemID,873);	
	
							elem1.viva_formst_iconPosition = searchItemValue(plElement.contentResultNodeMask, result.itemID,848);
							elem1.viva_pagebreak = searchItemValue(plElement.contentResultNodeMask, result.itemID,854);
							elem1.viva_tableDisplay = searchItemValue(plElement.contentResultNodeMask, result.itemID,877);
							elem1.viva_columnGroup = searchItemValue(plElement.contentResultNodeMask, result.itemID,878);
							let dummy = searchItemValue(plElement.contentResultNodeMask, result.itemID,890);
							elem1.viva_columnSelect = [];
							if(dummy != undefined && dummy != "") {
								let x  = dummy.split(',');	
								for (let i0 = 0; i0 < x.length; i0++) {
									elem1.viva_columnSelect.push(parseInt(x[i0], 10));
								}
							}
							elem1.preview = undefined;
							elem1.forceRefresh = forceRefresh;
		
							if(result.prevImage !== null) {
								elem1.image= result.prevImage;
								elem1.class = ""; //plElement.contentResultMask.contentResult.meta.defaultIcon;
							}
							else {
								elem1.image= undefined;
								elem1.class = plElement.contentResultMask.contentResult.meta.defaultIcon;
							}
							elem1.subtitle = generateSmallNodeMask(plElement.contentResultNodeMask, result.itemID, "bottom", true);
							elem1.subTitle= result.itemID;
							elem1.dbId = result.dbId;
							if(refId == 0) {
								elem1.children = prChilds
								childs.push(elem1);
							}
							else
								prChilds.push(elem1);
					//	}
					});
					elem.children = childs;
				}
				else 
					elem.children = [];

				elem.noDragging = false;				
				elem.id = plElement.id;

				treeData.push(elem);
			});	
		
		return 	treeData;
	}


function isValueSelected(values, whichId) {

	let vals = makeArray(values);
	let displayValue = false;


	vals.map(function(val) {
		if(val == whichId)
			displayValue = true;
	});
	return displayValue;
}

function displayArrayValues(values) {
	let vals = makeArray(values);
	let displayValue = "";

	contentShow.map(function(selectValue) {
		vals.map(function(val) {
			if(val == selectValue.id)
				displayValue += selectValue.label + " ";
		});
	});
	if(displayValue.length > 20)
		return displayValue.substr(0,20) + "...";
	else	

		return displayValue;
}

function makeArray(val) {
	if(val == null)
		return [];
	if (typeof val === 'string') {
		let x = [];
		let p = val.split(',');
		p.map(function(o) {
			x.push(parseInt(o));
		});
		return x;
	}
	else
		return val;
	
}




class PlaningDetails extends Component {

    constructor(props) {
        super(props);
        let timestampCurrent = Date.now();
        this.state = {
			showSubPlaning: false,
			showEditPLElement: false,
			oldNode: undefined,
			oldPath: undefined,
			oldTreeIndex: undefined,			
			show: false,
			showLayout: false,
			exportShow: false,
			showQuery: false,
			exportStatus: 0,
			currentTableId: 0,
			currentExportId: 0,
			currentPlaningId: 0,
			queryId: 0,
			currentExportName: "",
			offset: 0,
			tabValue: 0,
			tabValueOverview: 0,
			isLoaded : false,
			ItemsDialog: false,
			productData: [],
			masks: [],
			planingElementId: 0,
			previewDocument: {},
			currentHighlightItemId: -1,
			currentPlaning: {},
			exportPlaning: false,
			waitCursor: true,
			bookmarklabel: "",
			bookmarkLinks: [],
			forms: [],
			planungstypen: [],
			bookmarkfolderId: 0,
			newElemChoice: "sub",	
			notification: false,
			elementlabel: "",
			layoutWidth: "100",
			layoutContent: "all",
			showLayoutDetail: false,
			maskEdit: false,
			currentItemID: 0,
			viva_formst_preis: "",
			viva_formst_neu: "",
			viva_formst_text1: "",
			viva_formst_text2: "",
			viva_formst_text3: "",
			viva_formst_iconPosition: "",
			viva_pagebreak: "",
			exportItem: -1,
			exportItems: -1,
			viva_formBase: "",
			viva_formVersion: "",
			PLElement_Zusatz2: "",
			PLElement_Zusatz3: "",
			formsVersion: [],
			formsBase: [],
			planningMap: [],
			exports: [],
			planings: [],
			currentPlElementId: 0,
			templateId: 1,
			itemLanguage: "de",
			itemLanguages: [],
			anchorElFlag: null,
			confirmDialog: false,
			forceRefresh: false,
			classColumns: [],
			classColumnsSelect:[], 
			viva_columnSelect: [],
			timestamp: timestampCurrent,
			timestamp_sticky: timestampCurrent,
			planingFirstOpen: true,
			quality: "LowRes",
			qualities: ["LowRes", "HighRes"],
			tabValuePlElement: 0,
			treeData: [
				{ title: 'Chicken', children: [{ title: 'Egg' }] },
				{ title: 'Fish', children: [{ title: 'fingerline'}] }
			],
		};
        this.change = this.change.bind(this);
		this.addChapter = this.addChapter.bind(this);
		this.handleShowQuery = this.handleShowQuery.bind(this);
		this.handleExportClose = this.handleExportClose.bind(this);
		this.handleExportShow = this.handleExportShow.bind(this);
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleCloseEditPLElement = this.handleCloseEditPLElement.bind(this);
		
		this.handleTabChange = this.handleTabChange.bind(this);
		this.handleTabChangePlElement = this.handleTabChangePlElement.bind(this);
		this.gotoDetail = this.gotoDetail.bind(this);
		this.handleCloseItemDialog = this.handleCloseItemDialog.bind(this);
		this.handleInfo = this.handleInfo.bind(this);
		this.setCurrentItem = this.setCurrentItem.bind(this);
		this.reloadAction = this.reloadAction.bind(this);
		this.reloadPreview = this.reloadPreview.bind(this);
		this.handleExportPlaning = this.handleExportPlaning.bind(this);
		this.handleExportPlaningDetail = this.handleExportPlaningDetail.bind(this);
		
		this.handleShowSubPlaning = this.handleShowSubPlaning.bind(this);
		this.addBookmark = this.addBookmark.bind(this);
		this.handleFunctionClick = this.handleFunctionClick.bind(this);
		this.c = this.handleDeleteItem.bind(this);
		this.handleAddSubPlaning = this.handleAddSubPlaning.bind(this);
		this.handlePrepareAddSubPlaning = this.handlePrepareAddSubPlaning.bind(this);
		this.handlePrepareAddItems = this.handlePrepareAddItems.bind(this);
		this.handleChangeNewElem = this.handleChangeNewElem.bind(this);
		this.handleMousemove.bind(this);
		this.handleNotificationClick = this.handleNotificationClick.bind(this);
		this.handlemaskEditClick = this.handlemaskEditClick.bind(this);
		this.handleAddItems = this.handleAddItems.bind(this);
		this.changeElementlabel = this.changeElementlabel.bind(this);
		this.changeElementSubLabel = this.changeElementSubLabel.bind(this);
		
		this.handleEditItemClick = this.handleEditItemClick.bind(this);
		this.handleEditPLElementClick = this.handleEditPLElementClick.bind(this);
		this.handleCloseLayout = this.handleCloseLayout.bind(this);
		this.handleChangeLayoutItem = this.handleChangeLayoutItem.bind(this);
		this.handleChangeForm = this.handleChangeForm.bind(this);
		this.handleCloseLayoutDetail = this.handleCloseLayoutDetail.bind(this);
		this.handleChangeLayoutItemDetail = this.handleChangeLayoutItemDetail.bind(this);
		this.handleSavePlaning = this.handleSavePlaning.bind(this);
		this.handlePDFItemClickDetail = this.handlePDFItemClickDetail.bind(this);
		this.handleClickFlag = this.handleClickFlag.bind(this);
		this.handleCloseFlag = this.handleCloseFlag.bind(this);	
		this.changeItemLanguage = this.changeItemLanguage.bind(this);	
		this.handleConfirmDialog = this.handleConfirmDialog.bind(this);
		this.handleSearchOnChange = this.handleSearchOnChange.bind(this);
		this.handleChangeTemplate = this.handleChangeTemplate.bind(this);
		this.handleChangeQuality = this.handleChangeQuality.bind(this);
		this.handleChangeLayoutPLElement = this.handleChangeLayoutPLElement.bind(this);
		this.handleAddLayoutPLElement = this.handleAddLayoutPLElement.bind(this);
		this.prepareLayoutPLElement = this.prepareLayoutPLElement.bind(this);
		
		this.cleanForceRefresh = this.cleanForceRefresh.bind(this);
		this.saveTree = this.saveTree.bind(this);
		this.handleChangePLDialog = this.handleChangePLDialog.bind(this);
		this.handleDuplicateElement = this.handleDuplicateElement.bind(this);
	}
	
	
	handleDuplicateElement(rowInfo) {
		this.setState({
			showEditPLElement: true,
			key: "Allgemein",
			plDialogTitle: "PlanungsElement hinzufügen",		
			rowInfo: rowInfo,
			currentNode: rowInfo.node,
			PLElement_SubLabel: rowInfo.node.sublabel + " Kopie",
			PLElement_DialogTyp: "add",
		})		
	}
	
	
	addChapter() {
		let rowInfo = {};
		let node = {};
		node.merge= 0;
		node.type= 0;
		node.planungsTypId= 0;
	//	elem.class=plElement.type;
		node.subPlaning = 0;
		node.subPlaningDetail = "";
		node.export = 0;
		node.title= "neu";
		node.plId = 0;
		node.maskId = 0;
		node.nodeMaskId = 0;
		node.queryId = 0;

		node.subTitle= "";
		
		rowInfo.node = node;

		this.setState({
			showEditPLElement: true,
			key: "Allgemein",
			plDialogTitle: "PlanungsElement hinzufügen",
			rowInfo: rowInfo,
			currentNode: node,
			PLElement_SubLabel: node.sublabel,
			PLElement_SubLabelJson: node.sublabelJson,
			PLElement_Zusatz2: node.zusatz2,
			PLElement_Zusatz3: node.zusatz3,
			PLElement_startPage: node.startPage,
			PLElement_endPage: node.endPage,
			viva_relationship: node.viva_relationship,
			PLElement_DialogTyp: "add",
		});				
	}

	handleEditPLElementClick(rowInfo) {   
		this.setState({
			showEditPLElement: true,
			key: "Allgemein",
			rowInfo: rowInfo,
			plDialogTitle: "PlanungsElement editieren",
			currentNode: rowInfo.node,
			PLElement_SubLabel: rowInfo.node.sublabel,
			PLElement_SubLabelJson: rowInfo.node.sublabelJson,
			PLElement_Zusatz2: rowInfo.node.zusatz2,
			PLElement_Zusatz3: rowInfo.node.zusatz3,
			PLElement_startPage: rowInfo.node.startPage,
			PLElement_endPage: rowInfo.node.endPage,
			viva_relationship: rowInfo.node.viva_relationship,
			PLElement_DialogTyp: "change",
		});
	}		

	handleChangePLDialog(e) {
		if(e.target.name == "subPlaning") {
			let currentNode = this.state.currentNode;
			currentNode.subPlaning =  e.target.value;
	        this.setState({
	            currentNode: currentNode
	        }); 
		}        		
		if(e.target.name == "export") {
			let currentNode = this.state.currentNode;
			currentNode.export =  e.target.value;
	        this.setState({
	            currentNode: currentNode
	        }); 
		}        		
		if(e.target.name == "nodeMaskId") {
			let currentNode = this.state.currentNode;
			currentNode.nodeMaskId =  e.target.value;
	        this.setState({
	            currentNode: currentNode
	        }); 
		}        		
		if(e.target.name == "maskId") {
			let currentNode = this.state.currentNode;
			currentNode.maskId =  e.target.value;
	        this.setState({
	            currentNode: currentNode
	        }); 
		}
		if(e.target.name == "planungsTypId") {
			let currentNode = this.state.currentNode;
			currentNode.planungsTypId =  e.target.value;
	        this.setState({
	            currentNode: currentNode
	        }); 
		}

	}

	prepareLayoutPLElement(e) { 
		let dummyJson = "{\"de\":{\"content\":\"\"},\"en\":{\"content\":\"\"}}";
		
		let plElement = {};
		plElement.plID = parseInt(this.props.match.params.planingId);		
        plElement.zusatz1 =	setconvertJSON(dummyJson, this.state.PLElement_SubLabel, "de");
		plElement.elementId = parseInt(this.state.currentNode.plId);	

		let planungstypen = this.state.planungstypen[this.state.currentNode.planungsTypId];
		let planungsTypId = this.state.currentNode.planungsTypId
		this.state.planungstypen.map(function(ex) {
			if(ex.id == planungsTypId)
				planungstypen = ex;
		});

		plElement.subPlaning = parseInt(planungstypen.def_subplannung);		
		plElement.export = parseInt(planungstypen.def_export);		
		plElement.nodeMaskId = parseInt(planungstypen.def_nodeMask);		
		plElement.maskId = parseInt(planungstypen.def_mask);		
		plElement.formId = parseInt(planungstypen.def_form);		
		plElement.queryId = parseInt(planungstypen.def_query);		
		plElement.planungsTypId = parseInt(this.state.currentNode.planungsTypId);		
		plElement.typ = planungstypen.typ;		
		
		
		if(this.state.PLElement_DialogTyp === "add")
			this.handleAddLayoutPLElement(plElement);
		else if(this.state.PLElement_DialogTyp === "change")
			this.handleChangeLayoutPLElement(plElement);
		
	}
	
	handleChangeLayoutPLElement(plElement) { 

        axios.post(getAppUrl() + "/gatebackend/rest/content/planningElement/change", 
                plElement
            ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
			this.setState({ showEditPLElement: false });
			this.props.enqueueSnackbar(this.props.t('PlanungsElement geändert'), { variant: 'info' });
			this.reload(this.props.match.params.planingId);
		}
            
        )
        .catch((error) => {
            alert("error");
        });  
         
	}	
		
	handleAddLayoutPLElement(plElement) { 

        axios.post(getAppUrl() + "/gatebackend/rest/content/planningElement/add", 
                plElement
            ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
			this.setState({ showEditPLElement: false });
			this.props.enqueueSnackbar(this.props.t('PlanungsElement hinzugefügt'), { variant: 'info' });
			this.reload(this.props.match.params.planingId);

		}
            
        )
        .catch((error) => {
            alert("error");
        });  
         
	}	
	saveTree() {
		alert("Tree save");
	}
	
	
	handleChangeTemplate(e) {
		this.setState({
			templateId: e.target.value,
			timestamp: Date.now()
		});		
	}
	
	handleChangeQuality(e) {
		this.setState({
			quality: e.target.value,
		});		
	}
	
	
	
	handleSearchOnChange(e){
    	this.setState({
             searchQuery: e.target.value,
        });
	};
	  
	changeItemLanguage(language) {
		this.setState({
			itemLanguage: language,
			anchorElFlag: null
		})
	}

	handleCloseFlag() {
		this.setState({
			anchorElFlag: null
		})
	}	
	
	handleClickFlag(event) {
		this.setState({
			anchorElFlag: event.currentTarget,
		})
	}	            
	
	handleSavePlaning() {
        axios.post(getAppUrl() + "/gatebackend/rest/settings/savePlaning", 
	       this.state.treeData,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }      
        ).then(repsonse => {
			this.props.enqueueSnackbar(this.props.t('Planung gespeichert'), { variant: 'info' });
			
        }
            
        )
        .catch((error) => {
            alert("error");
        });
        
    }

	handleAddItems() {
		alert("hier");
	}


	handlemaskEditClick(kind) {
        this.setState({
            maskEdit: kind
        })       
	}
	
	handleNotificationClick(kind) {
        this.setState({
            notification: kind
        })       
    }
    handleMousedown = e => {
        this.setState({ isResizing: true, lastDownX: e.clientX });
	};
	
	handleMousemove = e => {
        // we don't want to do anything if we aren't resizing.
        if (!this.state.isResizing) {
            return;
        }

        let offsetRight =
        document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        let minWidth = 400;
        let maxWidth = 1400;
        if (offsetRight > minWidth && offsetRight < maxWidth) {
        this.setState({ newWidthLinkItems: { width: offsetRight },
                        currentMaskWidthLink: offsetRight });
        }
	};
	
    handleMouseup = e => {
        this.setState({ isResizing: false });
	};

	handleChangeForm(e) {
		if(e.target.name == "viva_formst_preis") {
			this.setState({
				viva_formst_preis : e.target.value,
			})
		}	
		
		if(e.target.name == "viva_tableDisplay") {
			this.setState({
				viva_tableDisplay : e.target.value,
			})
		}
		if(e.target.name == "viva_columnGroup") {
			this.setState({
				viva_columnGroup : e.target.value,
			})
		}		
		if(e.target.name == "viva_columnSelect") {
			this.setState({
				viva_columnSelect : e.target.value,
			})
		}		
		
		if(e.target.name == "viva_markeLogo") {
			this.setState({
				viva_markeLogo : e.target.value,
			})
		}
		if(e.target.name == "viva_formst_neu") {
			this.setState({
				viva_formst_neu : e.target.value,
			})
		}		
		if(e.target.name == "viva_formst_text1") {
			this.setState({
				viva_formst_text1 : e.target.value,
			})
		}		
		if(e.target.name == "viva_formst_text2") {
			this.setState({
				viva_formst_text2 : e.target.value,
			})
		}		
		if(e.target.name == "viva_formst_text3") {
			this.setState({
				viva_formst_text3 : e.target.value,
			})
		}
		if(e.target.name == "viva_formst_text3") {
			this.setState({
				viva_formst_text3 : e.target.value,
			})
		}
		if(e.target.name == "viva_formst_seitenmotiv") {
			this.setState({
				viva_formst_seitenmotiv : e.target.value,
			})
		}		
		if(e.target.name == "viva_anzahl_bilder") {
			this.setState({
				viva_anzahl_bilder : e.target.value,
			})
		}		
		
		if(e.target.name == "viva_formst_textForm2") {
			this.setState({
				viva_formst_textForm2 : e.target.value,
			})
		}		
		if(e.target.name == "viva_formst_textForm3") {
			this.setState({
				viva_formst_textForm3 : e.target.value,
			})
		}
		if(e.target.name == "viva_formst_textValue1") {
			this.setState({
				viva_formst_textValue1 : e.target.value,
			})
		}	
		if(e.target.name == "viva_formst_textValue2") {
			this.setState({
				viva_formst_textValue2 : e.target.value,
			})
		}
		if(e.target.name == "viva_formst_textValue3") {
			this.setState({
				viva_formst_textValue3 : e.target.value,
			})
		}				
		if(e.target.name == "viva_formst_iconPosition") {
			this.setState({
				viva_formst_iconPosition : e.target.value,
			})
		}		
		if(e.target.name == "viva_pagebreak") {
			this.setState({
				viva_pagebreak : e.target.value,
			})
		}		
		
		if(e.target.name == "viva_formBase") {
			this.setState({
				viva_form: e.target.value + " " + this.state.viva_formVersion,
				viva_formBase : e.target.value,
			})
		}
		if(e.target.name == "viva_formVersion") {
			this.setState({
				viva_formVersion : e.target.value,
				viva_form: this.state.viva_formBase + " " + e.target.value,
			})
		}
		if(e.target.name == "viva_formz1") {
			this.setState({
				viva_formz1 : e.target.value,
			})
		}		
		if(e.target.name == "viva_formz2") {
			let x = makeArray(e.target.value);
			this.setState({
				viva_formz2 : x,
			})
		}		
		if(e.target.name == "viva_formz3") {
			this.setState({
				viva_formz3 : e.target.value,
			})
		}
		if(e.target.name == "viva_formz4") {
			this.setState({
				viva_formz4 : e.target.value,
			})
		}		
		if(e.target.name == "viva_relationship") {
			this.setState({
				viva_relationship : e.target.value,
			})
		}
		if(e.target.name == "viva_layout") {
			this.setState({
				viva_layout : e.target.value,
			})
		}						
	}

	handleChangeNewElem(e) {
		this.setState({
			newElemChoice : e.target.value,
		})
	}

	changeElementlabel(e) {
		this.setState({
			elementlabel : e.target.value,
		})
	}

	changeElementSubLabel(e) {
		this.setState({
			PLElement_SubLabel : e.target.value,
		})
	}

	changeElementZusatz2(e) {
		this.setState({
			PLElement_Zusatz2 : e.target.value,
		})
	}


	changeElementZusatz3(e) {
		this.setState({
			PLElement_Zusatz3: e.target.value,
		})
	}	

	change(e) { 
        var select = this.state.select;
        if(e.target.name === "bookmarklabel") {
            this.setState({
                bookmarklabel : e.target.value,
            })
		}
        if(e.target.name === "bookmarkfolder") {
            this.setState({
                bookmarkfolderId :e.target.value,
            })
        }		
	}
	addBookmark(kind) {
		var k = true;
		if(kind === 'true') {
			axios.get(getAppUrl() + '/gatebackend/rest/settings/bookmarks',
			{
				headers: { 
					"Authorization": " Bearer " + getToken()
				}
			}).then(res2 => {
				this.setState({
					bookmarkOpen: kind,
					bookmarkLinks: res2.data
				})  
			}).catch((error) => {
				
			});
		}
		else {
			if(kind === 'false')
				k = false;
			if(kind === 'add') {
				let bookmark = {};
				bookmark.label = this.state.bookmarklabel;
				bookmark.link = "/planing/"+ this.props.match.params.planingId ;
				bookmark.itemId = 0;
				bookmark.folder = parseInt(this.state.bookmarkfolderId);
				axios.post(getAppUrl() + '/gatebackend/rest/settings/bookmarks/add', 
					bookmark
				,
				{
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}
				).then(repsonse => {
				this.props.enqueueSnackbar(this.props.t('Bookmark hinzugefügt'), { variant: 'info' });
				}
					
				)
				.catch((error) => {
					alert("error");
				});
				k = false;
			}
			this.setState({
				bookmarkOpen : k
			})  

		}
	}	
	
	handleExportPlaningDetail(rowInfo) {   
		let {node, treeIndex, path} = rowInfo;	
		if(node.type === "plItem") {
			this.setState({
					exportItem: node.dbId,
					rowInfo: rowInfo,
					exportPlaning: true,
					exportStatus: 1,	
					exportItems: -1		
			})
		}
		else if (node.type === "item"){
			this.setState({
					exportItem: -1,
					rowInfo: rowInfo,
					exportPlaning: true,
					exportStatus: 1,	
					exportItems: node.plElementId		
			})
			
		}
	}
	
	handleExportPlaningComplete() {
		this.setState({
					exportItem: -1,
					exportPlaning: true,
					exportStatus: 1,	
					exportItems: -1	
		})		
	}	
	
	handleCreatePlaningComplete() {
		this.setState({
				exportItems: -1,
				exportItem: -1,
				planingFirstOpen: false,
		})		
	}
	

	handlePDFItemClickDetail(rowInfo) {   
		let {node, treeIndex, path} = rowInfo;	
		if(node.type === "plItem") {	
				if(this.state.timestamp == this.state.timestamp_sticky)	{
					axios.get(getAppUrl() + '/gatebackend/rest/content/planungsitem/'+node.dbId, 
							{
								headers: { 
									"Authorization": " Bearer " + getToken()
								}
							}).then(res2 => {			
								this.setState({
										tabValue: 0,
										exportItem: node.dbId,
										exportItems: -1,
										rowInfo: rowInfo,
										preview: res2.data.preview,
										forceRefresh: node.forceRefresh,				
										planingFirstOpen: false,

								})
							})
				}
				else {
					let ts = Date.now();
					this.setState({
							tabValue: 0,
							exportItem: node.dbId,
							exportItems: -1,
							rowInfo: rowInfo,
							preview: undefined,
							timestamp: ts,
							timestamp_sticky: ts,
							forceRefresh: node.forceRefresh,
							planingFirstOpen: false,

					})
					
				}
		}
		if(node.type === "item") {		
			axios.get(getAppUrl() + '/gatebackend/rest/content/planungsElementPreview/'+node.plElementId, 
				{
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}).then(res2 => {			
					this.setState({
							tabValue: 0,
							exportItem: -1,
							exportItems: node.plElementId,
							rowInfo: rowInfo,
							preview: res2.data.preview,
							forceRefresh: node.forceRefresh,
							planingFirstOpen: false,

					})
				})
		}
	}

	handlePrepareAddItems(rowInfo) { 
		let {node, treeIndex, path} = rowInfo;			     
		let nw = {width: 1200};
		this.setState({
			newWidthLinkItems: nw,
			notification: true,
			rowInfo: rowInfo,
			currentPlElementId: node.plElementId
		})
	}


	handleEditItemClickDetail(rowInfo) {   
		let {node, treeIndex, path} = rowInfo;
		this.setState({
			showLayoutDetail: true,
			rowInfo: rowInfo,
			viva_layout: node.viva_layout,
			viva_relationship: node.viva_relationship,
		});
	}

	handleEditItemClick(rowInfo) {   
		let {node, treeIndex, path} = rowInfo;
		let a = [];
		if(node.viva_form != null && node.viva_form != "")
			a = node.viva_form.split(" ");
		let b = "";
		let v = "";
		if(a.length == 2) {
			b = a[0];
			v = a[1];
		}
		

		
		
		
		this.setState({
			showLayout: true,
			rowInfo: rowInfo,
			viva_formBase: b,
			viva_formVersion : v,
			viva_form: node.viva_form,
			viva_formz1: node.viva_formz1,
			viva_formz2: node.viva_formz2.split(','),
			viva_formz3: node.viva_formz3,
			viva_formz4: node.viva_formz4,
			viva_formst_preis: node.viva_formst_preis,
			viva_tableDisplay: node.viva_tableDisplay,
			classColumns: node.classColumns,
			classColumnsSelect: node.classColumnsSelect,			
			viva_columnGroup: node.viva_columnGroup,	
			viva_columnSelect: node.viva_columnSelect,	
					
			viva_formst_neu: node.viva_formst_neu,
			viva_markeLogo: node.viva_markeLogo,
			viva_formst_text1: node.viva_formst_text1,
			viva_formst_text2: node.viva_formst_text2,
			viva_formst_text3: node.viva_formst_text3,
			viva_formst_seitenmotiv: node.viva_formst_seitenmotiv,
			viva_anzahl_bilder: node.viva_anzahl_bilder,
			
			viva_formst_textForm1: node.viva_formst_textForm1,
			viva_formst_textForm2: node.viva_formst_textForm2,
			viva_formst_textForm3: node.viva_formst_textForm3,
			viva_formst_textValue1: node.viva_formst_textValue1,
			viva_formst_textValue2: node.viva_formst_textValue2,
			viva_formst_textValue3: node.viva_formst_textValue3,
			viva_tableDisplay: node.viva_tableDisplay,
			
						
			viva_formst_iconPosition: node.viva_formst_iconPosition,
			viva_pagebreak: node.viva_pagebreak
		});

	}	

	handleChangeLayoutPLElement1() { 
		let {node, treeIndex, path} = this.state.rowInfo;


		let smallPlanung = [];
		let elem = {};
		elem.type = "item";
		elem.sticky = "saveElement";
		elem.plElementId = node.plElementId;
		elem.zusatz1 =  setconvertJSON(this.state.PLElement_SubLabelJson, this.state.PLElement_SubLabel, "de");

		elem.zusatz2 = this.state.PLElement_Zusatz2;
		elem.zusatz3 = this.state.PLElement_Zusatz3;
		elem.children = [];
		elem.children.push(node);
		smallPlanung.push(elem);
        axios.post(getAppUrl() + "/gatebackend/rest/settings/savePlaningItem", 
                smallPlanung
            ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
			let planningMap = generatePlanningMap(this.state.treeData, this.state.formsOrig);

			this.props.enqueueSnackbar(this.props.t('PlanungsItem Layout gespeichert'), { variant: 'info' });
			this.setState({ showEditPLElement: false });
		//	this.reload(this.props.match.params.planingId);

		}
            
        )
        .catch((error) => {
            alert("error");
        });    
	}	

	cleanForceRefresh() { 
		let {node, treeIndex, path} = this.state.rowInfo;
		node.forceRefresh = false;

	}
	
	handleChangeLayoutItem() { 
		let {node, treeIndex, path} = this.state.rowInfo;
		node.viva_form = this.state.viva_form;
		node.subtitle = "Nr:" + node.artnr +" | Form:" + this.state.viva_form + " | ";

		node.viva_formz1 = this.state.viva_formz1;
		
		node.viva_formz2 = makeArray(this.state.viva_formz2).join(',');
		node.viva_formz3 = this.state.viva_formz3;
		node.viva_formz4 = this.state.viva_formz4;
		node.viva_formst_preis =  this.state.viva_formst_preis;
		node.viva_formst_neu	= this.state.viva_formst_neu;
		node.viva_markeLogo = this.state.viva_markeLogo;
		node.viva_formst_text1	= this.state.viva_formst_text1;
		node.viva_formst_text2	= this.state.viva_formst_text2;
		node.viva_formst_text3	= this.state.viva_formst_text3;
		node.viva_formst_seitenmotiv	= this.state.viva_formst_seitenmotiv;
		node.viva_anzahl_bilder	= this.state.viva_anzahl_bilder;

		node.viva_formst_textValue1	= this.state.viva_formst_textValue1;
		node.viva_formst_textValue2	= this.state.viva_formst_textValue2;
		node.viva_formst_textValue3	= this.state.viva_formst_textValue3;
		node.viva_formst_textForm1	= this.state.viva_formst_textForm1;
		node.viva_formst_textForm2	= this.state.viva_formst_textForm2;
		node.viva_formst_textForm3	= this.state.viva_formst_textForm3;
		node.viva_tableDisplay	= this.state.viva_tableDisplay;
		node.classColumns = this.state.classColumns;
		node.classColumnsSelect = this.state.classColumnsSelect;
		node.viva_columnGroup	= this.state.viva_columnGroup;
		node.viva_columnSelect	= this.state.viva_columnSelect;
		node.columnsString = node.viva_columnSelect.join(",");
		
		node.viva_formst_iconPosition= this.state.viva_formst_iconPosition;
		node.viva_pagebreak = this.state.viva_pagebreak;
		node.forceRefresh = true;
		if(this.state.exportItem == node.dbId)
			this.state.forceRefresh = true;
		let smallPlanung = [];
		let elem = {};
		elem.type = "item";
		elem.sticky = "noSave";
		elem.children = [];
		elem.children.push(node);
		smallPlanung.push(elem);

		
        axios.post(getAppUrl() + "/gatebackend/rest/settings/savePlaningItem", 
                smallPlanung
            ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
			let planningMap = generatePlanningMap(this.state.treeData, this.state.formsOrig);

            this.props.enqueueSnackbar(this.props.t('PlanungsItem Layout gespeichert'), { variant: 'info' });
			this.setState({
				showLayout: false,
				planningMap: planningMap
			});

		}
            
        )
        .catch((error) => {
            alert("error");
        });
    

	}
	
	handleChangeLayoutItemDetail() { 
		let {node, treeIndex, path} = this.state.rowInfo;
		node.viva_relationship = this.state.viva_relationship;
		node.viva_layout = this.state.viva_layout;
		this.setState({
			showLayoutDetail: false,
		});
    }	
	
	handleCloseLayout() {      
		this.setState({
			showLayout: false,
		});
	}
	
	handleCloseLayoutDetail() {      
		this.setState({
			showLayoutDetail: false,
		});
	}
	
	

	handlePrepareAddSubPlaning(rowInfo) {      
		this.setState({
			show: true,
			rowInfo: rowInfo
		});	
    }

	handleAddSubPlaning() {
		let newCounter = this.state.counter;
		let rowInfo = this.state.rowInfo;
		let newElemChoice = this.state.newElemChoice;
		let label = this.state.elementlabel;
        let NEW_NODE = {title: label, noDragging: false, id: newCounter--, class: newElemChoice, type: newElemChoice};
        let {node, treeIndex, path} = rowInfo;
        path.pop();
        let parentNode = getNodeAtPath({
            treeData: this.state.treeData,
            path : path,
            getNodeKey: ({ treeIndex }) =>  treeIndex,
            ignoreCollapsed : true
        });
        let getNodeKey = ({ node: object, treeIndex: number }) => {
            return number;
        };
        let parentKey = getNodeKey(parentNode);
        if(parentKey == -1) {
            parentKey = null;
        }
        let newTree = addNodeUnderParent({
                treeData: this.state.treeData,
                newNode: NEW_NODE,
                expandParent: true,
                parentKey: parentKey,
                getNodeKey: ({ treeIndex }) =>  treeIndex
         });
         this.setState({treeData: newTree.treeData,
                        show: false,
                        currentNode: NEW_NODE,
                        counter: newCounter
        });
    }


	handleShowSubPlaning() {
		let showSubPlaning = this.state.showSubPlaning;
		if(showSubPlaning === true)
			showSubPlaning = false;
		else 
			showSubPlaning = true;
		let treeData = [];
		treeData = generateTree(this.state.posts, showSubPlaning, false);

		this.setState({
			treeData : treeData,
			showSubPlaning: showSubPlaning
		})  
	}

	handleShowQuery(visible, id) {
		if(visible === true)
	        this.setState({ queryId: id,
							showQuery: true });
		else
	        this.setState({ showQuery: false });

	}

    handleExportClose() {
        this.setState({ exportShow: false });
    }

    handleExportShow(id) {        
        this.setState({ 
			currentExportId : id,
			
			exportShow: true 
		});
    }

	handleExportPlaning(visible) {
		if(visible === true) {
			this.setState({ exportPlaning: true,
							exportStatus: 1 })
		}
		else {
			this.setState({ exportPlaning: false,
							exportStatus: 0 })
		}
	}

	setCurrentItem (itemId) {
		this.setState({ currentHighlightItemId: itemId })
	}

	handleInfo(id) {
		alert("hier");
	}

    handleCloseItemDialog() {
        this.setState({
            ItemsDialog : false,
        })  
	}
	
	gotoDetail(id) {
		this.props.history.push("/planing/" + id);
	}

	handleTabChange(event, newValue) {
		this.setState({
			tabValue : newValue,
			exportItem: -1,
			exportItems: -1
		})
	}

	handleTabChangePlElement(event, newValue) {
		this.setState({
			tabValuePlElement : newValue,
			exportItem: -1,
			exportItems: -1
		})
	}
	
		
   	componentDidMount() {
       	document.addEventListener("mousemove", e => this.handleMousemove(e));
        document.addEventListener("mouseup", e => this.handleMouseup(e));
		this.reload(this.props.match.params.planingId);

	}
	
	reloadAction() {
		this.reloadPreview(this.props.match.params.planingId);
	}

	reloadPreview() {
		let maskID = this.state.currentPlaning.node.maskId;
		let planingElementId = this.state.currentPlaning.node.plId;
		let offset = 0;
		let orderField = "";
		let searchTerm = "";
		let modus = "";

		let getURL = getAppUrl() + '/gatebackend/rest/content/mask/'+ maskID +'/planingElementPreview/' + planingElementId +'?offset='+ offset +'&count=15&orderField='+orderField+'&searchTerm='+searchTerm+'&modus='+modus;		
		axios.get(getURL, 
		{
			headers: { 
				"Authorization": " Bearer " + getToken()
			}
		}).then(res => {
			this.setState({
				tabValue : 1,
				previewDocument: res.data
			})
		});
	}	

   	reload(id) {
		axios.get(getAppUrl() + '/gatebackend/rest/content/templates',  {
						headers: { 
							"Authorization": " Bearer " + getToken()
						}
					}).then(templates => {	
			
	
	
			axios.get(getAppUrl() + '/gatebackend/rest/content/languages',  {
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}).then(sprachen => {
					axios.get(getAppUrl() + '/gatebackend/rest/settings/forms', 
						{
							headers: { 
								"Authorization": " Bearer " + getToken()
							}
						}).then(res3 => {	   
							axios.get(getAppUrl() + '/gatebackend/rest/settings/masks', 
								{
									headers: { 
										"Authorization": " Bearer " + getToken()
									}
								}).then(res => {
									axios.get(getAppUrl() + '/gatebackend/rest/settings/queries', 
									{
										headers: { 
											"Authorization": " Bearer " + getToken()
										}
									}).then(res1 => {
										axios.get(getAppUrl() + '/gatebackend/rest/settings/planing/' + id, 
										{
											headers: { 
												"Authorization": " Bearer " + getToken()
											}
										}).then(res2 => {
											
											axios.get(getAppUrl() + '/gatebackend/rest/settings/exports', 
											{
												headers: { 
													"Authorization": " Bearer " + getToken()
												}
											}).then(res5 => {											
											

										        axios.get(getAppUrl() + '/gatebackend/rest/settings/planings', 
										            {
										                headers: { 
										                    "Authorization": " Bearer " + getToken()
										                }
										            }).then(res4 => {


												        axios.get(getAppUrl() + '/gatebackend/rest/settings/planungstypen', 
												            {
												                headers: { 
												                    "Authorization": " Bearer " + getToken()
												                }
												            }).then(res6 => {

											
																let treeData = [];
				
																treeData = generateTree(res2.data, this.state.showSubPlaning, false);
																let elem = {};
																elem.node = treeData[0];
																let planningMap = generatePlanningMap(treeData, res3.data);
																let forms = [];
																let bases = {};
																let versions = [];
																let oB = "";
																res3.data.map(function(ex) {
																	let a = ex.name.split(" ");
																	let b = a[0];
																	let v = a[1];
																	if(oB === "")
																		oB = b;
																	if(oB != b) {
																		bases[oB] = versions;
																		forms.push(oB);
																		versions = [];
																	}
																	versions.push(v);
																	oB = b;
																})
																forms.push(oB);
																bases[oB] = versions;

																this.setState({
																	tabValue: 0,
																	exportItem: -1,
																	exportItems: treeData[0].plElementId,
																	//rowInfo: rowInfo,
																	preview: treeData[0].preview,											
																	isLoaded : true,
																	treeData : treeData,
																	currentPlaning: elem,
																	posts: res2.data,
																	masks: res.data,
																	exports: res5.data,
																	planings: res4.data,
																	queries: res1.data,
																	planungstypen: res6.data,
																	forms: forms,
																	formsVersion: versions,
																	formsBase: bases,
																	planningMap: planningMap,
																	itemLanguages: sprachen.data,
																	formsOrig: res3.data,
																	templates: templates.data
																})   
														});
												});
										});
									}); 
								});	 
							});
						});
					});		
			});

	}	

    componentDidUpdate(prevProps) {
        if(this.props.match.params.planingId !== prevProps.match.params.planingId) {
            this.reload(this.props.match.params.planingId)
        }
    }

    handleCloseEditPLElement() {
        this.setState({ showEditPLElement: false });
    }
    
    handleClose() {
        this.setState({ show: false });
    }

    handleShow(e) {
        this.setState({currentTableId : e.currentTarget.dataset.id});
        this.setState({currentTableName : e.currentTarget.dataset.tablename});
        
        this.setState({ show: true });
    }

    toggleNodeExpansion = expanded => {
        this.setState(prevState => ({
			treeData: toggleExpandedForAll({
				treeData: prevState.treeData,
				expanded,
			}),
        }));
	};

	handleConfirmDialog(s) {
		if(s == "confirm") {
			let {node, treeIndex, path} = this.state.rowInfo;
			node.markAsDelete = "true";

			let smallPlanung = [];
			let elem = {};
			elem.type = "item";
			elem.sticky = "noSave";
			elem.children = [];
			elem.children.push(node);
			smallPlanung.push(elem);
			axios.post(getAppUrl() + "/gatebackend/rest/settings/savePlaningItem", 
				smallPlanung
				,
			{
				headers: { 
					"Authorization": " Bearer " + getToken()
				}
			}
			).then(repsonse => {
				const newTree = removeNodeAtPath({
					treeData: this.state.treeData,
					path,
					getNodeKey: ({ treeIndex }) => treeIndex,
			
				});

				let planningMap = generatePlanningMap(this.state.treeData, this.state.formsOrig);
		
				this.props.enqueueSnackbar(this.props.t('PlanungsItem gelöscht'), { variant: 'info' });
				this.setState({
					showLayout: false,
					planningMap: planningMap,
					treeData: newTree,
					confirmDialog: false
				});

			}
				
			)
			.catch((error) => {
				alert("error");
			});

		}
		else {
			this.setState({
				confirmDialog: false
			});
		}
	}

	handleDeleteItem(rowInfo) {

		this.setState({
			rowInfo: rowInfo,
			confirmDialog: true
		});

	}

	handleFunctionClick(rowInfo) {   
		let {node, treeIndex, path} = rowInfo;   
		let nw = {width: 1200};
		this.setState({
			newWidthLinkItems: nw,
			maskEdit: true,
			currentItemID: node.itemID
		})
	}

	handleNodeClick(rowInfo, e) {
		if(e._dispatchListeners.length === 1) {
			if(rowInfo.node.type !== "plItem") {
				let {node, treeIndex, path} = rowInfo;
				let oldNode = this.state.oldNode;
				let oldPath = this.state.oldPath;
				let oldTreeIndex = this.state.oldTreeIndex;
				if(oldNode !== undefined) {
					oldNode.isSelected = false;
					if (oldNode.class.indexOf("selectedNode") !== -1) { 
						oldNode.class = oldNode.class.replace('selectedNode','');
					}
					changeNodeAtPath({
							treeData: this.state.treeData,
							path: oldPath,
							newNode: oldNode,
							getNodeKey: ({ treeIndex }) =>  treeIndex,
							ignoreCollapsed: false,
					});
				}
				node.class = node.class + " selectedNode";
				node.isSelected = true;
				const treeData = changeNodeAtPath({
						treeData: this.state.treeData,
						path: path,
						newNode: node,
						getNodeKey: ({ treeIndex }) =>  treeIndex,
						ignoreCollapsed: false,
				});

				this.setState({treeData,
					oldNode: node,
					oldPath: path,
					oldTreeIndex: treeIndex,
					currentPlaning: rowInfo
				});
			}
		}
  	}

    render() {





		const {isLoaded, posts, planingFirstOpen} = this.state;

		const handleOpenItemDialog = id => event => {
			this.setState({
				ItemsDialog: true,
				planingElementId: id
			});		
		}
		
		const handleChange = id => event => {
			var posts = this.state.posts;
			posts.planingElemets.map(function(planingElement) {
				if(planingElement.id == id ) {
					if(event.target.name === "export")
						planingElement.export.id = event.target.value;
					if(event.target.name === "subplaning")
						planingElement.subPlaning = event.target.value;
				}
			});
			this.setState({
				posts: posts
			});
		};




        if(!isLoaded) {
            return (<div><Loader/></div>)
        }
        else {
			const planingElement = this.state.posts.planingElemets[0];
			let viva_columnSelect = this.state.viva_columnSelect;
            let elHeight = document.getElementById('actionFrame').clientHeight -20;
            var height = {
                'height': elHeight + 'px',
            };
            return (
                <>
                    <div class="Vivacard" style={height}>
                        <div class="Vivacardheader">
                           {this.props.t('Details ')}:&nbsp;{posts.label} 
							<Button className="buttonIcon pull-right" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.addBookmark('true'))}>
								<span className="fa fa-bookmark-o"></span>
							</Button>							

                        </div>
                        <div class="Vivacardbody">
							<div className="row " style={{ height: "calc(180vH - 200px)" }}>							
								<div style={{width: "50%"}}>
									<AppBar position="static" color="default">
											<Tabs 	className="grid-Cell-Header"
													value={this.state.tabValueOverview}
													onChange={this.handleTabChange}
													indicatorColor="secondary"
													textColor="white"
													variant="scrollable"
													scrollButtons="auto"
												>
												<Tab label={this.props.t('Darstellung')} />
											</Tabs>
									</AppBar>  
									{this.state.tabValueOverview === 0 &&
									<>

									<div class="Vivacardheader">
                                        <Button
                                            type="button"
                                            className="buttonIcon"
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                            onClick={(e) => this.handleSavePlaning(true)}
                                            >
                                            <span className="fa fa-download "></span>
                                        </Button>
                                        <Button
                                            type="button"
                                            className="buttonIcon"
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                            onClick={this.toggleNodeExpansion.bind(this, true)}
                                            >
                                            <span className="fa fa-expand "></span>
                                        </Button>   
                                        <Button
                                            type="button"
                                            className="buttonIcon"
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                            onClick={this.toggleNodeExpansion.bind(this, false)}
                                            >
                                            <span className="fa fa-compress "></span>
                                        </Button> 
										<Tooltip title={this.props.t('exportPlaning')} placement="top-start">
											<Button type="button"
													className="buttonIcon"
													style={{
														verticalAlign: 'middle',
													}}
													onClick={(e) => this.handleExportPlaningComplete(true)}
												>
												<span className="fa fa-file-excel-o"></span>
											</Button>
										</Tooltip>
										<Tooltip title={this.props.t('PDF erstellen')} placement="top-start">
											<Button type="button"
													className="buttonIcon"
													style={{
														verticalAlign: 'middle',
													}}
													onClick={(e) => this.handleCreatePlaningComplete(true)}
												>
												<span className="fa fa-file-pdf-o"></span>
											</Button>
										</Tooltip>
										<Tooltip title={this.props.t('showSubPlaning')} placement="top-start">
											<Button type="button"
													className="buttonIcon"
													style={{
														verticalAlign: 'middle',
													}}
													onClick={(e) => this.handleShowSubPlaning()}
												>
												<span className={this.state.showSubplaning === true ? 'fa fa-eye' : 'fa fa-eye-slash'}></span>
											</Button>	
										</Tooltip>
										<Tooltip title={this.props.t('Suche')} placement="top-start">
											<span >
											<input type="search" onChange={e => this.handleSearchOnChange(e)} />
											&nbsp;{this.state.searchFoundCount}
											</span>
										</Tooltip>

										<span style={{paddingLeft: "5px"}}>Template:
											<FormControl >
												<Select
													value={this.state.templateId}
													onChange={e => this.handleChangeTemplate(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="age"
													
												>
												{  this.state.templates.map(function(template) {
															return <MenuItem value={template.id}>{template.label}</MenuItem>
													}) 
												}	

												</Select>
											</FormControl>
										</span>										
										<span style={{paddingLeft: "5px"}}>Typ:
											<FormControl >
												<Select
													value={this.state.quality}
													onChange={e => this.handleChangeQuality(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="age"
													
												>
												{  this.state.qualities.map(function(q) {
															return <MenuItem value={q}>{q}</MenuItem>
													}) 
												}	

												</Select>
											</FormControl>
										</span>										
										<span className="pull-right">
											<Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.handleClickFlag(e)}>              
												<span className="fa fa-flag"></span>
											</Button>
											<Menu	id="simple-menuFlag "
													anchorEl={this.state.anchorElFlag}
													keepMounted
													open={Boolean(this.state.anchorElFlag)}
													onClose={this.handleCloseFlag}
												>											
												{this.state.itemLanguages.map(iT => (
													<>
													<MenuItem onClick={e => this.changeItemLanguage(iT.iso)}><span className={iT.iso == this.state.itemLanguage ? "fa fa-check" : ""}></span>&nbsp;{iT.label}</MenuItem>
													</>
												))}
											</Menu>	
										</span>
										<hr/>
										<Tooltip title={this.props.t('Kapitel hinzufügen')} placement="top-start">
											<Button
	                                            type="button"
	                                            className="buttonIcon"
	                                            style={{
	                                                verticalAlign: 'middle',
	                                            }}
	                                            onClick={(e) => this.addChapter(true)}
	                                            >
	                                            <span className="fa fa-plus "></span>
	                                        </Button>
	                               		</Tooltip>																																			                                                                             
                                    </div>	
										<SortableTree	
											nodeContentRenderer={CustomNodeContentRenderer}
											style={{marginLeft: "10px"}}
											rowHeight={44}
											treeData={this.state.treeData}
											canDrag={({ node }) => !node.noDragging}
											onMoveNode={(e) => this.handleSavePlaning(true)}
											onChange={treeData => this.setState({ treeData })}
											searchQuery={this.state.searchQuery}
											searchFinishCallback={matches =>
																		this.setState({
																			searchFoundCount: matches.length,
																			
																})
											}

											generateNodeProps={rowInfo => ({
												onClick: (e) => this.handleNodeClick(rowInfo, e),
												title: <div className={rowInfo.node.isSelected ? 'selectedNodeX' : ''}>{rowInfo.node.title}</div>	,									
												className: rowInfo.node.class,
												buttons: rowInfo.node.type === "plItem" ?
												rowInfo.node.refId == 0 ?												 
													[ <Button
														type="button"
														className="buttonIcon"
														style={{
															verticalAlign: 'middle',
														}}
														onClick={(e) => this.handleFunctionClick(rowInfo)}
														>
														<span className="fa fa-pencil "></span>
													</Button>,
													<Button
														type="button"
														className="buttonIcon"
														style={{
															verticalAlign: 'middle',
														}}
														onClick={(e) => this.handleDeleteItem(rowInfo)}
														>
														<span className="fa fa-minus "></span>
													</Button>,
													<Button
														type="button"
														className="buttonIcon"
														style={{
															verticalAlign: 'middle',
														}}
														onClick={(e) => this.handleEditItemClick(rowInfo)}
														>
														<span className="fa fa-cog "></span>
													</Button>,
													<Button
														type="button"
														className="buttonIcon"
														style={{
															verticalAlign: 'middle',
														}}
														onClick={(e) => this.handleExportPlaningDetail(rowInfo)}
														>
														<span className="fa fa-file-excel-o "></span>
													</Button>,													
													<Button
														type="button"
														className="buttonIcon"
														style={{
															verticalAlign: 'middle',
														}}
														onClick={(e) => this.handlePDFItemClickDetail(rowInfo)}
														>
														<span className="fa fa-file-pdf-o "></span>
													</Button>,
													]
													:
													[<Button
														type="button"
														className="buttonIcon"
														style={{
															verticalAlign: 'middle',
														}}
														onClick={(e) => this.handleFunctionClick(rowInfo)}
														>
														<span className="fa fa-pencil "></span>
													</Button>,
													<Button
														type="button"
														className="buttonIcon"
														style={{
															verticalAlign: 'middle',
														}}
														onClick={(e) => this.handleDeleteItem(rowInfo)}
														>
														<span className="fa fa-minus "></span>
													</Button>,
													<Button
														type="button"
														className="buttonIcon"
														style={{
															verticalAlign: 'middle',
														}}
														onClick={(e) => this.handleEditItemClickDetail(rowInfo)}
														>
														<span className="fa fa-cog "></span>
													</Button>,]
													: 
													rowInfo.node.type === "sub" ?
													[
														<Button
															type="button"
															className="buttonIcon"
															style={{
																verticalAlign: 'middle',
															}}
															onClick={(e) => this.handlePrepareAddSubPlaning(rowInfo)}
															>
															<span className="fa fa-plus "></span>
														</Button>,
														,]:
											rowInfo.node.type === "item" ?
											[											
											<Button
                                                type="button"
                                                className="buttonIcon"
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                onClick={(e) => this.handlePrepareAddItems(rowInfo)}
                                                >
                                                <span className="fa fa-plus "></span>
											</Button>,
											<Button
                                                type="button"
                                                className="buttonIcon"
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                onClick={(e) => this.handleDuplicateElement(rowInfo)}
                                                >
                                                <span className="fa fa-files-o "></span>
											</Button>,
											<Button
												type="button"
												className="buttonIcon"
												style={{
													verticalAlign: 'middle',
												}}
												onClick={(e) => this.handleEditPLElementClick(rowInfo)}
												>
												<span className="fa fa-cog "></span>
											</Button>,	
											<Button
												type="button"
												className="buttonIcon"
												style={{
													verticalAlign: 'middle',
												}}
												onClick={(e) => this.handleExportPlaningDetail(rowInfo)}
												>
												<span className="fa fa-file-excel-o "></span>
											</Button>,																					
											<Button
												type="button"
												className="buttonIcon"
												style={{
													verticalAlign: 'middle',
												}}
												onClick={(e) => this.handlePDFItemClickDetail(rowInfo)}
												>
												<span className="fa fa-file-pdf-o "></span>
											</Button>														
													] :
											[
												<Button
	                                                type="button"
	                                                className="buttonIcon"
	                                                style={{
	                                                    verticalAlign: 'middle',
	                                                }}
	                                                onClick={(e) => this.handlePrepareAddItems(rowInfo)}
	                                                >
	                                                <span className="fa fa-refresh "></span>
												</Button>,												
												<Button
	                                                type="button"
	                                                className="buttonIcon"
	                                                style={{
	                                                    verticalAlign: 'middle',
	                                                }}
	                                                onClick={(e) => this.handleDuplicateElement(rowInfo)}
	                                                >
	                                                <span className="fa fa-files-o "></span>
												</Button>,
												<Button
													type="button"
													className="buttonIcon"
													style={{
														verticalAlign: 'middle',
													}}
													onClick={(e) => this.handleEditPLElementClick(rowInfo)}
													>
													<span className="fa fa-cog "></span>
												</Button>,	
												<Button
													type="button"
													className="buttonIcon"
													style={{
														verticalAlign: 'middle',
													}}
													onClick={(e) => this.handleExportPlaningDetail(rowInfo)}
													>
													<span className="fa fa-file-excel-o "></span>
												</Button>,																					
												<Button
													type="button"
													className="buttonIcon"
													style={{
														verticalAlign: 'middle',
													}}
													onClick={(e) => this.handlePDFItemClickDetail(rowInfo)}
													>
													<span className="fa fa-file-pdf-o "></span>
												</Button>	
												],
											})}                                        
										/>
										</>
									}
								</div>
								<div style={{width: "50%"}}>
                            		<div class="planingCard">								
									<AppBar position="static" color="default">
											<Tabs 	className="grid-Cell-Header"
													value={this.state.tabValue}
													onChange={this.handleTabChange}
													indicatorColor="secondary"
													textColor="white"
													variant="scrollable"
													scrollButtons="auto"
												>
												<Tab label={this.props.t('PDF')} />												
												<Tab label={this.props.t('Einstellungen')} />
											</Tabs>
									</AppBar>  
									{this.state.tabValue === 2 && 
										<>	
											<div class="Vivacardheader">
												<Button
													type="button"
													className="buttonIcon"
													style={{
														verticalAlign: 'middle',
													}}
													
													>
													<span className="fa fa-refresh"></span>
												</Button>																												
											</div>	
											<div className="planingPreview">
												<div style={{margin: "0 auto", width: "650px", height: "1024px"}}>
													{this.state.planningMap.map(pl => (
														<>
															<div className="planingPreviewPage" style={{ width: pl.width+"px", height: pl.height + "px" }}>
																<div style={{overflow: "hidden", backgroundColor: "white", width: "100%", height: "18px",  fontSize: "10px"}}>
																	{pl.header}
																</div>
																<div style={{backgroundColor: "white", width: "100%", height: "298px" }}>
																	{pl.items.map(item => (
																		<div style={{backgroundImage: "url('/images/"+item.formVersion+".png')", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", float: "left", border: "2px solid #c8ced3", width: item.width+ "%", height: "" + item.height+"%"}}>
																		{item.dbId}
																		

																		</div>
																	))}
																</div>

															</div>
															
														</>
													))}
												</div>
											</div>
											 
										</>	
									}									
									{this.state.tabValue === 1 && 
										<>
											<div class="Vivacardheader">
												<Button
													type="button"
													className="buttonIcon"
													style={{
														verticalAlign: 'middle',
													}}
													
													>
													<span className="fa fa-download "></span>
												</Button>																												
											</div>										
											<div className="planingCard">	
												{this.state.currentPlaning.node &&
													<>
														{this.state.currentPlaning.node.type !== "plItem" ? (
															<>
																<div style={{backgroundColor: "#f0f3f5"}}>
																	<div className="row rowMaxWidth">
																		<div className="col-sm">
																			<Typography style={{paddingTop: "10px", paddingBottom: "10px"}}>{this.props.t('Planung')}: {this.state.currentPlaning.node.title}</Typography>							
																		</div>
																		<div className="col-sm">
																			<Typography style={{paddingTop: "10px", paddingBottom: "10px"}}>ElementId: {this.state.currentPlaning.node.plId}</Typography>
																		</div>
																	</div>
																	<div className="row rowMaxWidth">
																		<div className="col-sm">
																			<div className="marginTop">																																										
																				<FormControl  style={{display: "flex"}}>      
																					<InputLabel shrink htmlFor="export-label-placeholder">
																						{this.props.t('Maske')}
																					</InputLabel>
																					<Select
																						className="planingSelect"
																							value={this.state.currentPlaning.node.export}
																							onChange={handleChange(1)}
																							input={<Input name="age" id="age-label-placeholder" />}
																							displayEmpty
																							name="export"
																						>
																						<MenuItem value="0">{this.props.t('keine')}</MenuItem>
																						{
																							this.state.masks.map(function(ex) {
																								return (<MenuItem value={ex.id}>{ex.name}</MenuItem>)
																							})
																						}
																					</Select>
																				</FormControl>	
																			</div>	
																		</div>
																		<div className="col-sm">
																			<div className="marginTop">																																										
																				<FormControl  style={{display: "flex"}}>      
																					<InputLabel shrink htmlFor="export-label-placeholder">
																						{this.props.t('Tree Maske')}
																					</InputLabel>
																					<Select
																						className="planingSelect"
																							value={this.state.currentPlaning.node.nodeMaskId}
																							onChange={handleChange(1)}
																							input={<Input name="age" id="age-label-placeholder" />}
																							displayEmpty
																							name="export"
																						>
																						<MenuItem value="0">{this.props.t('keine')}</MenuItem>
																						{
																							this.state.masks.map(function(ex) {
																								return (<MenuItem value={ex.id}>{ex.name}</MenuItem>)
																							})
																						}
																					</Select>
																				</FormControl>	
																			</div>
																		</div>
																	</div>
																	<div className="row rowMaxWidth">
																		<div className="col-sm">
																			<FormGroup row>
																				<FormControlLabel
																					control={
																						<Switch
																							checked={this.state.currentPlaning.node.merge === 1 ? true : false}
																							onChange={handleChange('checkedA')}
																							value={this.state.currentPlaning.node.merge}
																							inputProps={{ 'aria-label': 'secondary checkbox' }}
																						/>
																					}
																					labelPlacement="start"
																					label={this.props.t('zusammenfassen')}
																				/>
																			</FormGroup>
																		</div>
																	</div>


																	<hr/>
																</div>
																

																													
															</>
														) : (<>
																<VivaMaskEingabe 	
																		maskId="148" 
																			tableId="49" 
																			itemId={this.state.currentItemID} 
																			anchor="xyz" 
																			nextQuery="0"
																			currentHighlightItemId={this.state.currentHighlightItemId} 
																>
																</VivaMaskEingabe>														
														
														</>)
													
													}
													</>
												}
											</div>
										</>
										
									}

									{this.state.tabValue === 0 && 
										<>
											<div>
												<PDFDisplay quality={this.state.quality} planingFirstOpen={this.state.planingFirstOpen} cleanForceRefresh={this.cleanForceRefresh} forceRefresh={this.state.forceRefresh} templateId={this.state.templateId} preview={this.state.preview} language={this.state.itemLanguage} items={this.state.exportItems} itemId={this.state.exportItem} nextMask={this.props.match.params.planingId}/>
											</div>
										</>
									}																		
									</div> 
								</div>
							</div> 
						</div>							
                    </div>

					<Drawer anchor="right" 
                            open={this.state.maskEdit} 
                            onClose={((e) => this.handlemaskEditClick(false))} 
                            PaperProps={{ style: this.state.newWidthLinkItems }}>
                        <div
                            id="dragger"
                            onMouseDown={event => {
                                this.handleMousedown(event);
                            }}
                            className="drawerDragger"
                        />
                        <div className="notificationFrame" style={{marginLeft: "5px"}}>
						
																<VivaMaskEingabe 	
																		maskId="101" 
																		tableId="2" 
																		itemId={this.state.currentItemID} 
																		anchor="xyz" 
																		nextQuery="0"
																		currentHighlightItemId={this.state.currentHighlightItemId} 
																		action={this.reloadAction}>
																</VivaMaskEingabe>						
                            
                        </div>
                    </Drawer>

                    <Drawer anchor="right" 
                            open={this.state.notification} 
                            onClose={((e) => this.handleNotificationClick(false))} 
                            PaperProps={{ style: this.state.newWidthLinkItems }}>
                        <div
                            id="dragger"
                            onMouseDown={event => {
                                this.handleMousedown(event);
                            }}
                            className="drawerDragger"
                        />
                        <div className="notificationFrame" style={{marginLeft: "5px"}}>
                            <VivaGrid2  maskId="67" 
                                        tableId="2" 
                                        itemId="-1" 
                                        maskModus="6" 
                                        linkToPlanungElementId={this.state.currentPlElementId}
                                        maskWidth={this.state.currentMaskWidthLink}
                                        actionOnClose={e =>  this.reload(this.props.match.params.planingId)}
                                        action={e => this.handleAddItems}/>
                            
                        </div>
                    </Drawer>					
					<Dialog open={this.state.show} onClose={this.handleClose} aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.props.t('Element hinzufügen')}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									{this.props.t('Vergeben Sie einen Namen für das Element')}
								</DialogContentText>
								<FormControl  style={{width: "100%"}}>
									<InputLabel htmlFor="age-native-helper">Element Typ</InputLabel>
									<Select
										value={this.state.newElemChoice}
										onChange={e => this.handleChangeNewElem(e)}
										input={<Input name="age" id="age-label-placeholder" />}
										displayEmpty
										name="age"
										
									>
										<MenuItem value="sub">Unterplanung</MenuItem>																			
										<MenuItem value="item">Produkte</MenuItem>																			
									
									</Select>
								</FormControl>								
								<TextField
									autoFocus
									margin="dense"
									name="bookmarklabel"
									label="Elementname"
									type="email"
									onChange={e => this.changeElementlabel(e)}
									value={this.state.elementlabel}
									fullWidth
								/>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} color="primary">
									{this.props.t('Abbruch')}
								</Button>
								<Button onClick={this.handleAddSubPlaning} color="primary">
									{this.props.t('OK')}
								</Button>
								</DialogActions>
					</Dialog>

					<Dialog fullWidth="md"
                            maxWidth="md" 
                            open={this.state.showEditPLElement} onClose={this.handleCloseEditPLElement} aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.state.plDialogTitle}</DialogTitle>
								<DialogContent>
									<div style={{backgroundColor: "#fff"}}>
										{this.state.currentNode &&
											<>
												<div className="row rowMaxWidth">
													<div className="col-sm">
														
														<TextField
															autoFocus
															margin="dense"
															name="Label"
															label="Label"
															onChange={e => this.changeElementSubLabel(e)}
															value={this.state.PLElement_SubLabel}
															fullWidth
														/>
														<Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.handleClickFlag(e)}>              
															<span className="fa fa-flag"></span>
														</Button>
														
													</div>
													
												</div>																	
												<div className="row rowMaxWidth">
													<div className="col-sm">
														<TextField
															autoFocus
															margin="dense"
															name="Zusatz1"
															label="Zusatz1"
															onChange={e => this.changeElementSubLabel(e)}
															value={this.state.PLElement_SubLabel}
															fullWidth
														/>
													</div>
													<div className="col-sm">
														<TextField
															autoFocus
															margin="dense"
															name="Zusatz2"
															label="Zusatz2"
															type="email"
															onChange={e => this.changeElementZusatz2(e)}
															value={this.state.PLElement_Zusatz2}
															fullWidth
														/>
													</div>
												</div>											
												<div className="row rowMaxWidth">
													<div className="col-sm">
														<TextField
															autoFocus
															margin="dense"
															name="Zusatz3"
															label="Zusatz3"
															type="email"
															onChange={e => this.changeElementZusatz3(e)}
															value={this.state.PLElement_Zusatz3}
															fullWidth
														/>	
													</div>
													<div className="col-sm">
													</div>
												</div>	
													<div className="row rowMaxWidth">
														<div className="col-sm">
															<TextField
																autoFocus
																margin="dense"
																name="SeiternummerierungStart"
																label="Seiternummerierung Start"
																type="email"
																onChange={e => this.changeElementZusatz3(e)}
																value={this.state.PLElement_startPage}
																fullWidth
															/>	
														
														</div>
														<div className="col-sm">
															<FormControl  style={{display: "flex"}}>      
																	<InputLabel shrink htmlFor="export-label-placeholder">
																		{this.props.t('Planungstyp ')}
																	</InputLabel>
																	<Select
																		className="planingSelect"
																			value={this.state.currentNode.planungsTypId}
																			onChange={this.handleChangePLDialog}
																			input={<Input name="age" id="age-label-placeholder" />}
																			displayEmpty
																			name="planungsTypId"
																		>
																		<MenuItem value="0">{this.props.t('keine')}</MenuItem>
																		{
																			this.state.planungstypen.map(function(ex) {
																				return (<MenuItem value={ex.id}>{ex.name}</MenuItem>)
																			})
																		}
																	</Select>
															</FormControl>	
														
														</div>
												</div>	
											</>
										}																						
									</div>											


									
								</DialogContent>
							<DialogActions>
								<Button onClick={this.handleCloseEditPLElement} color="primary">
									{this.props.t('Abbruch')}
								</Button>
								<Button onClick={this.prepareLayoutPLElement} color="primary">
									{this.props.t('OK')}
								</Button>
							</DialogActions>
					</Dialog>


					<Dialog open={this.state.showLayout} onClose={this.handleCloseLayout} aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.props.t('Layout Einstellung')}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									{this.props.t('Legen Sie die Form fest')} {this.state.viva_form}
								</DialogContentText>
									<div className="row ">
										<div className="col-md-5 col-lg-5 col-xl-5 disablePaddingRight">
											<img src={"/images/" + this.state.viva_formVersion + ".png"} width="200"/>

										</div>
										<div className="col-md-5 col-lg-5 col-xl-5 disablePaddingRight">
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">Formen Name</InputLabel>
												<Select
													value={this.state.viva_formBase}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formBase"
												>
													<MenuItem value="0">{this.props.t('keine')}</MenuItem>
													{
														this.state.forms.map(function(ex) {
															return (<MenuItem value={ex}>{ex}</MenuItem>)
														})
													}																				
												
												</Select>
											</FormControl>
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">Form Variante</InputLabel>
												<Select
													value={this.state.viva_formVersion}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formVersion"
												>
												{ this.state.viva_formBase != "" ? (						
													
														this.state.formsBase[this.state.viva_formBase].map(function(ex) {
															return (<MenuItem value={ex}>{ex}</MenuItem>)
														})
														
												) : (<></>)}

												</Select>
											</FormControl>											
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">TextBreite</InputLabel>
												<Select
													value={this.state.viva_formz1}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formz1"
												>
													<MenuItem value="100">100% Breite</MenuItem>																			
													<MenuItem value="75">75% Breite</MenuItem>																			
													<MenuItem value="66">66% Breite</MenuItem>																			
													<MenuItem value="50">50% Breite</MenuItem>																			
													<MenuItem value="40">40% Breite</MenuItem>																			
													<MenuItem value="33">33% Breite</MenuItem>																			
													<MenuItem value="25">25% Breite</MenuItem>																			
												
												</Select>
											</FormControl>
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">Text Spalten</InputLabel>
												<Select
													value={this.state.viva_formz3}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formz3"
												>
													<MenuItem value="1">1 spaltig</MenuItem>																			
													<MenuItem value="2">2 spaltig</MenuItem>																			
													<MenuItem value="3">3 spaltig</MenuItem>																			
													<MenuItem value="4">4 spaltig</MenuItem>																			
												
												</Select>
											</FormControl>	
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">Spalten Umbruch</InputLabel>
												<Select
													value={this.state.viva_formz4}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formz4"
												>
													<MenuItem value="normal">kein Umbruch</MenuItem>																			
													<MenuItem value="nachVorteilsPunkten">nach Vorteilspunkten</MenuItem>																			
																		
												
												</Select>
											</FormControl>
												
											<FormControl  style={{width: "100%"}}>
													<TextField
														autoFocus
														margin="dense"
														name="viva_formst_seitenmotiv"
														label="Seitenmotiv"
														type="email"
														onChange={e => this.handleChangeForm(e)}
														value={this.state.viva_formst_seitenmotiv}
														fullWidth
													/>												
											</FormControl>	
											<FormControl  style={{width: "100%"}}>
													<TextField
														autoFocus
														margin="dense"
														name="viva_anzahl_bilder"
														label="Anzahl Bilder"
														type="email"
														onChange={e => this.handleChangeForm(e)}
														value={this.state.viva_anzahl_bilder}
														fullWidth
													/>												
											</FormControl>	
																					
										</div>
									</div>
								<br/>
								<DialogContentText>
									{this.props.t('Legen Sie die Details fest')}
								</DialogContentText>					
								<div className="row ">
										<div className="col-md-4 col-lg-4 col-xl-4 disablePaddingRight">
											
										<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">Inhaltsanzeige</InputLabel>

											<Select
												multiple
												value={makeArray(this.state.viva_formz2)}
												onChange={e => this.handleChangeForm(e)}
												input={<Input id="select-multiple-checkbox" />}
												renderValue={selected => displayArrayValues(this.state.viva_formz2)}
												name="viva_formz2"
											>

											{contentShow.map((kv) => (
											            <MenuItem key={kv.id} value={kv.id}>
											              <Checkbox checked={isValueSelected(makeArray(this.state.viva_formz2), kv.id)} />
											              <ListItemText primary={kv.label} />
											            </MenuItem>
         									))}		
											
											
		
											</Select>



											
										</FormControl>

										<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">Icons Position</InputLabel>
											<Select
												value={this.state.viva_formst_iconPosition}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_formst_iconPosition"
											>
												<MenuItem value="ka">Keine Anzeige</MenuItem>																			
												<MenuItem value="Tabelle">Tabellenbereich</MenuItem>																			
												<MenuItem value="Bild">Bildbereich</MenuItem>																																																					
												<MenuItem value="Text">Im Text</MenuItem>																																																					
											</Select>
										</FormControl>										
										<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">Logo Position</InputLabel>
											<Select
												value={this.state.viva_markeLogo}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_markeLogo"
											>
												<MenuItem value="ka">Keine Anzeige</MenuItem>																			
												<MenuItem value="TL">Oben Links</MenuItem>																			
												<MenuItem value="TC">Oben Mitte</MenuItem>																			
												<MenuItem value="TR">Oben Rechts</MenuItem>																			
												<MenuItem value="CL">Mitte Links</MenuItem>																			
												<MenuItem value="CC">Mitte Mitte</MenuItem>																			
												<MenuItem value="CR">Mitte Rechts</MenuItem>																			
												<MenuItem value="BL">Unten Links</MenuItem>																			
												<MenuItem value="BC">Unten Mitte</MenuItem>																			
												<MenuItem value="BR">Unten Rechts</MenuItem>																																		
											</Select>
										</FormControl>
										</div>
										<div className="col-md-4 col-lg-4 col-xl-4 disablePaddingRight">
											<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">StörerPreis Position</InputLabel>
											<Select
												value={this.state.viva_formst_preis}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_formst_preis"
											>
												<MenuItem value="ka">Keine Anzeige</MenuItem>																			
												<MenuItem value="TL">Oben Links</MenuItem>																			
												<MenuItem value="TC">Oben Mitte</MenuItem>																			
												<MenuItem value="TR">Oben Rechts</MenuItem>																			
												<MenuItem value="CL">Mitte Links</MenuItem>																			
												<MenuItem value="CC">Mitte Mitte</MenuItem>																			
												<MenuItem value="CR">Mitte Rechts</MenuItem>																			
												<MenuItem value="BL">Unten Links</MenuItem>																			
												<MenuItem value="BC">Unten Mitte</MenuItem>																			
												<MenuItem value="BR">Unten Rechts</MenuItem>																																		
											</Select>
										</FormControl>
										<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">StörerNeu Position</InputLabel>
											<Select
												value={this.state.viva_formst_neu}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_formst_neu"
											>
												<MenuItem value="ka">Keine Anzeige</MenuItem>																			
												<MenuItem value="TL">Oben Links</MenuItem>																			
												<MenuItem value="TC">Oben Mitte</MenuItem>																			
												<MenuItem value="TR">Oben Rechts</MenuItem>																			
												<MenuItem value="CL">Mitte Links</MenuItem>																			
												<MenuItem value="CC">Mitte Mitte</MenuItem>																			
												<MenuItem value="CR">Mitte Rechts</MenuItem>																			
												<MenuItem value="BL">Unten Links</MenuItem>																			
												<MenuItem value="BC">Unten Mitte</MenuItem>																			
												<MenuItem value="BR">Unten Rechts</MenuItem>																																		
											</Select>
										</FormControl>
										<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">Seiten Umbruch</InputLabel>
												<Select
													value={this.state.viva_pagebreak}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_pagebreak"
												>
													<MenuItem value="nein">Kein Umbruch</MenuItem>																			
													<MenuItem value="ja">Umbruch</MenuItem>																			
																																															
												</Select>
											</FormControl>
										</div>
										<div className="col-md-4 col-lg-4 col-xl-4 disablePaddingRight">
											<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">MasterTabelle Ansicht</InputLabel>
											<Select
												value={this.state.viva_tableDisplay}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_tableDisplay"
											>
												<MenuItem value="large">Überschriften links</MenuItem>
												<MenuItem value="headlineTop">Überschriften oben</MenuItem>
												<MenuItem value="short">Gruppiert</MenuItem>																			
												<MenuItem value="ersatzteilTable">Ersatzteil Tabelle</MenuItem>																															
												<MenuItem value="articleAsTable">Als Tabelle</MenuItem>																															
																																														
											</Select>
										</FormControl>
										<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">Gruppierung nach</InputLabel>
											<Select
												value={this.state.viva_columnGroup}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_columnGroup"
											>
											<MenuItem value="keine">-keine-</MenuItem>																			
											{
												this.state.classColumns.map(function(ex) {
													return (<MenuItem value={ex}>{ex}</MenuItem>)
												})	
											}																	
																																														
											</Select>
										</FormControl>
										<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">Auswahl Columns</InputLabel>
											<Select
												value={this.state.viva_columnSelect}
												onChange={e => this.handleChangeForm(e)}

												input={<Input id="checkbox" />}
												multiple
												name="viva_columnSelect"
          										renderValue={(selected) => selected.join(", ")}

											>
											{
												this.state.classColumnsSelect.map(function(ex) {
													return (<MenuItem key={ex.id} value={ex.id}>
																<Checkbox  checked={viva_columnSelect.indexOf(ex.id) > -1} />
																<ListItemText primary={ex.name} />													
													</MenuItem>)
												})	
											}
											</Select>
										</FormControl>
										</div>
									</div>
									<div className="row " style={{marginTop: "5px"}}>
										<div className="col-md-4 col-lg-4 col-xl-4 disablePaddingRight">
											<TextField
												autoFocus
												margin="dense"
												name="viva_formst_textValue1"
												label="StörerText1"
												type="email"
												onChange={e => this.handleChangeForm(e)}
												value={this.state.viva_formst_textValue1}
												fullWidth
											/>
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">StörerText1 Position</InputLabel>
												<Select
													value={this.state.viva_formst_text1}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formst_text1"
												>
													<MenuItem value="ka">Keine Anzeige</MenuItem>																			
													<MenuItem value="TL">Oben Links</MenuItem>																			
													<MenuItem value="TC">Oben Mitte</MenuItem>																			
													<MenuItem value="TR">Oben Rechts</MenuItem>																			
													<MenuItem value="BL">Unten Links</MenuItem>																			
													<MenuItem value="BC">Unten Mitte</MenuItem>																			
													<MenuItem value="BR">Unten Rechts</MenuItem>																																		
												</Select>
											</FormControl>	
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">StörerText1 Form</InputLabel>
												<Select
													value={this.state.viva_formst_textForm1}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formst_textForm1"
												>
													<MenuItem value="">Keine</MenuItem>																			
													<MenuItem value="Stoerer_Rechteck.pdf">Rechteck</MenuItem>																			
													<MenuItem value="Stoerer_Polygon.pdf">Polygon</MenuItem>																			
													<MenuItem value="Stoerer_Kreis.pdf">Kreis</MenuItem>																			

												</Select>
											</FormControl>	
											</div>
											<div className="col-md-4 col-lg-4 col-xl-4 disablePaddingRight">	
											<TextField
												autoFocus
												margin="dense"
												name="viva_formst_textValue2"
												label="StörerText2"
												type="email"
												onChange={e => this.handleChangeForm(e)}
												value={this.state.viva_formst_textValue2}
												fullWidth
											/>									
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">StörerText2 Position</InputLabel>
												<Select
													value={this.state.viva_formst_text2}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formst_text2"
												>
													<MenuItem value="ka">Keine Anzeige</MenuItem>																			
													<MenuItem value="TL">Oben Links</MenuItem>																			
													<MenuItem value="TC">Oben Mitte</MenuItem>																			
													<MenuItem value="TR">Oben Rechts</MenuItem>																																						
													<MenuItem value="BL">Unten Links</MenuItem>																			
													<MenuItem value="BC">Unten Mitte</MenuItem>																			
													<MenuItem value="BR">Unten Rechts</MenuItem>																																		
												</Select>
											</FormControl>	
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">StörerText2 Form</InputLabel>
												<Select
													value={this.state.viva_formst_textForm2}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formst_textForm2"
												>
													<MenuItem value="">Keine</MenuItem>																			
													<MenuItem value="Stoerer_Rechteck.pdf">Rechteck</MenuItem>																			
													<MenuItem value="Stoerer_Polygon.pdf">Polygon</MenuItem>																			
													<MenuItem value="Stoerer_Kreis.pdf">Kreis</MenuItem>																																																						
												</Select>
											</FormControl>
											</div>
											<div className="col-md-4 col-lg-4 col-xl-4 disablePaddingRight">											
											<TextField
												autoFocus
												margin="dense"
												name="viva_formst_textValue3"
												label="StörerText3"
												type="email"
												onChange={e => this.handleChangeForm(e)}
												value={this.state.viva_formst_textValue3}
												fullWidth
											/>
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">StörerText3 Position</InputLabel>
												<Select
													value={this.state.viva_formst_text3}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formst_text3"
												>
													<MenuItem value="ka">Keine Anzeige</MenuItem>																			
													<MenuItem value="TL">Oben Links</MenuItem>																			
													<MenuItem value="TC">Oben Mitte</MenuItem>																			
													<MenuItem value="TR">Oben Rechts</MenuItem>																																						
													<MenuItem value="BL">Unten Links</MenuItem>																			
													<MenuItem value="BC">Unten Mitte</MenuItem>																			
													<MenuItem value="BR">Unten Rechts</MenuItem>																																		
												</Select>
											</FormControl>
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">StörerText3 Form</InputLabel>
												<Select
													value={this.state.viva_formst_textForm3}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formst_textForm3"
												>
													<MenuItem value="">Keine</MenuItem>																			
													<MenuItem value="Stoerer_Rechteck.pdf">Rechteck</MenuItem>																			
													<MenuItem value="Stoerer_Polygon.pdf">Polygon</MenuItem>																			
													<MenuItem value="Stoerer_Kreis.pdf">Kreis</MenuItem>																																																						
												</Select>
											</FormControl>											

										</div>
									</div>																																						
							</DialogContent>
							
							<DialogActions>
								<Button onClick={this.handleCloseLayout} color="primary">
									{this.props.t('Abbruch')}
								</Button>
								<Button onClick={this.handleChangeLayoutItem} color="primary">
									{this.props.t('OK')}
								</Button>
								</DialogActions>
					</Dialog> 
					<Dialog
                            open={this.state.confirmDialog}
                            onClose={((e) => this.handleConfirmDialog(false))}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">Bestätigen</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Möchten Sie das PlanungsItem wirklich löschen?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={((e) => this.handleConfirmDialog(false))} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={((e) => this.handleConfirmDialog("confirm"))} color="primary" autoFocus>
                            Ok
                        </Button>
                        </DialogActions>
                    </Dialog>
					<Dialog open={this.state.showLayoutDetail} onClose={this.handleCloseLayoutDetail} aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.props.t('Layout festlegen')}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									{this.props.t('Legen Sie das Layout fest')}
								</DialogContentText>
																
								<FormControl  style={{width: "100%"}}>
									<InputLabel htmlFor="age-native-helper">Beziehung</InputLabel>
									<Select
										value={this.state.viva_relationship}
										onChange={e => this.handleChangeForm(e)}
										input={<Input name="age" id="age-label-placeholder" />}
										displayEmpty
										name="viva_relationship"
									>
										<MenuItem value="alternativen">Alternative</MenuItem>																			
										<MenuItem value="detailartikel">Detailartikel</MenuItem>																			
										<MenuItem value="ersatzteil">Ersatzteil</MenuItem>																			
										<MenuItem value="notZubehoer">Notwendige Zubehör</MenuItem>																			
										<MenuItem value="optZubehoer">Optinales Zubehör</MenuItem>																			
										<MenuItem value="varianten">Varianten</MenuItem>																			
									
									</Select>
								</FormControl>								
								<FormControl  style={{width: "100%"}}>
									<InputLabel htmlFor="age-native-helper">Inhaltsanzeige</InputLabel>
									<Select
										value={this.state.viva_layout}
										onChange={e => this.handleChangeForm(e)}
										input={<Input name="age" id="age-label-placeholder" />}
										displayEmpty
										name="viva_layout"
									>
										<MenuItem value="alles">Alles</MenuItem>																			
										<MenuItem value="keine Beschreibung">keine Beschreibung</MenuItem>																			
																			
									
									</Select>
								</FormControl>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleCloseLayoutDetail} color="primary">
									{this.props.t('Abbruch')}
								</Button>
								<Button onClick={this.handleChangeLayoutItemDetail} color="primary">
									{this.props.t('OK')}
								</Button>
								</DialogActions>
					</Dialog> 					


					<Dialog 
						fullWidth="md"
						maxWidth="md"
						open={this.state.showQuery} 
						onClose={((e) => this.handleShowQuery(false))}>
						<DialogTitle id="alert-dialog-title">
							{this.props.t('Abfrage')}: {this.state.queryId}

						</DialogTitle>	
						<QueryDetail 
							queryId={this.state.queryId}
						/>
						<DialogActions>
							<Button onClick={((e) => this.handleShowQuery(false))} color="primary">
								{this.props.t('Abbruch')}
							</Button>
							<Button  autoFocus>
								{this.props.t('Speichern')}
							</Button>
						</DialogActions>   
					</Dialog>


					<Dialog fullWidth="md"
                            maxWidth="md"
							open={this.state.exportPlaning}
                            onClose={((e) => this.handleExportPlaning(false))}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">Export</DialogTitle>
                        <DialogContent>
                          {this.state.exportStatus === 0 && 
							  <span>Ihre Planung wird berechnet</span>
						  }
                          {this.state.exportStatus === 1 && 
							  <CSVDisplay language={this.state.itemLanguage} itemId={this.state.exportItem} items={this.state.exportItems} nextMask={this.props.match.params.planingId}/>
						  }

                        </DialogContent>
                        <DialogActions>
                        <Button onClick={((e) => this.handleExportPlaning(false))} color="primary">
                            {this.props.t('Abbruch')}
                        </Button>
                        <Button onClick={((e) => this.handleExportPlaning(false))} color="primary" autoFocus>
                            Ok
                        </Button>
                        </DialogActions>
                    </Dialog>


					<Dialog fullScreen open={this.state.ItemsDialog} onClose={this.handleCloseItemDialog}>
						<AppBar className="appBar title grid-Cell-Header">
							<Toolbar>
								<IconButton edge="start" color="inherit" onClick={this.handleCloseItemDialog} aria-label="Close">
									<CloseIcon />
								</IconButton>
								<Typography variant="h6" >
									PlanungsItems
								</Typography>
							</Toolbar>
						</AppBar>
						<div className="maxWidth" id="xyz">
							<VivaGrid 	maskId="67" 
										tableId="2" 
										planingElementId={this.state.planingElementId} 
										itemId="-1" 
										anchor="xyz"
										action={this.reloadAction}></VivaGrid>
						</div>
							
					</Dialog>	
					
					<Dialog open={this.state.bookmarkOpen} onClose={((e) => this.addBookmark('false'))} aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.props.t('Bookmark anlegen')}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									{this.props.t('Vergeben Sie einen Namen für ihren Bookmark')}
								</DialogContentText>
								<FormControl  style={{width: "100%"}}>
									<InputLabel htmlFor="age-native-helper">Ordner Auswahl</InputLabel>
									<NativeSelect
										value={this.state.bookmarkfolderId}
										onChange={e => this.change(e)}
										inputProps={{
											name: 'bookmarkfolder',
											id: 'age-native-helper',
										}}
									>
									{this.state.bookmarkLinks.length && this.state.bookmarkLinks.map(folder => (
										<option value={folder.id}>{folder.label}</option>
									))}
									</NativeSelect>
									<FormHelperText>wählen Sie den Ordner für den Bookmark aus.</FormHelperText>
								</FormControl>								
								<TextField
									autoFocus
									margin="dense"
									name="bookmarklabel"
									label="Bookmarkname"
									type="email"
									onChange={e => this.change(e)}
									value={this.state.bookmarklabel}
									fullWidth
								/>
							</DialogContent>
							<DialogActions>
								<Button onClick={((e) => this.addBookmark('false'))} color="primary">
									{this.props.t('Abbruch')}
								</Button>
								<Button onClick={((e) => this.addBookmark('add'))} color="primary">
									{this.props.t('OK')}
								</Button>
								</DialogActions>
						</Dialog>

					<Modal size="lg" show={this.state.exportShow} onHide={this.handleExportClose}>
						<Modal.Header closeButton>
							<Modal.Title>{this.props.t('Export')}: </Modal.Title>
						</Modal.Header>
						<Modal.Body class="modalBody">
							<ExportFields exportId={this.state.currentExportId}/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleExportClose}>
							{this.props.t('Abbruch')}
							</Button>
							<Button variant="primary" onClick={this.handleExportClose}>
							{this.props.t('Speichern')}
							</Button>
						</Modal.Footer>
					</Modal>									
                </> 
            );

		}

    }
}
export default withTranslation()(withSnackbar(PlaningDetails)) ;
