import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from 'axios';
import { withStyles } from "@material-ui/core/styles";
import Draggable from "react-draggable";
import {getToken} from '../helpers/Token';
import {getAppUrl} from '../helpers/Defaults';
import MaskFields from './MaskFields';
import { useSnackbar, withSnackbar } from 'notistack';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { ResizableBox } from "react-resizable";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
const styles = theme => ({
  resizable: {
    position: "relative",
    "& .react-resizable-handle": {
      position: "absolute",
      width: 20,
      height: 20,
      bottom: 0,
      right: 0,
      background:
        "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
      "background-position": "bottom right",
      padding: "0 3px 3px 0",
      "background-repeat": "no-repeat",
      "background-origin": "content-box",
      "box-sizing": "border-box",
      cursor: "se-resize"
    }
  }
  
});

function PaperComponent(props) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

class MaskEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMaskField: false,
            height: 600,
        };
        this.handleShowMaskField = this.handleShowMaskField.bind(this);
        this.handleCloseMaskField = this.handleCloseMaskField.bind(this);
        this.handleSaveMaskFields = this.handleSaveMaskFields.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
    }

    componentDidMount() {

    }

    handleStateChange(mask){
        this.props.handleStateChange(mask);
        this.setState({
            mask : mask,
        })
    }


    handleSaveMaskFields() {
        var maskID = this.props.maskid;

        this.setState({ showMaskField: false }); 

        axios.post(getAppUrl() + '/gatebackend/rest/settings/masks/'+ maskID +'/fields', 
                this.state.mask
            ,
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }
            ).then(repsonse => {
                this.props.enqueueSnackbar(this.props.t('Änderungen gespeichert'), { variant: 'info' });
                this.props.reload("reload", "");
            }
                
            )
            .catch((error) => {
                console.log(error);
                alert("error");
            });
    }

    handleCloseMaskField() {
        this.setState({ showMaskField: false });
    }

    handleShowMaskField(e) {   
		  
        this.setState({ showMaskField: true });
    }

    render() {
        return (
            <div >
                <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.handleShowMaskField(e))}>
                    <span className="fa fa-cog"></span>
                </Button>
                <Dialog
                        open={this.state.showMaskField}
                        onClose={this.handleCloseMaskField}
                        // TransitionComponent={Draggable}
                        maxWidth={false}
                        
                        aria-labelledby="draggable-dialog-title"
                >
                    <ResizableBox
                        height={600}
                        width={800}
                        className={this.props.classes.resizable}
                        onResizeStop={(e, data)  => {
                            this.setState({ height: data.size.height });

                            console.log(data);
                        }}
                        > 
                        <DialogTitle className="dialogTitle" 
                                    disableTypography id="form-dialog-title">Maske
                            <IconButton edge="end" color="inherit" onClick={this.handleCloseMaskField} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <div  style={{height: "calc(100% - 150px)"}}>
                            <div id="xwrapper">
                                <article >
                                <MaskFields height={this.state.height} maskId={this.props.maskid} handleStateChange={this.handleStateChange}></MaskFields>
                                </article>
                            </div>
                        </div>
                        
                        <DialogActions>
                            <Button variant="secondary" onClick={this.handleCloseMaskField}>
                            {this.props.t('Abbruch')}
                            </Button>
                            <Button variant="primary" onClick={this.handleSaveMaskFields}>
                            {this.props.t('Speichern')}
                            </Button>
                        </DialogActions>
                    </ResizableBox>
                </Dialog>
                               
            </div>
        );
    }
}

export default withTranslation()(withStyles(styles)(withSnackbar(MaskEdit)));