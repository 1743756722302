import React, { Component } from 'react';
import axios from 'axios';
import SortableTree, { addNodeUnderParent, removeNodeAtPath, changeNodeAtPath, getNodeAtPath, toggleExpandedForAll, defaultSearchMethod, map as mapTree } from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import Button from '@material-ui/core/Button';
import 'font-awesome/css/font-awesome.min.css';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CustomNodeContentRenderer from './blocks/CustomNodeInlineEdit/CustomNodeContentRenderer';
import Switch from '@material-ui/core/Switch';
import {getAppUrl} from '../helpers/Defaults';
import {getToken, getExtranetToken} from '../helpers/Token';
import Loader from './blocks/Loader'
import NativeSelect from '@material-ui/core/NativeSelect';
import DialogContentText from '@material-ui/core/DialogContentText';


function generateTree(plElements) {
    let treeData = [];
    if(plElements.entries !== undefined) {
        plElements.entries.map(function(article) {
            let elem = {};
            elem.expanded = true;
            elem.title= article.label;
            elem.subtitle = "ID:" + article.id + " Preis:" + article.price;
            elem.id = article.id;
            elem.editMode = 0;
            elem.type = "article";
            elem.displayType = article.displayType;
            elem.imageId = article.imageId;
            elem.description = article.description;
            elem.price = parseFloat(article.price);
            elem.artnr = article.artnr;
            elem.copy = true;            
            elem.status = article.status;           
            elem.entryDependencies = article.entryDependencies;
            if(article.entries !== null && article.entries.length > 0) {
                elem.children = generateTree(article);
            }            
            treeData.push(elem);

         });
    }
    else {
        plElements.sections.map(function(section) {
            let elem = {};
            elem.expanded = true;
            elem.editMode = 0;
            elem.title= section.label;
            elem.subtitle = "ID:" + section.id;
            elem.id = section.id;
            elem.displayType = section.displayType;
            elem.displayLocation = section.displayLocation;
            elem.imageId = section.imageId;

            elem.type = "section";
            elem.copy = true;            
            if(section.entries !== undefined && section.entries.length > 0) {
                elem.children = generateTree(section);
            }
            treeData.push(elem);
        });
    }
    return 	treeData;
}



class ConfigureCar extends Component {

    constructor(props) {
		super(props);
        this.state = {
            languages: [],
            counter: -1,
            copyShow: false,
            depCounter: -1,
            isLoaded : false,
            show: false,
            treeData: [],
            currentNode: {},
            personName: [],
            deletedNodes: [],
            cars: [],
            confCars: [],
            currentSearchAdd: "",
            currentSearchSub: "",
            currentCar: 4038861,
            carOnline: 1,
            listOpen: false,
            outHow: "print",
            sourcelanguageId: "de",
            chooseList: true,
            baseUrl: "http://127.0.0.1:8080"
        };
        //baseUrl: "http://127.0.0.1:8080"
        //baseUrl: "https://extranet.reimo-shops.de" 
        this.handleCopy = this.handleCopy.bind(this);
        this.downloadPricePDF = this.downloadPricePDF.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleAdd = this.handleAdd.bind(this);   
        this.searchChangeAdd = this.searchChangeAdd.bind(this);
        this.searchChangeSub = this.searchChangeSub.bind(this);
        this.searchCar = this.searchCar.bind(this);
        this.loadCar = this.loadCar.bind(this);
        this.reload = this.reload.bind(this);
        this.change = this.change.bind(this);
        this.checkStatus = this.checkStatus.bind(this);
        this.changeDependecy = this.changeDependecy.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSaveClose = this.handleSaveClose.bind(this);
        this.changeNewCarId =  this.changeNewCarId.bind(this);
        this.handleChangeOnline = this.handleChangeOnline.bind(this);
        this.listDialog = this.listDialog.bind(this);
        this.changeOutHow = this.changeOutHow.bind(this);
        this.changeSourceLanguage = this.changeSourceLanguage.bind(this);
    }	

    listDialog(which) {
        this.setState({ listOpen: which });
    }

    handleChangeOnline() {
        if(this.state.carOnline === 1)
                this.setState({ carOnline: 0 });        
        else
                this.setState({ carOnline: 1 });
    }

    changeNewCarId(e) {        
        let id = e.target.value;
        alert(id);
        this.setState({ newCarId: id
                      });        
    }


    loadCar(e) {
        this.setState({
			currentCar: e.target.value
		});        
        this.reload(e.target.value);
    }

    changeOutHow(e) {
        this.setState({outHow: e.target.value });
    }

    changeSourceLanguage(e) {
        this.setState({sourcelanguageId: e.target.value });
    }

    changeDependecy(kind, basisId,  detailId) {
        let bResult = false;
        let {node, treeIndex, path} = this.state.currentRowInfo;
        let depCounter  = this.state.depCounter;
        if(node.entryDependencies && node.entryDependencies.length > 0) {
            let tDep = node.entryDependencies;

            tDep.map(function(entryDependency) {
                if(entryDependency.refEntry === basisId && entryDependency.manageId === detailId && entryDependency.status === kind) {
                    bResult = true;
                    entryDependency.status = "nothing";
                }
                else if(entryDependency.refEntry === basisId && entryDependency.manageId === detailId && entryDependency.status === "nothing") {
                    bResult = true;
                    entryDependency.status = kind;
                }
            });   
            if(bResult === false) {
                let elem = {};
                elem.id = depCounter--;
                elem.refEntry = basisId;
                elem.manageId = detailId;
                elem.status = kind;
                tDep.push(elem);
            }
            const treeData = changeNodeAtPath({
                    treeData: this.state.treeData,
                    path: path,
                    newNode: node,
                    getNodeKey: ({ treeIndex }) =>  treeIndex,
                    ignoreCollapsed: true,
            });
            
            this.setState({ treeData,
                            depCounter: depCounter,
                            currentNode: node  });
        }
        else {
            let tDep = [];           
            let elem = {};
            elem.id = depCounter--;
            elem.refEntry = basisId;
            elem.manageId = detailId;
            elem.status = kind;
            tDep.push(elem);
            node.entryDependencies = tDep;
            const treeData = changeNodeAtPath({
                    treeData: this.state.treeData,
                    path: path,
                    newNode: node,
                    getNodeKey: ({ treeIndex }) =>  treeIndex,
                    ignoreCollapsed: true,
            });
            this.setState({ treeData,
                            depCounter: depCounter,
                            currentNode: node  });

        }
    }

    checkStatus(kind, basisId,  detailId) {
        let node = this.state.currentNode;
        let bResult = false;
        if(node.entryDependencies && node.entryDependencies.length > 0) {
            node.entryDependencies.map(function(entryDependency) {
                if(entryDependency.refEntry === basisId && entryDependency.manageId === detailId && entryDependency.status === kind)
                    bResult = true;
            });
        }
        return bResult;
    }

    change(e) {
       // let node = this.state.currentNode;

        let {node, treeIndex, path} = this.state.currentRowInfo;
        
        if(e.target.name == "label") {
            node.title = e.target.value;
        } 
        if(e.target.name == "location") {
            node.displayLocation = e.target.value;
        }           

        if(e.target.name == "display") {
                node.displayType = parseInt(e.target.value); 
        } 

        if(e.target.name == "imageId") {
                node.imageId = parseInt(e.target.value); 
        } 
        
        if(e.target.name == "status") {
            
            if(node.status === 1)
                node.status = 0;
            else
                node.status = 1; 
        }         

        if(e.target.name == "description") {
            node.description = e.target.value;
        }   

        if(e.target.name == "price") {
            node.price = parseFloat(e.target.value);
        } 
        if(e.target.name == "artnr") {
            node.artnr = e.target.value;
        } 
        const treeData = changeNodeAtPath({
                treeData: this.state.treeData,
                path: path,
                newNode: node,
                getNodeKey: ({ treeIndex }) =>  treeIndex,
                ignoreCollapsed: true,
        });
        
        this.setState({treeData,
                       currentNode: node  });
    }


    handleSaveClose() {
        this.setState({ isLoaded: false });
        let id = this.state.currentCar;
        let dObj = {};
        dObj.data = this.state.treeData;
        dObj.delNnodes = this.state.deletedNodes;
        dObj.showOnline = parseInt(this.state.carOnline);
        axios.post(getAppUrl() + "/extranet/rest/cms/interiorVariant/" + id, 
            dObj
            ,
        {
            headers: { 
                "x-profile-key": " reimo "
            }
        }
        ).then(repsonse => {
            alert("Daten gespeichert");
            
            let treeData = generateTree(repsonse.data.article[0].vehicleOptions);
            let carOnline = repsonse.data.article[0].showConfigurator;
            this.setState({
                carOnline : carOnline,
                isLoaded : true,
                posts: repsonse.data,
                treeData: treeData,
                show: false
            })   

        }
            
        )
        .catch((error) => {
            console.log(error);
            alert("error");
        });

        this.setState({ show: false });
    }

    handleCopyNode(rowInfo) {
        let {node, treeIndex, path} = rowInfo;
        if(node.copy === true)
            node.copy = false;
        else
            node.copy = true;
        const treeData = changeNodeAtPath({
                treeData: this.state.treeDataCopy,
                path: path,
                newNode: node,
                getNodeKey: ({ treeIndex }) =>  treeIndex,
                ignoreCollapsed: true,
        });
        
        this.setState({treeData,
                       });
    }

    handleDelete(rowInfo) {
        let {node, treeIndex, path} = rowInfo;
        let deletedNodes = this.state.deletedNodes;
        deletedNodes.push(node);
        const newTree = removeNodeAtPath({
            treeData: this.state.treeData,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex,
        });
        this.setState({treeData: newTree,
                       deletedNodes: deletedNodes});       
    }


    searchCar(e) {
        this.setState({
			currentCar: e.target.value
		});
    }

    searchChangeAdd(e) {
        this.setState({
			currentSearchAdd: e.target.value
		});
    }

    searchChangeSub(e) {
        this.setState({
			currentSearchSub: e.target.value
		});
    }    

    handleAdd(rowInfo) {
        let newCounter = this.state.counter;
        let NEW_NODE = { title: 'new Node ' + newCounter, noDragging: false, id: newCounter--, displayType: 1, imageId: 0, displayLocation: 'all' };
        let {node, treeIndex, path} = rowInfo;
        //path.pop();
        let parentNode = getNodeAtPath({
            treeData: this.state.treeData,
            path : path,
            getNodeKey: ({ treeIndex }) =>  treeIndex,
            ignoreCollapsed : true
        });
        let getNodeKey = ({ node: object, treeIndex: number }) => {
            return number;
        };
        let parentKey = getNodeKey(parentNode);
        if(parentKey == -1) {
            parentKey = null;
        }
        let newTree = addNodeUnderParent({
                treeData: this.state.treeData,
                newNode: NEW_NODE,
                expandParent: true,
                parentKey: parentKey,
                getNodeKey: ({ treeIndex }) =>  treeIndex
         });
         this.setState({treeData: newTree.treeData,
                        
                        currentNode: NEW_NODE,
                        currentRowInfo: rowInfo,
                        counter: newCounter
        });
    }    

    handleClose() {
        this.setState({ show: false });
    }

    handleCopy(visible) {
        console.log(visible);
        if(visible === true) {
            axios.get(getAppUrl() +"/extranet/rest/cms/getAllNotConfiguresCars", 
                {
                    headers: { 
                        "x-profile-key": " reimo "
                    }
                }).then(res => {
                    let copyTree = this.state.treeData;
                    this.setState({ copyShow: true,
                                    treeDataCopy: copyTree,
                                    cars: res.data
                                  }
                                 );  
            }); 

        }
        else if(visible === "save") {
            let id = this.state.currentCar;
            let newID = this.state.newCarId;
            let dObj = {};
            dObj.data = this.state.treeDataCopy;
            dObj.delNnodes = this.state.deletedNodes;
            axios.post(getAppUrl() + "/extranet/rest/cms/interiorVariantCopy/" + id + "/" + newID, 
                dObj
                ,
            {
                headers: { 
                    "x-profile-key": " reimo "
                }
            }
            ).then(repsonse => {
                alert("Daten kopiert");
                this.setState({ copyShow: false });
            }
                
            )
            .catch((error) => {
                console.log(error);
                alert("error");
            });

            this.setState({ copyShow: false });
        }

        else {
            this.setState({ copyShow: false,
                            });
        }

    }
    downloadPricePDF() {
        this.setState({ chooseList: false,
                            });
        let id = this.state.currentCar;
        axios.get(getAppUrl() + "/extranet/rest/cms/pricelist/"+id+"?cache=false&printReady=true&x-profile-key=reimo&language="+this.state.sourcelanguageId, 
            {
                headers: { 
                    "x-profile-key": " reimo "
                }
            }
            ).then(response => {
                    const downloadLink = document.createElement("a");
                    const fileName = "preisliste.pdf";
                    downloadLink.target = "new";

                    downloadLink.href = "data:application/pdf;base64,"+ response.data.base64;
                    downloadLink.download = fileName;
                    downloadLink.click();
            this.setState({ chooseList: true,
                            });            
            }
                
            )
            .catch((error) => {
                console.log(error);
                alert("error");
        });        
    }


    handleShow(rowInfo) {
        let {node, treeIndex, path} = rowInfo;

        this.setState({ show: true,
                        currentNode: node,
                        currentRowInfo: rowInfo,
                        path: path });
    }
    
    toggleNodeExpansion = expanded => {
        this.setState(prevState => ({
        treeData: toggleExpandedForAll({
            treeData: prevState.treeData,
            expanded,
        }),
        }));
    };

    reload(id) {
		axios.get(getAppUrl() + '/gatebackend/rest/content/languages',  {
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}).then(res2 => {
                    axios.get(getAppUrl() +"/extranet/rest/cms/getAllConfiguresCars", 
                        {
                            headers: { 
                                "x-profile-key": " reimo "
                            }
                        }).then(res1 => {
                                axios.get(getAppUrl() +"/extranet/rest/cms/interiorVariant/" + id, 
                                    {
                                        headers: { 
                                            "x-profile-key": " reimo "
                                        }
                                    }).then(res => {
                                        let treeData = generateTree(res.data.article[0].vehicleOptions);
                                        console.log(res.data);
                                        let carOnline = res.data.article[0].showConfigurator;
                                        console.log(res2.data);
                                        this.setState({
                                            languages: res2.data,
                                            carOnline : carOnline,
                                            isLoaded : true,
                                            posts: res.data,
                                            treeData: treeData,
                                            confCars: res1.data
                                        })   
                                });  
                    });   
				})
				.catch((error) => {
				});                    
    }

   	componentDidMount() {
        this.reload(this.props.match.params.itemId)          
    }

    render() {
        const {isLoaded} = this.state;
        if(!isLoaded) {
            return (<div><Loader/></div>)
        }
        else {
            let elHeight = document.getElementById('actionFrame').clientHeight -100;
            var height = {
                'max-height': elHeight + 'px',
            };            
            return (
                <div>
                    <div class="Vivacard" style={height}>
                        <div class="Vivacardheader">
                           {this.state.posts.article[0].artnr}
                        </div>
                        <div class="Vivacardbody" style={{ height: "calc(100vH - 200px)" }}>

                            <div className="row " style={{ height: "calc(100vH - 200px)" }}>
                                <div className="col-md-7 col-lg-7 col-xl-7 disablePaddingRight">
                                    <div class="Vivacardheader" style={{height: "35px"}}>
                                        <span>
                                            <Button
                                                type="button"
                                                className="buttonIcon"
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                onClick={this.toggleNodeExpansion.bind(this, true)}
                                                >
                                                <span className="fa fa-expand "></span>
                                            </Button>   
                                            <Button
                                                type="button"
                                                className="buttonIcon"
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                onClick={this.toggleNodeExpansion.bind(this, false)}
                                                >
                                                <span className="fa fa-compress "></span>
                                            </Button>   
                                            <Button
                                                type="button"
                                                className="buttonIcon"
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                onClick={e => this.handleCopy(true)}
                                                >
                                                <span className="fa fa-clone "></span>
                                            </Button>  
                                            <Button
                                                type="button"
                                                className="buttonIcon"
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                onClick={((e) => this.listDialog(true))}
                                                >
                                                <span className="fa fa-file-pdf-o "></span>
                                            </Button>  
                                            <Button
                                                type="button"
                                                className="buttonIcon"
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                target="_blank"
                                                href={"http://195.201.188.188:3000/de" + this.state.posts.article[0].configuratorUrl}
                                                >
                                                <span className="fa fa-external-link "></span>
                                            </Button> 
                                            offline 
                                            <Switch
                                                checked={this.state.carOnline}
                                                onChange={e => this.handleChangeOnline('checkedB')}
                                                value="checkedB"
                                                color="primary"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />     
                                            online                    
                                        </span>
                                        <span className="float-right" >
                                            <select name="newId" onChange={e => this.loadCar(e)} value={this.state.currentCar} >
                                                { this.state.confCars.map(car => (
                                                        <option value={car.id}>{car.label}</option>
                                                ))}
                                            </select>                            
                                        </span>                        

                                    </div>  
                                    <hr/>     

                                    <SortableTree
                                        nodeContentRenderer={CustomNodeContentRenderer}

                                        treeData={this.state.treeData}
                                        canDrag={({ node }) => !node.noDragging}
                                        onChange={treeData => this.setState({ treeData })}
                                        generateNodeProps={rowInfo => ({
                                            buttons: [
                                                <Button
                                                    type="button"
                                                    className="buttonIcon"
                                                    style={{
                                                        verticalAlign: 'middle',
                                                    }}
                                                    onClick={() => this.handleShow(rowInfo)}
                                                    >
                                                    <span className="fa fa-pencil "></span>
                                                </Button>,                                            
                                                <Button
                                                    type="button"
                                                    className="buttonIcon"
                                                    style={{
                                                        verticalAlign: 'middle',
                                                    }}
                                                    onClick={(e) => this.handleAdd(rowInfo)}
                                                    >
                                                    <span className="fa fa-plus "></span>
                                                </Button>,
                                                <Button
                                                    type="button"
                                                    className="buttonIcon"
                                                    style={{
                                                        verticalAlign: 'middle',
                                                    }}
                                                    onClick={(e) => this.handleDelete(rowInfo)}
                                                    >
                                                    <span className="fa fa-minus "></span>
                                                </Button>,                                           
                                            ],
                                        })}
                                    />
                                </div>
                            </div>
                            <Dialog onClose={this.handleClose} 
                                    aria-labelledby="customized-dialog-title" 
                                    fullWidth="lg"
                                    maxWidth="lg"
                                    open={this.state.show}>
                                <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                                    Eingabe 
                                </DialogTitle>
                                <DialogContent dividers>
                                    <div className="row clearmargin"  align="start">
                                        <div className="col-sm-3 ">
                                            Label: 
                                        </div>
                                        <div className="col-sm">
                                            <input  maxlength="200" 
                                                    size="200" 
                                                    type="text" 
                                                    name="label"  
                                                    onChange={e => this.change(e)} 
                                                    value={this.state.currentNode.title}/>
                                        </div>
                                    </div> 
                                    <div className="row clearmargin"  align="start">
                                        <div className="col-sm-3 ">
                                            Beschreibung: 
                                        </div>
                                        <div className="col-sm">
                                            <input  maxlength="200" 
                                                    size="200" 
                                                    type="text" 
                                                    name="description"  
                                                    onChange={e => this.change(e)} 
                                                    value={this.state.currentNode.description}/>
                                        </div>
                                    </div>                            
                                    <div className="row clearmargin"  align="start">
                                        <div className="col-sm-1 ">
                                            Preis: 
                                        </div>
                                        <div className="col-sm-3">
                                            <input maxlength="20" 
                                                size="20" 
                                                type="number" 
                                                name="price"  
                                                onChange={e => this.change(e)} 
                                                value={this.state.currentNode.price}/>
                                        </div>
                                        <div className="col-sm-1 ">
                                            Artnr: 
                                        </div>
                                        <div className="col-sm-3">
                                            <input maxlength="20" 
                                                size="20" 
                                                type="text" 
                                                name="artnr"  
                                                onChange={e => this.change(e)} 
                                                value={this.state.currentNode.artnr}/>
                                        </div>  
                                        <div className="col-sm-1 ">
                                            DisplayAs: 
                                        </div>
                                        <div className="col-sm-3">
                                            <select name={"display"} 
                                                    onChange={e => this.change(e)} 
                                                    value={this.state.currentNode.displayType}>
                                                    <option value="1">Normal</option>
                                                    <option value="2">Gruppiert Serie/Options</option>
                                            </select>

                                        </div>
                                        <div className="col-sm-1 ">
                                            Anzeige: 
                                        </div>
                                        <div className="col-sm-3">
                                            <select name={"location"} 
                                                    onChange={e => this.change(e)} 
                                                    value={this.state.currentNode.displayLocation}>
                                                    <option value="all">Überall</option>
                                                    <option value="configuratorCustomer">Konfigurator Webseite</option>
                                                    <option value="technicalData">Technische Daten</option>
                                            </select>

                                        </div>                                        
                                        <div className="col-sm-1 ">
                                            Image: 
                                        </div>
                                        <div className="col-sm-3">
                                            <input maxlength="20" 
                                                size="20" 
                                                type="text" 
                                                name="imageId"  
                                                onChange={e => this.change(e)} 
                                                value={this.state.currentNode.imageId}/>
                                        </div>                                                                                               
                                        <div className="col-sm-1 ">
                                            Serie: 
                                        </div>
                                        <div className="col-sm-3">
                                            <Checkbox
                                                name="status"
                                                checked={this.state.currentNode.status === 0 ? true : false}
                                                onChange={e => this.change(e)}
                                                value="primary"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />                                    
                                        </div>  

                                        <div className="col-sm-1 ">
                                            DatenID: 
                                        </div>
                                        <div className="col-sm-3">
                                            {this.state.currentNode.id}
                                        </div>                                                               
                                    </div> 

                                    <hr/>
                                    <div className="row clearmargin"  align="start">
                                        <div className="col-sm">
                                            <span>Zusätzlich:</span> 
                                            <span className="float-right">
                                                <form  noValidate autoComplete="off">
                                                    <TextField 
                                                        value={this.state.currentSearchAdd}
                                                        onChange={e => this.searchChangeAdd(e)}
                                                        id="standard-basic" 
                                                        label="Suche" />
                                        
                                                </form>
                                            </span>                                    
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row clearmargin"  align="start">
                                        <div className="col-sm" style={{minHeight: "200px", maxHeight: "200px", overflow: "scroll"}}>
                                            {this.state.posts !== undefined ? (
                                                <>
                                                    {this.state.posts.article[0].vehicleOptions.sections.map(entry => (
                                                        <div>
                                                            <span>{entry.label}</span>
                                                                {entry.entries.map(article => (
                                                                    <>
                                                                        {this.state.currentSearchAdd === "" || (article.label.includes(this.state.currentSearchAdd) ) ? (
                                                                            <div className="row">
                                                                                <div  className="col-sm-1">
                                                                                    <Checkbox
                                                                                        checked={this.checkStatus("enable", this.state.currentNode.id,  article.id)}
                                                                                        onChange={e => this.changeDependecy("enable", this.state.currentNode.id,  article.id)}
                                                                                        value="primary"
                                                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-sm">
                                                                                    (Id# {article.id})&nbsp;{article.label}
                                                                                </div>
                                                                            </div>                                                                    
                                                                            ) : (<></>)
                                                                        }                                                                
                                                                    </>
                                                                ))}
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (<></>) }
                                        </div>
                                    </div>                            
        <hr/>
                                    <div className="row clearmargin"  align="start">
                                        <div className="col-sm">
                                            <span>Ausschluss:</span> 
                                            <span className="float-right">
                                                <form  noValidate autoComplete="off">
                                                    <TextField 
                                                        value={this.state.currentSearchSub}
                                                        onChange={e => this.searchChangeSub(e)}
                                                        id="standard-basic" 
                                                        label="Suche" />
                                        
                                                </form>
                                            </span>                                    
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row clearmargin"  align="start">
                                        <div className="col-sm" style={{minHeight: "200px", maxHeight: "200px", overflow: "scroll"}}>
                                            {this.state.posts !== undefined ? (
                                                <>
                                                    {this.state.posts.article[0].vehicleOptions.sections.map(entry => (
                                                        <div>
                                                            <span>{entry.label}</span>
                                                                {entry.entries.map(article => (
                                                                    <>
                                                                        {this.state.currentSearchSub === "" || (article.label.includes(this.state.currentSearchSub) ) ? (
                                                                            <div className="row">
                                                                                <div  className="col-sm-1">
                                                                                    <Checkbox
                                                                                        checked={this.checkStatus("disable", this.state.currentNode.id,  article.id)}
                                                                                        onChange={e => this.changeDependecy("disable", this.state.currentNode.id,  article.id)}

                                                                                        value="primary"
                                                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-sm">
                                                                                    (Id# {article.id})&nbsp;{article.label}
                                                                                </div>
                                                                            </div>                                                                    
                                                                            ) : (<></>)
                                                                        }                                                                
                                                                    </>
                                                                ))}
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (<></>) }
                                        </div>
                                    </div>                            

                                </DialogContent>
                                <DialogActions>
                                <Button autoFocus onClick={this.handleClose} color="primary">
                                    OK
                                </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog open={this.state.listOpen} 
                                    onClose={((e) => this.listDialog(false))} 
                                    aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title">Preislisten exportieren</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Erstellen Sie eine Preisliste
                                    </DialogContentText>

                                    {this.state.chooseList === true ? (
                                        <>                                                  
                                        <FormControl  style={{width: "100%"}}>
                                            <InputLabel htmlFor="destination-helper">Ausgabeformat auswählen</InputLabel>
                                            <NativeSelect
                                                value={this.state.outHow}
                                                onChange={e => this.changeOutHow(e)}
                                                inputProps={{
                                                    name: 'bookmarkfolder',
                                                    id: 'destination-helper',
                                                }}
                                            >
                                            <option value="print">Druck optimiert</option>
                                            <option value="web">Webseiten optimiert</option>
                                            </NativeSelect>
                                        </FormControl>                                

                                        <FormControl  style={{width: "100%"}}>
                                            <InputLabel htmlFor="source-helper">Sprache auswählen</InputLabel>
                                            <NativeSelect
                                                value={this.state.sourcelanguageId}
                                                onChange={e => this.changeSourceLanguage(e)}
                                                inputProps={{
                                                    name: 'bookmarkfolder',
                                                    id: 'source-helper',
                                                }}
                                            >
                                                {
                                                this.state.languages.map(l => (
                                                    <option value={l.iso}>{l.label}</option>

                                                ))}
                                                
                                            </NativeSelect>
                                        </FormControl>
                                                                   
                                        </>                           
                                        
                                        )
                                        :(<div><Loader/></div>)
                                    }
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={((e) => this.listDialog(false))} color="primary">
                                        Abbruch
                                    </Button>
                                    <Button onClick={e => this.downloadPricePDF(true)} color="primary">
                                        OK
                                    </Button>
                                    </DialogActions>
                            </Dialog> 


                            <Dialog onClose={e => this.handleCopy(false)} 
                                    aria-labelledby="customized-dialog-title" 
                                    fullWidth="lg"
                                    maxWidth="lg"
                                    open={this.state.copyShow}>
                                <DialogTitle id="customized-dialog-title" onClose={e => this.handleCopy(false)}>
                                    Daten kopieren 
                                </DialogTitle>
                                <DialogContent style={{height: "40px"}}>
                                    Kopiere {this.state.posts.article[0].artnr} nach 

                                        <select name="newCarId" onChange={e => this.changeNewCarId(e)} >
                                        { this.state.cars.map(car => (
                                                <option value={car.id}>{car.label}</option>
                                        ))}
                                        </select>             


                                </DialogContent>


                                <DialogContent style={{height: "400px", overflow: "scroll"}}>
                                <SortableTree
                                    treeData={this.state.treeDataCopy}
                                    canDrag={({ node }) => !node.noDragging}
                                    generateNodeProps={rowInfo => ({
                                        buttons: [
                                            <Checkbox
                                                checked={rowInfo.node.copy}
                                                onChange={(e) => this.handleCopyNode(rowInfo)}
                                                value="primary"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />                                                                                     
                                        ],
                                    })}
                                />                        
                                </DialogContent>
                                <DialogActions>
                                <Button autoFocus onClick={e => this.handleCopy(false)} color="primary">
                                    Abbruch
                                </Button>
                                <Button autoFocus onClick={e => this.handleCopy("save")} color="primary">
                                    OK
                                </Button>
                                </DialogActions>
                            </Dialog>
                        </div>                                    
                    </div>
                    <div className="clearmargin gridBottom">
                        <Button onClick={this.handleSaveClose}>
								Speichern
						</Button>
                        <Button onClick={e => this.props.history.push("/maske/96/167/" + this.props.match.params.itemId)}>
								Abbrechen
						</Button>
                    </div>
                </div>
            );
       }
    }
}
export default ConfigureCar ;

