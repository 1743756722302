import React, { Component } from 'react';
import axios from 'axios';
import {getAppUrl} from '../helpers/Defaults';
import {getToken} from '../helpers/Token';
import {Tabs, Tab} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { useSnackbar, withSnackbar } from 'notistack';
import Fields from './Fields';
import CreateNew from './blocks/CreateNew';
import { ResizableBox } from "react-resizable";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  resizable: {
    position: "relative",
    "& .react-resizable-handle": {
      position: "absolute",
      width: 20,
      height: 20,
      bottom: 0,
      right: 0,
      background:
        "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
      "background-position": "bottom right",
      padding: "0 3px 3px 0",
      "background-repeat": "no-repeat",
      "background-origin": "content-box",
      "box-sizing": "border-box",
      cursor: "se-resize"
    }
  }
  
});

class Tables extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            currentTableId: 0,
            offset: 0,
            isLoaded : false,
            productData: [],
            currentSearch: "",
            fields: [],
            height: 600,
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.searchChange = this.searchChange.bind(this);
        this.handleCloseSave = this.handleCloseSave.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.createNew = this.createNew.bind(this);
        this.reload = this.reload.bind(this);
    }

    createNew(newName) {
        let table = {};
        table.id = -1;
        table.name = newName;
        axios.post(getAppUrl() + '/gatebackend/rest/settings/tables/add', 
            table
        ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
            this.props.enqueueSnackbar(this.props.t('Tabelle hinzugefügt'), { variant: 'info' });
            this.reload();
        }
            
        )
        .catch((error) => {
            console.log(error);
            alert("error");
        });        

    }

	handleStateChange(table) {
        this.setState({
			table: table
		});
	}

    searchChange(e) {
        this.setState({
			currentSearch: e
		});
    }
   componentDidMount() {
        this.reload();
    }
   reload() {
        axios.get(getAppUrl() + '/gatebackend/rest/settings/tables', 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.setState({
                    isLoaded : true,
                    posts: res.data
                })   
            });
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleCloseSave() {
        var tableId = this.state.currentTableId;

        axios.post(getAppUrl() + "/gatebackend/rest/settings/tables/" + tableId, 
                this.state.table
            ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
            this.props.enqueueSnackbar(this.props.t('Änderungen gespeichert'), { variant: 'info' });
            this.setState({ show: false });
        }
            
        )
        .catch((error) => {
            console.log(error);
            alert("error");
        });
            
    }

    handleShow(e) {
        this.setState({currentTableId : e.currentTarget.dataset.id});
        this.setState({currentTableName : e.currentTarget.dataset.tablename});
        
        this.setState({ show: true });
    }

    render() {
        const {isLoaded, posts} = this.state;
        if(!isLoaded) {
            return (<div>loading</div>)
        }
        else {
            let elHeight = document.getElementById('actionFrame').clientHeight -100;
            var height = {
                'max-height': elHeight + 'px',
            };
            return (
                <div>
                    <div class="Vivacard" style={height}>
                        <div class="Vivacardheader">                           
                            Tabellen
                            <CreateNew searchChange={this.searchChange} actionNew={this.createNew} title="Neue Tabelle"/>
                        </div>
                        <div class="Vivacardbody">
                            <div class="border">
                                <div class="row clearmargin"  align="start">
                                    <div class="grid-Cell grid-Cell-Header col-sm-1">
                                        <div class="grid-Cell__header "> 
                                            ID
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            Parent
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            Datensätze
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm">
                                        <div class="grid-Cell__header"> 
                                            Name
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            leeren
                                        </div>
                                    </div>
                                </div>
                                {
                                    posts.map(post => (
                                    <>
                                    {this.state.currentSearch === "" || ((post.id).toString().includes(this.state.currentSearch) ||  post.name.toLowerCase().includes((this.state.currentSearch).toLowerCase())) ? (

                                        <div class="row clearmargin grid-Row" data-id={post.id} data-tablename={post.name}  onDoubleClick={((e) => this.handleShow(e))} align="start">
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value" > 
                                                    {post.id}
                                                </div>
                                            </div>
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value"> 
                                                    {post.parentId}
                                                </div>
                                            </div>    
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value"> 
                                                    {post.amount}
                                                </div>
                                            </div>
                                            <div class="grid-Cell col-sm"> 
                                                <div class="grid-Cell__value"> 
                                                    {post.name}
                                                </div>
                                            </div> 
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value"> 
                                                    <ul>  
                                                        <li class="fa fa-trash-o gridIcon">

                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>                                                                                    
                                        </div>
                                        ): (<></>)}
                                        </>                                        
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                                        
                    <Dialog
                            open={this.state.show}
                            onClose={this.handleClose}
                            // TransitionComponent={Draggable}
                            maxWidth={false}
                            
                            aria-labelledby="draggable-dialog-title"
                    >
                        <ResizableBox
                            height={600}
                            width={800}
                            className={this.props.classes.resizable}
                            onResizeStop={(e, data)  => {
                                this.setState({ height: data.size.height });

                                console.log(data);
                            }}
                            > 
                            <DialogTitle className="dialogTitle" 
                                        disableTypography id="form-dialog-title">Tabelle: {this.state.currentTableName}
                                <IconButton edge="end" color="inherit" onClick={this.handleClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <div  style={{height: "calc(100% - 150px)"}}>
                                <div id="xwrapper">
                                    <article >
                                        <Fields height={this.state.height} handleStateChange={this.handleStateChange} tableId={this.state.currentTableId}></Fields>
                                    </article>
                                </div>
                            </div>
                            
                            <DialogActions>
                                <Button variant="secondary" onClick={this.handleClose}>
                                {this.props.t('Abbruch')}
                                </Button>
                                <Button variant="primary" onClick={this.handleCloseSave}>
                                {this.props.t('Speichern')}
                                </Button>
                            </DialogActions>
                        </ResizableBox>
                    </Dialog>

                    
                </div> 
            );
       }
    }
}
export default withTranslation()(withStyles(styles)(withSnackbar(Tables)));
