import React, { Component } from 'react';
import axios from 'axios';
import {getToken, getExtranetToken} from '../helpers/Token';
import {getAppUrl} from '../helpers/Defaults';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { useSnackbar, withSnackbar } from 'notistack';
import Loader from './blocks/Loader'
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DoneIcon from '@material-ui/icons/Done';
import GetAppIcon from '@material-ui/icons/GetApp';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {getWSUrl} from '../helpers/Defaults';

class ShopwareExports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            offset: 0,
            isLoaded : false,
            exportLanguageOpen: false,
            refreshArticleOpen: false,
            refreshImagesOpen: false,
            chooseLanguage: true,
            chooseArticle: true,
            chooseImages: true,
            sourcelanguageId: 3660,
            languageId: 3661,
            csvContent: "",
            articleNumber: "",
            noticeMsg: "",
            currentLanguage: -1,
            translateMissingTyp: ""
        };
        this.handleLanguageDialog = this.handleLanguageDialog.bind(this);
        this.refreshArticleDialog = this.refreshArticleDialog.bind(this);
        this.refreshImagesDialog = this.refreshImagesDialog.bind(this);
        this.exportLanguage = this.exportLanguage.bind(this);
        this.exportImages = this.exportImages.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
        this.changeSourceLanguage = this.changeSourceLanguage.bind(this);
        this.translateMissingText = this.translateMissingText.bind(this);

    }

    changeArticleNumber(e) {
        this.setState({articleNumber: e.target.value });
    }

    changeLanguage(e) {
        this.setState({languageId: e.target.value });
    }

    changeSourceLanguage(e) {
        this.setState({sourcelanguageId: e.target.value });
    }

	downloadFile(encoded, filename) {
		var fileDownload = require('js-file-download');
		fileDownload(encoded, filename);
    }
    
    componentDidMount() {       
        this.reload("reload", this.props.match.params.itemId);
    }

    componentWillMount() {
        let client = new W3CWebSocket(getWSUrl() + '/extranet/websocketendpoint');
        client.onopen = () => {
            console.log("connect WS1");
        };
        client.onmessage = (message) => {
            let obj = JSON.parse(message.data);
            if(obj.type === "translateUpdate") {
                this.setState({
                    noticeMsg : obj.message ,
                })     
            }

        };
    }

    translateMissingText() {
        this.setState({
            isLoaded : true,
            translateMissingTyp: "Texte"
        })        

        axios.get(getAppUrl() + '/extranet/rest/shopwareexport/doExport', 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.setState({
                    isLoaded : true
                }) 
            })
            .catch((error) => {
                console.log(error);
            });
    }

    
    

    reload(e) {  
        this.setState({
            isLoaded : true,
            posts: []
        });
            
		

    }

    async importArticle() {
        this.setState({chooseArticle: false });
        const getEToken = async ()  => {
            let x = await getExtranetToken();
            return x;
        }        
        let token =  await getEToken();
        axios.get(getAppUrl() + '/extranet/rest/as400/importer/' + this.state.articleNumber, 
            {
                headers: { 
                    "Authorization": " Bearer " + token,
                }
            }).then(res => {
                if(res.data.details[0].insertArticles === 1)
                    this.props.enqueueSnackbar(this.props.t('Artikel hinzugefügt'), { variant: 'info' });
                else if(res.data.details[0].updateArticles === 1)
                    this.props.enqueueSnackbar(this.props.t('Artikel aktualisiert'), { variant: 'info' });
                else
                    this.props.enqueueSnackbar(this.props.t('Fehler beim importieren'), { variant: 'info' });

                this.setState({ refreshArticleOpen: false, chooseArticle: true });
            }).catch((error) => {
                console.log(error);
                alert("error");
			});
    }    

    async exportImages() {
        this.setState({chooseImages: false });
        const getEToken = async ()  => {
            let x = await getExtranetToken();
            return x;
        }        
        let token =  await getEToken();
        
        axios.get(getAppUrl() + '/extranet/rest/articles/exportImages/csv?kind=all', 
            {
                headers: { 
                    "Authorization": " Bearer " + token
                }
            }).then(res => {
                let base64 = require('base-64');
                let utf8 = require('utf8');
                let bytes = base64.decode(res.data.bdata);
                let encoded = utf8.decode(bytes);
                this.downloadFile(encoded, 'images.csv');
                this.setState({ refreshImagesOpen: false, chooseImages: true });
            }).catch((error) => {
					console.log(error);
					alert("error");
			});
    }


    async exportLanguage() {
        this.setState({chooseLanguage: false });
        const getEToken = async ()  => {
            let x = await getExtranetToken();
            return x;
        }        
        let token =  await getEToken();
        
        axios.get(getAppUrl() + '/extranet/rest/articles/tranlation/csv?language=' + this.state.languageId + '&sourcelanguage=' + this.state.sourcelanguageId, 
            {
                headers: { 
                    "Authorization": " Bearer " + token
                }
            }).then(res => {
                let base64 = require('base-64');
                let utf8 = require('utf8');
                let bytes = base64.decode(res.data.bdata);
                let encoded = utf8.decode(bytes);
                this.downloadFile(encoded, 'language.csv');
                this.setState({ exportLanguageOpen: false, chooseLanguage: true });
            }).catch((error) => {
					console.log(error);
					alert("error");
			});
    }

    handleLanguageDialog(which) {
        this.setState({ exportLanguageOpen: which });
    }

    refreshArticleDialog(which) {
        this.setState({ refreshArticleOpen: which });
    }

    refreshImagesDialog(which) {
        this.setState({ refreshImagesOpen: which });
    }

    render() {
        const user = this.state.user;
        const {isLoaded, posts} = this.state;
        if(!isLoaded) {
            return (<div>1111<Loader/></div>)
        }
        else {
            return (
                <div>
                    <div class="Vivacardheader" >                           
                        {this.props.t('Export Übersicht')}
                    </div>

 

                    <div style={{paddingTop: "25px"}} >
                        <div >
                            <Paper>
                                <div className="card-icon" style={{background: "linear-gradient(60deg, #66bb6a, #43a047)"}}>
                                    <span className="fa fa-globe innerCenter"></span>
                                </div>
                                <span className="float-right headlineDashboard" > exporte</span>
                                <hr style={{marginLeft: "10px", marginRight: "10px"}}/>
                                    <List>
                                        <ListItem >
                                            <ListItemAvatar onClick={((e) => this.translateMissingText())}>
                                                <Avatar className="classesAvatar">
                                                    <GetAppIcon/>
                                                </Avatar>
                                            </ListItemAvatar>
                                        </ListItem>
                                        <ListItemText primary={
                                                <div>
                                                    <div>mmm</div>
                                                    <div className="MuiTypography-body2" style={{color: "#a00000"}} >{"Status: " + this.state.noticeMsg }</div>
                                                </div>
                                            }
                                        />
                                    </List>
                            </Paper> 
                        </div>   
                    </div>   


                    <Dialog open={this.state.refreshImagesOpen} 
                            onClose={((e) => this.refreshImagesDialog(false))} 
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{this.props.t('Images exportieren')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.props.t('Exportieren Sie die Bilderliste')}
                            </DialogContentText>

                            {this.state.chooseImages === true ? (
                                <>                                
                           
                                </>
                                )
                                :(<div><Loader/></div>)
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={((e) => this.refreshImagesDialog(false))} color="primary">
                                {this.props.t('Abbruch')}
                            </Button>
                            <Button onClick={((e) => this.exportImages())} color="primary">
                                {this.props.t('OK')}
                            </Button>
                            </DialogActions>
                    </Dialog>                     


                    <Dialog open={this.state.refreshArticleOpen} 
                            onClose={((e) => this.refreshArticleDialog(false))} 
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{this.props.t('Artikel aktualisieren')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.props.t('Aktualisieren Sie einen Artikel aus der AS400')}
                            </DialogContentText>

                            {this.state.chooseArticle === true ? (
                                <>                                
                                <FormControl  style={{width: "100%"}}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        name="articleNumber"
                                        label="Artikelnummer"
                                        type="email"
                                        onChange={e => this.changeArticleNumber(e)}
                                        value={this.state.articleNumber}
                                        fullWidth
                                    />
                                </FormControl>                            
                                </>
                                )
                                :(<div><Loader/></div>)
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={((e) => this.refreshArticleDialog(false))} color="primary">
                                {this.props.t('Abbruch')}
                            </Button>
                            <Button onClick={((e) => this.importArticle())} color="primary">
                                {this.props.t('OK')}
                            </Button>
                            </DialogActions>
                    </Dialog>                     

                    <Dialog open={this.state.exportLanguageOpen} 
                            onClose={((e) => this.handleLanguageDialog(false))} 
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{this.props.t('Sprache exportieren')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.props.t('Exportiern Sie hier alle Artikel in einer Sprache')}
                            </DialogContentText>

                            {this.state.chooseLanguage === true ? (
                                <>                                
                                <FormControl  style={{width: "100%"}}>
                                    <InputLabel htmlFor="source-helper">Ausgangssprache auswählen</InputLabel>
                                    <NativeSelect
                                        value={this.state.sourcelanguageId}
                                        onChange={e => this.changeSourceLanguage(e)}
                                        inputProps={{
                                            name: 'bookmarkfolder',
                                            id: 'source-helper',
                                        }}
                                    >
                                        <option value="3660">Deutsch</option>
                                        <option value="3661">English</option>
                                        <option value="3672">Französich</option>
                                        <option value="3675">Italienisch</option>
                                        <option value="3676">Spanisch</option>
                                        <option value="3677">Niederländisch</option>
                                        <option value="3678">Polnisch</option>
                                        <option value="3681">Dänisch</option>
                                        <option value="3682">Schwedisch</option>
                                        <option value="3683">Finnisch</option>
                                        <option value="3684">Norwegisch</option>
                                        <option value="3685">Türkisch</option>
                                    </NativeSelect>
                                </FormControl>
                                <FormControl  style={{width: "100%"}}>
                                    <InputLabel htmlFor="destination-helper">Zielsprache auswählen</InputLabel>
                                    <NativeSelect
                                        value={this.state.languageId}
                                        onChange={e => this.changeLanguage(e)}
                                        inputProps={{
                                            name: 'bookmarkfolder',
                                            id: 'destination-helper',
                                        }}
                                    >
                                        <option value="3660">Deutsch</option>
                                        <option value="3661">English</option>
                                        <option value="3672">Französich</option>
                                        <option value="3675">Italienisch</option>
                                        <option value="3676">Spanisch</option>
                                        <option value="3677">Niederländisch</option>
                                        <option value="3678">Polnisch</option>
                                        <option value="3681">Dänisch</option>
                                        <option value="3682">Schwedisch</option>
                                        <option value="3683">Finnisch</option>
                                        <option value="3684">Norwegisch</option>
                                        <option value="3685">Türkisch</option>
                                    </NativeSelect>
                                </FormControl>                            
                                </>
                                )
                                :(<div><Loader/></div>)
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={((e) => this.handleLanguageDialog(false))} color="primary">
                                {this.props.t('Abbruch')}
                            </Button>
                            <Button onClick={((e) => this.exportLanguage())} color="primary">
                                {this.props.t('OK')}
                            </Button>
                            </DialogActions>
                    </Dialog> 

                </div> 
            );
       }
    }
}
export default withTranslation()(withSnackbar(ShopwareExports)) ;

