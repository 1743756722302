import React, { Component } from 'react';
import axios from 'axios';
import qs from 'qs';
import {getAppUrl, getCompanyName} from '../helpers/Defaults';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            error: false,
            errorNetwork: false,
            pwdtype: "password",
            pwdclass: "fa fa-eye-slash",
            isLoaded: false,
            companyInfo: {}
        };
        this.change = this.change.bind(this);
        this.submit = this.submit.bind(this);
        this.showPWD = this.showPWD.bind(this);
        
    }

    componentDidMount() {
        axios.get(getAppUrl() + '/gatebackend/rest/settings/companyInfo/' + getCompanyName(), 

        ).then(res => {
            this.setState({
                isLoaded : true,
                companyInfo: res.data                            
            }) 

        }).catch((error) => {
            console.log(error);
        });
    }


    showPWD(e) {
        let pwdtype = this.state.pwdtype;
        let pwdclass = "";
        if(pwdtype === "password") {
            pwdtype = "text";
            pwdclass= "fa fa-eye";
        }
        else {
            pwdtype = "password";
            pwdclass= "fa fa-eye-slash";

        }

        this.setState({
            pwdtype: pwdtype,
            pwdclass: pwdclass
        })
    }

    change(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    submit(e) {
        e.preventDefault();
        const data = {
            username: this.state.username,
            password: this.state.password
        };
        this.setState({
            error: false,
            errorNetwork: false
        })
        axios.post(getAppUrl() + '/gatebackend/rest/authentication',        
            qs.stringify(data), 
            {
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "x-company": getCompanyName()
                }
            }).then(res => {
                localStorage.setItem('app-token', res.data.token)
				localStorage.setItem('app-user', res.data.id)
                this.props.history.push('/');   
            }).catch((error) => {
                var errorMSG  = "";
                var errorNetwork = false;
                var error = false
                console.log(error);
                if (error.response) {
                    error = true;
                }
                else if (error.request) {
                    errorNetwork = true;
                    error = true;
                }
                this.setState({
                    error: true,
                    errorNetwork: errorNetwork
                })
            });
    }

    render() {
        const isLoaded = this.state.isLoaded;
        if(!isLoaded) {
                return (<div>loading</div>)
        }
        else {
        const imageBig = {backgroundImage : "url('/images/"+this.state.companyInfo.imageBig+"')"};

            return(
                <div className="row no-gutters min-h-fullscreen bg-white">
                    <div className="col-md-6 col-lg-7 col-xl-8 d-none d-md-block bg-img border" style={imageBig}>
                        <div className="row h-100 pl-50">
                            <div className="col-md-10 col-lg-8 align-self-end">
                                <h4 className="text-white">VivaGate 10.0 für {this.state.companyInfo.label}</h4>
                                <p className="text-white"></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-5 col-xl-4 align-self-center">
                        <div className="px-80 py-30">
                                <div className="loginHeader">
                                    <span><Avatar >
                                        <LockOutlinedIcon />
                                    </Avatar></span>
                                    
                                    <h4>Sign in</h4>
                                </div>
                                <p><small>Anmelden, um VivaGate zu benutzen</small></p>
                                <form className="form-type-material" onSubmit={e => this.submit(e)}>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <i className="fa fa-user"></i>
                                        </span>
                                        <input type="text" className="form-control" name="username" placeholder="Username" onChange={e => this.change(e)} value={this.state.username} />
                                        
                                    </div>
                                    <br></br>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <i className="fa fa-lock"></i>
                                        </span>                                
                                        <input type={this.state.pwdtype} className="form-control password" name="password" placeholder="Passwort" onChange={e => this.change(e)}/>
                                        <button className="unmask " type="button" onClick={e => this.showPWD(e)}><i className={this.state.pwdclass}></i></button>
                                    </div>
                                    <br></br>
                                    <div className="form-group">
                                        <button className="btn btn-bold btn-block btn-primary" type="submit">Login</button>
                                    </div>
                                </form>   
                                {this.state.error === true ? (
                                    <p><small>Login oder Passswort ist falsch</small></p>                        
                                    ) : (<></>)
                                } 
                                {this.state.errorNetwork === true ? (
                                    <p><small>Netzwerk Fehler</small></p>                        
                                    ) : (<></>)
                                } 
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Login;