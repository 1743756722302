import axios from 'axios';
import {getAppUrl} from './Defaults';
import qs from 'qs';


export const getToken = ()  => {
    return  localStorage.getItem('app-token');
}

export const getExtranetToken = async ()  => {
    let x = await validatePimToken();
    return x;
}


export const validatePimToken = async () => {
    try {
        const loginData = {
            token: localStorage.getItem('extranet-token'),
        };        
        const { data } = await axios({
            method: 'post',
            url: getAppUrl() + '/extranet/rest/authentication/renewToken',
            data: qs.stringify(loginData),
            headers: { 
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });
        return localStorage.getItem('extranet-token');
    } catch (e) {
        return await getPimToken();
    }

};

export const getPimToken = async () => {
    try {
        const loginData = {
            username: "admin",
            password: "test1234!"
        };        
        const { data } = await axios({
            method: 'post',
            url: getAppUrl() + '/extranet/rest/authentication',
            data: qs.stringify(loginData),
            headers: { 
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });
        localStorage.setItem('extranet-token', data.token)
        return data.token;
    } catch (e) {
        return ;
    }

};



export const getUser = ()  => {
    return  localStorage.getItem('app-user');
}


export const getTokenx = async ()  => {
    const data = await getData();
    return  data.token;
}


export const getData = async () => {
    try {
        const { data } = await axios({
            method: 'get',
            url: 'http://localhost:8080/gatebackend/rest/authentication/' + localStorage.getItem('app-token'),
        });
        return data;
    } catch (e) {
        console.log(e);
        alert("token-error")
        return ;
    }
};


export const getExtranetTokenX = ()  => {
    const data = {
        username: "admin",
        password: "test1234!"
    };
    let res;
    axios.post(getAppUrl() + '/extranet/rest/authentication',        
        qs.stringify(data), 
        {
            headers: { 
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }).then(res => {
            localStorage.setItem('extranet-token', res.data.token)
        }).catch((error) => {
            var error = false
            if (error.response) {
                error = true;
            }
            else if (error.request) {
                error = true;
            }
            console.log(res.data.token);
        });

    return  localStorage.getItem('extranet-token');
}

