import React, { Component } from 'react';
import axios from 'axios';
import MaskEdit from './MaskEdit';
import {getAppUrl} from '../helpers/Defaults';
import {getToken} from '../helpers/Token';
import {Modal} from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CreateNew from './blocks/CreateNew';
import SortableTree, { addNodeUnderParent, removeNodeAtPath, changeNodeAtPath, getNodeAtPath, toggleExpandedForAll, defaultSearchMethod, map as mapTree } from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import { Resize, ResizeVertical, ResizeHorizon } from "react-resize-layout";
import Button from '@material-ui/core/Button';
import Access from './blocks/Access';

import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { useSnackbar, withSnackbar } from 'notistack';
const alertNodeInfo = ({ node, path, treeIndex }) => {
  const objectString = Object.keys(node)
    .map(k => (k === 'children' ? 'children: Array' : `${k}: '${node[k]}'`))
    .join(',\n   ');

  global.alert(
    'Info passed to the button generator:\n\n' +
      `node: {\n   ${objectString}\n},\n` +
      `path: [${path.join(', ')}],\n` +
      `treeIndex: ${treeIndex}`
  );
};
class GateMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            currentTableId: 0,
            offset: 0,
            isLoaded : false,
            productData: [],
            currentSearch: "",
            currentNode: {},
            treeData: [],
            counter: -1
        };
        this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleStateChange = this.handleStateChange.bind(this);
        this.reload = this.reload.bind(this);
        this.searchChange = this.searchChange.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.change = this.change.bind(this);
        this.handleSaveClose = this.handleSaveClose.bind(this);
    }

    change(e) {
        let node = this.state.currentNode;
        let newTree = this.state.treeData;
        let newTitle = node.title;
        let link = node.link;
        if(e.target.name == "label")
			newTitle = e.target.value;
        if(e.target.name == "link")
			link = e.target.value;
        newTree.map(function(node1) {
            if(node1.id === node.id) {
                node1.title = newTitle;
                node1.link = link;
            }
            if(node1.children) {
                node1.children.map(function(node2) {
                    if(node2.id === node.id) {
                        node2.title = newTitle;
                        node2.link = link;
                    }            
                    if(node2.children) {
                        node2.children.map(function(node3) {
                            if(node3.id === node.id) {
                                node3.title = newTitle;
                                node3.link = link;
                            } 
                            if(node3.children) {
                                node3.children.map(function(node4) {
                                    if(node4.id === node.id) {
                                        node4.title = newTitle;
                                        node4.link = link;
                                    }   
                                });                             
                            }
                        });
                    }
                });
            }
        });

        this.setState({ treeData: newTree});

    }

    searchChange(e) {
        this.setState({
			currentSearch: e
		});
    }

	reload() {
		;
	}

	handleStateChange() {
		;
	}

   componentDidMount() {
        axios.get(getAppUrl() + '/gatebackend/rest/settings/linksTree', 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.setState({
                    isLoaded : true,
                    treeData: res.data
                })   
            });
    }
    toggleNodeExpansion = expanded => {
        this.setState(prevState => ({
        treeData: toggleExpandedForAll({
            treeData: prevState.treeData,
            expanded,
        }),
        }));
    };
    handleClose() {
        this.setState({ show: false });
    }

    handleSaveClose() {
        axios.post(getAppUrl() + "/gatebackend/rest/settings/linksTree", 
                this.state.treeData
            ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
            this.props.enqueueSnackbar(this.props.t('Menü gespeichert'), { variant: 'info' });
            this.setState({ show: false });
        }
            
        )
        .catch((error) => {
            console.log(error);
            alert("error");
        });

        this.setState({ show: false });
    }

    handleDelete(rowInfo) {
        let {node, treeIndex, path} = rowInfo;
        const newTree = removeNodeAtPath({
            treeData: this.state.treeData,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex,
        });
        this.setState({treeData: newTree });       
    }
     
    handleAdd(rowInfo) {
        let newCounter = this.state.counter;
        let NEW_NODE = {title: 'Another Node', noDragging: false, id: newCounter--};
        let {node, treeIndex, path} = rowInfo;
        path.pop();
        let parentNode = getNodeAtPath({
            treeData: this.state.treeData,
            path : path,
            getNodeKey: ({ treeIndex }) =>  treeIndex,
            ignoreCollapsed : true
        });
        let getNodeKey = ({ node: object, treeIndex: number }) => {
            return number;
        };
        let parentKey = getNodeKey(parentNode);
        if(parentKey == -1) {
            parentKey = null;
        }
        let newTree = addNodeUnderParent({
                treeData: this.state.treeData,
                newNode: NEW_NODE,
                expandParent: true,
                parentKey: parentKey,
                getNodeKey: ({ treeIndex }) =>  treeIndex
         });
         this.setState({treeData: newTree.treeData,
                        show: true,
                        currentNode: NEW_NODE,
                        counter: newCounter
        });
    }

    handleShow(rowInfo) {
        let {node, treeIndex, path} = rowInfo;

        this.setState({ show: true,
                        currentNode: node,
                        path: path });
    }

    render() {
        const {isLoaded, posts} = this.state;
        if(!isLoaded) {
            return (<div>loading</div>)
        }
        else {
            let elHeight = document.getElementById('actionFrame').clientHeight -100;
            var height = {
                'max-height': elHeight + 'px',
            };
            return (
                <div>
                    <div class="Vivacard" style={height}>
                        <div class="Vivacardheader">
                           Menü
                        </div>
                        <div class="Vivacardbody" style={{ height: "calc(100vH - 200px)" }}>
                            <div className="row " style={{ height: "calc(100vH - 200px)" }}>
                                <div className="col-md-5 col-lg-5 col-xl-5 disablePaddingRight">
                                    <div class="Vivacardheader">
                                        <Button
                                            type="button"
                                            className="buttonIcon"
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                            onClick={this.handleSaveClose}
                                            >
                                            <span className="fa fa-download "></span>
                                        </Button>
                                        <Button
                                            type="button"
                                            className="buttonIcon"
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                            onClick={this.toggleNodeExpansion.bind(this, true)}
                                            >
                                            <span className="fa fa-expand "></span>
                                        </Button>   
                                        <Button
                                            type="button"
                                            className="buttonIcon"
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                            onClick={this.toggleNodeExpansion.bind(this, false)}
                                            >
                                            <span className="fa fa-compress "></span>
                                        </Button>                                                                              
                                    </div>                                
                                    <SortableTree
                                        treeData={this.state.treeData}
                                        onChange={treeData => this.setState({ treeData })}
                                        generateNodeProps={rowInfo => ({
                                        buttons: [
                                            <Button
                                                type="button"
                                                className="buttonIcon"
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                onClick={() => this.handleShow(rowInfo)}
                                                >
                                                <span className="fa fa-pencil "></span>
                                            </Button>,                                            
                                            <Button
                                                type="button"
                                                className="buttonIcon"
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                onClick={(e) => this.handleAdd(rowInfo)}
                                                >
                                                <span className="fa fa-plus "></span>
                                            </Button>,
                                            <Button
                                                type="button"
                                                className="buttonIcon"
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                onClick={(e) => this.handleDelete(rowInfo)}
                                                >
                                                <span className="fa fa-minus "></span>
                                            </Button>,                                           
                                        ],
                                        })}                                        
                                    />
                                </div>
                                <div className="col-md-5 col-lg-5 col-xl-5 disablePaddingRight">

                                </div>

                            </div>
                        </div>
                    </div>
                    <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>MenüPunkt: </Modal.Title>
                        </Modal.Header>
                        <Modal.Body class="modalBody">
                            <div class="Vivacard">
                                <div class="Vivacardheader">
                                    Allgemein                                
                                </div>
                                <div class="border">                               
                                    <div className="row clearmargin "  align="start">
                                        <div className="col-sm-3 ">
                                            ID: 
                                        </div>
                                        <div className="col-sm">
                                            {this.state.currentNode.id}
                                        </div>
                                    </div>
                                    <div className="row clearmargin "  align="start">
                                        <div className="col-sm-3 ">
                                            Label: 
                                        </div>
                                        <div className="col-sm">
                                            <input maxlength="30" size="30" type="text" name="label"  onChange={e => this.change(e)} value={this.state.currentNode.title}/>
                                        </div>
                                    </div>                           
                                    <div className="row clearmargin "  align="start">
                                        <div className="col-sm-3 ">
                                            Link: 
                                        </div>
                                        <div className="col-sm">
                                            <input maxlength="30" size="30" type="text" name="link"  onChange={e => this.change(e)} value={this.state.currentNode.link}/>
                                        </div>
                                    </div> 
                                    <div className="row clearmargin "  align="start">
                                        <div className="col-sm-3 ">
                                            Zugriff: 
                                        </div>
                                        <div className="col-sm">
                                            <Access type="menu" label={this.state.currentNode.title} id={this.state.currentNode.id}/>
                                        </div>
                                    </div>                                                              
                                </div>                           
                            </div>                           

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                            Abbruch
                            </Button>
                            <Button variant="primary" onClick={this.handleClose}>
                            Speichern
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div> 
            );
       }
    }
}

export default withTranslation()(withSnackbar(GateMenu)) ;
