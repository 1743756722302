import React, { Component, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withSnackbar, closeSnackbar, useSnackbar } from 'notistack';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {getWSUrl} from '../../helpers/Defaults';

const styles = {
    root: {
        flexGrow: 1,
        display: 'flex',
        margin: 16,
        justifyContent: 'center',
        alignItems: 'middle',
    },
    button: {
        margin: 8,
        color: '#fff',
        backgroundColor: '#313131',
    },
    success: {
        backgroundColor: '#43a047',
    },
    error: {
        backgroundColor: '#d32f2f',
    },
    info: {
        backgroundColor: '#2979ff',
    },
    warning: {
        backgroundColor: '#ffa000',
    },
    default: {
        backgroundColor: '#ff0000',
    },    
};

const client = new W3CWebSocket(getWSUrl() + '/gatebackend/websocketendpoint');

type Key = string | number | undefined;

interface CloseSnackbarProps {
  key: Key;
}



const action = key => (
    
    <Fragment>
        <IconButton
            aria-label="Close notification"
            color="inherit"
            onClick={() => this.close(key)}
            >
            <CloseIcon fontSize="small" />
        </IconButton>
    </Fragment>
);

const CloseButton: FC<CloseSnackbarProps> = ({ key }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      aria-label="Close notification"
      color="inherit"
      onClick={() => closeSnackbar(key)}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
};

class MessageButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        }
        this.handleExpandClick = this.handleExpandClick.bind(this);
        this.downloadClick = this.downloadClick.bind(this);
        this.close = this.close.bind(this);
    } 

    close(key) {
        alert(key);
    }


    downloadClick(obj) {
        let file = JSON.parse(obj.result);
        const downloadLink = document.createElement("a");

        downloadLink.href = file.url;
        downloadLink.download = file.filename;
        downloadLink.click();
    }

    handleExpandClick() {
        if(this.state.expanded === true) {
            this.state = {
                expanded: false,
            }
            console.log("false");
        }
        else {
            this.state = {
                expanded: true,
            }
            console.log("true");

        }
    };


    handleClick = button => () => {
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
        this.props.enqueueSnackbar(button.message, { variant: button.variant });
    };

    componentWillMount() {
        client.onopen = () => {
            this.props.enqueueSnackbar("connected", {variant: 'success', action: key => (
                            <>
                                <CardActions className="snackHeader">
                                    <div >
                                        <CloseButton key={key} />
                                    </div>
                                </CardActions>
            </>) });
        };
        
        client.onmessage = (message) => {
            console.log("JobReceived");

            let obj = JSON.parse(message.data);
            if(obj.type === "Job finish") {
                this.props.enqueueSnackbar("Job: "+ obj.message +" fertig", {
                        persist: true,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        },
                        action: key => (
                            <>
                                <CardActions className="snackHeader">
                                    <div >
                                        <CloseButton key={key} />
                                    </div>
                                </CardActions>
                                <div className="snackDownloadBottom"> 
                                    <Paper className="snackDownloadBottom">
                                        <Button size="small" 
                                                className={styles.button}
                                                onClick={(e) => this.downloadClick(obj)}>
                                            <SaveAltIcon  className={styles.checkIcon}/>
                                                &nbsp;Download File
                                        </Button>
                                    </Paper>
                                </div>                                
                            </>
                        ),
                    }
                );                 
            }
        };
    }


    handleClickWithAction = () => {
        this.props.enqueueSnackbar('Customise this snackbar youself.', {
            variant: 'default',
            action: (
                <Button color="secondary" size="small" onClick={() => alert('clicked on my custom action')}>
                    My action
                </Button>
            ),
            // Alternatively, you can access the key of current snackbar by passing an action of type function
             action: key => (
                 <Fragment>
                     <Button color="secondary" size="small" onClick={() => alert(`Clicked on action of snackbar with key: ${key}`)}>
                         Detail
                     </Button>
                     <Button color="secondary" size="small" onClick={() => this.props.closeSnackbar(key)}>
                         Dismiss
                     </Button>
                 </Fragment>
             ),
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <></>
        );
    }
}



MessageButtons.propTypes = {
    classes: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(
    withSnackbar(MessageButtons),
);