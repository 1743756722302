import React, { Component } from 'react';
import NumberField from './blocks/NumberInput';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import axios from 'axios';
import {getToken} from '../helpers/Token';
import Access from './blocks/Access';
import {Tabs, Tab} from 'react-bootstrap';
import {getAppUrl} from '../helpers/Defaults';
import Button from '@material-ui/core/Button';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { useSnackbar, withSnackbar } from 'notistack';
import FontIconPicker from '@fonticonpicker/react-fonticonpicker';
import TextField from '@material-ui/core/TextField';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import './blocks/icon-helper/superhero/style.css';
import * as iconDefs from './blocks/icon-helper/iconDefs';
import AlltoursIcons from './blocks/icon-helper/AlltoursIcons';
import Loader from './blocks/Loader'

class Fields extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            isLoaded : false,
            key: 'fields',
            productData: [],
            newCounter: -1,
            contentHeight: props.height - 200,
            iconValue:  []
        };

        this.change = this.change.bind(this);
        this.deleteEntry = this.deleteEntry.bind(this);
        this.addNewEntry = this.addNewEntry.bind(this); 
        this.changeIconValue = this.changeIconValue.bind(this);
    }
    
    changeIconValue(val) {
        this.setState({ iconValue: val });
    }

  
    addNewEntry(which) {		
        let table = this.state.table;
		let counter = this.state.newCounter;
		let cLE = {};
        cLE.id = counter;
        cLE.name = "NeuesFeld";
        cLE.type = "textinput";
        cLE.defaultValue = "";
        cLE.fieldSpec = 0;
        cLE.searchField = 0;
        cLE.selectedValues = "";
        cLE.markAsDelete = "false";
        cLE.dbProperties = 50;
        table.fields.push(cLE);

		counter--;
        this.setState({ table: table,
                        newCounter: counter}); 
        if (typeof this.props.handleStateChange === 'function') {
            this.props.handleStateChange(this.state.table);
        }         
    }

	deleteEntry(id) {
		let table = this.state.table;
		table.fields.map(function(field, i) {
			if(field.id === id) {
                field.markAsDelete = "true";
            }
		});
        this.setState({ table: table});
        if (typeof this.props.handleStateChange === 'function') {
            this.props.handleStateChange(this.state.table);
        }          
    }
    
    change(e) {
        console.log("hier");
        var table = this.state.table;
        var id = e.target.name.split("_");

		table.fields.map(function(field, i) {
            console.log(e.target.value);
            console.log(id[0]);
            if(field.id === parseInt(id[1])) {
                if(id[0] === "name")
                    field.name = e.target.value;
                if(id[0] === "type")
                    field.type = e.target.value;
                if(id[0] === "spec") 
                    field.fieldSpec = parseInt(e.target.value);
                if(id[0] === "dbProperties")  
                    field.dbProperties = parseInt(e.target.value);
                if(id[0] === "searchField") {
                    if(field.searchField === 1)
                        field.searchField = 0;
                    else
                        field.searchField = 1;
                }
            }
        });  
        if(e.target.name == "mName")
			table.name = e.target.value;     
        if(e.target.name == "searchTable") {
			if(table.searchTable === 1)     
                table.searchTable = 0;
			else    
                table.searchTable = 1;
        }
        if(e.target.name == "mIcon")
			table.icon = e.target.value;     
        if(e.target.name == "defaultMask")
			table.defaultMask = parseInt(e.target.value);     
        if(e.target.name == "defaultField")
			table.defaultField = parseInt(e.target.value);     
     
        this.setState({
            table: table
        });

        if (typeof this.props.handleStateChange === 'function') {
            this.props.handleStateChange(this.state.table);
        }        
    }
    
    getData() {
        var tableId = this.props.tableId;
        axios.get(getAppUrl() + '/gatebackend/rest/settings/tables/'+tableId, 
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }).then(res => {
            axios.get(getAppUrl() + '/gatebackend/rest/settings/chooseList', 
                {
                    headers: { 
                        "Authorization": " Bearer " + getToken()
                    }
                }).then(res1 => {
                    axios.get(getAppUrl() + '/gatebackend/rest/settings/masks', 
                        {
                            headers: { 
                                "Authorization": " Bearer " + getToken()
                            }
                        }).then(res2 => {
                            console.log(res.data);
                            this.setState({
                                isLoaded : true,
                                table: res.data,
                                chooseLists: res1.data,
                                masks: res2.data
                            }) ;
                            if (typeof this.props.handleStateChange === 'function') {
                                this.props.handleStateChange(res.data);
                            } 
                        });

            });   
        });
    }

    componentDidUpdate(prevProps) {
        if(this.props.tableId != prevProps.tableId) {
            this.getData();
        }
		if(this.props.height != prevProps.height)  {
			let h = this.props.height - 200;
			this.setState({contentHeight: h})
		}        
    }

    componentDidMount() {
        this.getData();
    }

    renderCellSpec(field) {
        if(field.type === "singlechoice")
            return  <select name={"spec_" + field.id} onChange={e => this.change(e)} value={field.fieldSpec}>
					{
						this.state.chooseLists.map(chooseList => (
							<option value={chooseList.id}>{chooseList.name}</option>
						))
					}
                    </select>
        else if(field.type === "textinput")
            return <input name={"dbProperties_" + field.id} 
                    onChange={e => this.change(e)} 
                    type="text"
                    value={field.dbProperties} />

        else
            return field.dbProperties;
    }

    render() {
            const renderSVG = svg => (
                <svg>
                    <use xlinkHref={`#${svg}`} />
                </svg>
            );

            const {isLoaded, table} = this.state;
            if(!isLoaded) {
                return (<div><Loader/></div>)
            }
            else {          
                return (
          
                <div>
                    <AlltoursIcons/>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={this.state.key}
                        onSelect={key => this.setState({ key })}
                    >
                        <Tab eventKey="fields" title="Felder">
							<div className="dialogInnerContentF" style={{maxHeight: this.state.contentHeight + "px", overflowX: "scroll"}}>
								<div style={{minWidth: "1460px"}}>
                                    <div class="row clearmargin maskFieldRow"  align="start">
                                        <div class="grid-Cell grid-Cell-Header maskField_300">
                                            <div class="grid-Cell__header"> 
                                                Field
                                            </div>
                                        </div>
                                        <div class="grid-Cell grid-Cell-Header maskField_250">
                                            <div class="grid-Cell__header"> 
                                                Type
                                            </div>
                                        </div>
                                        <div class="grid-Cell grid-Cell-Header maskField_250">
                                            <div class="grid-Cell__header"> 
                                                Eigenschaft
                                            </div>
                                        </div>
                                        <div class="grid-Cell grid-Cell-Header maskField_250">
                                            <div class="grid-Cell__header"> 
                                                Default
                                            </div>
                                        </div>
                                        <div class="grid-Cell grid-Cell-Header maskField_100">
                                            <div class="grid-Cell__header"> 
                                                Zugriff
                                            </div>
                                        </div>    
                                        <div className="grid-Cell grid-Cell-Header maskField_200">
                                            <div className="grid-Cell__header"> 
                                                Erlaube Suchen
                                            </div>
                                        </div>                                                                
                                        <div className="grid-Cell grid-Cell-Header maskField_100">
                                            <div className="grid-Cell__header"> 
                                                Löschen
                                            </div>
                                        </div>                                                                
                                    </div>
                                    <div class="row clearmargin maskFieldRow" style={{maxHeight: "260px"}} align="start">
                                    {
                                        table.fields.map(field => (
                                            (field.markAsDelete !== "true") && (

                                            <div class="row clearmargin grid-Row " data-id={field.id}  align="start">
                                                <div class="grid-Cell maskField_300"> 
                                                    <div class="grid-Cell__value"> 
                                                        <input  maxlength="30" 
                                                                    size="30" 
                                                                    type="text" 
                                                                    name={"name_" + field.id} 
                                                                    onChange={e => this.change(e)} 
                                                                    value={field.name} 
                                                        /> 
                                                    </div>
                                                </div>                                
                                                <div class="grid-Cell maskField_250"> 
                                                    <div class="grid-Cell__value"> 
                                                    
                                                        <select name={"type_" + field.id} onChange={e => this.change(e)} value={field.type}>
                                                            <option value="textarea">Textbereich</option>
                                                            <option value="textinput">Texteingabe</option>
                                                            <option value="bool">Ja/Nein</option>
                                                            <option value="singlechoice">Auswahl aus Liste</option>
                                                            <option value="singlechoiceDB">Auswahl aus Tabelle</option>
                                                            <option value="user">Benutzer</option>
                                                            <option value="date">Datum</option>
                                                            <option value="multichoice">Mehrfach Auswahl</option>
                                                            <option value="alignment">alignment</option>
                                                            <option value="planninglist">planninglist</option>
                                                            <option value="applet">applet</option>
                                                            <option value="float">Fließkommazahl</option>
                                                            <option value="integer">Ganzzahl</option>
                                                            <option value="json">Sprachenfeld</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="grid-Cell maskField_250">
                                                    <div class="grid-Cell__header"> 
                                                        {this.renderCellSpec(field)}
                                                    </div>
                                                </div>
                                                <div class="grid-Cell  maskField_250">
                                                    <div class="grid-Cell__header"> 
                                                        Default
                                                    </div>
                                                </div>                                                                                
                                                <div class="grid-Cell maskField_100"> 
                                                    <div class="grid-Cell__value" style={{backgroundColor: "#a0000"}}> 
                                                        <Access type="field" label={field.name} id={field.id}/>
                                                    </div>
                                                </div>
                                                <div class="grid-Cell maskField_200"> 
                                                    <div class="grid-Cell__value" style={{backgroundColor: "#a0000"}}> 
                                                        <FormGroup row>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        name={"searchField_" + field.id}
                                                                        checked={field.searchField === 1 ? true : false}
                                                                        onChange={e => this.change(e)}
                                                                        value={field.searchField}
                                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>                                            
                                                <div className="grid-Cell maskField_100"> 
                                                    <div className="grid-Cell__value">
                                                        <Button className="buttonIcon" aria-controls="simple-menuFlag" aria-haspopup="true" onClick={((e) => this.deleteEntry(field.id))}>
                                                            <span class="fa fa-trash-o pull-right"></span>
                                                        </Button>
                                                    </div>
                                                </div>                                            
                                            </div>
                                            )
                                        ))
                                    }
                                </div>
                                </div>
                            </div>
                            <Button variant="secondary" onClick={(e) => this.addNewEntry("field")} style={{border: "1px solid #a0a0a0", margin: "10px"}}>
                                <i class="fa fa-plus-circle"></i>&nbsp;
                                {this.props.t('Feld')}&nbsp;{this.props.t('hinzufügen')}
                            </Button>
                        </Tab>
                        <Tab eventKey="index" title="Indizes">
                            hier
                        </Tab>
                        <Tab eventKey="settings" title="Einstellungen">
                            <div class="Vivacard">
                                <div class="Vivacardheader">
                                    Allgemein                                
                                </div>
                                <div class="border">                               
                                    <div className="row clearmargin "  align="start">
                                        <div className="col-sm-3 ">
                                            ID: 
                                        </div>
                                        <div className="col-sm">
                                            {table.id}
                                        </div>
                                    </div>
									<div className="row clearmargin "  align="start">
                                        <div className="col-sm-3 ">
                                            InternerName: 
                                        </div>
                                        <div className="col-sm">
                                            {table.internalName}
                                        </div>
									</div>                                    
									<div className="row clearmargin "  align="start">
                                        <div className="col-sm-3 ">
                                            Label: 
                                        </div>
                                        <div className="col-sm">
                                            <input maxlength="30" size="30" type="text" name="mName"  onChange={e => this.change(e)} value={this.state.table.name}/>
                                        </div>
									</div>
                                </div>

                                <div class="border"> 
									<div className="row clearmargin "  align="start">
                                        <div className="col-sm-3 ">
                                            Icon: 
                                        </div>
                                        <div className="col-sm">
                                      
                                            <FontIconPicker
                                                        icons={iconDefs.svgs}
                                                        value={this.state.iconValue}
                                                        onChange={(val) => this.changeIconValue(val)}
                                                        renderFunc={renderSVG}
                                                        theme="default"
                                                        isMulti
                                            />

                                        </div>
									</div>
									<div className="row clearmargin "  align="start">
                                        <div className="col-sm-3 ">
                                            DefaultMaske: 
                                        </div>
                                        <div className="col-sm">
                                            <select name="defaultMask" onChange={e => this.change(e)} value={table.defaultMask}>
                                                <option value={0}>{this.props.t('Keine Angabe')}</option>
                                                {
                                                    this.state.masks.map(mask => (
                                                        <option value={mask.id}>{mask.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>	
									<div className="row clearmargin "  align="start">
                                        <div className="col-sm-3 ">
                                            DefaultField: 
                                        </div>
                                        <div className="col-sm">
                                            <select name="defaultField" onChange={e => this.change(e)} value={table.defaultField}>
                                                <option value={0}>{this.props.t('Keine Angabe')}</option>
                                                {
                                                    table.fields.map(field => (
                                                        <option value={field.id}>{field.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>  
                                                                      								
									<div className="row clearmargin "  align="start">
                                        <div className="col-sm-3 ">
                                            Erlaube Suchen:
                                        </div>
                                        <div className="col-sm">
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            name="searchTable"
                                                            checked={this.state.table.searchTable === 1 ? true : false}
                                                            onChange={e => this.change(e)}
                                                            value={this.state.table.searchTable}
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    }
                                                />
                                            </FormGroup>                                            
                                        </div>
									</div>                                                                      								
									
								</div>                            
 							
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            )
        }
     }

}

export default withTranslation()(withSnackbar(Fields));
