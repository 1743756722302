import React, { Component } from 'react';
import axios from 'axios';
import {getAppUrl} from '../helpers/Defaults';
import {getToken} from '../helpers/Token';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import CreateNew from './blocks/CreateNew';
import {Tabs, Tab} from 'react-bootstrap';
import { useSnackbar, withSnackbar } from 'notistack';
import Loader from './blocks/Loader'

class QueryDetail extends Component {

    constructor(props) {
		super(props);
		var tmp = {};
		tmp.compare = "eq";
		tmp.compareValue = "huhu";
		var tmp1 = [];
		tmp1[0] = tmp;

        this.state = {
            show: false,
            currentQueryId: 0,
            offset: 0,
            isLoaded : false,
			productData: [],
			queryData: tmp1,
			query: {},
			currentSearch: "",
			newCounter: -1,
			key: 'fields'
        };

		this.getQuery = this.getQuery.bind(this);
		this.addNewEntry = this.addNewEntry.bind(this); 

	}

    addNewEntry(which) {		
        let query = this.state.query;
		let counter = this.state.newCounter;
		let cLE = {};
		cLE.field = query.fields[0];
        cLE.id = counter;
        cLE.sortDir = "null";
        cLE.firstRelation = "eq";
        cLE.firstValue = "";

        query.queryElements.push(cLE);

		counter--;
        this.setState({ query: query,
                        newCounter: counter}); 
        if (typeof this.props.handleStateChange === 'function') {
            this.props.handleStateChange(this.state.table);
        }         
    }


   	componentWillMount() {
		this.getQuery(this.props.queryId);
	}

    componentDidUpdate(prevProps) {
        if(this.props.queryId != prevProps.queryId) {
            this.getQuery(this.props.queryId);
        }
    }	

	getQuery(id) {
        axios.get(getAppUrl() + '/gatebackend/rest/settings/query/' + id, 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
				console.log(res.data);
                this.setState({
					isLoaded: true,
					query: res.data,
					currentQueryId: id
                })   
        });
	}

    render() {

		const handleQueryChange = id => event => {
			var query 		  = this.state.query;
			var queryElements = query.queryElements;
			if(event.target.name === "firstValue")
				queryElements[id].firstValue = event.target.value;
			if(event.target.name === "firstRelation")
				queryElements[id].firstRelation = event.target.value;
			if(event.target.name === "sortDir") {
				queryElements[id].sortDir = event.target.value;
			}
			if(event.target.name === "elementValue_") {
				queryElements[id].sortDir = event.target.value;
			}
			
			this.setState({
				query: query
			})			
//			this.getQuery(this.state.queryId);
				
		};
		  

        const {isLoaded, posts} = this.state;
        if(!isLoaded) {
            return (<><Loader/></>)
        }
        else {
			console.log(this.state.query.queryElements);
            let elHeight = document.getElementById('actionFrame').clientHeight -100;
            var height = {
                'max-height': elHeight + 'px',
            };
            return (  
				<>              	
				<Tabs
					id="controlled-tab-example"
					activeKey={this.state.key}
					onSelect={key => this.setState({ key })}
				>
					<Tab eventKey="fields" title="Abfrage">							
						<div className="maxWidthx" id="xyz">
						<div className="maskfieldsArea ">						
						<div className="row clearmargin "  align="start"> 
							<div className="col-md-8 col-lg-8"  align="start"> 
								<div className="row clearmargin maskFieldRow"  align="start">
									<div className="grid-Cell grid-Cell-Header maskField_300">
										<div className="grid-Cell__header"> 
											Element
										</div>
									</div>
									<div className="grid-Cell grid-Cell-Header maskField_150">
										<div className="grid-Cell__header"> 
											Sortierung
										</div>
									</div>
									<div className="grid-Cell grid-Cell-Header maskField_100">
										<div className="grid-Cell__header"> 
											Bedingung
										</div>
									</div>
									<div className="grid-Cell grid-Cell-Header maskField_300">
										<div className="grid-Cell__header"> 
											Wert
										</div>
									</div>
								</div>
								{
								this.state.query.queryElements.map((queryElement,i) => (
								<div className="row clearmargin maskFieldRow"  align="start">
									<div className="grid-Cell grid-Cell-Field maskField_300">
										<div className="grid-Cell__header"> 
											<select name={"elementValue_"} onChange={handleQueryChange(i)} value={queryElement.field.id}>
												{  this.state.query.fields.map(field => (
													<option value={field.id} >{field.table}.{field.name}</option>
													))
												}

											</select>														
										</div>
									</div>
									<div className="grid-Cell grid-Cell-Field maskField_150">
										<div className="grid-Cell__header"> 
											<FormControl >
												<InputLabel htmlFor="age-helper">Richtung</InputLabel>
													<Select
														value={queryElement.sortDir}
														onChange={handleQueryChange(i)}
														input={<Input name="age" id="age-label-placeholder" />}
														displayEmpty
														name="sortDir"
													>
													<MenuItem value="null">keine</MenuItem>
													<MenuItem value="asc">aufsteigend</MenuItem>
													<MenuItem value="desc">absteigend</MenuItem>
												</Select>
											</FormControl>
										</div>
									</div>
									<div className="grid-Cell grid-Cell-Field maskField_100">
										<div className="grid-Cell__header"> 
											<FormControl >
												<InputLabel htmlFor="age-helper">Bool</InputLabel>
													<Select
														value={queryElement.firstRelation}
														onChange={handleQueryChange(i)}
														input={<Input name="age" id="age-label-placeholder" />}
														displayEmpty
														name="firstRelation"
													>
													<MenuItem value="eq">gleich</MenuItem>
													<MenuItem value="ne">ungleich</MenuItem>
													<MenuItem value="lt">kleiner</MenuItem>
													<MenuItem value="le">gleich</MenuItem>
													<MenuItem value="gt">größer</MenuItem>
													<MenuItem value="nt">nichts</MenuItem>
												</Select>
											</FormControl>
										</div>
									</div>												
									<div className="grid-Cell grid-Cell-Field maskField_300">
										<div className="grid-Cell__header"> 												
											<FormControl >
												{(queryElement.field.type === "singlechoice" &&
													<Select
															value={queryElement.firstValue}
															onChange={handleQueryChange(i)}
															input={<Input name="age" id="age-label-placeholder" />}
															displayEmpty
															name="firstValue"
														>
														{  queryElement.field.selectValues.map(selectValue => (
															<MenuItem value={selectValue.id}>{selectValue.name}</MenuItem>
															))
														}
														</Select>)
												|| (queryElement.field.type === "singlechoiceDB" &&
													<Select
															value={queryElement.firstValue}
															onChange={handleQueryChange(i)}
															input={<Input name="age" id="age-label-placeholder" />}
															displayEmpty
															name="firstValue"
														>
														{  queryElement.field.selectValues.map(selectValue => (
															<MenuItem value={selectValue.id}>{selectValue.name}</MenuItem>
															))
														}
														</Select>)
												|| (1 === 1 &&
													<TextField
															name="firstValue"
															className="marginQueryInput"
															value={queryElement.firstValue}
															onChange={handleQueryChange(i)}
															margin="normal"																
														/>)
												}



												
											</FormControl>
										</div>
									</div>
								</div>
								))}
								<Button variant="secondary" onClick={(e) => this.addNewEntry("field")} style={{border: "1px solid #a0a0a0", margin: "10px"}}>
									<i class="fa fa-plus-circle"></i>&nbsp;
									{this.props.t('Feld')}&nbsp;{this.props.t('hinzufügen')}
								</Button>
							</div>
							
						</div>
					</div>
					
				</div>
					<div className="row clearmargin height200"  align="start"> 
							<div className="col-md-8 col-lg-8"  align="start"> 
								{this.props.t('Vorschau Abfrage')}:<br/>
								<span className="float-right">
									<Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" 
									onClick={() => {navigator.clipboard.writeText(this.state.query.preview)}}>
										<span className="fa fa-clipboard"></span>
									</Button>
								</span>
								<div className="maxWidth" readonly="true">{this.state.query.preview}</div>
							</div>
							<div className="col-md-2 col-lg-2"  align="start">
								{this.props.t('Datensätze')}:<br/>
								{this.state.query.count}
							</div>
					</div>

					</Tab>
					<Tab eventKey="props" title="Einstellung">
						hier
					</Tab>	
				</Tabs>							
                </> 
            );
       }
    }
}
export default withTranslation()(withSnackbar(QueryDetail)) ;

