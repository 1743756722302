import React, { Component } from 'react';
import axios from 'axios';
import {getAppUrl} from '../helpers/Defaults';
import {getToken} from '../helpers/Token';
import {Tabs, Tab} from 'react-bootstrap';
import {Modal} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {getWSUrl} from '../helpers/Defaults';
import { useSnackbar, withSnackbar } from 'notistack';


class Imports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showExecute: false,
            currentTableId: 0,
            offset: 0,
            isLoaded : false,
            productData: [],
            key: 'fields',
            activeStep: 0,
            file:null,
            contentData: [],
            rowNumber: 1,
            rowNumbers: 3,
            separator: ";",
            trenner: "\"",
            insertRows: "0",
            updateRows: "0",
            failedRows: "0",
            importDone: "false",
            importKind: "insertupdate",
            importMSGs: "",
            importData: {}
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShowExecute = this.handleShowExecute.bind(this);
        this.handleCloseExecute = this.handleCloseExecute.bind(this);

        this.stepBack = this.stepBack.bind(this);
        this.stepFor = this.stepFor.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.backRow = this.backRow.bind(this);
        this.forwardRow = this.forwardRow.bind(this);
        this.changeCSVProps = this.changeCSVProps.bind(this);
        this.stepImport = this.stepImport.bind(this);
    }

    async onSubmit(e) {
        if(this.state.file === null)
            this.props.enqueueSnackbar(this.props.t('Keine Datei ausgewählt'), { variant: 'error' });
        else {
            e.preventDefault() 
            let res = await this.uploadFile(this.state.file, "preview");
        }
    }    
    
    async stepImport(e){
        e.preventDefault() 
        let res = await this.uploadFile(this.state.file, "import");
    }

    changeCSVProps(e) {   
        if(e === undefined)
            return; 
        if(e.target.name === "separator") {
            this.setState({
                separator : e.target.value,
            })
        }
        
        if(e.target.name === "trenner") {
            this.setState({
                trenner : e.target.value,
            })
        }
        
        if(e.target.name === "importKind") {
            this.setState({
                importKind : e.target.value,
            })
        }        
    }

    onChange(e) {
        this.setState({file:e.target.files[0]});
    }

    async uploadFile(file, type){
        const formData = new FormData();

        formData.append('file',file)

        return  await axios.post(getAppUrl() + "/gatebackend/rest/content/savecontentfile?importId=" + this.state.currentImportId +"&type=" + type + "&kind=" + this.state.importKind, formData,{
            headers: {
                'content-type': 'multipart/form-data',
                "Authorization": " Bearer " + getToken()
            }
        }).then(res => {
            var x = this.state.activeStep;
            x++;
            if(type === "preview") {
                this.setState({
                    contentData: res.data.importRows,
                    activeStep: x,
                    rowNumbers: res.data.rows
                })
            }
            console.log(res.data);
        
         });
      }

    componentWillMount() {
        let client = new W3CWebSocket(getWSUrl() + '/gatebackend/websocketendpoint');
        client.onopen = () => {
       //     alert("connect");
        };
        client.onmessage = (message) => {
            let obj = JSON.parse(message.data);
            if(obj.type === "importCSVUpdate") {
                this.setState({
                    updateRows : obj.message ,
                })     
            }
            if(obj.type === "importCSVInsert") {
                this.setState({
                    insertRows : obj.message ,
                })     
            }
            if(obj.type === "importCSVFailed") {
                this.setState({
                    failedRows : obj.message ,
                })     
            }            
            if(obj.type === "importCSVStatus") {
                this.setState({
                    importDone : obj.message ,
                })     
            }
            if(obj.type === "importMsg") {
                let msg = this.state.importMSGs;
                msg += obj.message + "<br/>";
                this.setState({
                    importMSGs : msg ,
                })     
            }            
            
        };
    }

    componentDidMount() {
        axios.get(getAppUrl() + '/gatebackend/rest/settings/imports', 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.setState({
                    isLoaded : true,
                    posts: res.data
                })   
            });
    }

    getStepContent(activeStep) {
        switch (activeStep) {
            case 0:
            return "<input type='file' />";
            case 1:
            return 'What is an ad group anyways?';
            case 2:
            return 'This is the bit I really care about!';
            default:
            return 'Unknown stepIndex';
        }
    }

    backRow(e) {
        let rowNumber = this.state.rowNumber;
        if(rowNumber > 1)
            rowNumber--;
        this.setState({
            rowNumber: rowNumber
        })
    }

    forwardRow(e) {
        let rowNumber = this.state.rowNumber;
        if(rowNumber < this.state.rowNumbers)
            rowNumber++;
        this.setState({
            rowNumber: rowNumber
        })
    }

    stepBack() {
        if(this.state.activeStep == 0) {

        }
        else if(this.state.activeStep == 1) {
            this.setState({ file: null, activeStep: 0, importDone: "false" });
        }
        else {
            let x = this.state.activeStep;
            x--;
            this.setState({ activeStep: x });
        }
    }

    stepFor() {
        var x = this.state.activeStep;
        x++;
        this.setState({ activeStep: x });
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow(e) {
        let id = e.currentTarget.dataset.id;
        let importname = e.currentTarget.dataset.tablename;
        axios.get(getAppUrl() + '/gatebackend/rest/settings/imports/' +id, 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.setState({
                    currentImportId : id,
                    currentTableName : importname,
                    show: true,
                    importData: res.data
                })   
            });
    }

    handleCloseExecute() {
        this.setState({ importMSGs: "", showExecute: false, activeStep: 0, insertRows: "0",failedRows: "0", updateRows: "0", file: null, importDone: "false" });
    }

    handleShowExecute(e) {
        this.setState({currentImportId : e.currentTarget.dataset.id});
        this.setState({currentTableName : e.currentTarget.dataset.tablename});
        this.setState({ showExecute: true });
    }    

    render() {
        const {isLoaded, posts, contentData, rowNumber, rowNumbers} = this.state;
        const activeStep = this.state.activeStep;
        const steps = ['Auswahl', 'Vorschau', 'Ausführung', 'Abschluss'];

        if(!isLoaded) {
            return (<div>loading</div>)
        }
        else {
            let elHeight = document.getElementById('actionFrame').clientHeight -100;
            var height = {
                'max-height': elHeight + 'px',
            };
            return (
                <div>
                    <div class="Vivacard" style={height}>
                        <div class="Vivacardheader">                 
                            Importe
                            <span class="fa fa-cog pull-right"></span>
                        </div>
                        <div class="Vivacardbody">
                            <div class="border">
                                <div class="row clearmargin"  align="start">
                                    <div class="grid-Cell grid-Cell-Header col-sm-1">
                                        <div class="grid-Cell__header "> 
                                            ID
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm">
                                        <div class="grid-Cell__header"> 
                                            Name
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            Bearbeiten
                                        </div>
                                    </div>
                                </div>
                                {
                                    posts.map(post => (
                                        <div class="row clearmargin grid-Row" data-id={post.id} data-tablename={post.name}  onDoubleClick={((e) => this.handleShowExecute(e))} align="start">
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value" > 
                                                    {post.id}
                                                </div>
                                            </div>
                                            <div class="grid-Cell col-sm"> 
                                                <div class="grid-Cell__value"> 
                                                    {post.name}
                                                </div>
                                            </div> 
                                            <div class="grid-Cell col-sm-1"> 
                                                <div class="grid-Cell__value"> 
                                                    <ul>  
                                                        <li data-id={post.id} data-tablename={post.name} class="fa fa-pencil gridIcon" onClick={((e) => this.handleShow(e))}>

                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>                                                                                    
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <Dialog
                            fullWidth="md"
                            maxWidth="md"
                            open={this.state.showExecute}
                            onClose={this.handleCloseExecute}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">Import ausführen: {this.state.currentTableName}</DialogTitle>
                        <DialogContent className="dialogHeight">
                            <div>
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {steps.map(label => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                    ))}
                                </Stepper>
                                <div>
                                    {activeStep === 0 ? (
                                        <>
                                            <div class="Vivacardheader">
                                                File                                
                                            </div>
                                            <div class="border">                               
                                                <div className="row clearmargin "  align="start">
                                                    <div className="col-sm-3 ">
                                                        Datei: 
                                                    </div>
                                                    <div className="col-sm">
                                                        <form onSubmit={ this.onSubmit }>
                                                            <input type="file" onChange={ this.onChange } />
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="row clearmargin "  align="start">
                                                    <div className="col-sm-3 ">
                                                        Type: 
                                                    </div>
                                                    <div className="col-sm">
                                                        <select name="importKind" onChange={e => this.changeCSVProps(e)} value={this.state.importKind}>
                                                            <option value="update">aktualisieren</option>
                                                            <option value="insert">neue einfügen</option>
                                                           <option value="insertupdate">aktualisieren und einfügen</option>
                                                        </select>  
                                                    </div>
                                                </div>
                                                								
                                            </div> 

                                            <div class="Vivacardheader">
                                                Eigenschaften                                
                                            </div>
                                            <div class="border">                               
                                                <div className="row clearmargin "  align="start">
                                                    <div className="col-sm-3 ">
                                                        Spalten getrennt durch: 
                                                    </div>
                                                    <div className="col-sm">
                                                        <select name="separator" onChange={e => this.changeCSVProps(e)} value={this.state.separator}>
                                                            <option value=";">;</option>
                                                            <option value=",">,</option>
                                                        </select>                                                        
                                                    </div>
                                                </div>
                                                <div className="row clearmargin "  align="start">
                                                    <div className="col-sm-3 ">
                                                        Spalten umschlossen von: 
                                                    </div>
                                                    <div className="col-sm">
                                                        <select name="trenner" onChange={e => this.changeCSVProps(e)} value={this.state.trenner}>
                                                            <option value='"'>"</option>
                                                            <option value="'">'</option>
                                                        </select> 
                                                    </div>
                                                </div>                                                								
                                            </div>

                                        </>                                        
                                        ) : (<></>)
                                    }
                                    {activeStep === 1 ? (
                                        <div>
                                            <div className="maskfieldsAreaTop" >
                                                <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.backRow(e))}>
                                                    <span className="fa fa-backward"></span>
                                                </Button> 
                                                <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.forwardRow(e))}>
                                                    <span className="fa fa-forward"></span>
                                                </Button>                        
                                                Datensatz: {rowNumber} von {rowNumbers}                                                 
                                            </div>
                                            <div className="maskfieldsArea">
                                                <div className="row clearmargin maskFieldRow"  align="start">
                                                    <div className="grid-Cell grid-Cell-Header maskField_300" >
                                                        <div className="grid-Cell__header "> 
                                                            DBField
                                                        </div>
                                                    </div>
                                                    <div className="grid-Cell grid-Cell-Header maskField_300">
                                                        <div className="grid-Cell__header"> 
                                                           CSVFeld 
                                                        </div>
                                                    </div>									
                                                    <div className="grid-Cell grid-Cell-Header maskField_300">
                                                        <div className="grid-Cell__header"> 
                                                            Properties
                                                        </div>
                                                    </div>									
                                                </div>
                                            </div> 
                                            <div className="maskFieldRow border importPreviewRows">
                                            {
                                                contentData[rowNumber - 1].importfields.map(importfield => (
                                                    <div class="row clearmargin maskFieldRow"  align="start">
                                                        <div class="grid-Cell maskField_300"> 
                                                            <div class="grid-Cell__value" > 
                                                                {importfield.csvfield}
                                                            </div>
                                                        </div>
                                                        <div class="grid-Cell maskField_300"> 
                                                            <div class="grid-Cell__value"> 
                                                                {importfield.value}
                                                            </div>
                                                        </div> 
                                                        <div class="grid-Cell maskField_300"> 
                                                            <div class="grid-Cell__value"> 
                                                                {importfield.properties}
                                                            </div>
                                                        </div>                                                                                    
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        </div>
                                        ) : (<></>)
                                    }
                                    {activeStep === 2 ? (
                                        <>
                                            <div class="Vivacardheader">
                                                Datensätze                                
                                            </div>
                                            <div class="border">                               
                                                <div className="row clearmargin "  align="start">
                                                    <div className="col-sm-3 ">
                                                        Update: 
                                                    </div>
                                                    <div className="col-sm">
                                                        {this.state.updateRows}
                                                    </div>
                                                </div>
                                                <div className="row clearmargin "  align="start">
                                                    <div className="col-sm-3 ">
                                                        Insert: 
                                                    </div>
                                                    <div className="col-sm">
                                                        {this.state.insertRows}
                                                    </div>
                                                </div>
                                                <div className="row clearmargin "  align="start">
                                                    <div className="col-sm-3 ">
                                                        Fehler: 
                                                    </div>
                                                    <div className="col-sm">
                                                        {this.state.failedRows}
                                                    </div>
                                                </div>                                                								
                                            </div> 
                                    <div class="Vivacardheader">
                                        Detail                                
                                    </div>
                                    <div class="border">
                                        <div align="start" style={{minHeight: "100px", maxHeight: "100px", overflow: "scroll"}} dangerouslySetInnerHTML={{__html: this.state.importMSGs}}>
                                            
                                        </div>
                                    </div>                                        
                                        </>
                                        ) : (<></>)

                                    }

                                </div>
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <Button variant="secondary" onClick={this.handleCloseExecute}>
                            Abbruch
                            </Button>
                            <Button variant="primary" onClick={this.stepBack}>
                            zurück
                            </Button>
                            {activeStep === 0 ? (
                                <Button variant="primary" onClick={this.onSubmit}>
                                Weiter
                                </Button>   
                            ) : (
                                <></>
                            )
                            }                         
                            {activeStep === 1 ? (
                                <Button variant="primary" onClick={this.stepFor}>
                                Weiter
                                </Button>   
                            ) : (
                                <></>
                            )
                            }  
                             {activeStep === 2 && this.state.importDone === "false" ? (
                                <Button variant="primary" onClick={this.stepImport}>
                                ausführen
                                </Button>   
                            ) : (
                                <></>
                            )
                            } 
                             {activeStep === 2 && this.state.importDone === "true" ? (
                                <Button variant="primary" onClick={this.stepFor}>
                                fertig
                                </Button>   
                            ) : (
                                <></>
                            )
                            }                                                                                  
                        </DialogActions>
                    </Dialog>

                    <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Import: {this.state.currentTableName}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body class="modalBody">
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={this.state.key}
                                onSelect={key => this.setState({ key })}
                            >
                                <Tab eventKey="fields" title="Felder">
                                    <div className="maskfieldsAreaTop" >
                                        <span className="float-right">
                                            <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.handleMaskItemAddDialog(true)}>
                                                <span className="fa fa-ellipsis-v"></span>
                                            </Button>
                                        </span>
                                    </div>
                                    <div className="maskfieldsArea">
                                        <div className="row clearmargin maskFieldRow"  align="start">
                                            <div className="grid-Cell grid-Cell-Header maskField_300" >
                                                <div className="grid-Cell__header "> 
                                                    DBFeld
                                                </div>
                                            </div>
                                            <div className="grid-Cell grid-Cell-Header maskField_300">
                                                <div className="grid-Cell__header"> 
                                                    CSVFeld
                                                </div>
                                            </div>									
                                            <div className="grid-Cell grid-Cell-Header maskField_100">
                                                <div className="grid-Cell__header"> 
                                                    Zwingend
                                                </div>
                                            </div>									
                                            <div className="grid-Cell grid-Cell-Header maskField_100">
                                                <div className="grid-Cell__header"> 
                                                    Eindeutig
                                                </div>
                                            </div>									
                                        </div>
                                    
                                        {this.state.importData && this.state.importData.importfields ? (
                                            <>
                                        { this.state.importData.importfields.map(importfield => (                                    
                                            <div className="row clearmargin maskFieldRow"  align="start">
                                                <div className="grid-Cell  maskField_300" >
                                                    <div className="grid-Cell__header "> 
                                                        {importfield.field.name}
                                                    </div>
                                                </div>
                                                <div className="grid-Cell  maskField_300">
                                                    <div className="grid-Cell__header"> 
                                                        {importfield.name}
                                                    </div>
                                                </div>									
                                                <div className="grid-Cell  maskField_100">
                                                    <div className="grid-Cell__header"> 
                                                        <Checkbox
                                                            checked={importfield.mandatory}
                                                            value="true"
                                                            color="primary"
                                                            inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                            }}
                                                        />
                                                    </div>
                                                </div>									
                                                <div className="grid-Cell  maskField_100">
                                                    <div className="grid-Cell__header"> 
                                                        <Checkbox
                                                            checked={importfield.unique}
                                                            value="true"
                                                            color="primary"
                                                            inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            ))
                                        }
                                        </>) : (<></>)
                                        }
                                    </div>
                                </Tab>
                                <Tab eventKey="settings" title="Einstellungen">
                                    <div class="Vivacard">
                                        <div class="Vivacardheader">
                                            Allgemein                                
                                        </div>
                                        <div class="border">                               
                                            <div className="row clearmargin "  align="start">
                                                <div className="col-sm-2 ">
                                                    ID: 
                                                </div>
                                                <div className="col-sm">
                                                    1
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>

                            </Tabs>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                            Abbruch
                            </Button>
                            <Button variant="primary" onClick={this.handleClose}>
                            Speichern
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div> 
            );
       }
    }
}
export default withTranslation()(withSnackbar(Imports));
