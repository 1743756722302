import React, { Component } from 'react';
import axios from 'axios';
import {getAppUrl} from '../helpers/Defaults';
import {getToken} from '../helpers/Token';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import CreateNew from './blocks/CreateNew';
import {Tabs, Tab} from 'react-bootstrap';
import QueryDetail from './QueryDetail.js';

import { useSnackbar, withSnackbar } from 'notistack';

class Queries extends Component {

    constructor(props) {
		super(props);
		var tmp = {};
		tmp.compare = "eq";
		tmp.compareValue = "huhu";
		var tmp1 = [];
		tmp1[0] = tmp;

        this.state = {
            show: false,
            queryId: 0,
            offset: 0,
            isLoaded : false,
			productData: [],
			queryData: tmp1,
			query: {},
			currentSearch: "",
			newCounter: -1,
			key: 'fields'

        };
        this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.searchChange = this.searchChange.bind(this);
	}	

	searchChange(e) {
        this.setState({
			currentSearch: e
		});
    }

   	componentDidMount() {
        axios.get(getAppUrl() + '/gatebackend/rest/settings/queries', 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.setState({
                    isLoaded : true,
                    posts: res.data
                })   
        });
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow(e) {
		this.setState({ show: true,
						queryId: e.currentTarget.dataset.id });
	}

    render() {
        const {isLoaded, posts} = this.state;
        if(!isLoaded) {
            return (<div>loading</div>)
        }
        else {
            let elHeight = document.getElementById('actionFrame').clientHeight -100;
            var height = {
                'max-height': elHeight + 'px',
            };
            return (
                <div>
                    <div class="Vivacard" style={height}>
                        <div class="Vivacardheader">                           
                            {this.props.t('Abfragen')}
                           <CreateNew searchChange={this.searchChange} actionNew={this.createNew} title="Neue Abfrage"/>
                        </div>
                        <div class="Vivacardbody">
                            <div class="border">
                                <div class="row clearmargin"  align="start">
                                    <div class="grid-Cell grid-Cell-Header col-sm-1">
                                        <div class="grid-Cell__header "> 
                                            ID
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm">
                                        <div class="grid-Cell__header"> 
                                            Name
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            Bearbeiten
                                        </div>
                                    </div>
                                </div>
                                {
                                    posts.map(post => (
										<>
										{this.state.currentSearch === "" || ((post.id).toString().includes(this.state.currentSearch) ) ? (

											<div class="row clearmargin grid-Row" data-id={post.id} data-tablename={post.name}  onDoubleClick={((e) => this.handleShow(e))} align="start">
												<div class="grid-Cell col-sm-1"> 
													<div class="grid-Cell__value" > 
														{post.id}
													</div>
												</div>
												<div class="grid-Cell col-sm"> 
													<div class="grid-Cell__value"> 
														{post.label}
													</div>
												</div> 
												<div class="grid-Cell col-sm-1"> 
													<div class="grid-Cell__value"> 
														<ul>  
															<li class="fa fa-pencil gridIcon">

															</li>
														</ul>
													</div>
												</div>                                                                                    
											</div>
										
										) : (<></>)
										}
										</>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
					
					
					<Dialog 
						fullWidth="md"
						maxWidth="md"
						open={this.state.show} 
						onClose={this.handleClose}>
						<DialogTitle id="alert-dialog-title">
							{this.props.t('Abfrage')}: {this.state.query.label}

						</DialogTitle>	
						<QueryDetail 
							queryId={this.state.queryId}
						/>
						<DialogActions>
							<Button onClick={this.handleClose} color="primary">
								{this.props.t('Abbruch')}
							</Button>
							<Button  autoFocus>
								{this.props.t('Speichern')}
							</Button>
						</DialogActions>   
					</Dialog>


                </div> 
            );
       }
    }
}
export default withTranslation()(withSnackbar(Queries)) ;

