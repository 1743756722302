import React, { Component, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import {getToken} from '../../helpers/Token';
import {getAppUrl} from '../../helpers/Defaults';
import { Document, Page } from "react-pdf";
import { pdfjs } from 'react-pdf';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {getWSUrl} from '../../helpers/Defaults';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';

import Loader from './Loader'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PDFDisplaySmall extends Component {


    constructor(props) {

        super(props);
        this.state = {
            posts: [],
            isLoaded : false,
            numPages: null,
            pageNumber: 1,
            zoom: 1.0,
            items: props.items,
            itemId: props.itemId,
            language: props.language,
            planungsId: props.planungsId,
            showLOG: false,
            nextMask: props.nextMask,
            logTXT: ""
        };
        this.refresh = this.refresh.bind(this);
        this.back = this.back.bind(this);
        this.forward = this.forward.bind(this);
        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.createPDF = this.createPDF.bind(this);
        this.handleCloseLog = this.handleCloseLog.bind(this);
    }
    
	handleCloseLog() {      
		this.setState({
			showLOG: false,
		});
	}
	
    zoomOut(e) {
        let zoom = parseFloat(this.state.zoom);
        if(zoom > parseFloat(0.5))
            zoom = parseFloat(zoom) - parseFloat(0.5);
        try {
            zoom = zoom.toFixed(2);
        }
        catch (e) {
            zoom = 1.0;
        }
        this.setState({
            zoom: zoom
        })
    }
    
    zoomIn(e) {
        let zoom = parseFloat(this.state.zoom);
        if(zoom < parseFloat(5))
            zoom = parseFloat(zoom) + parseFloat(0.5);
        try {
            zoom = zoom.toFixed(2);
        }
        catch (e) {
            console.log("zoomIn error:" + zoom);
            zoom = 1.0;
        }            
        this.setState({
            zoom: zoom
        })
    }    

    refresh(e) {
        this.createPDF(this.props.itemId, this.props.items, this.props.language);
    }    
    
    back(e) {
        var pageNumber = this.state.pageNumber;
        if(pageNumber > 1)
            pageNumber--;
        this.setState({
            pageNumber: pageNumber
        })
    }

    forward(e) {
        var pageNumber = this.state.pageNumber;
        if(pageNumber < this.state.numPages)
            pageNumber++;
        this.setState({
            pageNumber: pageNumber
        })
    }

    componentDidUpdate() {
        if(this.props.items != this.state.items || this.props.itemId != this.state.itemId || this.props.language != this.state.language) {
	        if(this.props.preview != undefined && this.props.preview != "") {
		        
		        let obj = {};
		        obj.url = "data:application/pdf;base64," + this.props.preview;
		        obj.file= "data:application/pdf;base64," + this.props.preview;
		        obj.result = "success";
		       	obj.filename = "preview.pdf";
		        this.setState({
		            isLoaded : true,
		            posts: obj,
		            preview: this.props.preview,
		            planungsId: this.props.planungsId,
		          	itemId: this.props.itemId,
            		items: this.props.items,
            		pageNumber: 1,
            		language: this.props.language
		        });        
			}
			else	
				this.createPDF(this.props.itemId, this.props.items, this.props.language);
		}
    }

    componentWillMount() {
		console.log("componentWillMount"  + this.props.items + " -- " + this.props.itemId);
		try {
	        let client = new W3CWebSocket(getWSUrl() + '/gatebackend/websocketendpoint');
	        client.onopen = () => {
	            console.log("connect WS");
	        };
	        client.onmessage = (message) => {
	            let obj = JSON.parse(message.data);
	            if(obj.type === "renderPDFUpdate") {
	                this.setState({
	                    noticeMsg : obj.message ,
	                })     
	            }
	
	        };
		}
		catch (e) { 
			}       
        if(this.props.preview != undefined && this.props.preview != "") {
			const byteCharacters = atob(this.props.preview);
			const byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
			      byteNumbers[i] = byteCharacters.charCodeAt(i);
			      }
			const byteArray = new Uint8Array(byteNumbers);
			let pdf = new Blob([byteArray], {type: 'application/pdf'});        
	        
	        
	        let obj = {};
	        obj.url = "data:application/pdf;base64," + this.props.preview;
	        obj.file= pdf;
	        obj.result = "success";
	        this.setState({
	            isLoaded : true,
	            posts: obj
	        });        
		}
		else {
        	this.createPDF(this.state.itemId, this.state.items, this.state.language)   
			
		}
    }
    
    createPDF(itemId, items, language) {

        this.setState({
            isLoaded : false,
            itemId: itemId,
            items: items,
            language: language
        });
        if(items == undefined )
        	items = -1;
        if(language == undefined )
        	language = "de";
        let sItemId = "?itemId="+itemId ;
        console.log("hier" + getAppUrl() + '/gatebackend/rest/content/createPDF/'+ this.state.planungsId +   sItemId+ "&language="+language+ "&items="+items);
        axios.get(getAppUrl() + '/gatebackend/rest/content/createPDF/'+ this.state.planungsId +   sItemId+ "&language="+language+ "&items="+items, 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
				console.log("juhuu");
                console.log(res.data);
                this.setState({
                    isLoaded : true,
                    posts: res.data,
                    itemId: itemId
                }) 
            })
            .catch((error) => {
       //         localStorage.removeItem("app-token");
         //       this.props.history.push("/login");
            });  
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }
    
    downloadCSV(e) {
        const linkSource = "data:application/csv;base64,"+ this.state.posts.contentCSV;
        const downloadLink = document.createElement("a");
        const fileName = "database.csv";

        downloadLink.href = this.state.posts.contentCSV;
        downloadLink.download = fileName;
        downloadLink.click();
    }   
     
    downloadPDF(e) {
        const linkSource = "data:application/pdf;base64,"+ this.state.posts.url;
        const downloadLink = document.createElement("a");
        const fileName = this.state.posts.filename;

        downloadLink.href = this.state.posts.url;
        downloadLink.download = fileName;
        downloadLink.click();
    }    
    
    downloadTXT(e) {

        fetch(this.state.posts.messageTxt)
          .then(response2 => response2.text())
          .then(response2 => {
              console.log(response2);
            this.setState({
                showLOG: true,
                logTXT: response2
            })
          })

    }

    downloadnNWPD(e) {
        const linkSource = "data:application/x-binary;base64,"+ this.state.posts.filenameNWPD;
        const downloadLink = document.createElement("a");
        const fileName = "document.nwpd";

        downloadLink.href = this.state.posts.filenameNWPD;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    
    render() {
        const {isLoaded, posts} = this.state;
        console.log(posts);
        const { pageNumber, numPages, zoom } = this.state;
        if(!isLoaded) {
            return (<>
                    <div class="Vivacardheader"></div>
                    <div>    
                        <div style={{width: "50%", margin: "0 auto"}}><Loader/><br/>{this.state.noticeMsg}</div>
                    </div>
                    </>)
        }
        else {
            return (
                <>     
                    <div class="Vivacardheader" style={{padding: "0"}}>
                        <Button
                            type="button"
                            className="buttonIcon"
                            onClick={((e) => this.refresh(e))}
                            style={{
                                verticalAlign: 'middle',
                                fontSize: "0.6rem",
                            }}
                            >
                            <span className="fa fa-refresh"></span>
                        </Button>	
                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.back(e))}                             
                        	style={{
                                fontSize: "0.6rem",
                            }}
							>
                            <span className="fa fa-backward"></span>
                        </Button> 
                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.forward(e))}
	                        style={{
	                                fontSize: "0.6rem",
	                            }}
	                        >
                            <span className="fa fa-forward"></span>
                        </Button>   
                        <span style={{
	                                fontSize: "0.6rem",
	                            }}
	                     >                     
                             {pageNumber} von {numPages} 
                         </span>
                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.zoomOut(e))}
	                        style={{
	                                fontSize: "0.6rem",
	                            }}
                        >
                            <span className="fa fa-search-minus"></span>
                        </Button> 
                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.zoomIn(e))}
							style={{
                                fontSize: "0.6rem",
                            }}                        
                        >
                            <span className="fa fa-search-plus"></span>
                        </Button>   
                        <span className="fa fa-search-plu">{zoom}</span>    
                        <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.downloadTXT(e)}
							style={{
                                fontSize: "0.6rem",
                            }}                        
                        >
                            <span className="fa fa-file-text"></span>
                        </Button>   

                       
                    </div>   
                    {posts.result === "success" ? (                        
                        <Paper>
                            <div style={{maxHeight: "240px", overflow: "scroll"}}>
                                <Document
                                    file={posts.url}
                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                >
                                    <Page pageNumber={pageNumber} scale={zoom} width={227}  />
                                </Document>
                            </div>
                        </Paper>
                    ) : (
                        <>
                            <div>    
                                <div style={{width: "50%", margin: "0 auto"}}>
                                    PDF konnte nicht geladen werden<br/>
                                    <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.downloadTXT(e)}>
                                        <span className="fa fa-file-text">&nbsp;LogFile</span>
                                    </Button>
                                </div>
                            </div>                        
                        </>
                    )}

                    <Dialog open={this.state.showLOG} 
                            onClose={this.handleCloseLog} 
                            fullWidth
                            maxWidth='lg'
                            aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.props.t('LogFile VivaNWP')}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									{this.props.t('Ausgabe')}
								</DialogContentText>
							    <textarea style={{ width: "100%", height: "300px" }}>
                                    {this.state.logTXT}
                                </textarea>			

							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleCloseLog} color="primary">
									{this.props.t('OK')}
								</Button>
								</DialogActions>
					</Dialog>
                </>
        );

    }
    }
}


export default withTranslation() (PDFDisplaySmall);