import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import qs from 'qs';
import { createBrowserHistory } from "history";
import {getToken,getExtranetToken} from '../helpers/Token';
import {getAppUrl} from '../helpers/Defaults';
import MaskEdit from './MaskEdit';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import VivaGrid from './VivaGrid'
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from "@material-ui/core/styles";
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import PDFDisplay from './blocks/PDFDisplay';
import NWPFormDialog from './blocks/NWPFormDialog';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import ImageUploader from 'react-images-upload';
import { useSnackbar, withSnackbar } from 'notistack';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import * as ReactQuill from 'react-quill'; // Typescript
import { Resize, ResizeVertical, ResizeHorizon } from "react-resize-layout";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Popover } from "@material-ui/core";
import Loader from './blocks/Loader'
import NativeSelect from '@material-ui/core/NativeSelect';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import Editor from './blocks/Editor';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {getWSUrl} from '../helpers/Defaults';
import Moment from 'moment';
import Grid from '@material-ui/core/Grid';
import {Route, Link } from "react-router-dom";

require('react-quill/dist/quill.snow.css'); // CommonJS
const styles = {
  Tab: {
    flexDirection: "row-reverse"
  }
};

function isValueSelected(datafields, contentFieldfieldId, values, whichId) {
	let vals = makeArray(values);
	let displayValue = false;
	datafields.map(function(datafield) {
		if(datafield.id == contentFieldfieldId ) {
			datafield.selectValues.map(function(selectValue) {
				vals.map(function(val) {
					if(val == whichId)
						displayValue = true;
				});
			});
		}
	});
	return displayValue;
}

function displayArrayValues(datafields, contentFieldfieldId, values) {
	let vals = makeArray(values);
	let displayValue = "";
	datafields.map(function(datafield) {
		if(datafield.id == contentFieldfieldId ) {
			datafield.selectValues.map(function(selectValue) {
				vals.map(function(val) {
					if(val == selectValue.id)
						displayValue += selectValue.name + " ";
				});
			});
		}
	});
	if(displayValue.length > 20)
		return displayValue.substr(0,20) + "...";
	else
		return displayValue;
}

function makeArray(val) {
	if (typeof val === 'string') {
		let x = [];
		let p = val.split(',');
		p.map(function(o) {
			x.push(parseInt(o));
		});
		return x;
	}
	else
		return val;
	
}

class VivaMaskEingabe extends Component {
    constructor(props) {
		super(props);
		let tableId =0;
		let maskId = 0;
		let itemId = 0;	
		        this.history = createBrowserHistory();;
	
		if(this.props.match !== undefined && this.props.match.params !== undefined) {
			tableId =this.props.match.params.tableId;
			maskId = this.props.match.params.maskId;
			itemId = this.props.match.params.itemId;		
		}
        else {
			tableId =this.props.tableId;
			maskId = this.props.maskId;
			itemId = this.props.itemId;		
		}


		this.state = {
			tableId: tableId,
			maskId: maskId,
			itemId: itemId,
			
            suche: "",
            offset: 0,
            isLoaded : false,
            tabValue: 0,
			LinkDialog: false,
			LinkMask: 0,
			LinkTable: 0,
			LinkMaskNextQuery: 0,
			LinkText: "",
			personName: [],
			pictures: [],
			currentMaskWidthLink: 1200,
			text: '',
			couldSave: true,
			adviceOpen: false,
			mainFrameWidth: -1,
			smallFrameWidth: -1,
			leftFrameWidth: 300,
			anchorEl: null,
			smallMasks: [],
			bookmarkLinks: [],
			bookmarkfolderId: 0,
			op: 0,
			anchorElFlag: null,
			itemLanguage: "de",
			itemLanguages: [],
			linkDialogOpen: false,
			searchTerm: "",
            showGlobalSearch: false,
			searchStatus: "start",
			searchResult: [],
			switchLanguage: false,
			linkname: "",
			exportOpen: false,
			profillabel: "reimo-com",
			profillanguage: "de",
			shopProfiles: [],
			articleCheck: false,
			pdfDialog: false,
			showUserAdvice: false,
			viewUserAdvice: false,
			UserAdviceTitle: "mein Titel",
			UserAdviceStatus: "offen",
			UserAdviceDescription: "meine Anmerkung",
			UserAdviceField: -1,
			UserAdviceToUser: -1,
			UserAdviceFieldName: "",
			UserAdviceRefId: 0,
			userAdvice: undefined,
			noticeMsg: "",
			noteTop: "100px",
			noteLeft: "100px",
			planingFirstOpen: false
		};
		

	    
        this.handleStateChange = this.handleStateChange.bind(this);
        this.reload = this.reload.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleMaskButtonClick = this.handleMaskButtonClick.bind(this);
		this.handleCloseLinkDialog = this.handleCloseLinkDialog.bind(this);
		this.renderTabs = this.renderTabs.bind(this);
		this.renderField = this.renderField.bind(this);
		this.onDrop = this.onDrop.bind(this);
    	this.handleEditorChange = this.handleEditorChange.bind(this);
		this.addBookmark = this.addBookmark.bind(this);
		this.saveDefaultStyle = this.saveDefaultStyle.bind(this);
		this.change = this.change.bind(this);
		this.handleMousemove.bind(this);
		this.adviceClose = this.adviceClose.bind(this);
		this.resizeMain = this.resizeMain.bind(this);
		this.handleTabMenuClick = this.handleTabMenuClick.bind(this);
		this.handleTabMenuClose = this.handleTabMenuClose.bind(this);
		this.handleMenuTabClick = this.handleMenuTabClick.bind(this);
		this.closeSmallPreview = this.closeSmallPreview.bind(this);
		this.handleClickFlag = this.handleClickFlag.bind(this);
        this.handleCloseFlag = this.handleCloseFlag.bind(this);
        this.handleTChange = this.handleTChange.bind(this);
		this.addLinks = this.addLinks.bind(this);
		this.setSearchTerm = this.setSearchTerm.bind(this);
		this.setLinkTerm = this.setLinkTerm.bind(this);
        this.searchGlobal = this.searchGlobal.bind(this);
		this.catchReturn = this.catchReturn.bind(this);
		this.exportClose = this.exportClose.bind(this);
		this.fileDownload = this.fileDownload.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.handlepdfDialog = this.handlepdfDialog.bind(this);
        this.openUserAdvice = this.openUserAdvice.bind(this);
        this.handleUserAdviceChange = this.handleUserAdviceChange.bind(this);

	}
	
	downloadFile(encoded, filename) {
		var fileDownload1 = require('js-file-download');
		fileDownload1(encoded, filename);
	}
	
	fileDownload(url, file) {
		alert(file);
	}

	catchReturn(ev) {
		if (ev.key === 'Enter') {
			this.searchGlobal();
			ev.preventDefault();
		}
	}

	searchGlobal() {
            let sObj = {};
            sObj.searchTerm = this.state.searchTerm;
            this.setState({
                showGlobalSearch: true,
                searchResult: [],
                searchStatus: "searching"
            });             
            axios.post(getAppUrl() + '/gatebackend/rest/content/globalSearch', 
                sObj,
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                let searchStatus = "finish";
                if(res.data.length === 0)
                    searchStatus = "emptyResult";
                
                this.setState({
                    showGlobalSearch: true, 
                    searchResult: res.data,
                    searchStatus: searchStatus
                });               
            });
		}
		
	    handlepdfDialog(kind, e) {
			if(kind === true) {
				this.setState({
					pdfDialog: kind,
	                leftItemId: e.target.dataset.leftitemid,
	                nextMask: e.target.dataset.nextmask,
	                op: e.target.dataset.op,
	                planungsItemId: e.target.dataset.leftitemid,
	                planingFirstOpen: false
				})
			}
			else {
				this.setState({
					pdfDialog: kind,
				})
			}
	    }  		
		
	setLinkTerm(e) {
            this.setState({
                linkname: e.target.value,
            });  
	}
	
    getAvatar(userAlt) {
        let sResult = "";
        let user = this.state.user;
        if(userAlt !== undefined)
            user = userAlt;
        if(user.firstname !== "")
            sResult = user.firstname.charAt(0);
        if(user.lastname !== "")
            sResult += user.lastname.charAt(0);

        return sResult.toUpperCase();
    }	
	
	setSearchTerm(e) {
            this.setState({
                searchTerm: e.target.value,
            });  
	}
		
	handleTChange () {
		alert("jjjj");
	}	
	
	openUserAdvice (e, id, name) {
        this.setState({
            showUserAdvice: true,
            UserAdviceField: id,
			UserAdviceFieldName: name, 
        });  
   	}
   		
	viewUserAdvice (e, id) {
		let ouserAdvice = {};
		this.state.posts.contentResult.meta.userAdvices.map(function(userAdvice) {
			if(id == userAdvice.id) {
				ouserAdvice = userAdvice;						
			}
				
		});		
		
		
        this.setState({
            viewUserAdvice: true,
            userAdvice: ouserAdvice,
        });  
   	}
   	
   	userAdviceClose(e,type) {
		if(type == "send") {
			let userAdvice = {};
			userAdvice.refId = 0;
			userAdvice.label = this.state.UserAdviceTitle;
			userAdvice.description = this.state.UserAdviceDescription;
			userAdvice.refFieldId = this.state.UserAdviceField;
			userAdvice.refMaskId = this.state.maskId;
			userAdvice.refItemId = this.state.itemId;
			userAdvice.toUserId = this.state.UserAdviceToUser;
			

			axios.post(getAppUrl() + '/gatebackend/rest/settings/useradvice', 
	                userAdvice
	            ,
	            {
	                headers: { 
	                    "Authorization": " Bearer " + getToken()
	                }
	            }
	            ).then(repsonse => {
			        this.setState({
	            		showUserAdvice: false,
	        		}); 
					this.props.enqueueSnackbar(this.props.t('Kommentar wurde hinzugefügt'), { variant: 'info' });
	            }
	                
	            )
	            .catch((error) => {
	                console.log(error);
	                alert("error");
	        });		
		}
		else {
	        this.setState({
	            showUserAdvice: false,
	        });  
	 	}
   	}
   	
   	


	changeItemLanguage(language) {
		this.setState({
			switchLanguage: true,
			itemLanguage: language,
			anchorElFlag: null
		})
	}

	handleCloseFlag() {
		this.setState({
			switchLanguage: false,
			anchorElFlag: null
		})
	}	
	
	handleClickFlag(event) {
		this.setState({
			switchLanguage: false,
			anchorElFlag: event.currentTarget,
		})
	}	

	handleUserAdviceChange(e) {
		if(e.target.name == "UserAdviceTitle")
			this.setState({ UserAdviceTitle: e.target.value});
		if(e.target.name == "UserAdviceDescription")
			this.setState({ UserAdviceDescription: e.target.value});
		if(e.target.name == "UserAdviceToUser")
			this.setState({ UserAdviceToUser: e.target.value});
		if(e.target.name == "UserAdviceStatus")
			this.setState({ UserAdviceStatus: e.target.value});
	}

	closeSmallPreview(id) {
		let smallMasks = this.state.smallMasks;
		let newsmallMasks = [];
		smallMasks.map(function(mask) {
			if(mask.id !== id)
				newsmallMasks.push(mask);
		})
		this.setState({ smallMasks: newsmallMasks}); 		
	}


	handleMenuTabClick() {
		let maskToAdd = this.state.maskToPermanent;
		let smallMasks = this.state.smallMasks;
		let cLE = {};
		cLE.id = maskToAdd.fieldID;
		cLE.nextMask = maskToAdd.elementValue;
		cLE.tableId = maskToAdd.tableId;
		smallMasks.push(cLE);
		this.setState({ smallMasks: smallMasks,
						anchorEl: null}); 
	}

	handleTabMenuClose() {
		this.setState({
			anchorEl: null
		});
  	}
	
	handleTabMenuClick(event, mask) {
		event.stopPropagation();
		this.setState({
			anchorEl: event.currentTarget,
			maskToPermanent: mask
		});
  	}

	resizeMain(e) {
        var elem = document.getElementsByClassName('resizeMain')[0];
		let width = elem.offsetWidth;
        var elem1 = document.getElementsByClassName('resizeSmall')[0];
        let widthSmall = elem1.offsetWidth;		
        this.setState({
			mainFrameWidth : width,
			smallFrameWidth : widthSmall
		})
	}

	adviceClose(e) {
        this.setState({ adviceOpen: false});
	}

    handleMousedown = e => {
        this.setState({ isResizing: true, lastDownX: e.clientX });
    };

    handleMousemove = e => {
        // we don't want to do anything if we aren't resizing.
        if (!this.state.isResizing) {
            return;
        }

        let offsetRight =
        document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        let minWidth = 400;
        let maxWidth = 1400;
        if (offsetRight > minWidth && offsetRight < maxWidth) {
        this.setState({ newWidthLinkItems: { width: offsetRight },
                        currentMaskWidthLink: offsetRight });
        }
    };
    handleMouseup = e => {
        this.setState({ isResizing: false });
    };

    change(e) {   
        if(e.target.name === "profillanguage") {
            this.setState({
                profillanguage : e.target.value,
            })
		}
        if(e.target.name === "profillabel") {
            this.setState({
                profillabel : e.target.value,
            })
		}
        if(e.target.name === "bookmarklabel") {
            this.setState({
                bookmarklabel : e.target.value,
            })
		}
        if(e.target.name === "bookmarkfolder") {
            this.setState({
                bookmarkfolderId :e.target.value,
            })
		}	
			
	}

	handleEditorChange(value) {
		this.setState({ text: value })
	}
	
	onDrop(picture) {
        this.setState({
            pictures: this.state.pictures.concat(picture),
        });
	}

	saveDefaultStyle() {
		let smallMasks = this.state.smallMasks;
		axios.post(getAppUrl() + '/gatebackend/rest/settings/maskdisplay/'+ this.state.maskId, 
                smallMasks
            ,
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }
            ).then(repsonse => {
				this.props.enqueueSnackbar(this.props.t('Einstellungen zu dieser Maske gespeichert'), { variant: 'info' });
            }
                
            )
            .catch((error) => {
                console.log(error);
                alert("error");
        });		
	}

		
	addLinks(kind, value, typ) {
		if(kind == "add") {
			let quillEditor = this.state.quillEditor;
			let linkname = this.state.linkname;
			const cursorPosition = this.state.cursorPosition;
			quillEditor.insertText(cursorPosition, "[Link id='"+ value +"' name='"+ linkname +"' type='"+ typ +"']");
			quillEditor.setSelection(cursorPosition + 1);	
		}

		this.setState({ linkDialogOpen: false});
	}

	addBookmark(kind) {
		var k = true;
		if(kind === 'true') {
			axios.get(getAppUrl() + '/gatebackend/rest/settings/bookmarks',
			{
				headers: { 
					"Authorization": " Bearer " + getToken()
				}
			}).then(res2 => {
				this.setState({
					bookmarkOpen: kind,
					bookmarkLinks: res2.data
				})  
			}).catch((error) => {
				
			});
		}
		else {
			if(kind === 'false')
				k = false;
			if(kind === 'add') {

				let bookmark = {};
				bookmark.label = this.state.bookmarklabel;
				bookmark.link = "/maske/"+ this.state.tableId +"/"+ this.state.maskId +"/" + this.state.itemId;
				bookmark.itemId = parseInt(this.state.itemId);
				bookmark.folder = parseInt(this.state.bookmarkfolderId);
				axios.post(getAppUrl() + '/gatebackend/rest/settings/bookmarks/add', 
					bookmark
				,
				{
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}
				).then(repsonse => {
				this.props.enqueueSnackbar(this.props.t('Bookmark hinzugefügt'), { variant: 'info' });
				}
					
				)
				.catch((error) => {
					console.log(error);
					alert("error");
				});
				k = false;
			}
			this.setState({
				bookmarkOpen : k
			})  

		}
	}
	
    handleCloseLinkDialog() {
        this.setState({
            LinkDialog : false,
        })  
    }

    handleTabChange(event, newValue) {
        this.setState({
            tabValue : newValue,
        })
	}
	
   	componentDidUpdate() {      
		var gb = document.getElementsByClassName('gridBottom')[0];
		if(gb != undefined) {
			
			let noteTop = (gb.offsetTop - 160) + "px";
			let noteLeft = (gb.offsetWidth - 220) + "px";

			if(noteTop != this.state.noteTop && noteLeft != this.state.noteLeft)
			this.setState({
				noteTop : noteTop,
				noteLeft : noteLeft
			})
		}
			
		let smallFrameWidth = this.state.smallFrameWidth;
		let mainFrameWidth = this.state.mainFrameWidth;
		if(smallFrameWidth === -1 && mainFrameWidth === -1) {
			var elem = document.getElementsByClassName('resizeMain')[0];
			let width = elem.offsetWidth;
			var elem1 = document.getElementsByClassName('resizeSmall')[0];
			let widthSmall = elem1.offsetWidth;		
			this.setState({
				smallFrameWidth : widthSmall,
				mainFrameWidth : width
			})
		}

	}

   	componentDidMount() {  
        document.addEventListener("mousemove", e => this.handleMousemove(e));
        document.addEventListener("mouseup", e => this.handleMouseup(e)); 		   
		axios.get(getAppUrl() + '/gatebackend/rest/content/itemstatus/' + this.state.itemId + "?status=edit", 
			{
				headers: { 
					"Authorization": " Bearer " + getToken()
				}
			}); 

   
		this.reload();

	}

    componentWillMount() {
        let client = new W3CWebSocket(getWSUrl() + '/extranet/websocketendpoint');
        client.onopen = () => {
            console.log("connect WS");
        };
        client.onmessage = (message) => {
			let obj = JSON.parse(message.data);
            console.log(obj.message);
			
            if(obj.type === "translateUpdate") {
                this.setState({
                    noticeMsg : obj.message ,
                })     
            }

        };
    }	
	
    componentWillUnmount() {
		axios.get(getAppUrl() + '/gatebackend/rest/content/itemstatus/' + this.state.itemId + "?status=free", 
			{
				headers: { 
					"Authorization": " Bearer " + getToken()
				}
			}); 		
    }	
    reload() {	
		if(this.props.location !== undefined && this.props.location.state !== undefined && this.props.location.state.selections.length > 0 ) {
			axios.get(getAppUrl() + '/gatebackend/rest/content/languages',  {
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}).then(res1 => {				
					axios.post(getAppUrl() + '/gatebackend/rest/content/'+ this.state.tableId +'/'+ this.state.maskId +'/itemsmultiple', 
						this.props.location.state.selections, 
						{
							headers: { 
								"Authorization": " Bearer " + getToken()
							}
						}).then(res => {
							this.setState({
								isLoaded : true,
								posts: res.data,
								itemLanguages: res1.data
							}) 
						})
						.catch((error) => {
						});
				})
				.catch((error) => {
				});

		}

		else {
			axios.get(getAppUrl() + '/gatebackend/rest/content/languages',  {
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}).then(res1 => {			
			
			axios.get(getAppUrl() + '/gatebackend/rest/content/'+ this.state.tableId +'/'+ this.state.maskId +'/item/' + this.state.itemId,  {
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}).then(res => {
					let smallMasks = [];
					res.data.mask.maskfields.map(function(mask) {
						if(mask.type === "mask" && mask.addDisplayMode === "preview") {
							let cLE = {};
							cLE.id = mask.fieldID;
							cLE.nextMask = mask.elementValue;
							cLE.tableId = mask.tableId;
							smallMasks.push(cLE);							
						}

					});
					console.log(res.data);
					this.setState({
						isLoaded : true,
						posts: res.data,
						smallMasks: smallMasks,
						itemLanguages: res1.data
					}) 
				})
				.catch((error) => {
				});

})
				.catch((error) => {
				});

			}

		}
		
	handleStateChange(mask){
			this.setState({
				mask : mask,
			})

    }

    handleMaskButtonClick(e, maskid, tableid, LinkMaskNextQuery, text, func) {
// nur speichern

		if(func == 14) {
			this.props.history.push("/maske/" + tableid  + "/" + maskid);
		}
		
		if(func == 13) {
			if(this.state.couldSave === false) {
				this.setState({
					adviceOpen: true
				})
			}
			else {
				axios.post(getAppUrl() + '/gatebackend/rest/content/savecontent', 
					this.state.posts
				,
				{
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}
				).then(response => {
					if(response.data.itemId == -1) {
						this.props.enqueueSnackbar(response.data.msg, { variant: 'error' });
					}
					else {
						if(response.data.msg === "new")
							this.state.itemId = response.data.itemId;
						this.props.history.push("/maske/" + this.state.tableId  + "/" + this.state.maskId);
						this.props.enqueueSnackbar(this.props.t('Daten gespeichert'), { variant: 'info' });
					}
				}                
				)
				.catch((error) => {
					console.log(error);
					alert("error");
				});
			}
		}

		if(func == 29) {
			if(this.state.couldSave === false) {
				this.setState({
					adviceOpen: true
				})
			}
			else {
				axios.post(getAppUrl() + '/gatebackend/rest/content/savecontent', 
					this.state.posts
				,
				{
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}
				).then(response => {
					if(response.data.itemId == -1) {
						this.props.enqueueSnackbar(response.data.msg, { variant: 'error' });
					}
					else {
						if(response.data.msg === "new")
							this.state.itemId = response.data.itemId;
						this.reload();
						this.props.enqueueSnackbar(this.props.t('Daten gespeichert'), { variant: 'info' });
					}
				}                
				)
				.catch((error) => {
					console.log(error);
					alert("error");
				});
			}
		}
// linken aufrufen		
		if(func == 11) {
            let nw = {width: 1200};
			this.setState({
                newWidthLinkItems: nw,			
				LinkDialog : true,
				LinkMaskNextQuery: LinkMaskNextQuery,
				LinkMask: maskid,
				LinkTable: tableid,
				LinkText: text
			}) 
		}
		if(func == 36) {
			let itemID = "";
			this.state.posts.contentResult.gateContent[0].contentFields.map(function(contentField) {
				if(contentField.fieldId == 98 ) {
					itemID = contentField.plainContent;
				}
			});
			this.props.history.push("/configureMaster/" + itemID +"/"+ this.state.itemId);
		}
		if(func == 37) {
			this.props.history.push("/configureCar/" + this.state.itemId );
		}
		if(func == 39) {
			this.props.history.push("/handleText/" + this.state.itemId );
//			this.history.push("/handleText/" + this.state.itemId );
		}
		if(func == 40) {
			this.doShowExport();
		}
						
	}   
	

    async doShowExport() {
        this.setState({chooseArticle: false });
        const getEToken = async ()  => {
            let x = await getExtranetToken();
            return x;
        }        
        let token =  await getEToken();
        axios.get(getAppUrl() + '/extranet/rest/shop/profiles', 
            {
                headers: { 
                    "Authorization": " Bearer " + token,
                }
            }).then(res => {
				this.setState({ exportOpen: true,
								shopProfiles:  res.data});
            }).catch((error) => {
                console.log(error);
                alert("error");
			});
    }	


	exportClose(action) {
		if(action == "do")
			this.exportArticle();
		else {
			this.setState({
				exportOpen: false,
				articleCheck: false
			});	
		}
	}

	async exportArticle() {
		this.setState({
			articleCheck: true
		});	
		let itemID = "";
		this.state.posts.contentResult.gateContent[0].contentFields.map(function(contentField) {
			if(contentField.fieldId == 98 ) {
				itemID = contentField.plainContent;
			}
		});		
        const getEToken = async ()  => {
            let x = await getExtranetToken();
            return x;
        }        
		let token =  await getEToken();
		const data = {
            profil: this.state.profillabel
		};


		axios.get(getAppUrl() + '/extranet/rest/articles/check/'+itemID+'?language='+this.state.profillanguage+'&country='+this.state.profillanguage, 
			
            {
                headers: { 
					"Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": " Bearer " + token,
                }
            }).then(res => {
				if(res.data.result === true) {
					this.props.enqueueSnackbar(this.props.t('Check erfolgreich abgeschlossen'), { variant: 'info' });
					axios.post(getAppUrl() + '/extranet/rest/shop/'+itemID+'?language='+this.state.profillanguage+'&country='+this.state.profillanguage, 
					qs.stringify(data),
					{
						headers: { 
							"Content-Type": "application/x-www-form-urlencoded",
							"Authorization": " Bearer " + token,
						}
					}).then(res1 => {
						this.props.enqueueSnackbar(this.props.t('Artikelexport eingetragen'), { variant: 'info' });
					}).catch((error) => {
						console.log(error);
						alert("error");
					});

				}
				else {
					this.props.enqueueSnackbar(this.props.t('Check fehlgeschlagen'), { variant: 'info' });
					let base64 = require('base-64');
					let utf8 = require('utf8');
					let bytes = base64.decode(res.data.bdata);
					let encoded = utf8.decode(bytes);
					this.downloadFile(encoded, 'report.csv');
				}
				this.setState({
					exportOpen: false,
					articleCheck: false
				});				
            }).catch((error) => {
                console.log(error);
                alert("error");
		});

    }   


	renderTabs(maskfields, currentState, itemId, maskWidth) {
		var i = 1;
		return maskfields.map(function(mask) {
			if(mask.type === "mask" && currentState === i) {
				i++;
				return (<div><VivaGrid language="de" maskWidth={maskWidth} maskId={mask.elementValue} tableId={mask.tableId} nextQuery={mask.nextQuery}  itemId={itemId} maskModus="6"></VivaGrid></div>)
			}
			else if(mask.type === "mask")
				i++;
		});
	}

	renderChat(userAdvice, oddeven) {
		if(oddeven == "odd")
			oddeven = "";
		else
			oddeven = "odd";
		return <>
				<li class={"clearfix " + oddeven}>
					<div class="chat-avatar">
						{userAdvice.fromUser == undefined || userAdvice.fromUser.avatar === "" ? (
	                        <>
	                        <span className="profilAvatarSmall" style={{backgroundColor: "#3f51b5", color: "white", paddingTop: "8px"}}>{this.getAvatar(userAdvice.fromUser)}</span>
	                        </>
	                    ) : (
	                        <img className="profilAvatarSmall" src={userAdvice.fromUser.avatar}/>
	                    ) }  
	                    <br/>
						<i>{Moment(userAdvice.createDate).format('DD MM YYYY') }</i>					
					</div>
					<div class="conversation-text">	
						<div class="ctext-wrap">
							<i>{userAdvice.label}</i>
							<p>{userAdvice.description}</p>
						</div>
					</div>
				</li>
				{userAdvice.items.length !== 0 ? (
					<>
						{userAdvice.items.map(ua => (
							this.renderChat(ua, oddeven)
						))}
					</>
					) :(<></>)}
				</>			
				
	}

	renderField(maskfield, gateContent, datafields) {	
		const getFilepath = value => {
			var val = value.split(";");
			var md5 = require('md5');
			var path = md5(val[0]);
			return "/" + path[0] + path[1] + "/" + path[2] + path[3] + "/" + val[0];
		};

		const getFilename = value => {
			var val = value.split(";");
			return val[0];
		};
		const getFiletype = value => {
			var val = value.split(";");
			return val[1];
		};

		const handleOnDrop = id =>  event => {
			var x = "";
			let file = event[0], reader = new FileReader(), self = this;
			reader.onload = function(event) {
				x = event.target.result;
				var posts = self.state.posts;
				posts.contentResult.gateContent[0].contentFields.map(function(contentField) {
					if(contentField.fieldId == id ) {
						contentField.data = x;
						contentField.content = file.name+";"+file.type;						
					}
				});
				self.setState({
					posts: posts
				});
			};
			 reader.readAsDataURL(file);
		};		

		const translate = txt => event => {
			return this.props.t(txt);
		};

		const handleInternLinks = (quillEditor) => {	
			const cursorPosition = quillEditor.getSelection().index;
			this.setState({ linkDialogOpen: true,
							cursorPosition: cursorPosition,
							quillEditor: quillEditor});
		};

		const handleEChange = (value, id) => {
			var posts = this.state.posts;
			let switchLanguage = this.state.switchLanguage;
			posts.contentResult.gateContent[0].contentFields.map(function(contentField) {
				if(contentField.fieldId == id ) {
					if(contentField.tableFieldTyp == "json") {
						if(!switchLanguage == true) {
							let tJ = JSON.parse(contentField.content);
							var escapeJSON = require('escape-json-node');
							tJ[itemLanguage].content = escapeJSON(value);
							contentField.content = JSON.stringify(tJ);
							contentField.sticky = 1;
						}						
					}
					else {
						contentField.content = value;
						contentField.sticky = 1;
					}
				}
			});
			this.setState({
				switchLanguage: false,
				posts: posts
			});
		};

		const fileDownload = (url,file)  => event => {

			axios({
			  url: url,
			  method: 'GET',
			  responseType: 'blob', // important
			}).then((response) => {
			  const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
			  const link = document.createElement('a');
			  link.href = url;
			  link.target = '_blank'
			  link.click();
			});
		}

		const handleChange = (id,maskfield)  => event => {
			var posts = this.state.posts;
			let couldSave = this.state.couldSave;
			let counter = 0;
			if(this.props.location != undefined && this.props.location.state != undefined && this.props.location.state.selections.length > 0 ) {
				this.props.location.state.selections.map(function(selection) {
					posts.contentResult.gateContent[counter].contentFields.map(function(contentField) {
						if(contentField.fieldId == id ) {
							if(contentField.content !== event.target.value) {
								contentField.content = event.target.value;
								contentField.sticky = 1;
							}
						}
					});
				counter++;
				});
			}
			else {
				posts.contentResult.gateContent[0].contentFields.map(function(contentField) {
					if(contentField.fieldId == id ) {						
						if(maskfield.displayType === "checkbox") {
							if(contentField.content === 1)
								contentField.content = 0;
							else
								contentField.content = 1;
							contentField.sticky = 1;
						}	
						else if(contentField.tableFieldTyp == "json") {
							let tJ = JSON.parse(contentField.content);
							tJ[itemLanguage].content = event.target.value;
							contentField.content = JSON.stringify(tJ);
							contentField.sticky = 1;
						}					
						else if(contentField.content !== event.target.value) {
							contentField.content = event.target.value;
							contentField.sticky = 1;

							contentField.validationText = "";
							if(maskfield.validateEntry === "notNull") {
								if(event.target.value === "")
									contentField.validationText = "darf nicht leer sein";
							}
							else if(maskfield.validateEntry === "onlyNumbers") {
								let isnum = /^\d+$/.test(event.target.value);
								if(!isnum)
									contentField.validationText = "keine Zahl";
							}
							if(contentField.validationText !== "")
								couldSave = false;
							else
								couldSave = true;
						}
						
					}
				});
			}
			this.setState({
				posts: posts,
				couldSave: couldSave
			});
		};

		var plItem = 0;
			gateContent[0].contentFields.map(function(contentField) {   
        	if(contentField.fieldId ==  881) {
				plItem = contentField.content;
				
			}
			
		});
		var itemLanguage = this.state.itemLanguage;
		let self = this;
		if(maskfield.type == "link") {
			if(maskfield.elementValue == 21) {
					return 	<>
							<span>{maskfield.name}</span><br/>
							<span className={maskfield.iconName} data-nextmask="619" data-op={maskfield.elementValue} data-leftitemid={plItem}  onClick={e => this.handlepdfDialog(true,e)}></span>
							</>
			}
			else if(maskfield.elementValue == 56) {
				
					return 	<>
							<span>{maskfield.name}</span>HIER<br/>
	                     	<NWPFormDialog planungsId="619" itemId={plItem} iconName={maskfield.iconName} plItem={plItem} handleStateChange={this.handleStateChange} reload={this.reload}></NWPFormDialog>
						</>
			}			
		}
		return gateContent[0].contentFields.map(function(contentField) {   
                if(contentField.fieldId == maskfield.elementValue ) {
					let hasComment = false;
					let userAdviceId = 0;
					self.state.posts.contentResult.meta.userAdvices.map(function(userAdvice) {
						if(contentField.fieldId == userAdvice.refFieldId) {
							hasComment = true;
							userAdviceId = userAdvice.id;							
						}
							
					});	
					var value =  contentField.content ;
					var plainContent = contentField.plainContent;
					var tableField;
					datafields.map(function(datafield) {
						if(datafield.id == contentField.fieldId ) 
							tableField = datafield;
					});

					if(maskfield.displayType === "statictext" && tableField.type === "singlechoice") {
						tableField.selectValues.map(function(selectValue) {
							if(selectValue.id == value) {
								value = selectValue.name;
								return;
							}
						});
					}

					if(tableField.type === "json") {
						try {
							let tJ = JSON.parse(contentField.content);
							value = tJ[itemLanguage].content;
						}
						catch (e) {
							alert("error");
						}
					}
				

// do the display dance	

					contentField.validationText = "";
					if(maskfield.validateEntry === "notNull") {
						if(value === "")
							contentField.validationText = "darf nicht leer sein";
					}
					else if(maskfield.validateEntry === "onlyNumbers") {
						let isnum = /^\d+$/.test(value);
						if(!isnum)
							contentField.validationText = "keine Zahl";
					}

					if(contentField.access === "readOnly") {
						return <>
							        {contentField.tableFieldTyp === "user" ? ( 
										<FormControl 
											style={{width: maskfield.width + "%"}}
											error={contentField.validationText !== ""}>      
											<InputLabel shrink htmlFor="age-label-placeholder">
													{maskfield.name}
											</InputLabel>
											<span style={{marginTop: "15px"}} dangerouslySetInnerHTML={{__html: contentField.plainContent}}></span>
										</FormControl>
	                                    ): (                                
										<TextField
											className={contentField.tableFieldTyp === "user" ? "" : "readOnlyIcon"}
											name="standard-name"
											label={maskfield.name}
											value={plainContent}
											margin="normal"
										InputProps={{
		          							readOnly: true,
		        						}}
										>
										{contentField.plainContent}					
										</TextField>
									)}				
								</>			
							
					}
                    if(contentField.access === "forbidden") {
                        return  <TextField
								name="standard-name"
								label={maskfield.name}
								value="kein Zugriff"
								margin="normal"
								InputProps={{
          							readOnly: true,
        						}}
							/>
                    }
					if(maskfield.displayType === "statictext") {
						return <TextField
								name="standard-name"
								label={maskfield.name}
								value={value}
								margin="normal"
								InputProps={{
          							readOnly: true,
        						}}
							/>
					}
					if(maskfield.displayType === "textarea") {
						return <TextField
								
								name={"field_" + contentField.fieldId}
								label={maskfield.name}							
								value={value}
								margin="normal"
								multiline
								onChange={handleChange(contentField.fieldId, maskfield)}
							/>
					}
					
					if(maskfield.displayType === "WebEditor") {
						return <div style={{height: "280px"}}>
									<div class="">
										<span class="fa fa-ellipsis-v readOnlyIcon" onClick={e => self.openUserAdvice(e, contentField.fieldId, maskfield.name)}>&nbsp;</span>
										{hasComment === true ? (<span class="fa fa-comment readOnlyIcon redComment" onClick={e => self.viewUserAdvice(e, userAdviceId)}>&nbsp;</span>
											) : (<></>)}

										{maskfield.name}
									</div>
									<ReactQuill style={{height: "200px"}}
												value={value}
												id={"field_" + contentField.fieldId}
												ref={"field_" + contentField.fieldId}
												onChange={(	text: string, 
															delta: any, 
															source: string, 
															editor: any) => {
																if (source == 'user') {
																	handleEChange(text, contentField.fieldId);
																}
															}} 
									/>						
								</div>
					}
					if(maskfield.displayType === "WebEditorLanguage") {
						return <div style={{height: "280px"}}>
									<div class="">
										<span className="fa fa-flag"></span> {maskfield.name}
									</div>
									
									<Editor style={{height: "200px"}}
												value={value}
												id={"field_" + contentField.fieldId}
												ref={"field_" + contentField.fieldId}
												internLinks={handleInternLinks}
												onChange={(	text: string, 
															delta: any, 
															source: string, 
															editor: any) => {
																
																	handleEChange(text, contentField.fieldId);
																
												}} 
									/>																
								</div>
					}	
					if(maskfield.displayType === "edittext") {
						return <>
								<FormControl 
									style={{width: maskfield.width + "%"}}
									error={contentField.validationText !== ""}>      
									<InputLabel shrink htmlFor="age-label-placeholder">
											<span class="fa fa-ellipsis-v readOnlyIcon" onClick={e => self.openUserAdvice(e, contentField.fieldId, maskfield.name)}>&nbsp;</span>
											{hasComment === true ? (<span class="fa fa-comment readOnlyIcon redComment" onClick={e => self.viewUserAdvice(e, userAdviceId)}>&nbsp;</span>
												) : (<></>)}

											{maskfield.name}
									</InputLabel>
									<TextField 	style={{width: maskfield.width + "%"}}
												name={"field_" + contentField.fieldId}
												value={value}
												margin="normal"
												onChange={handleChange(contentField.fieldId, maskfield)}
												error={contentField.validationText !== ""}
												helperText={contentField.validationText !== "" ? contentField.validationText : ' '}
											/>
								
								</FormControl>
								</>
					}														
					if(maskfield.displayType === "edittextLanguage") {
						return <><span className="fa fa-flag"></span>
									<TextField 	style={{width: maskfield.width + "%"}}
											name={"field_" + contentField.fieldId}
											label={maskfield.name}							
											value={value}
											margin="normal"
											onChange={handleChange(contentField.fieldId, maskfield)}
											error={contentField.validationText !== ""}
											helperText={contentField.validationText !== "" ? contentField.validationText : ' '}
									/></>
					}					
					if(maskfield.displayType === "checkbox") {
						return <FormGroup  aria-label="position" name="position"  onChange={handleChange(contentField.fieldId, maskfield)} >
									<FormControlLabel
									checked={value == 1 ? true: false}
									value={1}
									control={<Switch color="primary" />}
									label={maskfield.name}
									labelPlacement="top"
									className="alignItems"
									/>
								</FormGroup>
					}	
					if(maskfield.displayType === "filename") {
						 return <TextField
								name="standard-name"
								label={maskfield.name}
								value={getFilename(value)}
								margin="normal"
								InputProps={{
          							readOnly: true,
        						}}
							/>
					}
					if(maskfield.displayType === "filetype") {
						 return <TextField
								name="standard-name"
								label={maskfield.name}
								value={getFiletype(value)}
								margin="normal"
								InputProps={{
          							readOnly: true,
        						}}
							/>
					}
					if(maskfield.displayType === "filepath") {
						 return <TextField
								name="standard-name"
								label={maskfield.name}
								value={getFilepath(value)}
								margin="normal"
								InputProps={{
          							readOnly: true,
        						}}
							/>
					}					
					if(maskfield.displayType === "uploadfile") {
						 return <FormControl>      
									<InputLabel shrink htmlFor="age-label-placeholder">
												{maskfield.name}
									</InputLabel>
									<ImageUploader
									withIcon={true}
									buttonText="Bild auswählen"
									onChange={handleOnDrop(contentField.fieldId)}		
									imgExtension={['.jpg', '.png']}
									maxFileSize={5242880}
									withPreview={true}
									label = "Max file size: 5mb, accepted: jpg|png"
									singleImage={true}
									/>
								</FormControl>
					}

					if(maskfield.displayType === "filepreview") {
						 return <FormControl>      
									<InputLabel shrink htmlFor="age-label-placeholder">
												{maskfield.name}
									</InputLabel>
									<div className="previewBorder">
										<img className="previewBig" src={"/imagepool" + getFilepath(getFilename(value))}/>
									</div>
								</FormControl>
					}
					if(maskfield.displayType === "single_choice_list") {
						return 	<FormControl 
									style={{width: maskfield.width + "%"}}
									error={contentField.validationText !== ""}>
									<InputLabel shrink htmlFor="age-label-placeholder">
										{maskfield.name}
									</InputLabel>						
									<Select
										className="visibleMaskItem"
										multiple
										value={makeArray(value)}
										onChange={handleChange(contentField.fieldId, maskfield)}
										input={<Input id="select-multiple-checkbox" />}
										renderValue={selected => displayArrayValues(datafields, contentField.fieldId, value)}
									>
									{	
										datafields.map(function(datafield) {
											if(datafield.id == contentField.fieldId ) {
												return datafield.selectValues.map(function(selectValue) {
													return	<MenuItem key={selectValue.id} value={selectValue.id}>
																<Checkbox  checked={isValueSelected(datafields, contentField.fieldId, value, selectValue.id)}/>
																<ListItemText primary={selectValue.name} />
															</MenuItem>
												})
											}
										})
									
									}


									</Select>
								</FormControl>
					}

					else if(maskfield.displayType === "popup") {
						return  <>
									
									<FormControl 
										style={{width: maskfield.width + "%", marginBottom: "15px"}}
										error={contentField.validationText !== ""}>      
										<InputLabel shrink htmlFor="age-label-placeholder">
												<span class="fa fa-ellipsis-v readOnlyIcon"  onClick={e => self.openUserAdvice(e, contentField.fieldId, maskfield.name)}>&nbsp;</span>
												{hasComment === true ? (<span class="fa fa-comment readOnlyIcon redComment" onClick={e => self.viewUserAdvice(e, userAdviceId)}>&nbsp;</span>
													) : (<></>)}

												{maskfield.name}
										</InputLabel>
										<Select
											value={value}
											onChange={handleChange(contentField.fieldId, maskfield)}
											input={<Input name="age" id="age-label-placeholder" />}
											displayEmpty
											name="age"
											error={contentField.validationText !== ""}
										>
																				
										{  datafields.map(function(datafield) {
												if(datafield.id == contentField.fieldId ) {
													return datafield.selectValues.map(function(selectValue) {
														return <MenuItem value={selectValue.id}>{selectValue.name}</MenuItem>
	
													})
												}
											}) 
										}			
										</Select>
										{contentField.validationText !== "" ? (
											 <FormHelperText>{contentField.validationText}</FormHelperText>
										) : (<></>)}
										 
									</FormControl>
								</>
					}				
									  
					else if(maskfield.displayType === "FileDownload") {
						return <span onClick={fileDownload("/" + value, value)}>Download File</span>
					}

					else
						return "render as " + maskfield.displayType	+ " Value: " + value				
				}

		 });
		 

	}

    render() {

		const language = this.state.itemLanguage;
		const tabMenueOpen =Boolean(this.state.anchorEl);
        const Transition = React.forwardRef(function Transition(props, ref) {
            return <Slide direction="up" ref={ref} {...props} />;
        });
        const {offset, isLoaded, posts, noteTop, noteLeft} = this.state;
		

        if(!isLoaded) {
            return (<div><Loader/></div>)
        }
        else {

		const { classes } = this.props;
			return (
				<>
				<Resize handleWidth="5px" handleColor="#777" onResizeStop={(e) => this.resizeMain(e)}>
					<ResizeHorizon width="75%" minWidth="150px">				
                    <div className="Vivacard">
                        <div className="Vivacardheader">
                            <div className="row">
                                <div className="col-sm">
                                    {posts.mask.displayName}
                                </div>
                                <div className="col-sm ">
                                    <span className="pull-right"> 
										<Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={e => this.handleClickFlag(e)}>              
											<span className="fa fa-flag"></span>
										</Button>
										<Menu	id="simple-menuFlag "
                                                anchorEl={this.state.anchorElFlag}
                                                keepMounted
                                                open={Boolean(this.state.anchorElFlag)}
                                                onClose={this.handleCloseFlag}
                                            >											
											{this.state.itemLanguages.map(iT => (
												<>
												<MenuItem onClick={e => this.changeItemLanguage(iT.iso)}><span className={iT.iso == language ? "fa fa-check" : ""}></span>&nbsp;{iT.label}</MenuItem>
												</>
											))}
                                        </Menu>										
                                    </span>	

                                    <span className="pull-right"> 
										<Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.saveDefaultStyle('true'))}>              
											<span className="fa fa-wrench"></span>
										</Button>
                                    </span>								
                                    <span className="pull-right"> 
										<Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.addBookmark('true'))}>              
											<span className="fa fa-bookmark-o"></span>
										</Button>
                                    </span>
                                    <span className="pull-right"> 
                                        <MaskEdit maskid={this.state.maskId} handleStateChange={this.handleStateChange} reload={this.reload}></MaskEdit>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="Vivacardbody vivaGridBody resizeMain" >
                            <AppBar position="static" color="default">
                                    <Tabs
                                    className="grid-Cell-Header"
                                    value={this.state.tabValue}
                                    onChange={this.handleTabChange}
                                    indicatorColor="secondary"
                                    textColor="white"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    >
                                    <Tab label="Allgemein"
									></Tab>
                                        {
                                            posts.mask.maskfields.map(mask => (
												(mask.type === "mask")  &&
											<Tab label={mask.name}
												classes={{ wrapper: classes.Tab }}
												icon={<ArrowDropDownIcon onClick={(e) => this.handleTabMenuClick(e, mask)} />}/>
                                        ))}
                                    </Tabs>
                            </AppBar>


							{this.state.tabValue === 0 && 
								<div className="Vivacardbody vivaGridBody" style={{height: "calc(100vh - 289px)"}}>
									<div className="row rowpadding">
										<div className="col">
										{
											posts.mask.maskfields.map(mask => (
												((mask.type === "field" || mask.type === "link") && mask.gravity === "left")  &&
												<div> 
													{this.renderField(mask, posts.contentResult.gateContent, posts.contentResult.fields)}
												</div>
										))}
										</div>
										<div className="col">
										{
											posts.mask.maskfields.map(mask => (
												((mask.type === "field" || mask.type === "link") && mask.gravity === "right")  &&
												<div> 
													{this.renderField(mask, posts.contentResult.gateContent, posts.contentResult.fields)}
												</div>
										))}
										</div>
									</div>
									{posts.contentResult.meta.userAdvicesRightItems.length > 0 ? (
										<>
										<div className="note ui-draggable ui-draggable-handle" style={{right: "1490px", top: noteTop, left: noteLeft, transform: "rotate(-1deg)"}}>
											<div style={{marginBottom: "20px"}}>
												<IconButton edge="end" color="inherit"  aria-label="close" style={{width: "10px", height: "10px", position: "absolute", right: "12px", top: "0px"}}>
					                                <CloseIcon />
					                            </IconButton>											
											</div>
											<ul style={{padding: "0px", paddingLeft: "20px"}}>
											{posts.contentResult.meta.userAdvicesRightItems.map(ua => (
													<li>{ua.label}&nbsp;
														<Link to={{
                                                                pathname: "/maske/"+ua.refTableId+"/"+ua.refMaskId+"/"+ua.refItemId,
                                                            }}    
                                                      	>		
                                                      		<i className="fa fa-arrow-right"></i>											
														</Link>
													
													</li>
											))}
											</ul>
										</div>
										</>										
									) :(<></>)}
										

									
								</div>
							}
							<div className="gridBottom" 
								style={{color: "#000000"}}
								>
								{this.renderTabs(posts.mask.maskfields, this.state.tabValue, this.state.itemId, this.state.mainFrameWidth)}
								{
									posts.mask.maskfields.map(mask => (
									(mask.type === "submit")  &&
									<Button function={mask.elementValue} 
											id={mask.elementValue} 
											maskId={mask.nextMask} 
											tableId={mask.tableId} 
											variant="outline-primary" 
											onClick={((e) => this.handleMaskButtonClick(e, mask.nextMask, mask.tableId, 0, mask.name, mask.elementValue))}>
											{mask.name}
									</Button>
								))}
							</div>
	
                        </div>

						<Dialog open={this.state.linkDialogOpen} 
						    	fullWidth="md"
					        	maxWidth="md"						
								onClose={((e) => this.addLinks('false'))} 
								aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">Link anlegen</DialogTitle>
							<div style={{height: "400px"}}>
								<DialogContent style={{height: "100%"}}>
									<DialogContentText>
										Geben Sie einen Suchbegriff ein.
									</DialogContentText>
									<Paper className="searchRoot">
										<InputBase
											onChange={e => this.setLinkTerm(e)}
											className="searchInput"
											placeholder={this.props.t('LinkName')}
											inputProps={{ 'aria-label': 'Linkname' }}
										/>
										<InputBase
											onChange={e => this.setSearchTerm(e)}
											className="searchInput"
											onKeyPress={this.catchReturn}
											placeholder={this.props.t('Suchbegriff')}
											inputProps={{ 'aria-label': 'Suchbegriff' }}
										/>		
										<IconButton className="searchIconButton" 
                                                aria-label="Search"
                                                onClick={e => this.searchGlobal()}>
                                        <SearchIcon />
                                    </IconButton>
									</Paper>	
									<List>
										{this.state.searchResult && this.state.searchResult.length > 0 ? (
											<div style={{overflow: "scroll"}}>
										{ this.state.searchResult.map(lchange => (
										<ListItem >
											<ListItemAvatar>
											<Avatar className="classesAvatar">
												{
													lchange.prevImage !== null && lchange.prevImage !== "/" ? ( <img src={"/imagepool" + lchange.prevImage}/>) : (<LocalOfferIcon/>)
												}
											</Avatar>
											</ListItemAvatar>
											<ListItemText primary={
												<div>
													<div>{lchange.msg + ": " + lchange.defaultName  }</div>
													<div className="MuiTypography-body2">{lchange.searchResultTxt}</div>
												</div>
												}
											/>
											<ListItemSecondaryAction>
													<IconButton edge="end" aria-label="Open" >
                                                        <EditIcon onClick={((e) => this.addLinks('add', lchange.itemId, "car"))}/>
                                                    </IconButton>																
												
											</ListItemSecondaryAction>
										</ListItem>
										))}
										</div>) : (
											<>
												{this.state.searchStatus === "searching" ? (<><Loader/></>) : 
													(<>
														{this.state.searchStatus === "finish" ?(<>
															Die Suche nach "{this.state.searchTerm}" führte zu keinem Ergebnis."
															</>) : (<></>)}
													</>)}
												
											</>
										)
										}
									</List> 
								</DialogContent>
							</div>
							<DialogActions>
								<Button onClick={((e) => this.addLinks('false'))} color="primary">
									OK
								</Button>
							</DialogActions>
						</Dialog>
					
						<Dialog
	                            fullWidth="md"
	                            maxWidth="md"
	                            open={this.state.pdfDialog}
	                            onClose={((e) => this.handlepdfDialog(false,e))}
	                            aria-labelledby="alert-dialog-title"
	                            aria-describedby="alert-dialog-description"
	                        >
	                        <DialogTitle id="alert-dialog-title">{this.props.t('PDF')}</DialogTitle>
	                        <DialogContent>
	                            <DialogContentText id="alert-dialog-description">
									<PDFDisplay nextMask="619" planingFirstOpen={this.state.planingFirstOpen} cleanForceRefresh="true" forceRefresh="true" preview="" language="de" items="-1" itemId={this.state.planungsItemId} />	                            
	                            </DialogContentText>
	                        </DialogContent>
	                        <DialogActions>
	                        <Button onClick={((e) => this.handlepdfDialog(false,e))} color="primary">
	                            Cancel
	                        </Button>
	                        <Button onClick={((e) => this.handlepdfDialog(false,e))} color="primary">
	                            Ok
	                        </Button>
	                        </DialogActions>
	                    </Dialog> 

						<Dialog open={this.state.bookmarkOpen} onClose={((e) => this.addBookmark('false'))} aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.props.t('Bookmark anlegen')}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									{this.props.t('Vergeben Sie einen Namen für ihren Bookmark')}
								</DialogContentText>
								<FormControl className={classes.formControl} style={{width: "100%"}}>
									<InputLabel htmlFor="age-native-helper">Ordner Auswahl</InputLabel>
									<NativeSelect
										value={this.state.bookmarkfolderId}
										onChange={e => this.change(e)}
										inputProps={{
											name: 'bookmarkfolder',
											id: 'age-native-helper',
										}}
									>
									{this.state.bookmarkLinks.length && this.state.bookmarkLinks.map(folder => (
										<option value={folder.id}>{folder.label}</option>
									))}
									</NativeSelect>
									<FormHelperText>wählen Sie den Ordner für den Bookmark aus.</FormHelperText>
								</FormControl>								
								<TextField
									autoFocus
									margin="dense"
									name="bookmarklabel"
									label="Bookmarkname"
									type="email"
									onChange={e => this.change(e)}
									value={this.state.bookmarklabel}
									fullWidth
								/>
							</DialogContent>
							<DialogActions>
								<Button onClick={((e) => this.addBookmark('false'))} color="primary">
									{this.props.t('Abbruch')}
								</Button>
								<Button onClick={((e) => this.addBookmark('add'))} color="primary">
									{this.props.t('OK')}
								</Button>
								</DialogActions>
						</Dialog>


						<Dialog open={this.state.adviceOpen} 
						    fullWidth="sm"
					        maxWidth="sm"
							onClose={((e) => this.adviceClose(e))} 
							aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.props.t('Hinweis')}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									{this.props.t('Bitte korrigieren Sie zunächst die Fehler')}
								</DialogContentText>								
							</DialogContent>
							<DialogActions>
								<Button onClick={((e) => this.adviceClose(e))} color="primary">
									{this.props.t('OK')}
								</Button>
								</DialogActions>
						</Dialog>



						
						<Dialog open={this.state.showUserAdvice} 
						    fullWidth="md"
					        maxWidth="md"
							onClose={((e) => this.userAdviceClose(e))} 
							aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.props.t('Anmerkung')} zu Feld: {this.state.UserAdviceFieldName}</DialogTitle>
							<DialogContent>
								
								<DialogContentText>
									<FormControl 
										style={{width: "100%"}}>      
										<InputLabel shrink htmlFor="age-label-placeholder">	
											Titel
										</InputLabel>
										<TextField 	style={{width: "100%"}}
													name="UserAdviceTitle"
													value={this.state.UserAdviceTitle}
													margin="normal"
													onChange={e => this.handleUserAdviceChange(e)}
												/>
									</FormControl>								
								
									<TextField
										style={{width: "100%"}}
										name="UserAdviceDescription"
										label="Beschreibung"							
										value={this.state.UserAdviceDescription}
										margin="normal"
										multiline
										minRows={4}
										maxRows={4}
										onChange={e => this.handleUserAdviceChange(e)}

									/>
									<Grid container  spacing={2} style={{marginTop: "25px"}}>
	                                	<Grid item md={12}>
	                                    	<Grid container justify="center" spacing={2}>                                        
 													<Grid style={{width: "33%", minHeight: "20px"}} key={1} item>		
														<FormControl 
															style={{width: "200px", marginBottom: "15px"}}
															>      
															<InputLabel shrink htmlFor="age-label-placeholder">
																für Benutzer:
															</InputLabel>
															<Select
																value={this.state.UserAdviceToUser}
																input={<Input name="age" id="age-label-placeholder" />}
																displayEmpty
																name="UserAdviceToUser"
																onChange={e => this.handleUserAdviceChange(e)}
															>
																									
															{  this.state.posts.contentResult.meta.users.map(function(user) {
																		return <MenuItem value={user.id}>{user.firstname}&nbsp;{user.lastname}</MenuItem>
																	})
															}			
															</Select>
					
															 
														</FormControl>									
													</Grid>
													<Grid style={{width: "33%", minHeight: "20px"}} key={1} item>
														<FormControl 
															style={{width: "200px", marginBottom: "15px"}}
															>      
															<InputLabel shrink htmlFor="age-label-placeholder">
																Status:
															</InputLabel>
															<Select
																value={this.state.UserAdviceStatus}
																input={<Input name="age" id="age-label-placeholder" />}
																displayEmpty
																name="UserAdviceStatus"
																readonly
																onChange={e => this.handleUserAdviceChange(e)}
															>
															<MenuItem value="offen">Offen</MenuItem>																									
															<MenuItem value="antwort">Antwort</MenuItem>			
															</Select>
														</FormControl>														
													</Grid>
													
													<Grid style={{width: "33%", minHeight: "20px"}} key={1} item>
													</Grid>

												</Grid>
											</Grid>
									</Grid>
									
									
									<FormControl 
										style={{width: "100%"}}>      
										<InputLabel shrink htmlFor="age-label-placeholder">	
											Eingetragen von:
										</InputLabel>
										
									</FormControl>
								</DialogContentText>								
								
							</DialogContent>
							<DialogActions>
								<Button onClick={((e) => this.userAdviceClose(e, "close"))} color="primary">
									{this.props.t('Abbruch')}
								</Button>
								<Button onClick={((e) => this.userAdviceClose(e, "send"))} color="primary">
									{this.props.t('eintragen')}
								</Button>
								</DialogActions>
						</Dialog>



						<Dialog open={this.state.exportOpen} 
							onClose={((e) => this.exportClose("false"))} 
							aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.props.t('Hinweis')}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									{this.props.t('Exportieren Sie diesen Artikel')}
								</DialogContentText>
								{this.state.articleCheck === false ? (
									<>
									<FormControl className={classes.formControl} style={{width: "100%"}}>
										<InputLabel htmlFor="age-native-helper">Sprache</InputLabel>
										<Select
											value={this.state.profillanguage}
											onChange={e => this.change(e)}
											inputProps={{
												name: 'profillanguage',
												id: 'age-native-helper',
											}}
										>
										{this.state.itemLanguages.map(iT => (
											<option value={iT.iso}>{iT.label}</option>
										))}
										</Select>
									</FormControl>		
									<FormControl className={classes.formControl} style={{width: "100%"}}>
										<InputLabel htmlFor="age-native-helper">ShopwareProfile</InputLabel>
										<Select
											value={this.state.profillabel}
											onChange={e => this.change(e)}
											inputProps={{
												name: 'profillabel',
												id: 'age-native-helper',
											}}
										>
										{this.state.shopProfiles.map(iT => (
											<option value={iT.name}>{iT.name}</option>
										))}
										</Select>
									</FormControl>		
								</>) : (
									<>
									Check {this.state.noticeMsg}
									</>
								)}
								
							</DialogContent>
							<DialogActions>
								<Button onClick={((e) => this.exportClose("false"))} color="primary">
									{this.props.t('Abbrechen')}
								</Button>
								<Button onClick={((e) => this.exportClose("do"))} color="primary">
									{this.props.t('OK')}
								</Button>
								</DialogActions>
						</Dialog>						


						<Drawer anchor="right" 
								open={this.state.LinkDialog} 
								onClose={this.handleCloseLinkDialog}
								PaperProps={{ style: this.state.newWidthLinkItems }}>
							<div
								id="dragger"
								onMouseDown={event => {
									this.handleMousedown(event);
								}}
								className="drawerDragger"
							/>								
                            <AppBar className="appBar title grid-Cell-Header">
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={this.handleCloseLinkDialog} aria-label="Close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" >
                                        {this.state.LinkText}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <div className="maxWidth" id="xyz">
								<VivaGrid maskId={this.state.LinkMask} 
										  tableId={this.state.LinkTable} 
										  itemId="-1" 
										  maskModus="6" 
										  nextQuery={this.state.LinkMaskNextQuery}
										  leftItemId={this.state.itemId}  
										  maskWidth={this.state.currentMaskWidthLink} 
										  anchor="xyz"></VivaGrid>
							</div>
                        </Drawer>
							<Popover
								open={tabMenueOpen}
								anchorEl={this.state.anchorEl}
								onClose={this.handleTabMenuClose}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "center"
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "center"
								}}
								>
								<MenuItem onClick={() => this.handleMenuTabClick("Three")}>
									permarnent darstellen
								</MenuItem>

							</Popover>
                    </div>
				</ResizeHorizon>
				
				<ResizeHorizon width="25%" minWidth="150px">
					<Resize handleWidth="5px" handleColor="red">
						{this.state.smallMasks && this.state.smallMasks.length > 0 ? (
							<>
	
							{this.state.smallMasks.map(mask => (
								<ResizeVertical height="400px" minHeight="400px" overflow="unset">
									<div>
										<span className="pull-rightm"> 
											<Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.closeSmallPreview(mask.id))}>              
												<span className="fa fa-window-close-o"></span>
											</Button>
										</span>
									</div>													
									<div className="resizeSmall">	
										<VivaGrid maskId={mask.nextMask} 
												tableId={mask.tableId} 
												itemId={this.state.itemId} 
												maskModus="11" 
												maskWidth={this.state.smallFrameWidth} 
												isSmallMask="true"
										></VivaGrid>
									</div>
								</ResizeVertical>
							))}
						</>) :(<>
								<ResizeVertical height="400px" minHeight="400px" overflow="unset">						
									<div className="resizeSmall">	
									Add Tabs here
									</div>
								</ResizeVertical>					
						</>)}
					</Resize>
				</ResizeHorizon>
			</Resize>
			</>                        
            );
        }
    }
}

export default withStyles(styles)(withTranslation()(withSnackbar(VivaMaskEingabe)));