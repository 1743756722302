import React, { Component } from 'react';
import {BrowserRouter, Switch, Route, Link} from 'react-router-dom';
import ApplicationFrame from './components/ApplicationFrame';
import Login from './components/Login';
import SignNew from './components/SignNew';
import MessageButtons from './components/blocks/MessageButtons';
import './App.css';
import { SnackbarProvider, Snackbar } from 'notistack';
import SnackMessage from './components/blocks/SnackMessage';

class App extends Component {
    render() {
        
        return (
            <BrowserRouter>
                <Switch>
                  <Route path='/login'  component={Login} />  
                  <Route path='/signNew'  component={SignNew} />  
                  <Route path='/'  component={ApplicationFrame} />  
                </Switch>
            </BrowserRouter>
        );
    }
}

export default () => (
    <SnackbarProvider maxSnack={3} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
        }}

    >
        <MessageButtons />
            <BrowserRouter>
                <Switch>
                  <Route path='/login'  component={Login} />  
                  <Route path='/signNew'  component={SignNew} /> 
                  <Route path='/'  component={ApplicationFrame}  />/>  
                </Switch>
            </BrowserRouter>
    </SnackbarProvider>
);

