import React, { Component } from 'react';
import axios from 'axios';
import {getToken} from '../helpers/Token';
import {getAppUrl} from '../helpers/Defaults';
import {Modal} from 'react-bootstrap';
import MaskEdit from './MaskEdit';
import VivaMaskEingabe from './VivaMaskEingabe';
import { stringify } from 'querystring';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import {Route, Link, Switch } from "react-router-dom";
import VivaGrid from './VivaGrid'
class VivaMask extends Component {

    constructor(props) {
        super(props);
        var maskID = this.props.match.params.maskId;
        let t = maskID.split("?");
        maskID = t[0];
        let nextQuery = 0;
        
        this.state = {
            select: [],
            fields: {},
            suche: "",
            show: false,
            offset: 0,
            isLoaded : false,
            productData: [],
            orderField: "",
            maskHeaderClass: undefined,
            searchfield: "",
            anchorElGridMenue: null,
            maskId: maskID,
            nextQuery: nextQuery
        };

        this.handleStateChange = this.handleStateChange.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleFunctionClick = this.handleFunctionClick.bind(this);
        this.change = this.change.bind(this);
        this.handleClickGridMenue = this.handleClickGridMenue.bind(this);
        this.handleCloseGridMenue = this.handleCloseGridMenue.bind(this);        
        this.reload = this.reload.bind(this);
    }   

    handleClickGridMenue(event) {
        this.setState({
            anchorElGridMenue: event.currentTarget
        })
    }

    handleCloseGridMenue() {
        this.setState({
            anchorElGridMenue: null
        })
    }

    handleStateChange(mask){
        this.setState({
            mask : mask,
        })
    }
    
    componentDidUpdate(prevProps) {
        if(this.props.match.params.tableId !== prevProps.match.params.tableId || this.props.match.params.maskId !== prevProps.match.params.maskId) {
				let filterSQL = "";
				let tree = "";
            var maskID = this.props.match.params.maskId;
            let t = maskID.split("?");
            maskID = t[0];
            let nextQuery = 0;
            let x = window.location.href.split("?");
            if(x.length > 1) {
               // maskID = x[0];
                let o = x[1].split("=");
                if(o[0] === "nextQuery")
                    nextQuery = o[1];
            }
            this.setState({ offset: 0, 
                            orderField: "", 
                            searchfield: "",
                            maskId: maskID,
                            nextQuery: nextQuery,
		                    filterSQL: filterSQL,
		                    tree: tree                            
                          });
            this.reload("reload", "")
        }
    }

    updateDimensions() {
        var elem = document.getElementsByClassName('applicationAction')[0];
        var width = elem.offsetWidth;
        this.setState({
            width : width,
        })
    }

    componentDidMount() {  
		console.log("componentDidMount Mask");
    	let filterSQLID =  new URLSearchParams(this.props.location.search).get("filterSQLID");
		if(this.props.location.search == "") {
			let x  = decodeURIComponent(this.props.location.pathname);
			var p1 = x.split("filterSQLID=")
			if(p1.length == 2)
				filterSQLID = p1[1];
		}	
		if(filterSQLID == null)
		filterSQLID = 0;
		
		axios.get(getAppUrl() + '/gatebackend/rest/settings/getUserDashboard/'+ filterSQLID, 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
				let filterSQL = "";
				let tree = "";

				if(res.data.filterResults.length != 0) {
					let f = res.data.filterResults[0];
					filterSQL = f.sql;
					tree = f.tree;
				}
				
		        var maskID = this.props.match.params.maskId;
		        let t = maskID.split("?");
		        maskID = t[0];
		        let nextQuery = 0;
		        let x = maskID.split("?");
		        if(x.length > 1) {
		            maskID = x[0];
		            let o = x[1].split("=");
		            if(o[0] === "nextQuery")
		                nextQuery = o[1];
		        }                    
		        this.setState({orderField: "", 
		                       searchfield: "",
		                       maskId: maskID,
		                       nextQuery: nextQuery,
		                       filterSQL: filterSQL,
		                       tree: tree});
		        this.reload("reload", "");
		        window.addEventListener("resize", this.updateDimensions);
		        this.updateDimensions();

	
            })
            .catch((error) => {
//                localStorage.removeItem("app-token");
//                this.props.history.push("/login");
            });	

    }

    reload(e, orderField, event) {     
        let getRows = 15;   
        if(event != undefined)
            console.log(event.target.dataset.maskid);
        var searchTerm = "";
        if(this.state.searchfield != undefined)
            searchTerm =this.state.searchfield;
        var offset = this.state.offset;
        if(orderField == undefined)
            orderField = this.state.orderField;
        else if(orderField != "") {
            var oldOrderField = this.state.orderField;
            var maskHeaderClass = this.state.maskHeaderClass;

            for (var key in maskHeaderClass) {
                maskHeaderClass[key] = "fa fa-sort-alpha-asc pull-right showLink";
            }

            maskHeaderClass[event.target.dataset.maskid] = "fa fa-sort-alpha-asc pull-right showLink fa-actice";
            if(oldOrderField == orderField) {
                orderField = orderField + " DESC ";
                maskHeaderClass[event.target.dataset.maskid] = "fa fa-sort-alpha-desc pull-right showLink fa-actice";
            }
            else if(oldOrderField ==  orderField + " DESC ") {
                maskHeaderClass[event.target.dataset.maskid] = "fa fa-sort-alpha-asc pull-right showLink";
                orderField = "";

            }
            this.setState({orderField: orderField,
                          maskHeaderClass: maskHeaderClass});
        }
        if(e === "step-backward") {
            offset = 0;
        }
        if(e === "backward") {
            offset = offset - getRows;
            if(offset < 0)
                offset = 0;
        }
        if(e === "forward") {
            offset = offset + getRows;
            if(offset > this.state.posts.contentResult.meta.total -getRows)
                offset = this.state.posts.contentResult.meta.total -getRows;
        }
        if(e === "step-forward") {
            offset = this.state.posts.contentResult.meta.total -getRows;
            if(offset < 0)
                offset = 0;
        }

        this.setState({
            isLoaded : false,
            posts: []
        });
        let nextQuery = this.state.nextQuery;
        var tableID = this.props.match.params.tableId;
        var maskID = this.props.match.params.maskId;
        let x = maskID.split("?");
        if(x.length > 1) {
            maskID = x[0];
            let o = x[1].split("=");
            if(o[0] === "nextQuery")
                nextQuery = o[1];
        }

        axios.get(getAppUrl() + '/gatebackend/rest/content/mask/'+ maskID +'?offset='+ offset +'&count='+getRows+'&orderField='+orderField+'&searchTerm='+searchTerm + "&nextQuery="+nextQuery, 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                var width = 0;
                var maskHeaderClass = [];
                res.data.mask.maskfields.map(function(mask) {
                    if(mask.type !== "submit" && mask.type != "constant") {
                        width += mask.width;
                    }
                    maskHeaderClass[mask.elementValue] = "fa fa-sort-alpha-asc pull-right showLink";
                });
                if(this.state.maskHeaderClass == undefined) {
                    this.setState({
                        currentMaskWidth: width,
                        offset: offset,
                        isLoaded : true,
                        posts: res.data,
                        maskHeaderClass: maskHeaderClass,
                        nextQuery: nextQuery
                    }) 
                }
                else {
                    this.setState({
                        currentMaskWidth: width,
                        offset: offset,
                        isLoaded : true,
                        posts: res.data
                    }) 
                }
                
//                var breadcrumbs = ["Produkte", res.data.mask.name];
//                this.props.location.handleStateChange(breadcrumbs);
            })
            .catch((error) => {
//                localStorage.removeItem("app-token");
//                this.props.history.push("/login");
            });

    
    }

    handleFunctionClick(e) {
        axios.post(getAppUrl() + '/gatebackend/rest/content/savecontent', 
                this.state.posts
            ,
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }
            ).then(repsonse => {
               alert("save done");
            }
                
            )
            .catch((error) => {
                console.log(error);
                alert("error");
            });       
        alert("click" + e.target.id);
    }

    calculateCellWidth(x) {
        var currentWidth = this.state.width - 25;
        var restWidth = currentWidth - this.state.currentMaskWidth;
        var addionalWidth = restWidth / this.state.currentMaskWidth * x;
        return (x + addionalWidth) + "px";
    }

    change(e) {
        var select = this.state.select;
        var id = e.target.name.split("_");
        
        if(e.target.name.startsWith("select")) {
            if(select[id[1]] === "on")
                select[id[1]] = undefined; 
            else
                select[id[1]] = e.target.value;        

            this.setState({
                select : select,
            })
        }
        if(e.target.name === "searchfield") {
            this.setState({
                searchfield : e.target.value,
            })
        }        
    }  

    renderCellValue(mask, row) {
        if(mask.type === "field") {
            return row.contentFields.map(function(contentField) {   
                if(contentField.fieldId == mask.elementValue ) {
                    return contentField.content
                }
            }) 
        }
        else if(mask.type === "link") {
            if(mask.elementValue == 10)
                return  <Link to={"/maske/2/101/" + row.itemID}>
                            <span className={mask.iconName} />
                        </Link>
            else
                return  <span className={mask.iconName}></span>
        }
        else if(mask.type === "select") {
            return <input type='checkbox'  name={"select_" + row.itemID} onChange={e => this.change(e)} checked={this.state.select[row.itemID]}/>
        }
    }

    renderCellHeader1(field, mask, width) {
        var maskHeaderClass = this.state.maskHeaderClass;
        if(field.id == mask.elementValue)
            return <div className="grid-Cell grid-Cell-Header"  style={{width : width}}>
                <div className="grid-Cell__header" > 
                    <span>{mask.name}</span>
                    <span data-maskid={mask.elementValue} className={maskHeaderClass[mask.elementValue]}  onClick={event => this.reload("reload",field.table+"."+field.name, event)}>&nbsp;</span>
                </div>
            </div> 
    }

    renderCellHeader(mask, fields) {
        if(mask.type !== "submit")
            if(mask.type === "field") {
                var width = this.calculateCellWidth(mask.width);
                return fields.contentResult.fields.map(field => (
                    this.renderCellHeader1(field, mask, width)
                ))
            }
            else if(mask.type === "link" || mask.type === "select" )
                return  <div className="grid-Cell grid-Cell-Header" style={{width : this.calculateCellWidth(mask.width)}}>
                            <div className="grid-Cell__header" > 
                                {mask.name}
                            </div>
                        </div>
    }

    render() {
        const {offset, isLoaded, posts} = this.state;
		var parts = this.props.location.pathname.split("/");


        if(!isLoaded) {
            return (<div>loading</div>)
        }
        else if(parts.length === 5) {
            return (<div>
                    <Switch>
                        <Route path='/maske/:tableId/:maskId/:itemId'  component={VivaMaskEingabe} /> 
                    </Switch> 
                </div>                
            )
        }
        else {
            return (
                <div>          
                    <VivaGrid filterSQL={this.state.filterSQL} tree={this.state.tree}  nextQuery={this.state.nextQuery} maskId={this.state.maskId} tableId={this.props.match.params.tableId} itemId="-1" maskModus="1"></VivaGrid>
                </div>               
            );
       }
    }
}

export default VivaMask;
