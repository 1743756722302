import React, { Component } from 'react';
import axios from 'axios';
import {getToken} from '../helpers/Token';
import {Tabs, Tab} from 'react-bootstrap';
import {getAppUrl} from '../helpers/Defaults';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Access from './blocks/Access';

import { useSnackbar, withSnackbar } from 'notistack';


class Fields extends Component {

    constructor(props) {
        super(props);
        this.state = {
			contentHeight: props.height - 200,
			globalFieldCounter: -1,
            mask: {},
            isLoaded : false,
			key: 'fields',
			itemAddDialog: false,
			fieldAddBox: [],
			newCounter: -1
		};
		this.maskItemAddElement = this.maskItemAddElement.bind(this);
		this.handleFieldAddBox = this.handleFieldAddBox.bind(this);
		this.addNewEntry = this.addNewEntry.bind(this); 
		this.deleteEntry = this.deleteEntry.bind(this);

	}

	deleteEntry(field) {
		let mask = this.state.mask;
		mask.maskfields.map(function(fieldO, i) {
			if(fieldO.fieldID === field.fieldID)
				field.markAsDelete = "true";
		});
        this.setState({ mask: mask});
	}

    addNewEntry(which) {		
        let mask = this.state.mask;
		let counter = this.state.newCounter;
		let cLE = {};
		if(which === "Button") {
			cLE.fieldID = counter;
			cLE.name = "neuer Button";
			cLE.type = "submit";
			cLE.elementValue = "";
			cLE.width = 10;
			cLE.tableId = 2;
			cLE.nextMask = 0;
			cLE.sortId = 0;
			cLE.gravity = "";
			cLE.displayType = "unknown";
			cLE.markAsDelete = "false";
			cLE.modi = [];
		}
		if(which === "field") {
			let field =  this.state.mask.tablefields[0];
			cLE.fieldID = counter;
			cLE.name = "neues Feld";
			cLE.type = "field";
			cLE.elementValue = field.id;
			cLE.width = 10;
			cLE.tableId = 2;
			cLE.nextMask = 0;
			cLE.gravity = "";
			cLE.sortId = 0;
			cLE.displayType = "unknown";
			cLE.markAsDelete = "false";
			cLE.modi = [];
		}
		if(which === "link") {
			cLE.fieldID = counter;
			cLE.name = "Funktion";
			cLE.type = "link";
			cLE.elementValue = "";
			cLE.width = 10;
			cLE.tableId = 2;
			cLE.nextMask = 0;
			cLE.gravity = "";
			cLE.sortId = 0;
			cLE.displayType = "unknown";
			cLE.markAsDelete = "false";
			cLE.modi = [];
		}
		if(which === "Mask") {
			cLE.fieldID = counter;
			cLE.name = "Neue Maske";
			cLE.type = "mask";
			cLE.elementValue = "";
			cLE.width = 10;
			cLE.tableId = 2;
			cLE.nextMask = 0;
			cLE.gravity = "";
			cLE.sortId = 0;

			cLE.displayType = "unknown";
			cLE.markAsDelete = "false";
			cLE.modi = [];
		}						

        mask.maskfields.push(cLE);
        mask.fields[counter] = cLE;
		counter--;
        this.setState({ mask: mask,
						newCounter: counter});
		
	}
		
	handleFieldAddBox(id, e) {
		var tmp = this.state.fieldAddBox;
		if(tmp[id] === true)
			tmp[id] = false;
		else
			tmp[id] = true;

		this.setState({
			fieldAddBox : tmp
		});	
		
	}

	maskItemAddElement() {
		var mask = this.state.mask;
		var self = this;
		var globalFieldCounter = this.state.globalFieldCounter;
		this.state.fieldAddBox.forEach(function (newItem, i) {
			self.state.mask.tablefields.map(function(tfield, j) {
				if(tfield.id === i) {
					var field = ({fieldID : globalFieldCounter,
								name: tfield.name,
								type: "field",
								elementValue: i,
								width: 80,
								iconName: "",
								tableId: 0,
								nextMask: 0,
								sortId: 0,
								gravity: "left",
								displayType: "edittext",
								modi: []});
					mask.maskfields.push(field);
					mask.fields[field.fieldID] = field;	
					globalFieldCounter --;							
				}
			});
		});


		this.setState({
			itemAddDialog : false,
			globalFieldCounter: globalFieldCounter,
			mask: mask
		});		
	}



    getData() {
        var maskId = this.props.maskId;
        axios.get(getAppUrl() + '/gatebackend/rest/settings/masks/'+maskId, 
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }).then(res => {
			axios.get(getAppUrl() + '/gatebackend/rest/settings/masks/', 
			{
				headers: { 
					"Authorization": " Bearer " + getToken()
				}
			}).then(res1 => {
				var fields = {};
				res.data.maskfields.map(function(field, i) {
					fields[field.fieldID] = field;
				});
				
				var mask = res.data;
				mask.fields = fields;
				this.props.handleStateChange(mask);

				axios.get(getAppUrl() + '/gatebackend/rest/settings/tables', 
					{
						headers: { 
							"Authorization": " Bearer " + getToken()
						}
					}).then(res2 => {
							this.setState({
								isLoaded : true,
								mask: mask,
								masks: res1.data,
								tables: res2.data
							})
					});
			});
  
        });
    }

    componentDidUpdate(prevProps) {
        if(this.props.tableId != prevProps.tableId) {
            this.getData();
		}

		if(this.props.height != prevProps.height)  {
			let h = this.props.height - 200;
			this.setState({contentHeight: h})
		}				
	}
	

    change(e) {
        var mask = this.state.mask;
		var id = e.target.name.split("_");
        if(e.target.name.startsWith("name"))
            mask.fields[id[1]].name = e.target.value;
        if(e.target.name.startsWith("width"))
            mask.fields[id[1]].width = parseInt(e.target.value);            
        if(e.target.name.startsWith("sortId")) {
				mask.fields[id[1]].sortId = parseInt(e.target.value) || 0;  				     
		}
        if(e.target.name.startsWith("iconName"))
            mask.fields[id[1]].iconName = e.target.value;        
        if(e.target.name.startsWith("elementValue"))
			mask.fields[id[1]].elementValue = e.target.value;  
        if(e.target.name.startsWith("displayType"))
			mask.fields[id[1]].displayType = e.target.value;			   
        if(e.target.name.startsWith("validate"))
			mask.fields[id[1]].validateEntry = e.target.value;	
        if(e.target.name.startsWith("gravity"))
			mask.fields[id[1]].gravity = e.target.value;	
        if(e.target.name.startsWith("nextMask"))
			mask.fields[id[1]].nextMask = parseInt(e.target.value);			   
        if(e.target.name.startsWith("modi"))
			mask.fields[id[1]].modi = e.target.value;     
        if(e.target.name == "table")
			mask.tableId = parseInt(e.target.value);     
        if(e.target.name == "mName")
			mask.name = e.target.value;     
        if(e.target.name == "dName")
			mask.displayName = e.target.value;     
        if(e.target.name == "qsField1")
			mask.qsField1 = parseInt(e.target.value);     
        if(e.target.name == "qsField2")
			mask.qsField2 =parseInt( e.target.value);     
			   
        this.setState({
            mask : mask,
        })
        this.props.handleStateChange(mask);
    }

    renderCellIcon(field) {
        if(field.type === "link")
            return  <select name={"iconName_" + field.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[field.fieldID].iconName}>
                        <option value="">{this.props.t('Kein')}</option>
                        <option value="fa fa-edit">Stift</option>
                        <option value="fa fa-file-pdf-o">Pdf</option>
                        <option value="fa fa-download">Export</option>
                        <option value="fa fa-file-excel-o">CSVExport</option>
                        <option value="fa fa-clone">Kopie</option>
                        <option value="fa fa-unlock">UnLink</option>
                        <option value="fa fa-cog">Einstellungen</option>
                    </select>

        else if(field.tableFieldTyp === "applet")
            return  <select name={"iconName_" + field.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[field.fieldID].iconName}>
 						<option value="">{this.props.t('Kein')}</option>
                        <option value="fa fa-crop">Cropper</option>
                    </select>

        else
            return ""
    }

	renderCellMask(field, masks) {
	    return  <select name={"elementValue_" + field.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[field.fieldID].elementValue}>
					{
						masks.map(mask => (
							<option value={mask.id}>{mask.name}</option>
						))
					}
				</select>
	}

	renderCellVisibility(field) {
        const names = [
						'Show',
						'New',
						'Find',
						'Change',
						'Link',
						'MultiEdit',					
						'SmallMask'						
					];

		return 	<div id="mgo1">
					<FormControl 
						className="visibleMaskItem"
					>
					<Select
						className="visibleMaskItem"
						name={"modi_" + field.fieldID}
						multiple
						value={this.state.mask.fields[field.fieldID].modi}
						onChange={e => this.change(e)}
						input={<Input id="select-multiple-checkbox" />}
						renderValue={selected => selected.join(', ')}
					>
					{names.map(name => (
						<MenuItem key={name} value={name}>
						<Checkbox checked={this.state.mask.fields[field.fieldID].modi.indexOf(name) > -1} />
						<ListItemText primary={name} />
						</MenuItem>
					))}
					</Select>
					
					</FormControl>
				</div>
	}

	renderElementValue(maskfield, tablefields) {
		var tf;
		if(maskfield.type === "field") {
			return  <select name={"elementValue_" + maskfield.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[maskfield.fieldID].elementValue}>
						{  this.state.mask.tablefields.map(field => (
							<option value={field.id} >{field.table}.{field.name}</option>
							))
						}

					</select>
		}
		else if(maskfield.type === "link")
            return  <select name={"elementValue_" + maskfield.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[maskfield.fieldID].elementValue}>

			            <option value="0">Keine</option>
                        <option value="14">Abbrechen</option>
                        <option value="5">Alle anzeigen</option>
                        <option value="1">Ändern</option>
                        <option value="10">Ändern vorbereiten</option>
                        <option value="24">Duplizieren</option>
                        <option value="42">exchange</option>
                        <option value="20">Export</option>
                        <option value="31">Export ZIP</option>
                        <option value="19">Freigeben</option>
                        <option value="39">Komplett Kopieren</option>
                        <option value="9">Link löschen</option>
                        <option value="15">Linke Links anzeigen</option>
                        <option value="11">Linken vorbereiten</option>
                        <option value="7">Links anbinden</option>
                        <option value="4">Löschen</option>
                        <option value="2">Neu</option>
                        <option value="13">Neu vorbereiten</option
                        ><option value="40">OfficeImport</option>
                        <option value="22">OK</option>
                        <option value="41">OptionExecute</option>
                        <option value="6">Rechte Links anzeigen</option>
                        <option value="8">Rechts anbinden</option>
                        <option value="28">Script</option>
                        <option value="29">Sichern</option>
                        <option value="55">sichern und Warenkorb</option>
                        <option value="18">Sperren</option>
                        <option value="3">Suchen</option>
                        <option value="54">Suchen AJAX</option>
                        <option value="12" >Suchen vorbereiten</option>
                        <option value="48">Viva NWP Button</option>
                        <option value="56">Viva NWP Einstellungen</option>
                        <option value="52">Viva NWP Button (silent)</option>
                        <option value="51">VivaComposer (Bayer)</option>
                        <option value="25">VivaIP Dokument</option>
                        <option value="21">VivaIP PDF LowRes</option>
                        <option value="57">Vorschau</option>
										
                    </select>
        else
            return ""		
	}

   renderValidate(maskfield, tablefields) {
		var tf;
		if(maskfield.type === "field") {
			 tablefields.map(function(tablefield) {	
				if(tablefield.id === parseInt(maskfield.elementValue) ) {
					tf =  tablefield;
				}
			});
			if(tf != undefined) {
				if(maskfield.type === "field" && tf.type === "textinput") {
					return  <select name={"validate_" + maskfield.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[maskfield.fieldID].validateEntry}>
								<option value="nothing" >{this.props.t("Keine")}</option>
								<option value="notNull">{this.props.t("nicht leer")}</option>
								<option value="onlyNumbers">{this.props.t("Nur Zahlen")}</option>
							</select>
							
				}
				if(maskfield.type === "field" && tf.type === "textarea") {
					return  <select name={"validate_" + maskfield.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[maskfield.fieldID].validate}>
								<option value="textarea">{this.props.t("Textbereich")}</option>
								<option value="WebEditor">{this.props.t("HTML")}</option>
							</select>
							
				}				
				else if(maskfield.type === "field" && (tf.type === "singlechoice" || tf.type === "form")) {
					return <select name={"validate_" + maskfield.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[maskfield.fieldID].validateEntry}>
								<option value="nothing" >{this.props.t("Keine")}</option>
								<option value="notNull">{this.props.t("nicht leer")}</option>
							</select>
							
				}			
				else if(maskfield.type === "field" && tf.type === "bool") {
					return  <></>
							
				}			
				else if(maskfield.type === "field" && tf.type === "serialno") {
					return  <></>
							
				}
				else if(maskfield.type === "field" && tf.type === "file") {
					return  <></>
							
				}				
				else
					return <></>
			}
		}
	}
    renderCellLocation(maskfield, tablefields) {
		return  <select name={"gravity_" + maskfield.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[maskfield.fieldID].gravity}>
					<option value="" >{this.props.t("Keine Angabe")}</option>
					<option value="top" >{this.props.t("top")}</option>
					<option value="bottom">{this.props.t("bottom")}</option>
					<option value="left">{this.props.t("left")}</option>
					<option value="right">{this.props.t("right")}</option>
					<option value="center">{this.props.t("center")}</option>
				</select>
	}

    renderCellDisplay(maskfield, tablefields) {
		var tf;
		if(maskfield.type === "link") {
			return  <select name={"nextMask_" + maskfield.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[maskfield.fieldID].nextMask}>
					{
						this.state.masks.map(mask => (
							<option value={mask.id}>{mask.name}</option>
						))
					}					
					</select>
		}
		if(maskfield.type === "field") {
			 tablefields.map(function(tablefield) {	
				if(tablefield.id === parseInt(maskfield.elementValue) ) {
					tf =  tablefield;
				}
			});
			if(tf != undefined) {
				if(maskfield.type === "field" && tf.type === "textinput") {
					return  <select name={"displayType_" + maskfield.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[maskfield.fieldID].displayType}>
								<option value="edittext" >{this.props.t("Textzeile")}</option>
								<option value="textarea">{this.props.t("Textbereich")}</option>
								<option value="statictext">{this.props.t("Statischer Text")}</option>
							</select>
							
				}
				if(maskfield.type === "field" && tf.type === "json") {
					return  <select name={"displayType_" + maskfield.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[maskfield.fieldID].displayType}>
								<option value="edittextLanguage">{this.props.t("Textzeile Sprache")}</option>
								<option value="WebEditorLanguage">{this.props.t("HTML Sprache")}</option>
								<option value="statictext">{this.props.t("Statischer Text")}</option>
							</select>
							
				}				
				if(maskfield.type === "field" && tf.type === "textarea") {
					return  <select name={"displayType_" + maskfield.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[maskfield.fieldID].displayType}>
								<option value="textarea">{this.props.t("Textbereich")}</option>
								<option value="WebEditor">{this.props.t("HTML")}</option>
							</select>
							
				}				
				else if(maskfield.type === "field" && (tf.type === "singlechoice" || tf.type === "form" || tf.type === "status")) {
					return  <select name={"displayType_" + maskfield.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[maskfield.fieldID].displayType}>
								<option value="popup">{this.props.t("Auswahl")}</option>
								<option value="statictext">{this.props.t("Statischer Text")}</option>
							</select>
							
				}			
				else if(maskfield.type === "field" && tf.type === "bool") {
					return  <select name={"displayType_" + maskfield.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[maskfield.fieldID].displayType}>
								<option value="checkbox">{this.props.t("Checkbox")}</option>
								<option value="statictext">{this.props.t("Statischer Text")}</option>
							</select>
							
				}	
				else if(maskfield.type === "field" && tf.type === "float") {
					return  <select name={"displayType_" + maskfield.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[maskfield.fieldID].displayType}>
								<option value="edittext" >{this.props.t("Textzeile")}</option>
								<option value="statictext">{this.props.t("Statischer Text")}</option>
							</select>
							
				}							
				else if(maskfield.type === "field" && tf.type === "serialno") {
					return  <select name={"displayType_" + maskfield.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[maskfield.fieldID].displayType}>
								<option value="statictext">{this.props.t("Statischer Text")}</option>
							</select>
							
				}
				else if(maskfield.type === "field" && tf.type === "master") {
					return  <select name={"displayType_" + maskfield.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[maskfield.fieldID].displayType}>
								<option value="statictext">{this.props.t("Statischer Text")}</option>
							</select>
							
				}
				else if(maskfield.type === "field" && tf.type === "file") {
					return  <select name={"displayType_" + maskfield.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[maskfield.fieldID].displayType}>
								<option value="filename">Dateiname</option>
								<option value="filetype">Dateityp</option>
								<option value="filesize">Dateigröße</option>
								<option value="filepath">Dateipfad</option>
								<option value="filepreview">Dateivorschau</option>
								<option value="filedata">Dateiinhalt</option>
								<option value="uploadfile">Datei uploaden</option>
								<option value="uploadfile_dialog">Datei uploaden</option>
								<option value="deletefile">Datei löschen</option>
								<option value="FileDownload">Datei download</option>
							</select>
							
				}				
				else
					return <span>{tf.type}</span>
			}
		}
	}


    renderCellNextMask(field) {
        if(field.type === "submit")
            return  <select name={"nextMask_" + field.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[field.fieldID].nextMask}>
				{
					this.state.masks.map(mask => (
						<option value={mask.id}>{mask.name}</option>
					))
				}					
				</select>
        else
            return ""
	}

    renderCellFunctionSubmit(field) {
        if(field.type === "submit")
            return  <select name={"elementValue_" + field.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[field.fieldID].elementValue}>
			            <option value="0">Keine</option>
						<option value="4">Löschen</option>
						<option value="7">Links anbinden</option>						
						<option value="11">Link Maske aufrufen</option>						
						<option value="13">Alles Speichern</option>						
						<option value="14">Abbrechen</option>						
						<option value="29">Speichern</option>						
						<option value="30">Items zur Planung</option>						
						<option value="31">PlanungsItems löschen</option>						
						<option value="32">Verlinkung löschen</option>						
						<option value="33">Auswahl bearbeiten</option>						
						<option value="34">Neuer Datensatz</option>						
						<option value="35">Datensätze bearbeiten</option>						
						<option value="36">Master bearbeiten</option>						
						<option value="37">Fahrzeug bearbeiten</option>
						<option value="38">Bilder exportieren</option>
						<option value="39">Texte bearbeiten</option>
						<option value="40">Exportieren Shop</option>
												
                    </select>
        else
            return ""
	}

    componentDidMount() {
        this.getData();
    }


    render() {
            const isLoaded = this.state.isLoaded;
			const mask = this.state.mask;
			const masks = this.state.masks;
            if(!isLoaded) {
                return (<div>loading</div>)
            }
            else {          
                return (
          
                <div>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={this.state.key}
                        onSelect={key => this.setState({ key })}
					>
						<Tab eventKey="fields" title="Felder">
							<div className="dialogInnerContentF" style={{maxHeight: this.state.contentHeight + "px", overflowX: "scroll"}}>
								<div style={{minWidth: "2200px"}}>
	                                <div className="row clearmargin maskFieldRow"  align="start">
										<div className="grid-Cell grid-Cell-Header maskField_300" >
											<div className="grid-Cell__header "> 
												Name
											</div>
										</div>										
										<div className="grid-Cell grid-Cell-Header maskField_400">
											<div className="grid-Cell__header"> 
												DBFeld / Funktion
											</div>
										</div>									

										<div className="grid-Cell grid-Cell-Header maskField_250">
											<div className="grid-Cell__header"> 
												Darstellung / Maske
											</div>
										</div>									
										<div className="grid-Cell grid-Cell-Header maskField_200">
											<div className="grid-Cell__header"> 
												Validierung
											</div>
										</div>										
										<div className="grid-Cell grid-Cell-Header maskField_100">
											<div className="grid-Cell__header"> 
												Icon
											</div>
										</div>

										<div className="grid-Cell grid-Cell-Header maskField_200">
											<div className="grid-Cell__header"> 
												Location
											</div>
										</div>
										<div className="grid-Cell grid-Cell-Header maskField_100">
											<div className="grid-Cell__header"> 
												Sortierung
											</div>
										</div>										
										<div className="grid-Cell grid-Cell-Header maskField_100">
											<div className="grid-Cell__header"> 
												Breite
											</div>
										</div>
										<div className="grid-Cell grid-Cell-Header maskField_100">
											<div className="grid-Cell__header"> 
												Type
											</div>
										</div>
										<div className="grid-Cell grid-Cell-Header maskField_200">
											<div className="grid-Cell__header"> 
												Anzeige
											</div>
										</div>
										<div className="grid-Cell grid-Cell-Header maskField_100">
											<div className="grid-Cell__header"> 
												DB Zugriff
											</div>
										</div>										
										<div className="grid-Cell grid-Cell-Header maskField_100">
											<div className="grid-Cell__header"> 
												Löschen
											</div>
										</div>										
									</div>
									<div className="maskFieldRow border">
										<div className="dialogInnerContent" style={{maxHeight: this.state.contentHeight + "px", overflow: "scroll"}}>
											{
												mask.maskfields.map(field => (
													(field.type !== "submit" && field.type !== "mask" && field.markAsDelete !== "true") && (
														<div className="row clearmargin grid-Row maskFieldRow" data-id={field.fieldID}  align="start">
															<div className="grid-Cell maskField_300"> 
																<div className="grid-Cell__value" > 
																	<input maxlength="30" size="30" type="text" name={"name_" + field.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[field.fieldID].name} />
																</div>
															</div>
															<div className="grid-Cell maskField_400"> 
																<div className="grid-Cell__value"> 
																	{this.renderElementValue(field, mask.tablefields)}
																</div>
															</div>													
															<div className="grid-Cell maskField_250"> 
																<div className="grid-Cell__value"> 
																	{this.renderCellDisplay(field, mask.tablefields)}
																</div>
															</div>	
															<div className="grid-Cell maskField_200"> 
																<div className="grid-Cell__value"> 
																	{this.renderValidate(field, mask.tablefields)}
																</div>
															</div> 																								
															<div className="grid-Cell maskField_100"> 
																<div className="grid-Cell__value"> 
																	{this.renderCellIcon(field)}
																</div>
															</div>                                

															<div className="grid-Cell maskField_200"> 
																<div className="grid-Cell__value"> 
																	{this.renderCellLocation(field, mask.tablefields)}
																</div>
															</div>	
															<div className="grid-Cell maskField_100"> 
																<div className="grid-Cell__value"> 
																	<input maxlength="4" size="4" type="text" name={"sortId_" + field.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[field.fieldID].sortId} />
																</div>
															</div> 																									                               
															<div className="grid-Cell maskField_100"> 
																<div className="grid-Cell__value"> 
																	<input maxlength="4" size="4" type="text" name={"width_" + field.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[field.fieldID].width} />
																</div>
															</div>  
															<div className="grid-Cell maskField_100"> 
																<div className="grid-Cell__value"> 
																	{field.type}
																</div>
															</div>
															<div className="grid-Cell maskField_200"> 
																<div className="grid-Cell__value">
																	{this.renderCellVisibility(field)}
																</div>
															</div>
															<div class="grid-Cell maskField_100"> 
																<div class="grid-Cell__value" style={{backgroundColor: "#a0000"}}> 
																	{console.log(field)}
																	<Access type="field" label={field.name} id={field.elementValue}/>
																</div>
															</div>															
															<div className="grid-Cell maskField_100"> 
																<div className="grid-Cell__value">
																	<Button className="buttonIcon" aria-controls="simple-menuFlag" aria-haspopup="true" onClick={((e) => this.deleteEntry(field))}>
																		<span class="fa fa-trash-o pull-right"></span>
																	</Button>
																</div>
															</div>													
														</div>
													)
													
												))
											}
									</div>
                                    <Button variant="secondary" onClick={(e) => this.addNewEntry("field")} style={{border: "1px solid #a0a0a0", margin: "10px"}}>
                                        <i class="fa fa-plus-circle"></i>&nbsp;
                                        {this.props.t('Feld')}&nbsp;{this.props.t('hinzufügen')}
									</Button>	
									<Button variant="secondary" onClick={(e) => this.addNewEntry("link")} style={{border: "1px solid #a0a0a0", margin: "10px"}}>
                                    	<i class="fa fa-plus-circle"></i>&nbsp;
                                        {this.props.t('Funktion')}&nbsp;{this.props.t('hinzufügen')}
                                    </Button>								
									</div>
								</div>
							</div>
						</Tab>
						<Tab eventKey="buttons" title="Buttons">
								<div className="maskfieldsArea">                            
									<div className="row clearmargin maskFieldRow"  align="start">
										<div className="grid-Cell grid-Cell-Header maskField_300" >
											<div className="grid-Cell__header "> 
												Name
											</div>
										</div>
										<div className="grid-Cell grid-Cell-Header maskField_200">
											<div className="grid-Cell__header"> 
												Icon
											</div>
										</div>
										<div className="grid-Cell grid-Cell-Header maskField_300">
											<div className="grid-Cell__header"> 
												Funktion
											</div>
										</div>
										<div className="grid-Cell grid-Cell-Header maskField_100">
											<div className="grid-Cell__header"> 
												Sortierung
											</div>
										</div>
										<div className="grid-Cell grid-Cell-Header maskField_100">
											<div className="grid-Cell__header"> 
												Breite
											</div>
										</div>
										<div className="grid-Cell grid-Cell-Header maskField_250">
											<div className="grid-Cell__header"> 
												Nächste Maske
											</div>
										</div>
										<div className="grid-Cell grid-Cell-Header maskField_200">
											<div className="grid-Cell__header"> 
												Anzeige
											</div>
										</div>	

										<div className="grid-Cell grid-Cell-Header maskField_100">
											<div className="grid-Cell__header"> 
												Löschen
											</div>
										</div>																			
									</div>
								<div className="maskFieldRow border">
									{
										mask.maskfields.map(field => (
											field.type === "submit" && field.markAsDelete !== "true" && (
												<div className="row clearmargin grid-Row maskFieldRow" data-id={field.fieldID}  align="start">
													<div className="grid-Cell maskField_300"> 
														<div className="grid-Cell__value" > 
															<input maxlength="30" size="30" type="text" name={"name_" + field.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[field.fieldID].name} />
														</div>
													</div>
													<div className="grid-Cell maskField_200"> 
														<div className="grid-Cell__value"> 
															{this.renderCellIcon(field)}
														</div>
													</div>                                
													<div className="grid-Cell maskField_300"> 
														<div className="grid-Cell__value"> 
															{this.renderCellFunctionSubmit(field)}
														</div>
													</div>       
													<div className="grid-Cell maskField_100"> 
														<div className="grid-Cell__value"> 
															<input maxlength="4" size="4" type="text" name={"sortId_" + field.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[field.fieldID].sortId} />
														</div>
													</div> 													                         
													<div className="grid-Cell maskField_100"> 
														<div className="grid-Cell__value"> 
															<input maxlength="4" size="4" type="text" name={"width_" + field.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[field.fieldID].width} />
														</div>
													</div>  
													<div className="grid-Cell maskField_250"> 
														<div className="grid-Cell__value"> 
															{this.renderCellNextMask(field)}
														</div>
													</div>
													<div className="grid-Cell maskField_200"> 
														<div className="grid-Cell__value">
															{this.renderCellVisibility(field)}
														</div>
													</div>	
													<div className="grid-Cell maskField_100"> 
														<div className="grid-Cell__value">
															<Button className="buttonIcon" aria-controls="simple-menuFlag" aria-haspopup="true" onClick={((e) => this.deleteEntry(field))}>
																<span class="fa fa-trash-o pull-right"></span>
															</Button>
														</div>
													</div>																									
												</div>
											)
										))
									}
                                    <Button variant="secondary" onClick={(e) => this.addNewEntry("Button")} style={{border: "1px solid #a0a0a0", margin: "10px"}}>
                                        <i class="fa fa-plus-circle"></i>&nbsp;
                                        {this.props.t('hinzufügen')}
                                    </Button>
								</div>
							</div>
						</Tab>	
						<Tab eventKey="mask" title="Tabs">
								<div className="maskfieldsArea">                             
									<div className="row clearmargin maskFieldRow"  align="start">
										<div className="grid-Cell grid-Cell-Header maskField_300" >
											<div className="grid-Cell__header "> 
												Name
											</div>
										</div>
										<div className="grid-Cell grid-Cell-Header maskField_200">
											<div className="grid-Cell__header"> 
												Icon
											</div>
										</div>
										<div className="grid-Cell grid-Cell-Header maskField_400">
											<div className="grid-Cell__header"> 
												Maske
											</div>
										</div>
										<div className="grid-Cell grid-Cell-Header maskField_100"> 
											<div className="grid-Cell__header"> 
												Sortierung
											</div>
										</div> 										
										<div className="grid-Cell grid-Cell-Header maskField_100">
											<div className="grid-Cell__header"> 
												Type
											</div>
										</div>
										<div className="grid-Cell grid-Cell-Header maskField_200"> 
											<div className="grid-Cell__value">
												Anzeige
											</div>
										</div>										
										<div className="grid-Cell grid-Cell-Header maskField_100">
											<div className="grid-Cell__header"> 
												Löschen
											</div>
										</div>										
									</div>
								<div className="maskFieldRow border">
									{
										mask.maskfields.map(field => (
											field.type === "mask" && field.markAsDelete !== "true" && (
												<div className="row clearmargin grid-Row maskFieldRow" data-id={field.fieldID}  align="start">
													<div className="grid-Cell maskField_300"> 
														<div className="grid-Cell__value" > 
															<input maxlength="30" size="30" type="text" name={"name_" + field.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[field.fieldID].name} />
														</div>
													</div>
													<div className="grid-Cell maskField_200"> 
														<div className="grid-Cell__value"> 
															{this.renderCellIcon(field)}
														</div>
													</div>                                
													<div className="grid-Cell maskField_400"> 
														<div className="grid-Cell__value"> 
															{this.renderCellMask(field, masks)}
														</div>
													</div> 
													<div className="grid-Cell maskField_100"> 
														<div className="grid-Cell__value"> 
															<input maxlength="4" size="4" type="text" name={"sortId_" + field.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[field.fieldID].sortId} />
														</div>
													</div> 													                                
													<div className="grid-Cell maskField_100"> 
														<div className="grid-Cell__value"> 
															{field.type}
														</div>
													</div>
													<div className="grid-Cell maskField_200"> 
														<div className="grid-Cell__value">
															{this.renderCellVisibility(field)}
														</div>
													</div>
													<div className="grid-Cell maskField_100"> 
														<div className="grid-Cell__value">
															<Button className="buttonIcon" aria-controls="simple-menuFlag" aria-haspopup="true" onClick={((e) => this.deleteEntry(field))}>
																<span class="fa fa-trash-o pull-right"></span>
															</Button>
														</div>
													</div>													
												</div>
											)
											
										))
									}
                                    <Button variant="secondary" onClick={(e) => this.addNewEntry("Mask")} style={{border: "1px solid #a0a0a0", margin: "10px"}}>
                                        <i class="fa fa-plus-circle"></i>&nbsp;
                                        {this.props.t('hinzufügen')}
                                    </Button>
								</div>
							</div>
                        </Tab>											
                        <Tab eventKey="settings" title="Einstellungen">
                            <div class="Vivacard">
                                <div class="Vivacardheader">
                                    Allgemein                                
                                </div>
                                <div class="border">                               
                                    <div className="row clearmargin "  align="start">
                                        <div className="col-sm-3 ">
                                            ID: 
                                        </div>
                                        <div className="col-sm">
                                            {this.state.mask.id}
                                        </div>
                                    </div>
									<div className="row clearmargin "  align="start">
                                        <div className="col-sm-3 ">
                                            Name: 
                                        </div>
                                        <div className="col-sm">
                                            <input maxlength="30" size="30" type="text" name="mName"  onChange={e => this.change(e)} value={this.state.mask.name}/>
                                        </div>
									</div>
									<div className="row clearmargin "  align="start">
                                        <div className="col-sm-3 ">
                                            Label: 
                                        </div>
                                        <div className="col-sm">
                                            <input maxlength="30" size="30" type="text" name="dName"  onChange={e => this.change(e)} value={this.state.mask.displayName}/>
                                        </div>
									</div>

									<div className="row clearmargin "  align="start">
                                        <div className="col-sm-3 ">
                                            Tabelle: 
                                        </div>
                                        <div className="col-sm">
                                            <select name="table" onChange={e => this.change(e)} value={this.state.mask.tableId}>
												<option value="0">Keine</option>
												{
												this.state.tables.map(table => (
													<option value={table.id}>{table.name}</option>
												))
												}
											</select>
                                        </div>
                                    </div>									
									<div className="row clearmargin "  align="start">
                                        <div className="col-sm-3 ">
                                            Planungsmaske: 
                                        </div>
                                        <div className="col-sm">
                                            {mask.tableId}
                                        </div>
                                    </div>									
								</div>                            
                                <div class="Vivacardheader">
                                    Schnellsuche                                
								</div>
                                <div class="border">                               
                                    <div className="row clearmargin "  align="start">
                                        <div className="col-sm-2 ">
                                            Suchfeld 1: 
                                        </div>
										<div className="col-sm">
											<select name="qsField1" onChange={e => this.change(e)} value={this.state.mask.qsField1}>
												<option value="0">Keins</option>
												{
												mask.tablefields.map(field => (
													<option value={field.id}>{field.name}</option>
												))
												}
											</select>
                                        </div>
                                    </div>
									<div className="row clearmargin "  align="start">
                                        <div className="col-sm-2 ">
                                            Suchfeld2: 
                                        </div>
                                        <div className="col-sm">
											<select name="qsField2" onChange={e => this.change(e)} value={this.state.mask.qsField2}>
												<option value="0">Keins</option>
												{
												mask.tablefields.map(field => (
													<option value={field.id}>{field.name}</option>
												))
												}
											</select>
										</div>
                                    </div>
                                </div>  								
                            </div>
                        </Tab>
					</Tabs>

                </div>
            )
        }
     }

}


export default withTranslation()(withSnackbar(Fields));