export const getAppUrl = ()  => {
//    return  "http://localhost:8080";
//  return  "https://api02.reimo-shops.de";
// return  "https://api-dev.reimo-shops.de";
// return  "https://api-dev2.reimo-shops.de";
  return  "http://195.201.188.188:8080";
}

export const getApiUrl = ()  => {
	return "";
}



export const getWSUrl = ()  => {
//    return  "ws://localhost:8080";
//  return  "wss://api02.reimo-shops.de";
//  return  "wss://api-dev.reimo-shops.de";
//  return  "wss://api-dev2.reimo-shops.de";
  return  "ws://195.201.188.188:8080";
}

export const getCompanyName = ()  => {
//    return  "alltours_live"; 
  return  "gate"; 
}


