import React, { Component } from 'react';
import axios from 'axios';
import {getAppUrl} from '../helpers/Defaults';
import {getToken} from '../helpers/Token';
import {Modal, Button} from 'react-bootstrap';
import CreateNew from './blocks/CreateNew';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { useSnackbar, withSnackbar } from 'notistack';
import Loader from './blocks/Loader'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import {Tabs, Tab} from 'react-bootstrap';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import VivaMaskEingabe from './VivaMaskEingabe';
import Switch from '@material-ui/core/Switch';

function setconvertJSON(c, t, l) {
	let tJ = JSON.parse(c);
	tJ[l].content = t;
	return JSON.stringify(tJ);
}

function convertJSON(c, l)  {
	let tJ = JSON.parse(c);
	return  tJ[l].content;
}

class Planingtypes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            currentTableId: 0,
            offset: 0,
            isLoaded : false,
            duplicatePlaning: false,
            productData: [],
            currentSearch: "",
			showEditPLElement: false,            
            newValue: ""
        };
        this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
        this.gotoDetail = this.gotoDetail.bind(this);
        this.searchChange = this.searchChange.bind(this);
        this.reload = this.reload.bind(this);
		this.duplicatePlaning = this.duplicatePlaning.bind(this);
        this.change = this.change.bind(this);
		this.doDuplicatePlaning = this.doDuplicatePlaning.bind(this);
		this.handleEditPLElementClick = this.handleEditPLElementClick.bind(this);
		this.handleCloseEditPLElement = this.handleCloseEditPLElement.bind(this);
		this.addPLElementClick = this.addPLElementClick.bind(this);
		this.prepareLayoutPLElement = this.prepareLayoutPLElement.bind(this);
		this.handleChangePLDialog = this.handleChangePLDialog.bind(this);
        this.createNew = this.createNew.bind(this);         

    }
 	
     createNew(newName) {
        this.setState({
            newName: newName
        });		
		this.addPLElementClick();
    }
    	
 	handleCloseEditPLElement() {
        this.setState({ showEditPLElement: false });
    }
    
    change(e) {
        this.setState({
            newValue: e.target.value
        });          
    }
    
	addPLElementClick() {
		let rowInfo = {};
		
		rowInfo.def_subplannung = 0;
		rowInfo.def_export = 0;
		rowInfo.def_nodeMask = 0;
		rowInfo.def_mask = 0;
		rowInfo.def_form = 0;
		

		this.setState({
			showEditPLElement: true,
			key: "Allgemein",
			currentPlanungTyp: rowInfo,
			plDialogTitle: "Planungstyp hinzufügen",
			PLElement_DialogTyp: "add",
		});				
	}
	    
	handleEditPLElementClick(rowInfo) {   
		this.setState({
			showEditPLElement: true,
			key: "Allgemein",
			currentPlanungTyp: rowInfo,
			plDialogTitle: "PlanungsTyp editieren",
			PLElement_DialogTyp: "change",
		});
	}	

	handleChangePLDialog(e) {
		if(e.target.name == "subPlaning") {
			let currentNode = this.state.currentPlanungTyp;
			currentNode.def_subplannung =  e.target.value;
	        this.setState({
	            currentPlanungTyp: currentNode
	        }); 
		}        		
		if(e.target.name == "export") {
			let currentNode = this.state.currentPlanungTyp;
			currentNode.def_export =  e.target.value;
	        this.setState({
	            currentPlanungTyp: currentNode
	        }); 
		}        		
		if(e.target.name == "nodeMaskId") {
			let currentNode = this.state.currentPlanungTyp;
			currentNode.def_nodeMask =  e.target.value;
	        this.setState({
	            currentPlanungTyp: currentNode
	        }); 
		}        		
		if(e.target.name == "maskId") {
			let currentNode = this.state.currentPlanungTyp;
			currentNode.def_mask =  e.target.value;
	        this.setState({
	            currentPlanungTyp: currentNode
	        }); 
		}
		if(e.target.name == "formId") {
			let currentNode = this.state.currentPlanungTyp;
			currentNode.def_form =  e.target.value;
	        this.setState({
	            currentPlanungTyp: currentNode
	        }); 
		}
		if(e.target.name == "query") {
			let currentNode = this.state.currentPlanungTyp;
			currentNode.def_query =  e.target.value;
	        this.setState({
	            currentPlanungTyp: currentNode
	        }); 
		}
		if(e.target.name == "typ") {
			let currentNode = this.state.currentPlanungTyp;
			currentNode.typ =  e.target.value;
	        this.setState({
	            currentPlanungTyp: currentNode
	        }); 
		}		
	}
	
	prepareLayoutPLElement(e) { 
		let dummyJson = "{\"de\":{\"content\":\"\"},\"en\":{\"content\":\"\"}}";
		let currentNode = this.state.currentPlanungTyp;
		let plElement = {};
        plElement.zusatz1 =	setconvertJSON(dummyJson, this.state.PLElement_SubLabel, "de");
		plElement.id = parseInt(currentNode.id);	
		plElement.name = this.state.newName;


		plElement.def_subplannung = parseInt(currentNode.def_subplannung);		
		plElement.def_export = parseInt(currentNode.def_export);		
		plElement.def_nodeMask = parseInt(currentNode.def_nodeMask);		
		plElement.def_mask = parseInt(currentNode.def_mask);		
		plElement.def_form = parseInt(currentNode.def_form);		
		plElement.def_query = parseInt(currentNode.def_query);		
		plElement.typ = currentNode.typ;		

		
		if(this.state.PLElement_DialogTyp === "add")
			this.handleAddLayoutPLElement(plElement);
		else if(this.state.PLElement_DialogTyp === "change")
			this.handleChangeLayoutPLElement(plElement);
		
	}
	
	handleChangeLayoutPLElement(plElement) { 

        axios.post(getAppUrl() + "/gatebackend/rest/content/planungsTyp/change", 
                plElement
            ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
			this.setState({ showEditPLElement: false });
			this.props.enqueueSnackbar(this.props.t('PlanungsTyp geändert'), { variant: 'info' });
			this.reload(this.props.match.params.planingId);
		}
            
        )
        .catch((error) => {
            alert("error");
        });  
         
	}	
		
	handleAddLayoutPLElement(plElement) { 

        axios.post(getAppUrl() + "/gatebackend/rest/content/planungsTyp/add", 
                plElement
            ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
			this.setState({ showEditPLElement: false });
			this.props.enqueueSnackbar(this.props.t('PlanungsTyp hinzugefügt'), { variant: 'info' });
			this.reload(this.props.match.params.planingId);

		}
            
        )
        .catch((error) => {
            alert("error");
        });  
         
	}	
	
	duplicatePlaning(kind,planingId, name) {
        this.setState({ duplicatePlaning: kind,
        				planingId: planingId,
        				newValue: name + " Duplikat"
        				});		
	}	
	
    doDuplicatePlaning() {
        let chooseList = {};
        chooseList.id =  this.state.planingId;
        chooseList.name = this.state.newValue;
        this.setState({ duplicatePlaning: false});
       	axios.post(getAppUrl() + '/gatebackend/rest/content/planning/double', 
            chooseList
        ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
           this.props.enqueueSnackbar(this.props.t('Planung dupliziert'), { variant: 'info' });
         
 			this.reload();
        }
            
        )
        .catch((error) => {
            console.log(error);
        });

    }
	

    searchChange(e) {
        this.setState({
			currentSearch: e
		});
    }
    
    reload() {
		axios.get(getAppUrl() + '/gatebackend/rest/content/templates',  {
			headers: { 
				"Authorization": " Bearer " + getToken()
			}
			}).then(templates => {	
				axios.get(getAppUrl() + '/gatebackend/rest/content/languages',  {
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}).then(sprachen => {
					axios.get(getAppUrl() + '/gatebackend/rest/settings/forms', 
						{
							headers: { 
								"Authorization": " Bearer " + getToken()
							}
						}).then(forms => {	   
							axios.get(getAppUrl() + '/gatebackend/rest/settings/masks', 
								{
									headers: { 
										"Authorization": " Bearer " + getToken()
									}
								}).then(masks => {
									axios.get(getAppUrl() + '/gatebackend/rest/settings/queries', 
									{
										headers: { 
											"Authorization": " Bearer " + getToken()
										}
									}).then(queries => {
											
											axios.get(getAppUrl() + '/gatebackend/rest/settings/exports', 
											{
												headers: { 
													"Authorization": " Bearer " + getToken()
												}
											}).then(exporte => {											
											

										        axios.get(getAppUrl() + '/gatebackend/rest/settings/planings', 
										            {
										                headers: { 
										                    "Authorization": " Bearer " + getToken()
										                }
										            }).then(planings => {


												        axios.get(getAppUrl() + '/gatebackend/rest/settings/planungstypen', 
												            {
												                headers: { 
												                    "Authorization": " Bearer " + getToken()
												                }
												            }).then(res6 => {
																this.setState({
																	isLoaded : true,

																	posts: res6.data,
																	masks: masks.data,
																	exports: exporte.data,
																	planings: planings.data,
																	queries: queries.data,
																	planungstypen: res6.data,
																	itemLanguages: sprachen.data,
																	formen: forms.data,
																	templates: templates.data
																})   
														});
												});
										});
									}); 
								});	 
							});
						});
					});		
	

	}

    componentDidMount() {
		this.reload();
    }

	gotoDetail(id) {
		this.props.history.push("/planing/" + id);
	}

    handleClose() {
        this.setState({ show: false });
    }

    handleShow(e) {        
        this.setState({ show: true });
    }

    render() {
        const {isLoaded, posts} = this.state;
        if(!isLoaded) {
            return (<div><Loader/></div>)
        }
        else {
            let elHeight = document.getElementById('actionFrame').clientHeight -100;
            var height = {
                'max-height': elHeight + 'px',
            };
            return (
                <div>
                    <div class="Vivacard" style={height}>
                        <div class="Vivacardheader">
                            {this.props.t('Planungstypen')}
                            <CreateNew searchChange={this.searchChange} actionNew={this.createNew} title="Neuer Planungstype"/>
                        </div>
                        <div class="Vivacardbody">
                            <div class="border">
                                <div class="row clearmargin"  align="start">
                                    <div class="grid-Cell grid-Cell-Header col-sm-1">
                                        <div class="grid-Cell__header "> 
                                            {this.props.t('ID')}
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm">
                                        <div class="grid-Cell__header"> 
                                            {this.props.t('Name')}
                                        </div>
                                    </div>                           
                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            {this.props.t('Duplizieren')}
                                        </div>
                                    </div>  
                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            {this.props.t('bearbeiten')}
                                        </div>
                                    </div>
                                </div>
                                {
                                    posts.map(post => (
                                        <>
                                        {this.state.currentSearch === "" || ((post.id).toString().includes(this.state.currentSearch) ||  post.name.toLowerCase().includes((this.state.currentSearch).toLowerCase())) ? (
											<>
											
		                                        <div class="row clearmargin grid-Row" data-id={post.id} data-tablename={post.name}  onDoubleClick={((e) => this.handleEditPLElementClick(post))} align="start">
		                                            <div class="grid-Cell col-sm-1"> 
		                                                <div class="grid-Cell__value" > 
		                                                    {post.id}
		                                                </div>
		                                            </div>
		                                            <div class="grid-Cell col-sm"> 
		                                                <div class="grid-Cell__value"> 
		                                                    {post.name}
		                                                </div>
		                                            </div>  
		                                            <div class="grid-Cell col-sm-1"> 
		                                                <div class="grid-Cell__value"> 
		                                                    <ul>  
		                                                        <li class="fa fa-files-o gridIcon" onClick={((e) => this.duplicatePlaning(true,post.id, post.name))}>
		
		                                                        </li>
		                                                    </ul>
		                                                </div>
		                                            </div>                                                                                          
		                                            <div class="grid-Cell col-sm-1"> 
		                                                <div class="grid-Cell__value"> 
		                                                    <ul>  
		                                                        <li class="fa fa-pencil gridIcon" onClick={((e) => this.handleEditPLElementClick(post))}>
		
		                                                        </li>
		                                                    </ul>
		                                                </div>
		                                            </div>                                                                                    
		                                        </div>
	                                        </>
                                        ) : (<></>)}
                                        </>                                        
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    
					<Dialog
                            open={this.state.duplicatePlaning}
                            onClose={((e) => this.duplicatePlaning(false))}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">{this.props.t('Planung duplizieren')}</DialogTitle>
	                        <DialogContent>
		                        <DialogContentText>
		                            Bitte geben Sie einen Namen ein.
		                        </DialogContentText>
		                        <TextField
		                            autoFocus
		                            name="newLabel"
		                            margin="dense"
		                            type="input"
		                            onChange={e => this.change(e)}
		                            value={this.state.newValue}
		                            fullWidth
		                        />
	                        </DialogContent>
                        <DialogActions>
                        <Button onClick={((e) => this.duplicatePlaning(false))} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={((e) => this.doDuplicatePlaning())} color="primary">
                            Ok
                        </Button>
                        </DialogActions>
                    </Dialog>                      
                           
					<Dialog fullWidth="md"
                            maxWidth="md" 
                            open={this.state.showEditPLElement} onClose={this.handleCloseEditPLElement} aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.state.plDialogTitle}</DialogTitle>
								<DialogContent>
										<Tabs 	                                
										id="controlled-tab-example"
                                		activeKey={this.state.key}
                                		onSelect={key => this.setState({ key })}
											>
											<Tab eventKey="Allgemein" title={this.props.t('Allgemein')}>
											
												<div style={{backgroundColor: "#fff"}}>
													<div className="row rowMaxWidth">
														<div className="col-sm">
															<TextField
																autoFocus
																margin="dense"
																name="Label"
																label="Label"
																type="email"
																onChange={e => this.changeElementSubLabel(e)}
																value={this.state.PLElement_SubLabel}
																fullWidth
															/>
														</div>
														
													</div>																	
													<div className="row rowMaxWidth">
														<div className="col-sm">
															<TextField
																autoFocus
																margin="dense"
																name="Zusatz1"
																label="Zusatz1"
																type="email"
																onChange={e => this.changeElementSubLabel(e)}
																value={this.state.PLElement_SubLabel}
																fullWidth
															/>
														</div>
														<div className="col-sm">
															<TextField
																autoFocus
																margin="dense"
																name="Zusatz2"
																label="Zusatz2"
																type="email"
																onChange={e => this.changeElementZusatz2(e)}
																value={this.state.PLElement_Zusatz2}
																fullWidth
															/>
														</div>
													</div>											
													<div className="row rowMaxWidth">
														<div className="col-sm">
															<TextField
																autoFocus
																margin="dense"
																name="Zusatz3"
																label="Zusatz3"
																type="email"
																onChange={e => this.changeElementZusatz3(e)}
																value={this.state.PLElement_Zusatz3}
																fullWidth
															/>	
														</div>
														<div className="col-sm">
														</div>
													</div>	
														<div className="row rowMaxWidth">
															<div className="col-sm">
																<TextField
																	autoFocus
																	margin="dense"
																	name="SeiternummerierungStart"
																	label="Seiternummerierung Start"
																	type="email"
																	onChange={e => this.changeElementZusatz3(e)}
																	value={this.state.PLElement_startPage}
																	fullWidth
																/>	
															
															</div>
															<div className="col-sm">
																<TextField
																	autoFocus
																	margin="dense"
																	name="SeiternummerierungEnde"
																	label="Seiternummerierung Ende"
																	type="email"
																	onChange={e => this.changeElementZusatz3(e)}
																	value={this.state.PLElement_endPage}
																	fullWidth
																/>	
															
															</div>
													</div>																							
												</div>											


	
											
											</Tab>
											<Tab eventKey="Einstellungen" title={this.props.t('Einstellungen')} >
												<div className="planingCard">	
												{this.state.currentPlanungTyp &&
													<>												
																	<div style={{backgroundColor: "#fff"}}>
																		<div className="row rowMaxWidth">
																			<div className="col-sm">
																				<Typography style={{paddingTop: "10px", paddingBottom: "10px"}}>{this.props.t('Element')}: {this.state.currentPlanungTyp.name}</Typography>							
																			</div>
																			<div className="col-sm">
																				<Typography style={{paddingTop: "10px", paddingBottom: "10px"}}>ElementId: {this.state.currentPlanungTyp.id}</Typography>
																			</div>
																		</div>
																		<div className="row rowMaxWidth">
																			<div className="col-sm">
																				<FormGroup row>
																					<FormControlLabel
																						control={
																							<Switch
																								checked={this.state.currentPlanungTyp.merge === 1 ? true : false}
																								value={this.state.currentPlanungTyp.merge}
																								inputProps={{ 'aria-label': 'secondary checkbox' }}
																							/>
																						}
																						labelPlacement="start"
																						label={this.props.t('zusammmenfassen')}
																					/>
																				</FormGroup>
																			</div>
																			<div className="col-sm">
																				<FormControl  style={{display: "flex"}}>      
																					<InputLabel shrink htmlFor="export-label-placeholder">
																						{this.props.t('DefaultForm für Items ')}
																					</InputLabel>
																					<Select
																						className="planingSelect"
																							value={this.state.currentPlanungTyp.def_form}
																							onChange={this.handleChangePLDialog}
																							input={<Input name="age" id="age-label-placeholder" />}
																							displayEmpty
																							name="formId"
																						>
																						<MenuItem value="0">{this.props.t('keine')}</MenuItem>
																						{
																							this.state.formen.map(function(ex) {
																								return (<MenuItem value={ex.id}>{ex.name}</MenuItem>)
																							})
																						}
																					</Select>
																				</FormControl>
																			</div>
																		</div>

																		<div className="row rowMaxWidth">
																			<div className="col-sm">
																				<div className="marginTop">																																										
																					<FormControl  style={{display: "flex"}}>      
																						<InputLabel shrink htmlFor="export-label-placeholder">
																							{this.props.t('Maske für Itemsauswahl ')}
																						</InputLabel>
																						<Select
																							className="planingSelect"
																								value={this.state.currentPlanungTyp.def_mask}
																								onChange={this.handleChangePLDialog}
																								input={<Input name="age" id="age-label-placeholder" />}
																								displayEmpty
																								name="maskId"
																							>
																							<MenuItem value="0">{this.props.t('keine')}</MenuItem>
																							{
																								this.state.masks.map(function(ex) {
																									return (<MenuItem value={ex.id}>{ex.name}</MenuItem>)
																								})
																							}
																						</Select>
																					</FormControl>	
																				</div>	
																			</div>
																			<div className="col-sm">
																				<div className="marginTop">																																										
																					<FormControl  style={{display: "flex"}}>      
																						<InputLabel shrink htmlFor="export-label-placeholder">
																							{this.props.t('Maske für Treeansicht')}
																						</InputLabel>
																						<Select
																							className="planingSelect"
																								value={this.state.currentPlanungTyp.def_nodeMask}
																								onChange={this.handleChangePLDialog}
																								input={<Input name="age" id="age-label-placeholder" />}
																								displayEmpty
																								name="nodeMaskId"
																							>
																							<MenuItem value="0">{this.props.t('keine')}</MenuItem>
																							{
																								this.state.masks.map(function(ex) {
																									return (<MenuItem value={ex.id}>{ex.name}</MenuItem>)
																								})
																							}
																						</Select>
																					</FormControl>	
																				</div>
																			</div>
																		</div>
																		<div className="row rowMaxWidth">
																			<div className="col-sm">
																				<div className="marginTop">																																										
																					<FormControl  style={{display: "flex"}}>      
																						<InputLabel shrink htmlFor="export-label-placeholder">
																							{this.props.t('Export')}
																						</InputLabel>
																						<Select
																							className="planingSelect"
																								value={this.state.currentPlanungTyp.def_export}
																								onChange={this.handleChangePLDialog}
																								input={<Input name="age" id="age-label-placeholder" />}
																								displayEmpty
																								name="export"
																							>
																							<MenuItem value="0">{this.props.t('keine')}</MenuItem>
																							{
																								this.state.exports.map(function(ex) {
																									return (<MenuItem value={ex.id}>{ex.name}</MenuItem>)
																								})
																							}
																						</Select>
																					</FormControl>	
																				</div>	
																			</div>
																			<div className="col-sm">
																				<div className="marginTop">																																										
																					<FormControl  style={{display: "flex"}}>      
																						<InputLabel shrink htmlFor="export-label-placeholder">
																							{this.props.t('Unterplanung')}
																						</InputLabel>
																						<Select
																							className="planingSelect"
																								value={this.state.currentPlanungTyp.def_subplannung}
																								onChange={this.handleChangePLDialog}
																								input={<Input name="age" id="age-label-placeholder" />}
																								displayEmpty
																								name="subPlaning"
																							>
																							<MenuItem value="0">{this.props.t('keine')}</MenuItem>
																							{
																								this.state.planings.map(function(ex) {
																									return (<MenuItem value={ex.id}>{ex.label}</MenuItem>)
																								})
																							}
																						</Select>
																					</FormControl>	
																				</div>
																			</div>
																		</div>																		
																		<div className="row rowMaxWidth">
																			<div className="col-sm">
																				<div className="marginTop">																																										
																					<FormControl  style={{display: "flex"}}>      
																						<InputLabel shrink htmlFor="export-label-placeholder">
																							{this.props.t('Abfrage')}
																						</InputLabel>
																						<Select
																							className="planingSelect"
																								value={this.state.currentPlanungTyp.def_query}
																								onChange={this.handleChangePLDialog}
																								input={<Input name="age" id="age-label-placeholder" />}
																								displayEmpty
																								name="query"
																							>
																							<MenuItem value="0">{this.props.t('keine')}</MenuItem>
																							{
																								this.state.queries.map(function(ex) {
																									return (<MenuItem value={ex.id}>{ex.label}</MenuItem>)
																								})
																							}
																						</Select>
																					</FormControl>	
																				</div>	
																			</div>
																			<div className="col-sm">
																				<div className="marginTop">	
																					<FormControl  style={{display: "flex"}}>      
																						<InputLabel shrink htmlFor="export-label-placeholder">
																							{this.props.t('Typ')}
																						</InputLabel>
																						<Select
																							className="planingSelect"
																								value={this.state.currentPlanungTyp.typ}
																								onChange={this.handleChangePLDialog}
																								input={<Input name="age" id="age-label-placeholder" />}
																								displayEmpty
																								name="typ"
																							>
																							<MenuItem value="item">{this.props.t('Item')}</MenuItem>
																							<MenuItem value="query">{this.props.t('Abfrage')}</MenuItem>
																							<MenuItem value="table">{this.props.t('Abfrage Item anzeige')}</MenuItem>

																						</Select>
																					</FormControl>																																																													
																				</div>
																			</div>
																		</div>	
	
	
																		<hr/>
																	</div>
																	
													</>												
													}

												</div>											
											</Tab>
											
										</Tabs>
								</DialogContent>
							<DialogActions>
								<Button onClick={this.handleCloseEditPLElement} color="primary">
									{this.props.t('Abbruch')}
								</Button>
								<Button onClick={this.prepareLayoutPLElement} color="primary">
									{this.props.t('OK')}
								</Button>
							</DialogActions>
					</Dialog>                           
                                     
                </div> 
            );
       }
    }
}
export default withTranslation()(withSnackbar(Planingtypes)) ; 
