import React, { Component } from 'react';
import axios from 'axios';
import {getAppUrl} from '../helpers/Defaults';
import {getToken} from '../helpers/Token';
import ExportFields from './ExportFields.js';
import {Modal} from 'react-bootstrap';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useSnackbar, withSnackbar } from 'notistack';
import CreateNew from './blocks/CreateNew';


class Queries extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            currentTableId: 0,
            offset: 0,
            isLoaded : false,
            productData: [],
            currentSearch: "",
            currentExportId: 0,
            currentExportName:""
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.searchChange = this.searchChange.bind(this);
    }

    searchChange(e) {
        this.setState({
			currentSearch: e
		});
    }    

    componentDidMount() {
        axios.get(getAppUrl() + '/gatebackend/rest/settings/exports', 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.setState({
                    isLoaded : true,
                    posts: res.data
                })   
            });
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow(e) {
        this.setState({currentExportId : e.currentTarget.dataset.id});
        this.setState({currentExportName : e.currentTarget.dataset.tablename});
        
        this.setState({ show: true });
    }

    render() {
        const {isLoaded, posts} = this.state;
        if(!isLoaded) {
            return (<div>loading</div>)
        }
        else {
            let elHeight = document.getElementById('actionFrame').clientHeight -100;
            var height = {
                'max-height': elHeight + 'px',
            };
            return (
                <div>
                    <div class="Vivacard" style={height}>
                        <div class="Vivacardheader">                           
                            {this.props.t('Exporte')}
                            <CreateNew searchChange={this.searchChange} actionNew={this.createNew} title="Neuer Export"/>

                        </div>
                        <div class="Vivacardbody">
                            <div class="border">
                                <div class="row clearmargin"  align="start">
                                    <div class="grid-Cell grid-Cell-Header col-sm-1">
                                        <div class="grid-Cell__header "> 
                                            ID
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm">
                                        <div class="grid-Cell__header"> 
                                            Name
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            Bearbeiten
                                        </div>
                                    </div>
                                </div>
                                {
                                    posts.map(post => (
                                        <>
                                        {this.state.currentSearch === "" || ((post.id).toString().includes(this.state.currentSearch) ||  post.name.toLowerCase().includes((this.state.currentSearch).toLowerCase())) ? (                                        
                                            <div class="row clearmargin grid-Row" data-id={post.id} data-tablename={post.name}  onDoubleClick={((e) => this.handleShow(e))} align="start">
                                                <div class="grid-Cell col-sm-1"> 
                                                    <div class="grid-Cell__value" > 
                                                        {post.id}
                                                    </div>
                                                </div>
                                                <div class="grid-Cell col-sm"> 
                                                    <div class="grid-Cell__value"> 
                                                        {post.name}
                                                    </div>
                                                </div> 
                                                <div class="grid-Cell col-sm-1"> 
                                                    <div class="grid-Cell__value"> 
                                                        <ul>  
                                                            <li class="fa fa-pencil gridIcon">

                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>                                                                                    
                                            </div>
                                        ) : (<></>)}
                                        </>                                            
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>{this.props.t('Export')}: {this.state.currentExportName}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body class="modalBody">
                           <ExportFields exportId={this.state.currentExportId}/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                            {this.props.t('Abbruch')}
                            </Button>
                            <Button variant="primary" onClick={this.handleClose}>
                            {this.props.t('Speichern')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div> 
            );
       }
    }
}
export default withTranslation()(withSnackbar(Queries)) ;
