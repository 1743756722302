import React, { Component } from 'react';
import axios from 'axios';
import {getToken, getExtranetToken} from '../helpers/Token';
import {getAppUrl} from '../helpers/Defaults';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { useSnackbar, withSnackbar } from 'notistack';
import Loader from './blocks/Loader'
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DoneIcon from '@material-ui/icons/Done';
import GetAppIcon from '@material-ui/icons/GetApp';
import AccessibleForwardIcon from '@material-ui/icons/AccessibleForward';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {getWSUrl} from '../helpers/Defaults';


import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

class ASTextExport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            languages: [],
            show: false,
            offset: 0,
            isLoaded : false,
            exportLanguageOpen: false,
            refreshArticleOpen: false,
            refreshImagesOpen: false,
            listDialog: false,
            chooseLanguage: true,
            chooseArticle: true,
            chooseImages: true,
            sourcelanguageId: 3660,
            languageId: 3661,
            csvContent: "",
            articleNumber: "",
            noticeMsg: "",
            currentLanguage: -1,
            translateMissingTyp: "",
            exportPriceOpen: false,
            transferOrderOpen: false,
            artnr: "",
            priceResult: {},
            chooseList: true,
            listId: 3903,
            shopwareId: "complete",
            outHow: "csv",
            profile: "reimo",
            sourceShop: "reimo-com",
            selectedDate: new Date('2021-08-18T21:11:54')
        };
        this.handleLanguageDialog = this.handleLanguageDialog.bind(this);
        this.handlePriceDialog = this.handlePriceDialog.bind(this);
        this.refreshArticleDialog = this.refreshArticleDialog.bind(this);
        this.refreshImagesDialog = this.refreshImagesDialog.bind(this);
        this.listDialog = this.listDialog.bind(this);
        
        this.exportLanguage = this.exportLanguage.bind(this);
        this.checkPrice = this.checkPrice.bind(this);
        this.exportImages = this.exportImages.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
        this.changeList = this.changeList.bind(this);
        this.changeShopware = this.changeShopware.bind(this);
        this.changeOutHow = this.changeOutHow.bind(this);
        this.changeSourceLanguage = this.changeSourceLanguage.bind(this);
        this.changeProfile = this.changeProfile.bind(this);
        this.translateMissingText = this.translateMissingText.bind(this);
        this.translateMissingAdv = this.translateMissingAdv.bind(this);
        this.change = this.change.bind(this);
        this.checkExport = this.checkExport.bind(this);
        this.doTransferOrder = this.doTransferOrder.bind(this);
        this.handleShopwareDialog = this.handleShopwareDialog.bind(this);
        this.changeSourceShop = this.changeSourceShop.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    change(e) {   
        if(e.target.name === "artnr") {
            this.setState({
                artnr : e.target.value,
            })
		}
    }
    handleDateChange(e) {
        this.setState({selectedDate: e});
        console.log(e);
    }
    changeArticleNumber(e) {
        this.setState({articleNumber: e.target.value });
    }

    changeLanguage(e) {
        this.setState({languageId: e.target.value });
    }

    changeList(e) {
        this.setState({listId: e.target.value });
    }
    changeShopware(e) {
        this.setState({shopwareId: e.target.value });
    }
    

    changeOutHow(e) {
        this.setState({outHow: e.target.value });
    }

    changeSourceLanguage(e) {
        this.setState({sourcelanguageId: e.target.value });
    }

    changeProfile(e) {
        this.setState({profile: e.target.value });
    }

    changeSourceShop(e) {
        this.setState({sourceShop: e.target.value });
    }

	downloadFile(encoded, filename) {
		var fileDownload = require('js-file-download');
		fileDownload(encoded, filename);
    }
    
    componentDidMount() {       
        this.reload("reload", this.props.match.params.itemId);
    }

    componentWillMount() {
        let client = new W3CWebSocket(getWSUrl() + '/extranet/websocketendpoint');
        client.onopen = () => {
            console.log("connect WS");
        };
        client.onmessage = (message) => {
			let obj = JSON.parse(message.data);
			
            if(obj.type === "translateUpdate") {
                this.setState({
                    noticeMsg : obj.message ,
                })     
            }

        };
    }

    translateMissingText(language) {
        this.setState({
            isLoaded : true,
            currentLanguage: language,
            translateMissingTyp: "Texte"
        })        

        axios.get(getAppUrl() + '/gatebackend/rest/content/doTranslationsText?language='+language, 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                let posts = this.state.posts;
            posts.map(function(post) {
                if(post.language.refChooseList == language  && post.label == "Texte")
                    post.count = res.data.count;
            });
            console.log(this.state.posts);
                this.setState({
                    isLoaded : true,
                    currentLanguage: -1,
                    posts:posts
                }) 
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async doTransferOrder()  {
                const getEToken = async ()  => {
            let x = await getExtranetToken();
            return x;
        }  
        let selectedDate = new Date(this.state.selectedDate);
        let sdate = selectedDate.getFullYear() + "-" + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + "-" + ("0" + selectedDate.getDate()).slice(-2);
        console.log(sdate);
        
        let orderObj = {};
        orderObj.name = "transferOrders";
        let params = {};
        params.shop_profile = this.state.sourceShop;
        if(this.state.sourceShop == "reimo-com")
           params.command = "reimo_offers";
        else
           params.command = "pan_offers";
        params.outHow = "get";
        params.date = sdate;
        orderObj.params = params;

        let token =  await getEToken();
        axios.post(getAppUrl() + '/extranet/rest/jobs/create/transferOrders', 
            orderObj,
            {
                headers: { 
                    "Authorization": " Bearer " + token
                }
            }).then(res => {
                this.props.enqueueSnackbar(this.props.t('Eintrag für die Bestellungen hinzugefügt'), { variant: 'info' });

                this.setState({
                    transferOrderOpen : false
                }) 
            }).catch((error) => {
					console.log(error);
					alert("error");
			});


    }

    translateMissingAdv(language) {
        this.setState({
            isLoaded : true,
            currentLanguage: language,
            translateMissingTyp: "Vorteilspunkte"
        })         
        axios.get(getAppUrl() + '/gatebackend/rest/content/doTranslationsAdv?language='+language, 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                let posts = this.state.posts;
            posts.map(function(post) {
                if(post.language.refChooseList == language && post.label == "Vorteilspunkte")
                    post.count = res.data.count;;
            });
            console.log(this.state.posts);
            console.log(res.data);
                this.setState({
                    isLoaded : true,
                    currentLanguage: -1,
                    posts:posts
                }) 
            })
            .catch((error) => {
                console.log(error);
            });
    }

    reload(e) {  
        this.setState({
            isLoaded : false,
            posts: []
        });
            
		axios.get(getAppUrl() + '/gatebackend/rest/content/languages',  {
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}).then(res1 => {	
                    console.log(res1.data);
                        this.setState({
                            languages: res1.data,
                            isLoaded : true,
                        }) 
                    
				})
				.catch((error) => {
				});

    }
    

    async checkExport() {
        this.setState({chooseList: false });
        const getEToken = async ()  => {
            let x = await getExtranetToken();
            return x;
        }        
        let data = {};
        data.language = "de";
        data.country = "de";
        let groups = [];
        groups.push('G', 'D', 'V');
        data.groups = groups;
        let token =  await getEToken();
        axios.post(getAppUrl() + '/extranet/rest/articles/checkShopware?type=' + this.state.shopwareId, 
            data,
            {
                headers: { 
                    "Authorization": " Bearer " + token
                }
            }).then(res => {
                let base64 = require('base-64');
                let utf8 = require('utf8');
                let bytes = base64.decode(res.data.bdata);
                let encoded = utf8.decode(bytes);
                this.downloadFile(encoded, 'report.csv');
                
                this.setState({ shopwareOpen: false, chooseList: true });
            }).catch((error) => {
					console.log(error);
					alert("error");
			});

    }            

    async exportList() {
        this.setState({chooseList: false });
        const getEToken = async ()  => {
            let x = await getExtranetToken();
            return x;
        }        
        let token =  await getEToken();
        axios.get(getAppUrl() + '/extranet/rest/articles/exportList?kind=' + this.state.outHow + '&list=' + this.state.listId + '&destinationlanguage=' + this.state.languageId + '&sourcelanguage=' + this.state.sourcelanguageId, 
            {
                headers: { 
                    "Authorization": " Bearer " + token
                }
            }).then(res => {
                let base64 = require('base-64');
                let utf8 = require('utf8');
                let bytes = base64.decode(res.data.bdata);
                let encoded = utf8.decode(bytes);

                if(this.state.outHow == "pdfListe") {
                    const downloadLink = document.createElement("a");
                    const fileName = "link.pdf";
                    downloadLink.target = "new";

                    downloadLink.href = encoded;
                    downloadLink.download = fileName;
                    downloadLink.click(); 
                }
                if(this.state.outHow == "aufsteller") {
                    const downloadLink = document.createElement("a");
                    const fileName = "link.pdf";
                    downloadLink.target = "new";

                    downloadLink.href = encoded;
                    downloadLink.download = fileName;
                    downloadLink.click(); 
                }

                if(this.state.outHow == "csv") {
                    this.downloadFile(encoded, 'list.csv');
                }
                this.setState({ listOpen: false, chooseList: true });
            }).catch((error) => {
					console.log(error);
					alert("error");
			});

    }
    

    async importArticle() {
        this.setState({chooseArticle: false });
        const getEToken = async ()  => {
            let x = await getExtranetToken();
            return x;
        }        
        let token =  await getEToken();
        axios.get(getAppUrl() + '/extranet/rest/as400/importer/' + this.state.articleNumber, 
            {
                headers: { 
                    "Authorization": " Bearer " + token,
                }
            }).then(res => {
                if(res.data.details[0].insertArticles === 1)
                    this.props.enqueueSnackbar(this.props.t('Artikel hinzugefügt'), { variant: 'info' });
                else if(res.data.details[0].updateArticles === 1)
                    this.props.enqueueSnackbar(this.props.t('Artikel aktualisiert'), { variant: 'info' });
                else
                    this.props.enqueueSnackbar(this.props.t('Fehler beim importieren'), { variant: 'info' });

                this.setState({ refreshArticleOpen: false, chooseArticle: true });
            }).catch((error) => {
                console.log(error);
                alert("error");
			});
    }    

    async exportImages() {
        this.setState({chooseImages: false });
        const getEToken = async ()  => {
            let x = await getExtranetToken();
            return x;
        }        
        let token =  await getEToken();
        
        axios.get(getAppUrl() + '/extranet/rest/articles/exportImages/csv?kind=all', 
            {
                headers: { 
                    "Authorization": " Bearer " + token
                }
            }).then(res => {
                let base64 = require('base-64');
                let utf8 = require('utf8');
                let bytes = base64.decode(res.data.bdata);
                let encoded = utf8.decode(bytes);
                this.downloadFile(encoded, 'images.csv');
                this.setState({ refreshImagesOpen: false, chooseImages: true });
            }).catch((error) => {
					console.log(error);
					alert("error");
			});
    }

    async checkPrice() {
        this.setState({chooseLanguage: false });
        const getEToken = async ()  => {
            let x = await getExtranetToken();
            return x;
        }        
        let token =  await getEToken();
        
        axios.get(getAppUrl() + '/extranet/rest/articles/checkPrice/'+this.state.artnr+'?sourcelanguage=' + this.state.sourcelanguageId + "&profile="+ this.state.profile, 
            {
                headers: { 
                    "Authorization": " Bearer " + token
                }
            }).then(res => {
                console.log(res.data);

                this.setState({ exportLanguageOpen: false, 
                                chooseLanguage: true,
                                priceResult: res.data  });
            }).catch((error) => {
					console.log(error);
					alert("error");
			});
    }


    async exportLanguage() {
        this.setState({chooseLanguage: false });
        const getEToken = async ()  => {
            let x = await getExtranetToken();
            return x;
        }        
        let token =  await getEToken();
        
        axios.get(getAppUrl() + '/extranet/rest/articles/tranlation/csv?language=' + this.state.languageId + '&sourcelanguage=' + this.state.sourcelanguageId, 
            {
                headers: { 
                    "Authorization": " Bearer " + token
                }
            }).then(res => {
                let base64 = require('base-64');
                let utf8 = require('utf8');
                let bytes = base64.decode(res.data.bdata);
                let encoded = utf8.decode(bytes);
                this.downloadFile(encoded, 'language.csv');
                this.setState({ exportLanguageOpen: false, chooseLanguage: true });
            }).catch((error) => {
					console.log(error);
					alert("error");
			});
    }

    handlePriceDialog(which) {
        this.setState({ exportPriceOpen: which });
    }
    handleShopwareDialog(which) {
        this.setState({ transferOrderOpen: which });
    }

    handleLanguageDialog(which) {
        this.setState({ exportLanguageOpen: which });
    }

    refreshArticleDialog(which) {
        this.setState({ refreshArticleOpen: which });
    }

    refreshImagesDialog(which) {
        this.setState({ refreshImagesOpen: which });
    }

    listDialog(which) {
        this.setState({ listOpen: which });
    }
    
    shopwareDialog(which) {
        this.setState({ shopwareOpen: which });
    }
    
    render() {
        const user = this.state.user;
        const {isLoaded, posts} = this.state;
        if(!isLoaded) {
            return (<div><Loader/></div>)
        }
        else {
            return (
                <div>
                    <div class="Vivacardheader" >                           
                        {this.props.t('Dashboard')}
                    </div>

                    <div style={{paddingTop: "25px"}} >
                        <div >
                            <Paper>
                                <div className="card-icon" style={{background: "linear-gradient(60deg, #66bb6a, #43a047)"}}>
                                    <span className="fa fa-blind innerCenter"></span>
                                </div>
                                <span className="float-right headlineDashboard" >Shopware Export</span>
                                <hr style={{marginLeft: "10px", marginRight: "10px"}}/>
                                    <List>
                                        <ListItem >
                                            <ListItemAvatar onClick={((e) => this.shopwareDialog(true))}>
                                                <Avatar className="classesAvatar">
                                                    <GetAppIcon/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Testen Sie den Shopware Export"/>
                                        </ListItem>
                                    </List>
                            </Paper> 
                        </div>   
                    </div>  

                    <div style={{paddingTop: "25px"}} >
                        <div >
                            <Paper>
                                <div className="card-icon" style={{background: "linear-gradient(60deg, #66bb6a, #43a047)"}}>
                                    <span className="fa fa-picture-o innerCenter"></span>
                                </div>
                                <span className="float-right headlineDashboard" > Listen Export</span>
                                <hr style={{marginLeft: "10px", marginRight: "10px"}}/>
                                    <List>
                                        <ListItem >
                                            <ListItemAvatar onClick={((e) => this.listDialog(true))}>
                                                <Avatar className="classesAvatar">
                                                    <GetAppIcon/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Exportieren Sie eine Liste"/>
                                        </ListItem>
                                    </List>
                            </Paper> 
                        </div>   
                    </div>  
                    
                    <div style={{paddingTop: "25px"}} >
                        <div >
                            <Paper>
                                <div className="card-icon" style={{background: "linear-gradient(60deg, #66bb6a, #43a047)"}}>
                                    <span className="fa fa-database innerCenter"></span>
                                </div>
                                <span className="float-right headlineDashboard" > Artikel Aktualisierung</span>
                                <hr style={{marginLeft: "10px", marginRight: "10px"}}/>
                                    <List>
                                        <ListItem >
                                            <ListItemAvatar onClick={((e) => this.refreshArticleDialog(true))}>
                                                <Avatar className="classesAvatar">
                                                    <ImportExportIcon/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Importieren/aktualisieren Sie einen Artikel aus der AS400"/>
                                        </ListItem>
                                    </List>
                            </Paper> 
                        </div>   
                    </div>


                    <div style={{paddingTop: "25px"}} >
                        <div >
                            <Paper>
                                <div className="card-icon" style={{background: "linear-gradient(60deg, #66bb6a, #43a047)"}}>
                                    <span className="fa fa-database innerCenter"></span>
                                </div>
                                
                                <span className="float-right headlineDashboard" > Preisabfrage</span>
                                <hr style={{marginLeft: "10px", marginRight: "10px"}}/>
                                    <List>
                                        <ListItem >
                                            <ListItemAvatar onClick={((e) => this.handlePriceDialog(true))}>
                                            <Avatar className="classesAvatar">
                                                <GetAppIcon />
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Welcher Preis ist aktuell" />
                                        </ListItem>
                                    </List>
                            </Paper> 
                        </div>   
                    </div>

                    <div style={{paddingTop: "25px"}} >
                        <div >
                            <Paper>
                                <div className="card-icon" style={{background: "linear-gradient(60deg, #66bb6a, #43a047)"}}>
                                    <span className="fa fa-database innerCenter"></span>
                                </div>
                                
                                <span className="float-right headlineDashboard" > ShopwareBestellungen</span>
                                <hr style={{marginLeft: "10px", marginRight: "10px"}}/>
                                    <List>
                                        <ListItem >
                                            <ListItemAvatar onClick={((e) => this.handleShopwareDialog(true))}>
                                            <Avatar className="classesAvatar">
                                                <GetAppIcon />
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Übertragen der Shopware Bestellungen" />
                                        </ListItem>
                                    </List>
                            </Paper> 
                        </div>   
                    </div>                    

                    <div style={{paddingTop: "25px"}} >
                        <div >
                            <Paper>
                                <div className="card-icon" style={{background: "linear-gradient(60deg, #66bb6a, #43a047)"}}>
                                    <span className="fa fa-database innerCenter"></span>
                                </div>
                                
                                <span className="float-right headlineDashboard" > Sprachen Export</span>
                                <hr style={{marginLeft: "10px", marginRight: "10px"}}/>
                                    <List>
                                        <ListItem >
                                            <ListItemAvatar onClick={((e) => this.handleLanguageDialog(true))}>
                                            <Avatar className="classesAvatar">
                                                <GetAppIcon />
                                            </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Exportieren Sie alle Artikel in einer Sprache als CSV" />
                                        </ListItem>
                                    </List>
                            </Paper> 
                        </div>   
                    </div>
                    



                    <div style={{paddingTop: "25px"}} >
                        <div >
                            <Paper>
                                <div className="card-icon" style={{background: "linear-gradient(60deg, #66bb6a, #43a047)"}}>
                                    <span className="fa fa-picture-o innerCenter"></span>
                                </div>
                                <span className="float-right headlineDashboard" > Bilder Export</span>
                                <hr style={{marginLeft: "10px", marginRight: "10px"}}/>
                                    <List>
                                        <ListItem >
                                            <ListItemAvatar onClick={((e) => this.refreshImagesDialog(true))}>
                                                <Avatar className="classesAvatar">
                                                    <GetAppIcon/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Exportieren Sie eine Liste aller Produkte mit Kontext Bilder"/>
                                        </ListItem>
                                    </List>
                            </Paper> 
                        </div>   
                    </div>   

 


                    <Dialog open={this.state.refreshImagesOpen} 
                            onClose={((e) => this.refreshImagesDialog(false))} 
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{this.props.t('Images exportieren')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.props.t('Exportieren Sie die Bilderliste')}
                            </DialogContentText>

                            {this.state.chooseImages === true ? (
                                <>                                
                           
                                </>
                                )
                                :(<div><Loader/></div>)
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={((e) => this.refreshImagesDialog(false))} color="primary">
                                {this.props.t('Abbruch')}
                            </Button>
                            <Button onClick={((e) => this.exportImages())} color="primary">
                                {this.props.t('OK')}
                            </Button>
                            </DialogActions>
                    </Dialog>

                    <Dialog open={this.state.shopwareOpen} 
                            onClose={((e) => this.shopwareDialog(false))} 
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{this.props.t('ShopwareExport testen')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.props.t('Text kommmt noch')}
                            </DialogContentText>

                            {this.state.chooseList === true ? (
                                <>                  
                                    <FormControl  style={{width: "100%"}}>
                                        <InputLabel htmlFor="destination-helper">AusgabeArt</InputLabel>
                                        <NativeSelect
                                            value={this.state.shopwareId}
                                            onChange={e => this.changeShopware(e)}
                                            inputProps={{
                                                name: 'bookmarkfolder',
                                                id: 'destination-helper',
                                            }}
                                        >
                                        <option value="complete">kompletter Report</option>
                                        <option value="errorOnly">kompletter Report (nur Fehler)</option>
                                        <option value="masterOnly">nur Master</option>
                                        <option value="missingArticles">Differenz PIM zu AS400</option>
                                        <option value="checkFailed">fehlerhafte Artikel</option>

                                        </NativeSelect>
                                    </FormControl>                                
                                
                                    <FormControl  style={{width: "100%"}}>
                                        <InputLabel htmlFor="source-helper">Sprache auswählen</InputLabel>
                                        <NativeSelect
                                            value={this.state.sourcelanguageId}
                                            onChange={e => this.changeSourceLanguage(e)}
                                            inputProps={{
                                                name: 'bookmarkfolder',
                                                id: 'source-helper',
                                            }}
                                        >
                                            {
                                            this.state.languages.map(l => (
                                                <option value={l.refChooseList}>{l.label}</option>

                                            ))}
                                            
                                        </NativeSelect>
                                    </FormControl>
                                                          
                                </>                           
                                
                                )
                                :(<><div>Status:</div>
                                    <div>-{this.state.noticeMsg}-</div>
                                </> )
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={((e) => this.shopwareDialog(false))} color="primary">
                                {this.props.t('Abbruch')}
                            </Button>
                            <Button onClick={((e) => this.checkExport())} color="primary">
                                {this.props.t('OK')}
                            </Button>
                            </DialogActions>
                    </Dialog>                                         

                    <Dialog open={this.state.listOpen} 
                            onClose={((e) => this.listDialog(false))} 
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{this.props.t('Listen exportieren')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.props.t('Exportieren Sie eine Liste')}
                            </DialogContentText>

                            {this.state.chooseList === true ? (
                                <>                  
                                <FormControl  style={{width: "100%"}}>
                                    <InputLabel htmlFor="destination-helper">Liste auswählen</InputLabel>
                                    <NativeSelect
                                        value={this.state.listId}
                                        onChange={e => this.changeList(e)}
                                        inputProps={{
                                            name: 'bookmarkfolder',
                                            id: 'destination-helper',
                                        }}
                                    >
                                    <option value="3903">Messe UK</option>
                                    <option value="4190">Messe Aufsteller Niederlande</option>

                                    <option value="4192">LATE ARRIVAL</option>
                                    <option value="4193">xxxx</option>
                                    <option value="4182">Neuheiten 2024 Zubehör allgemein</option>
                                    <option value="4183">Neuheiten 2024 Handelsware</option>
                                    <option value="4184">Neuheiten 2024 Möbel</option>
                                    <option value="4185">Neuheiten 2024 Projekte</option>
                                    <option value="4186">Neuheiten 2024 Technik</option>                                    
                                    <option value="4187">Neuheiten 2024 Zelte</option>  

                                    <option value="3896">Liste 2023 Technik</option>
                                    <option value="3897">Liste 2023 Projekte</option>
                                    <option value="3898">Liste 2023 Zelte</option>
                                    <option value="3899">Liste 2023 Möbel</option>
                                    <option value="3900">Liste 2023 Fenster etc.</option>                                    
                                    <option value="3901">Liste 2023 Handelsware</option>  
                                                                      
                                    <option value="3902">Aufsteller 2022</option>                                    
                                    <option value="3875">Liste 2022 Technik</option>
                                    <option value="3876">Liste 2022 Projekte</option>
                                    <option value="3877">Liste 2022 Zelte</option>
                                    <option value="3878">Liste 2022 Möbel</option>
                                    <option value="3879">Liste 2022 Rest</option>
                                    </NativeSelect>
                                </FormControl>                                
                                <FormControl  style={{width: "100%"}}>
                                    <InputLabel htmlFor="destination-helper">Ausgabeformat auswählen</InputLabel>
                                    <NativeSelect
                                        value={this.state.outHow}
                                        onChange={e => this.changeOutHow(e)}
                                        inputProps={{
                                            name: 'bookmarkfolder',
                                            id: 'destination-helper',
                                        }}
                                    >
                                    <option value="pdfListe">Listen Ansicht</option>
                                    <option value="aufsteller">Aufsteller</option>
                                    <option value="csv">CSV Format</option>
                                    </NativeSelect>
                                </FormControl>                                

                                <FormControl  style={{width: "100%"}}>
                                    <InputLabel htmlFor="source-helper">Erste Sprache auswählen</InputLabel>
                                    <NativeSelect
                                        value={this.state.sourcelanguageId}
                                        onChange={e => this.changeSourceLanguage(e)}
                                        inputProps={{
                                            name: 'bookmarkfolder',
                                            id: 'source-helper',
                                        }}
                                    >
                                        {
                                        this.state.languages.map(l => (
                                            <option value={l.refChooseList}>{l.label}</option>

                                        ))}
                                        
                                    </NativeSelect>
                                </FormControl>
                                <FormControl  style={{width: "100%"}}>
                                    <InputLabel htmlFor="destination-helper">Zweite Sprache auswählen</InputLabel>
                                    <NativeSelect
                                        value={this.state.languageId}
                                        onChange={e => this.changeLanguage(e)}
                                        inputProps={{
                                            name: 'bookmarkfolder',
                                            id: 'destination-helper',
                                        }}
                                    >
                                        {
                                        this.state.languages.map(l => (
                                            <option value={l.refChooseList}>{l.label}</option>

                                        ))}
                                    </NativeSelect>
                                </FormControl>                            
                                </>                           
                                
                                )
                                :(<div><Loader/></div>)
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={((e) => this.listDialog(false))} color="primary">
                                {this.props.t('Abbruch')}
                            </Button>
                            <Button onClick={((e) => this.exportList())} color="primary">
                                {this.props.t('OK')}
                            </Button>
                            </DialogActions>
                    </Dialog> 

                    <Dialog open={this.state.refreshArticleOpen} 
                            onClose={((e) => this.refreshArticleDialog(false))} 
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{this.props.t('Artikel aktualisieren')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.props.t('Aktualisieren Sie einen Artikel aus der AS400')}
                            </DialogContentText>

                            {this.state.chooseArticle === true ? (
                                <>                                
                                <FormControl  style={{width: "100%"}}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        name="articleNumber"
                                        label="Artikelnummer"
                                        type="email"
                                        onChange={e => this.changeArticleNumber(e)}
                                        value={this.state.articleNumber}
                                        fullWidth
                                    />
                                </FormControl>                            
                                </>
                                )
                                :(<div><Loader/></div>)
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={((e) => this.refreshArticleDialog(false))} color="primary">
                                {this.props.t('Abbruch')}
                            </Button>
                            <Button onClick={((e) => this.importArticle())} color="primary">
                                {this.props.t('OK')}
                            </Button>
                            </DialogActions>
                    </Dialog>                     

                    <Dialog open={this.state.exportPriceOpen} 
                            onClose={((e) => this.handlePriceDialog(false))} 
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{this.props.t('Preiseprüfung')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.props.t('Welcher Preis ist gültig')}
                            </DialogContentText>

                            {this.state.chooseLanguage === true ? (
                                <>   
                                 								<TextField
									autoFocus
									margin="dense"
									name="artnr"
									label="Artikelnummer"
									type="email"
									onChange={e => this.change(e)}
									value={this.state.artnr}
									fullWidth
								/> 
                                <FormControl  style={{width: "100%"}}>
                                    <InputLabel htmlFor="source-helper">Profile </InputLabel>
                                    <NativeSelect
                                        value={this.state.profile}
                                        onChange={e => this.changeProfile(e)}
                                        inputProps={{
                                            name: 'bookmarkfolder',
                                            id: 'source-helper',
                                        }}
                                    >
                                    <option value="reimo">Reimo</option>
                                    <option value="pan">Reimo Pan</option>                                        
                                    </NativeSelect>
                                </FormControl>                                               
                                <FormControl  style={{width: "100%"}}>
                                    <InputLabel htmlFor="source-helper">Land </InputLabel>
                                    <NativeSelect
                                        value={this.state.sourcelanguageId}
                                        onChange={e => this.changeSourceLanguage(e)}
                                        inputProps={{
                                            name: 'bookmarkfolder',
                                            id: 'source-helper',
                                        }}
                                    >
                                        {
                                        this.state.languages.map(l => (
                                            <option value={l.iso}>{l.label}</option>

                                        ))}
                                        
                                    </NativeSelect>
                                </FormControl>
                                                   <div>
                                    Ausgabe:<br/>
                                    <pre>{JSON.stringify(this.state.priceResult, null, 2) }</pre>
                                    
                                </div> 
                                </>
                                )
                                :(<div><Loader/></div>)
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={((e) => this.handlePriceDialog(false))} color="primary">
                                {this.props.t('Abbruch')}
                            </Button>
                            <Button onClick={((e) => this.checkPrice())} color="primary">
                                {this.props.t('OK')}
                            </Button>
                            </DialogActions>
                    </Dialog> 

                    <Dialog open={this.state.transferOrderOpen} 
                            onClose={((e) => this.handleShopwareDialog(false))} 
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{this.props.t('TransferOrders')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.props.t('übertragen der Bestellungen')}
                            </DialogContentText>

                            {this.state.chooseLanguage === true ? (
                                <>   
 								<MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="Datum"
                                        value={this.state.selectedDate}
                                        onChange={e => this.handleDateChange(e)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        />     
                                </MuiPickersUtilsProvider>                                                              
                                <FormControl  style={{width: "100%"}}>
                                    <InputLabel htmlFor="source-helper">Shop </InputLabel>
                                    <NativeSelect
                                        value={this.state.sourceShop}
                                        onChange={e => this.changeSourceShop(e)}
                                        inputProps={{
                                            name: 'bookmarkfolder',
                                            id: 'source-helper',
                                        }}
                                    >

                                    <option value={"reimo-com"}>{"Reimo"}</option>
                                    <option value={"reimo-pan"}>{"Reimo Pan"}</option>

                                        
                                    </NativeSelect>
                                </FormControl>
                   
                                </>
                                )
                                :(<div><Loader/></div>)
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={((e) => this.handleShopwareDialog(false))} color="primary">
                                {this.props.t('Abbruch')}
                            </Button>
                            <Button onClick={((e) => this.doTransferOrder())} color="primary">
                                {this.props.t('OK')}
                            </Button>
                            </DialogActions>
                    </Dialog> 


                    <Dialog open={this.state.exportLanguageOpen} 
                            onClose={((e) => this.handleLanguageDialog(false))} 
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{this.props.t('Sprache exportieren')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.props.t('Exportiern Sie hier alle Artikel in einer Sprache')}
                            </DialogContentText>

                            {this.state.chooseLanguage === true ? (
                                <>                                
                                <FormControl  style={{width: "100%"}}>
                                    <InputLabel htmlFor="source-helper">Ausgangssprache auswählen</InputLabel>
                                    <NativeSelect
                                        value={this.state.sourcelanguageId}
                                        onChange={e => this.changeSourceLanguage(e)}
                                        inputProps={{
                                            name: 'bookmarkfolder',
                                            id: 'source-helper',
                                        }}
                                    >
                                        {
                                        this.state.languages.map(l => (
                                            <option value={l.refChooseList}>{l.label}</option>

                                        ))}
                                        
                                    </NativeSelect>
                                </FormControl>
                                <FormControl  style={{width: "100%"}}>
                                    <InputLabel htmlFor="destination-helper">Zielsprache auswählen</InputLabel>
                                    <NativeSelect
                                        value={this.state.languageId}
                                        onChange={e => this.changeLanguage(e)}
                                        inputProps={{
                                            name: 'bookmarkfolder',
                                            id: 'destination-helper',
                                        }}
                                    >
                                        {
                                        this.state.languages.map(l => (
                                            <option value={l.refChooseList}>{l.label}</option>

                                        ))}
                                    </NativeSelect>
                                </FormControl>                            
                                </>
                                )
                                :(<><div>Status:</div>
                                    <div>-{this.state.noticeMsg}-</div>
                                </>)
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={((e) => this.handleLanguageDialog(false))} color="primary">
                                {this.props.t('Abbruch')}
                            </Button>
                            <Button onClick={((e) => this.exportLanguage())} color="primary">
                                {this.props.t('OK')}
                            </Button>
                            </DialogActions>
                    </Dialog> 

                </div> 
            );
       }
    }
}
export default withTranslation()(withSnackbar(ASTextExport)) ;

