import React, { Component, Fragment } from 'react';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';

class Loader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            posts: [],
        };
    }

    render() {
        return (
            <div style={{height: "70px"}}>          
                <div class="loader">loading...</div> 
            </div>          
        );
    
    }
}

export default withTranslation()(Loader) ;