import React, { Component } from 'react';
import {Route, Redirect} from "react-router-dom";

export default function AuthenticatedRoute({ component: C, appProps, ...rest }) {
    console.log(appProps);
  return (
    <Route
      {...rest}
      render={props =>
        appProps.isAuthenticated === true
          ? <C {...props} {...appProps} />
          : <div>
              Sorry 
            </div>}
    />
  );
}