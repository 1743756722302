import React, { Component } from 'react';
import axios from 'axios';
import {getToken} from '../helpers/Token';
import {Tabs, Tab} from 'react-bootstrap';
import {getAppUrl} from '../helpers/Defaults';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { useSnackbar, withSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
class ExportFields extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mask: {},
            isLoaded : false,
            key: 'fields',
            export: {}
		};
	}

    getData(exportId) {
        axios.get(getAppUrl() + '/gatebackend/rest/settings/exports/' + exportId, 
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }).then(res => {
            this.setState({
                isLoaded : true,
                export: res.data,
            })   
  
        });
    }

    componentWillMount() {
        this.getData(this.props.exportId);
    }

    componentDidUpdate(prevProps) {
        
        if(this.props.exportId != prevProps.exportId) {
            this.getData(this.props.exportId);
        }
    }

    change(e) {

    }

	renderCellMask(field, masks) {
	    return  <select name={"elementValue_" + field.fieldID} onChange={e => this.change(e)} value={this.state.mask.fields[field.fieldID].elementValue}>
					{
						masks.map(mask => (
							<option value={mask.id}>{mask.name}</option>
						))
					}
				</select>
	}


    componentDidMount() {
        this.getData();
    }

     render() {
            const isLoaded = this.state.isLoaded;
			const mask = this.state.mask;
			const masks = this.state.masks;
            if(!isLoaded) {
                return (<div>loading</div>)
            }
            else {          
                return (
          
                <div>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={this.state.key}
                        onSelect={key => this.setState({ key })}
                    >
                        <Tab eventKey="fields" title="Felder">
                            
                            <div className="row clearmargin maskFieldRow"  align="start">
                                <div className="grid-Cell grid-Cell-Header maskField_300" >
                                    <div className="grid-Cell__header "> 
                                        Exportfield
                                    </div>
                                </div>
                                <div className="grid-Cell grid-Cell-Header maskField_200">
                                    <div className="grid-Cell__header"> 
                                        Muster
                                    </div>
                                </div>									
                                <div className="grid-Cell grid-Cell-Header maskField_200">
                                    <div className="grid-Cell__header"> 
                                        Inhalt
                                    </div>
                                </div>
                                <div className="grid-Cell grid-Cell-Header maskField_300">
                                    <div className="grid-Cell__header"> 
                                        Funktion
                                    </div>
                                </div>
                                <div className="grid-Cell grid-Cell-Header maskField_100">
                                    <div className="grid-Cell__header"> 
                                        Breite
                                    </div>
                                </div>
                                <div className="grid-Cell grid-Cell-Header maskField_100">
                                    <div className="grid-Cell__header"> 
                                        Type
                                    </div>
                                </div>
                                <div className="grid-Cell grid-Cell-Header maskField_200">
                                    <div className="grid-Cell__header"> 
                                        Anzeige
                                    </div>
                                </div>
                            </div>
                            <div className="border maskFieldRow">
                                {this.state.export && this.state.export.exportItems ? (
                                    <>
                                        <RowEntry data={this.state.export.exportItems} />
                                    </>) : (<></>)
                                }  
                            </div>                          
						</Tab>
					</Tabs>
				</div>
				)
        }
     }
}

const RowEntry = ({ data }) =>
  Object.entries(data).map(([k, v]) => (
     
    <div key={k}>
    {console.log(v)}
        <div className="row clearmargin grid-Row maskFieldRow"  align="start">
            <div className="grid-Cell maskField_300" >
                <div className="grid-Cell__header "> 
                    {v.label}
                </div>
            </div>
        </div>
    </div>
  ));

export default withTranslation()(withSnackbar(ExportFields));
