import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
const resources = {
  de: {
    translation: {
      "Suchbegriff": "Suchbegriff",
      "Produktdaten": "Produktdaten",
      "Profile": "Profile",
      "Daten": "Daten",
      "Bilder": "Bilder",
      "Backoffice": "Backoffice",
      "Importe": "Importe",
      "Administration": "Administration",
      "Datenbank": "Datenbank",
      "Tabellen": "Tabellen",
      "Masken": "Masken",
      "Suche": "Suche",
      "Alles sichern": "Alles sichern",
      "Detailsuche": "Detailsuche",
      "Markierte Datensätze löschen": "Markierte Datensätze löschen",
      "Produktname": "Produktname",
      "Benutzer": "Benutzer",
      "Geändert": "Geändert",
      "Kopie": "Kopie",
      "löschen/linken": "löschen/linken",
      "Vorschau": "Vorschau",
      "Textbereich": "Textbereich",
      "Statischer Text": "Statischer Text",
      "Auswahl": "Auswahl",	  
      "Textzeile": "Textzeile",
      "Bild auswählen": "Bild auswählen",
      "Vorname": "Vorname",
      "Nachname": "Nachname",
      "Email": "Email",
      "Profil ändern": "Profil ändern",
      "Passswort": "Passswort",
      "Passswort wiederholen": "Passswort wiederholen",
      "Abbruch": "Abbruch",
      "Speichern": "Speichern",
      "sub": "Unterplanung",
      "item": "Element",
      "query": "Abfrage",
      "zusammenfassen": "zusammenfassen",
      "Export": "Export",
      "showSubPlaning": "Unterplanung anzeigen",
      "exportPlaning": "Planung exportieren",
      "PDFJobSilent": "Job in der Pipe ",
      "ExportJobSilent": "Job in der Pipe",
      "JobSilent": "Gestart: ",
      "Bookmark hinzugefügt": "Bookmark hinzugefügt",
      "Auswahl aufgehoben": "Auswahl aufgehoben",
      "Items auf der Seite markiert": "Items auf der Seite markiert",
      "Alles markieren": "Alles markieren",
      "Tree Maske": "Tree Maske",
      "Keine Angabe": "Keine Angabe",
      "top": "Oben",
      "bottom": "Unten",
      "left": "Links",
      "right": "Rechts",
      "center": "Mitte",
      "Möchten Sie die markierten Datensätze löschen?": "Möchten Sie die markierten Datensätze löschen?",
      "Möchten Sie die markierten Datensätze zur Planung hinzufügen?": "Möchten Sie die markierten Datensätze zur Planung hinzufügen?",
      "Möchten Sie die markierten Datensätze aus der Planung löschen?": "Möchten Sie die markierten Datensätze aus der Planung löschen?",
      "Möchten Sie die markierten Verlinkungen löschen?": "Möchten Sie die markierten Verlinkungen löschen?",
      "Möchten Sie die markierten Datensätze linken?": "Möchten Sie die markierten Datensätze linken?",
      "Möchten Sie die markierten Datensätze editieren?": "Möchten Sie die markierten Datensätze editieren?",
      "Kein": "Kein",
      "Suchergebnis": "Suchergebnis",
      "JobResult": "JobResult",
      "Erlaube Suchen:": "Erlaube Suchen:",
      "Bookmark Manager" : "Bookmark Manager",
      "Bookmark anlegen" : "Bookmark anlegen"
      
	},
	
  },
    en: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
      "Suchbegriff": "Searchterm",
      "Produktdaten": "Product data",
      "Profile": "Profile",
      "Daten": "Data",
      "Bilder": "Pictures",
      "Backoffice": "Backoffice",
      "Importe": "Imports",
      "Administration": "Administration",
      "Datenbank": "Database",
      "Tabellen": "Tables",
      "Masken": "Masks",
      "Suche": "Search",
      "Alles sichern": "Save all",
      "Detailsuche": "Detail search",
      "Markierte Datensätze löschen": "Delete marked",
      "Produktname": "Productname",
      "Benutzer": "User",
      "Geändert": "Changed",
      "Kopie": "Copy",
      "löschen/linken": "delete/link",
      "Vorschau": "Preview",
      "Textbereich": "Textarea",
      "Statischer Text": "Static text",
      "Auswahl": "Select",	  
      "Textzeile": "InputText",
      "Bild auswählen": "choose image",
      "Export": "Export"
	  }
  }

};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "de",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;