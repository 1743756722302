import React, { Component } from 'react';
import axios from 'axios';
import {getAppUrl} from '../helpers/Defaults';
import {getToken} from '../helpers/Token';
import {Modal, Button} from 'react-bootstrap';
import CreateNew from './blocks/CreateNew';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { useSnackbar, withSnackbar } from 'notistack';
import Loader from './blocks/Loader'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CSVDisplay from './CSVDisplay';
import PDFDisplay from './blocks/PDFDisplay';
import TextField from '@material-ui/core/TextField';


class Planings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            currentTableId: 0,
            offset: 0,
            isLoaded : false,
            duplicatePlaning: false,
            productData: [],
            currentSearch: "",
            newValue: ""
        };
        this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
        this.gotoDetail = this.gotoDetail.bind(this);
        this.createNew = this.createNew.bind(this);         
        this.searchChange = this.searchChange.bind(this);
        this.renderSilentPDF = this.renderSilentPDF.bind(this);
        this.renderSilentExport = this.renderSilentExport.bind(this);
        this.reload = this.reload.bind(this);
        this.handleExportPlaningComplete = this.handleExportPlaningComplete.bind(this);
		this.handleExportClose = this.handleExportClose.bind(this);
		this.handleExportPlaning = this.handleExportPlaning.bind(this);
		this.duplicatePlaning = this.duplicatePlaning.bind(this);
        this.change = this.change.bind(this);
		this.doDuplicatePlaning = this.doDuplicatePlaning.bind(this);
    }
    
    change(e) {
        this.setState({
            newValue: e.target.value
        });          
    }
    
	handleExportPlaning(visible) {
		if(visible === true) {
			this.setState({ exportPlaning: true,
							exportStatus: 1 })
		}
		else {
			this.setState({ exportPlaning: false,
							exportStatus: 0 })
		}
	}
	
	handleExportPlaningComplete(planingId) {
		this.setState({
					exportItem: -1,
					exportPlaning: true,
					exportStatus: 1,	
					exportItems: -1,
					planingId: planingId
		})		
	}

    handleExportClose() {
        this.setState({ exportShow: false });
    }

	duplicatePlaning(kind,planingId, name) {
        this.setState({ duplicatePlaning: kind,
        				planingId: planingId,
        				newValue: name + " Duplikat"
        				});		
	}
	
    handlepdfDialog(kind, planingId) {
		if(kind === true) {
			this.setState({
				pdfDialog: kind,
                plItemId: -1,
                planingId: planingId,
                planingFirstOpen: false
			})
		}
		else {
			this.setState({
				pdfDialog: kind,
			})
		}
    }  	
	
	
    doDuplicatePlaning() {
        let chooseList = {};
        chooseList.id =  this.state.planingId;
        chooseList.name = this.state.newValue;
        this.setState({ duplicatePlaning: false});
       	axios.post(getAppUrl() + '/gatebackend/rest/content/planning/double', 
            chooseList
        ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
           this.props.enqueueSnackbar(this.props.t('Planung dupliziert'), { variant: 'info' });
         
 			this.reload();
        }
            
        )
        .catch((error) => {
            console.log(error);
        });

    }
	
    createNew(newName) {
        let chooseList = {};
        chooseList.id = -1;
        chooseList.name = newName;
       	axios.post(getAppUrl() + '/gatebackend/rest/content/planning/add', 
            chooseList
        ,
        {
            headers: { 
                "Authorization": " Bearer " + getToken()
            }
        }
        ).then(repsonse => {
           this.props.enqueueSnackbar(this.props.t('Planung hinzugefügt'), { variant: 'info' });
         
 			this.reload();
        }
            
        )
        .catch((error) => {
			console.log("======");
            console.log(error);
        });

    }
    
    renderSilentPDF(id)  {
        let elem = {};
        elem.job = "renderPDF";
        elem.planing = id;
        elem.item = -1;
        elem.name = "Render Planung - ID:" + id;
        axios.post(getAppUrl() + '/gatebackend/rest/settings/insertSilentJob',
        elem,
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.props.enqueueSnackbar(this.props.t('JobSilent') + elem.name, { variant: 'info' });
  
            });
    }

    renderSilentExport(id)  {
        let elem = {};
        elem.job = "exportCSV";
        elem.planing = id;
        elem.item = -1;       
        elem.name = "Export CSV - ID:" + id;
 
        axios.post(getAppUrl() + '/gatebackend/rest/settings/insertSilentJob',
        elem,
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.props.enqueueSnackbar(this.props.t('JobSilent') + elem.name, { variant: 'info' });  
            });
    }    

    searchChange(e) {
        this.setState({
			currentSearch: e
		});
    }
    
    reload() {
        axios.get(getAppUrl() + '/gatebackend/rest/settings/planings', 
            {
                headers: { 
                    "Authorization": " Bearer " + getToken()
                }
            }).then(res => {
                this.setState({
                    isLoaded : true,
                    posts: res.data
                })   
            });	
	}

    componentDidMount() {
		this.reload();
    }

	gotoDetail(id) {
		this.props.history.push("/planing/" + id);
	}

    handleClose() {
        this.setState({ show: false });
    }

    handleShow(e) {
        this.setState({currentTableId : e.currentTarget.dataset.id});
        this.setState({currentTableName : e.currentTarget.dataset.tablename});
        
        this.setState({ show: true });
    }

    render() {
        const {isLoaded, posts} = this.state;
        if(!isLoaded) {
            return (<div><Loader/></div>)
        }
        else {
            let elHeight = document.getElementById('actionFrame').clientHeight -100;
            var height = {
                'max-height': elHeight + 'px',
            };
            return (
                <div>
                    <div class="Vivacard" style={height}>
                        <div class="Vivacardheader">
                            {this.props.t('Planungen')}
                            <CreateNew searchChange={this.searchChange} actionNew={this.createNew} title="Neue Planung"/>
                        </div>
                        <div class="Vivacardbody">
                            <div class="border">
                                <div class="row clearmargin"  align="start">
                                    <div class="grid-Cell grid-Cell-Header col-sm-1">
                                        <div class="grid-Cell__header "> 
                                            {this.props.t('ID')}
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm">
                                        <div class="grid-Cell__header"> 
                                            {this.props.t('Name')}
                                        </div>
                                    </div>
                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            {this.props.t('Export')}
                                        </div>
                                    </div>        
                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            {this.props.t('PDF')}
                                        </div>
                                    </div>                            
                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            {this.props.t('Duplizieren')}
                                        </div>
                                    </div>  
                                    <div class="grid-Cell grid-Cell-Header  col-sm-1">
                                        <div class="grid-Cell__header"> 
                                            {this.props.t('bearbeiten')}
                                        </div>
                                    </div>
                                </div>
                                {
                                    posts.map(post => (
                                        <>
                                        {this.state.currentSearch === "" || ((post.id).toString().includes(this.state.currentSearch) ||  post.label.toLowerCase().includes((this.state.currentSearch).toLowerCase())) ? (
											<>
											{post.show === 1 ? (
		                                        <div class="row clearmargin grid-Row" data-id={post.id} data-tablename={post.name}  onDoubleClick={((e) => this.gotoDetail(post.id))} align="start">
		                                            <div class="grid-Cell col-sm-1"> 
		                                                <div class="grid-Cell__value" > 
		                                                    {post.id}
		                                                </div>
		                                            </div>
		                                            <div class="grid-Cell col-sm"> 
		                                                <div class="grid-Cell__value"> 
		                                                    {post.label}
		                                                </div>
		                                            </div> 
		                                            <div class="grid-Cell col-sm-1"> 
		                                                <div class="grid-Cell__value"> 
		                                                    <ul>  
		                                                        <li class="fa fa-file-excel-o gridIcon" onClick={(e) => this.handleExportPlaningComplete(post.id)}>
		                                                        </li>
		                                                    </ul>
		                                                </div>
		                                            </div>
		                                            <div class="grid-Cell col-sm-1"> 
		                                                <div class="grid-Cell__value"> 
		                                                    <ul>  
		                                                        <li class="fa fa-file-pdf-o gridIcon" onClick={e => this.handlepdfDialog(true,post.id)}>
		
		                                                        </li>
		                                                    </ul>
		                                                </div>
		                                            </div>  
		                                            <div class="grid-Cell col-sm-1"> 
		                                                <div class="grid-Cell__value"> 
		                                                    <ul>  
		                                                        <li class="fa fa-files-o gridIcon" onClick={((e) => this.duplicatePlaning(true,post.id, post.label))}>
		
		                                                        </li>
		                                                    </ul>
		                                                </div>
		                                            </div>                                                                                          
		                                            <div class="grid-Cell col-sm-1"> 
		                                                <div class="grid-Cell__value"> 
		                                                    <ul>  
		                                                        <li class="fa fa-pencil gridIcon">
		
		                                                        </li>
		                                                    </ul>
		                                                </div>
		                                            </div>                                                                                    
		                                        </div>
		                                       ): (<></>)}
	                                        </>
                                        ) : (<></>)}
                                        </>                                        
                                    ))
                                }
                            </div>
                        </div>
                    </div>
					<Dialog fullWidth="md"
                            maxWidth="md"
							open={this.state.exportPlaning}
                            onClose={((e) => this.handleExportPlaning(false))}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">Export</DialogTitle>
                        <DialogContent>
                          {this.state.exportStatus === 0 && 
							  <span>Ihre Planung wird berechnet</span>
						  }
                          {this.state.exportStatus === 1 && 
							  <CSVDisplay language="de" itemId="-1" items="-1" nextMask={this.state.planingId}/>
						  }

                        </DialogContent>
                        <DialogActions>
                        <Button onClick={((e) => this.handleExportPlaning(false))} color="primary">
                            {this.props.t('Abbruch')}
                        </Button>
                        <Button onClick={((e) => this.handleExportPlaning(false))} color="primary" autoFocus>
                            Ok
                        </Button>
                        </DialogActions>
                    </Dialog>
					<Dialog
                            fullWidth="md"
                            maxWidth="md"
                            open={this.state.pdfDialog}
                            onClose={((e) => this.handlepdfDialog(false,e))}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">{this.props.t('PDF')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            	<PDFDisplay nextMask={this.state.planingId} planingFirstOpen={this.state.planingFirstOpen} cleanForceRefresh="true" forceRefresh="true" preview="" language="de" items="-1" itemId={this.state.plItemId} />	                            

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={((e) => this.handlepdfDialog(false,e))} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={((e) => this.handlepdfDialog(false,e))} color="primary">
                            Ok
                        </Button>
                        </DialogActions>
                    </Dialog>    
                    
					<Dialog
                            open={this.state.duplicatePlaning}
                            onClose={((e) => this.duplicatePlaning(false))}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">{this.props.t('Planung duplizieren')}</DialogTitle>
	                        <DialogContent>
		                        <DialogContentText>
		                            Bitte geben Sie einen Namen ein.
		                        </DialogContentText>
		                        <TextField
		                            autoFocus
		                            name="newLabel"
		                            margin="dense"
		                            type="input"
		                            onChange={e => this.change(e)}
		                            value={this.state.newValue}
		                            fullWidth
		                        />
	                        </DialogContent>
                        <DialogActions>
                        <Button onClick={((e) => this.duplicatePlaning(false))} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={((e) => this.doDuplicatePlaning())} color="primary">
                            Ok
                        </Button>
                        </DialogActions>
                    </Dialog>                      
                                     
                </div> 
            );
       }
    }
}
export default withTranslation()(withSnackbar(Planings)) ; 
