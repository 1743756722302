import React, { Component } from 'react';

class Dashboard extends Component {
    constructor(props) {
        super(props);


    }

    componentDidMount() {
        var breadcrumbs = ["Dashboard"];

        this.props.location.handleStateChange(breadcrumbs);

    }

    render() {

        return (
            <div className="Vivacard">
                <div className="Vivacardheader">
                    Whats New
                </div>
                <div className="Vivacardbody">
                    aaa
                </div>
            </div>
        );
    }
}

export default Dashboard;