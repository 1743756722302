import React, { Component, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import {getToken} from '../../helpers/Token';
import {getAppUrl} from '../../helpers/Defaults';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {getWSUrl} from '../../helpers/Defaults';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { useTranslation, withTranslation, Trans, Translation } from 'react-i18next';
import { useSnackbar, withSnackbar } from 'notistack';
import Loader from './Loader'
import PDFDisplaySmall from './PDFDisplaySmall';

const contentShow = [
  {
	"id":"showAll",
  	"label":"Alles anzeigen"
  },
  {
	"id":"showDescription",
  	"label":"Beschreibung anzeigen"
  },
  {
	"id":"showAdvPoints",
  	"label":"Vorteilspunkte anzeigen"
  },
  {
	"id":"showPictures",
  	"label":"Bilder anzeigen"
  },
  {
	"id":"showTechnicalDetails",
  	"label":"Technische Details anzeigen"
  },
  {
	"id":"showPrice",
  	"label":"Preise anzeigen"
  }
  
];

function isValueSelected(values, whichId) {

	let vals = makeArray(values);
	let displayValue = false;


	vals.map(function(val) {
		if(val == whichId)
			displayValue = true;
	});
	return displayValue;
}

function displayArrayValues(values) {
	let vals = makeArray(values);
	let displayValue = "";

	contentShow.map(function(selectValue) {
		vals.map(function(val) {
			if(val == selectValue.id)
				displayValue += selectValue.label + " ";
		});
	});
	if(displayValue.length > 20)
		return displayValue.substr(0,20) + "...";
	else	

		return displayValue;
}

function makeArray(val) {
	if(val == null)
		return [];
	if (typeof val === 'string') {
		let x = [];
		let p = val.split(',');
		p.map(function(o) {
			x.push(parseInt(o));
		});
		return x;
	}
	else
		return val;
	
}

class NWPFormDialog extends Component {


    constructor(props) {

        super(props);
        this.state = {
            posts: [],
			formsVersion: [],
			formsBase: [],
			forms: [],
			classColumns: [],
			classColumnsSelect: [],
			viva_columnSelect: [],
            isLoaded : false,
            items: props.items,
            itemId: props.itemId,
            plItem: props.plItem,
            language: props.language,
            nextMask: props.nextMask,
            planungsId: props.planungsId,

			showLayout: false,
				viva_form: "",
				viva_formst_textValue1: "",
				viva_formst_textValue2: "",
				viva_formst_textValue3: "",
				viva_formz1: "25",
				viva_formz2: "",
				viva_formz3: "1",
				viva_formz4: "normal",
				viva_formst_text1: "",
				viva_formst_text2: "",
				viva_formst_text3: "",
				viva_formst_textForm1: "",
				viva_formst_textForm2: "",
				viva_formst_textForm3: "",
				viva_formst_iconPosition: "",
				viva_formst_preis: "",
				viva_markeLogo: "large",
				viva_tableDisplay: "",
				viva_columnGroup: ""	,
				viva_formst_neu: "",
				preview: ""

        };
        this.handleCloseLayout = this.handleCloseLayout.bind(this);
        this.handleShowLayout = this.handleShowLayout.bind(this);
		this.handleChangeForm = this.handleChangeForm.bind(this);
		this.handleChangeLayoutItem = this.handleChangeLayoutItem.bind(this);
		this.handleChangeLayoutItemClose = this.handleChangeLayoutItemClose.bind(this);


    }
    
    handleShowLayout() {      
		this.setState({
			showLayout: true,
		});
	}    
	
    handleCloseLayout() {      
		this.setState({
			showLayout: false,
			viva_formst_preis: "",
			viva_formst_neu: "",
			viva_formst_text1: "",
			viva_formst_text2: "",
			viva_formst_text3: "",
			viva_formst_iconPosition: "",
			viva_pagebreak: "",
		});
	}

handleChangeForm(e) {
		if(e.target.name == "viva_formst_preis") {
			this.setState({
				viva_formst_preis : e.target.value,
			})
		}	
		if(e.target.name == "viva_columnSelect") {
			this.setState({
				viva_columnSelect : e.target.value,
			})
		}		
		if(e.target.name == "viva_tableDisplay") {
			this.setState({
				viva_tableDisplay : e.target.value,
			})
		}
		if(e.target.name == "viva_columnGroup") {
			this.setState({
				viva_columnGroup : e.target.value,
			})
		}		
		if(e.target.name == "viva_markeLogo") {
			this.setState({
				viva_markeLogo : e.target.value,
			})
		}
		if(e.target.name == "viva_formst_neu") {
			this.setState({
				viva_formst_neu : e.target.value,
			})
		}		
		if(e.target.name == "viva_formst_text1") {
			this.setState({
				viva_formst_text1 : e.target.value,
			})
		}		
		if(e.target.name == "viva_formst_text2") {
			this.setState({
				viva_formst_text2 : e.target.value,
			})
		}		
		if(e.target.name == "viva_formst_text3") {
			this.setState({
				viva_formst_text3 : e.target.value,
			})
		}
		if(e.target.name == "viva_formst_textForm1") {
			this.setState({
				viva_formst_textForm1 : e.target.value,
			})
		}		
		if(e.target.name == "viva_formst_textForm2") {
			this.setState({
				viva_formst_textForm2 : e.target.value,
			})
		}		
		if(e.target.name == "viva_formst_textForm3") {
			this.setState({
				viva_formst_textForm3 : e.target.value,
			})
		}
		if(e.target.name == "viva_formst_textValue1") {
			this.setState({
				viva_formst_textValue1 : e.target.value,
			})
		}	
		if(e.target.name == "viva_formst_textValue2") {
			this.setState({
				viva_formst_textValue2 : e.target.value,
			})
		}
		if(e.target.name == "viva_formst_textValue3") {
			this.setState({
				viva_formst_textValue3 : e.target.value,
			})
		}				
		if(e.target.name == "viva_formst_iconPosition") {
			this.setState({
				viva_formst_iconPosition : e.target.value,
			})
		}		
		if(e.target.name == "viva_pagebreak") {
			this.setState({
				viva_pagebreak : e.target.value,
			})
		}		
		
		if(e.target.name == "viva_formBase") {
			this.setState({
				viva_form: e.target.value + " " + this.state.viva_formVersion,
				viva_formBase : e.target.value,
			})
		}
		if(e.target.name == "viva_formVersion") {
			this.setState({
				viva_formVersion : e.target.value,
				viva_form: this.state.viva_formBase + " " + e.target.value,
			})
		}
		if(e.target.name == "viva_formz1") {
			this.setState({
				viva_formz1 : e.target.value,
			})
		}		
		if(e.target.name == "viva_formz2") {
			let x = makeArray(e.target.value);
			this.setState({
				viva_formz2 : x,
			})
		}		
		if(e.target.name == "viva_formz3") {
			this.setState({
				viva_formz3 : e.target.value,
			})
		}
		if(e.target.name == "viva_formz4") {
			this.setState({
				viva_formz4 : e.target.value,
			})
		}		
		if(e.target.name == "viva_relationship") {
			this.setState({
				viva_relationship : e.target.value,
			})
		}
		if(e.target.name == "viva_layout") {
			this.setState({
				viva_layout : e.target.value,
			})
		}						
	}
	handleChangeLayoutItemClose() { 
		this.handleChangeLayoutItem();
		this.setState({
						showLayout: false,
					});
	}
		
	handleChangeLayoutItem() { 
		let plItemDetail = {};
		plItemDetail.label = this.state.viva_form;
		plItemDetail.stoererText1 = this.state.viva_formst_textValue1;
		plItemDetail.stoererText2 = this.state.viva_formst_textValue2;
		plItemDetail.stoererText3 = this.state.viva_formst_textValue3;
		plItemDetail.viva_formz1 = this.state.viva_formz1;
		plItemDetail.viva_formz2 = makeArray(this.state.viva_formz2).join(',');
		plItemDetail.viva_formz3 = this.state.viva_formz3;
		plItemDetail.viva_formz4 = this.state.viva_formz4;
		plItemDetail.stoererText1Position = this.state.viva_formst_text1;
		plItemDetail.stoererText2Position = this.state.viva_formst_text2;
		plItemDetail.stoererText3Position = this.state.viva_formst_text3;
		plItemDetail.stoererForm1 = this.state.viva_formst_textForm1;
		plItemDetail.stoererForm2 = this.state.viva_formst_textForm2;
		plItemDetail.stoererForm3 = this.state.viva_formst_textForm3;
		plItemDetail.markeLogoPosition = this.state.viva_markeLogo;
		plItemDetail.iconPosition = this.state.viva_formst_iconPosition;
		plItemDetail.viva_formst_preis   = this.state.viva_formst_preis;
		plItemDetail.tableDisplay = this.state.viva_tableDisplay;
		plItemDetail.columnGroup = this.state.viva_columnGroup;
		plItemDetail.viva_formst_neu = this.state.viva_formst_neu;
		plItemDetail.itemId = this.props.plItem;
		plItemDetail.itemId = this.props.plItem;
		plItemDetail.columnsString = this.state.viva_columnSelect.join(",");
		plItemDetail.viva_formst_seitenmotiv = "";
		plItemDetail.viva_anzahl_bilder = "10";
		axios.post(getAppUrl() + '/gatebackend/rest/content/planungsitem/' + this.state.plItem, 
					plItemDetail
				,
				{
					headers: { 
						"Authorization": " Bearer " + getToken()
					}
				}
				).then(repsonse => {
					this.props.enqueueSnackbar(this.props.t('Einstellungen gespeichert'), { variant: 'info' });

				}
					
				)
				.catch((error) => {
					console.log(error);
					alert("error");
		});		
		
		
		

	}	
    
    componentDidUpdate() {

    }

    componentWillMount() {
		this.setState({
			plItem: this.props.plItem,
		});

		this.reload(this.props.plItem);
    }
    
   	reload1(id) {
	alert(id);
	}
   	reload(id) {
		axios.get(getAppUrl() + '/gatebackend/rest/content/languages',  {
				headers: { 
					"Authorization": " Bearer " + getToken()
				}
			}).then(res4 => {
				axios.get(getAppUrl() + '/gatebackend/rest/settings/forms', 
					{
						headers: { 
							"Authorization": " Bearer " + getToken()
						}
					}).then(res3 => {	
							axios.get(getAppUrl() + '/gatebackend/rest/content/planungsitem/'+id, 
						{
							headers: { 
								"Authorization": " Bearer " + getToken()
							}
						}).then(res2 => {
							console.log("planungsitem");
							console.log(res2.data);
							let forms = [];
							let bases = {};
							let versions = [];
							let oB = "";
							res3.data.map(function(ex) {
								let a = ex.name.split(" ");
									let b = a[0];
									let v = a[1];
									if(oB === "")
										oB = b;
									if(oB != b) {
										bases[oB] = versions;
										forms.push(oB);
										versions = [];
									}
									versions.push(v);
									oB = b;
								}
							)
							forms.push(oB);
							bases[oB] = versions;

							let a1 = [];
							if(res2.data.formName != null && res2.data.formName != "")
								a1 = res2.data.formName.split(" ");
							let b1 = "";
							let v1 = "";
							if(a1.length == 2) {
								b1 = a1[0];
								v1 = a1[1];
							}


							let dummy = res2.data.tableColumns;
							let viva_columnSelect = [];
							if(dummy != undefined && dummy != "") {
								let x  = dummy.split(',');	
								for (let i0 = 0; i0 < x.length; i0++) {
									viva_columnSelect.push(parseInt(x[i0], 10));
								}
							}								


							this.setState({
								isLoaded : true,
								forms: forms,
								formsVersion: versions,
								formsBase: bases,
								formsOrig: res3.data,
								viva_formBase: b1,
								viva_formVersion : v1,
								viva_form: res2.data.formName,
								viva_formst_textValue1: res2.data.stoererText1,
								viva_formst_textValue2: res2.data.stoererText2,
								viva_formst_textValue3: res2.data.stoererText3,
								viva_formz1: res2.data.form_Zusatz1,
								viva_formz2: res2.data.form_Zusatz2.split(','),
								viva_formz3: res2.data.form_Zusatz3,
								viva_formz4: res2.data.form_Zusatz4,
								viva_formst_text1: res2.data.stoererText1Position,
								viva_formst_text2: res2.data.stoererText2Position,
								viva_formst_text3: res2.data.stoererText3Position,
								viva_formst_textForm1: res2.data.stoererForm1,
								viva_formst_textForm2: res2.data.stoererForm2,
								viva_formst_textForm3: res2.data.stoererForm3,
								viva_formst_iconPosition: res2.data.iconPosition,
								viva_formst_preis: res2.data.stoererPreisPosition,
								viva_markeLogo: res2.data.markeLogoPosition,
								viva_tableDisplay: res2.data.tableDisplay,
								viva_columnGroup: res2.data.columnGroup	,
								viva_formst_neu: res2.data.stoererNeuPosition,
								preview: res2.data.preview,
								classColumnsSelect: res2.data.classColumnsSelect,
								viva_columnSelect: viva_columnSelect,
							})   
						}); 
					}); 
				});	 
	}	
    
    
    render() {
        const {isLoaded, posts} = this.state;
        			let viva_columnSelect = this.state.viva_columnSelect;

        if(!isLoaded) {
            return (<>
                    <div class="Vivacardheader"></div>
                    <div>    
                        <div style={{width: "50%", margin: "0 auto"}}><Loader/><br/>{this.state.noticeMsg}</div>
                    </div>
                    </>)
        }
        else {
            return (
                <>  
                    <Button className="buttonIcon" aria-controls="simple-menu" aria-haspopup="true" onClick={((e) => this.handleShowLayout(e))}>
                    <span className={this.props.iconName}></span>
                	</Button>   
					<Dialog open={this.state.showLayout} onClose={this.handleCloseLayout} aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title">{this.props.t('Layout Einstellung')}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									{this.props.t('Legen Sie die Form fest')} {this.state.viva_form}
								</DialogContentText>
									<div className="row ">
										<div className="col-md-5 col-lg-5 col-xl-5 disablePaddingRight" style={{maxWidth: "227px", maxHeight:"240px"}}>
											
											
											{ this.state.plItem > 0 ? (						
													
												<PDFDisplaySmall preview={this.state.preview} firstOpen="true" itemId={this.state.plItem} planungsId={this.state.planungsId}  planung="0"/>
														
												) : (<></>)}
										</div>
										
										<div className="col-md-5 col-lg-5 col-xl-5 disablePaddingRight">
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">Formen Name</InputLabel>
												<Select
													value={this.state.viva_formBase}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formBase"
												>
													<MenuItem value="0">{this.props.t('keine')}</MenuItem>
													{
														this.state.forms.map(function(ex) {
															return (<MenuItem value={ex}>{ex}</MenuItem>)
														})
													}																				
												
												</Select>
											</FormControl>
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">Form Variante</InputLabel>
												<Select
													value={this.state.viva_formVersion}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formVersion"
												>
												{ this.state.viva_formBase != "" ? (						
													
														this.state.formsBase[this.state.viva_formBase].map(function(ex) {
															return (<MenuItem value={ex}>{ex}</MenuItem>)
														})
														
												) : (<></>)}

												</Select>
											</FormControl>											
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">TextBreite</InputLabel>
												<Select
													value={this.state.viva_formz1}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formz1"
												>
													<MenuItem value="100">100% Breite</MenuItem>																			
													<MenuItem value="75">75% Breite</MenuItem>																			
													<MenuItem value="66">66% Breite</MenuItem>																			
													<MenuItem value="50">50% Breite</MenuItem>																			
													<MenuItem value="40">40% Breite</MenuItem>																			
													<MenuItem value="33">33% Breite</MenuItem>																			
													<MenuItem value="25">25% Breite</MenuItem>																			
												
												</Select>
											</FormControl>
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">Text Spalten</InputLabel>
												<Select
													value={this.state.viva_formz3}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formz3"
												>
													<MenuItem value="1">1 spaltig</MenuItem>																			
													<MenuItem value="2">2 spaltig</MenuItem>																			
													<MenuItem value="3">3 spaltig</MenuItem>																			
													<MenuItem value="4">4 spaltig</MenuItem>																			
												
												</Select>
											</FormControl>	
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">Spalten Umbruch</InputLabel>
												<Select
													value={this.state.viva_formz4}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formz4"
												>
													<MenuItem value="normal">kein Umbruch</MenuItem>																			
													<MenuItem value="nachVorteilsPunkten">nach Vorteilspunkten</MenuItem>																			
																		
												
												</Select>
											</FormControl>
																					
										</div>
									</div>
								<br/>
								<DialogContentText>
									{this.props.t('Legen Sie die Details fest')}
								</DialogContentText>					
								<div className="row ">
										<div className="col-md-4 col-lg-4 col-xl-4 disablePaddingRight">
											
										<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">Inhaltsanzeige</InputLabel>
											<Select
												multiple
												value={makeArray(this.state.viva_formz2)}
												onChange={e => this.handleChangeForm(e)}
												input={<Input id="select-multiple-checkbox" />}
												renderValue={selected => displayArrayValues(this.state.viva_formz2)}
												name="viva_formz2"
											>

											{contentShow.map((kv) => (
											            <MenuItem key={kv.id} value={kv.id}>
											              <Checkbox checked={isValueSelected(makeArray(this.state.viva_formz2), kv.id)} />
											              <ListItemText primary={kv.label} />
											            </MenuItem>
         									))}		
											
											
		
											</Select>
										</FormControl>

										<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">Piktogramme Position</InputLabel>
											<Select
												value={this.state.viva_formst_iconPosition}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_formst_iconPosition"
											>
												<MenuItem value="ka">Keine Anzeige</MenuItem>																			
												<MenuItem value="Position1">Textbereich</MenuItem>																			
												<MenuItem value="Position2">Bildbereich</MenuItem>																																																					
											</Select>
										</FormControl>										
										<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">Logo Position</InputLabel>
											<Select
												value={this.state.viva_markeLogo}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_markeLogo"
											>
												<MenuItem value="ka">Keine Anzeige</MenuItem>																			
												<MenuItem value="TL">Oben Links</MenuItem>																			
												<MenuItem value="TC">Oben Mitte</MenuItem>																			
												<MenuItem value="TR">Oben Rechts</MenuItem>																			
												<MenuItem value="CL">Mitte Links</MenuItem>																			
												<MenuItem value="CC">Mitte Mitte</MenuItem>																			
												<MenuItem value="CR">Mitte Rechts</MenuItem>																			
												<MenuItem value="BL">Unten Links</MenuItem>																			
												<MenuItem value="BC">Unten Mitte</MenuItem>																			
												<MenuItem value="BR">Unten Rechts</MenuItem>																																		
											</Select>
										</FormControl>
										</div>
										<div className="col-md-4 col-lg-4 col-xl-4 disablePaddingRight">
											<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">StörerPreis Position</InputLabel>
											<Select
												value={this.state.viva_formst_preis}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_formst_preis"
											>
												<MenuItem value="ka">Keine Anzeige</MenuItem>																			
												<MenuItem value="TL">Oben Links</MenuItem>																			
												<MenuItem value="TC">Oben Mitte</MenuItem>																			
												<MenuItem value="TR">Oben Rechts</MenuItem>																			
												<MenuItem value="CL">Mitte Links</MenuItem>																			
												<MenuItem value="CC">Mitte Mitte</MenuItem>																			
												<MenuItem value="CR">Mitte Rechts</MenuItem>																			
												<MenuItem value="BL">Unten Links</MenuItem>																			
												<MenuItem value="BC">Unten Mitte</MenuItem>																			
												<MenuItem value="BR">Unten Rechts</MenuItem>																																		
											</Select>
										</FormControl>
										<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">StörerNeu Position</InputLabel>
											<Select
												value={this.state.viva_formst_neu}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_formst_neu"
											>
												<MenuItem value="ka">Keine Anzeige</MenuItem>																			
												<MenuItem value="TL">Oben Links</MenuItem>																			
												<MenuItem value="TC">Oben Mitte</MenuItem>																			
												<MenuItem value="TR">Oben Rechts</MenuItem>																			
												<MenuItem value="CL">Mitte Links</MenuItem>																			
												<MenuItem value="CC">Mitte Mitte</MenuItem>																			
												<MenuItem value="CR">Mitte Rechts</MenuItem>																			
												<MenuItem value="BL">Unten Links</MenuItem>																			
												<MenuItem value="BC">Unten Mitte</MenuItem>																			
												<MenuItem value="BR">Unten Rechts</MenuItem>																																		
											</Select>
										</FormControl>
										<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">Umbruch erzwingen</InputLabel>
												<Select
													value={this.state.viva_pagebreak}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_pagebreak"
												>
													<MenuItem value="nein">Kein Umbruch</MenuItem>																			
													<MenuItem value="ja">Umbruch</MenuItem>																			
																																															
												</Select>
											</FormControl>
										</div>
										<div className="col-md-4 col-lg-4 col-xl-4 disablePaddingRight">
											<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">MasterTabelle Ansicht</InputLabel>
											<Select
												value={this.state.viva_tableDisplay}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_tableDisplay"
											>
												<MenuItem value="large">Überschriften links</MenuItem>
												<MenuItem value="headlineTop">Überschriften oben</MenuItem>
												<MenuItem value="short">Gruppiert</MenuItem>																			
												<MenuItem value="ersatzteilTable">Ersatzteil Tabelle</MenuItem>																															
												<MenuItem value="articleAsTable">Als Tabelle</MenuItem>																					
																																														
											</Select>
										</FormControl>
										<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">Gruppierung nach</InputLabel>
											<Select
												value={this.state.viva_columnGroup}
												onChange={e => this.handleChangeForm(e)}
												input={<Input name="age" id="age-label-placeholder" />}
												displayEmpty
												name="viva_columnGroup"
											>
											<MenuItem value="keine">-keine-</MenuItem>																			
											{
												this.state.classColumns.map(function(ex) {
													return (<MenuItem value={ex}>{ex}</MenuItem>)
												})	
											}																	
																																														
											</Select>
										</FormControl>									
										<FormControl  style={{width: "100%"}}>
											<InputLabel htmlFor="age-native-helper">Auswahl Columns</InputLabel>
											<Select
												value={this.state.viva_columnSelect}
												onChange={e => this.handleChangeForm(e)}

												input={<Input id="checkbox" />}
												multiple
												name="viva_columnSelect"
          										renderValue={(selected) => selected.join(", ")}

											>
											{
												this.state.classColumnsSelect.map(function(ex) {
													return (<MenuItem key={ex.id} value={ex.id}>
																<Checkbox  checked={viva_columnSelect.indexOf(ex.id) > -1} />
																<ListItemText primary={ex.name} />													
													</MenuItem>)
												})	
											}
											</Select>
										</FormControl>
										</div>
									</div>
									<div className="row " style={{marginTop: "5px"}}>
										<div className="col-md-4 col-lg-4 col-xl-4 disablePaddingRight">
											<TextField
												autoFocus
												margin="dense"
												name="viva_formst_textValue1"
												label="StörerText1"
												type="email"
												onChange={e => this.handleChangeForm(e)}
												value={this.state.viva_formst_textValue1}
												fullWidth
											/>
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">StörerText1 Position</InputLabel>
												<Select
													value={this.state.viva_formst_text1}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formst_text1"
												>
													<MenuItem value="ka">Keine Anzeige</MenuItem>																			
													<MenuItem value="TL">Oben Links</MenuItem>																			
													<MenuItem value="TC">Oben Mitte</MenuItem>																			
													<MenuItem value="TR">Oben Rechts</MenuItem>																			
													<MenuItem value="CL">Mitte Links</MenuItem>																			
													<MenuItem value="CC">Mitte Mitte</MenuItem>																			
													<MenuItem value="CR">Mitte Rechts</MenuItem>																			
													<MenuItem value="BL">Unten Links</MenuItem>																			
													<MenuItem value="BC">Unten Mitte</MenuItem>																			
													<MenuItem value="BR">Unten Rechts</MenuItem>																																		
												</Select>
											</FormControl>	
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">StörerText1 Form</InputLabel>
												<Select
													value={this.state.viva_formst_textForm1}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formst_textForm1"
												>
													<MenuItem value="Icon Blanco Quadrat">Blanco Quadrat</MenuItem>																			
													<MenuItem value="Icon Comfort Product">Comfort Product</MenuItem>																																																					
													<MenuItem value="Icon Designed_in_Germany">Designed_in_Germany</MenuItem>																																																					
													<MenuItem value="Icon Eco">Eco</MenuItem>																																																					
													<MenuItem value="Icon Exclusive_Product">Exclusive_Product</MenuItem>																																																					
													<MenuItem value="Icon Highlight_Product">Highlight_Product</MenuItem>																																																					
													<MenuItem value="Icon Info">Info</MenuItem>																																																					
													<MenuItem value="Icon Made_in_Europe">Made_in_Europe</MenuItem>																																																					
													<MenuItem value="Icon Made_in_Germany">Icon Made_in_Germany</MenuItem>																																																					
													<MenuItem value="Icon Neu">Neu</MenuItem>																																																					
													<MenuItem value="Icon Onlineshop">Icon Onlineshop</MenuItem>																																																					
													<MenuItem value="Icon Preis_1">Preis_1</MenuItem>																																																					
													<MenuItem value="Icon Preis_2">Preis_2</MenuItem>																																																					
													<MenuItem value="Icon Preis_Reimo">Preis_Reimo</MenuItem>																																																					
													<MenuItem value="Icon Premium_Product">Premium_Product</MenuItem>																																																					
													<MenuItem value="Icon Professional_Product">Professional_Product</MenuItem>																																																					
													<MenuItem value="Icon Rabatt">Rabatt</MenuItem>																																																					
													<MenuItem value="Icon Tip">Tip</MenuItem>																																																					

												</Select>
											</FormControl>	
											</div>
											<div className="col-md-4 col-lg-4 col-xl-4 disablePaddingRight">	
											<TextField
												autoFocus
												margin="dense"
												name="viva_formst_textValue2"
												label="StörerText2"
												type="email"
												onChange={e => this.handleChangeForm(e)}
												value={this.state.viva_formst_textValue2}
												fullWidth
											/>									
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">StörerText2 Position</InputLabel>
												<Select
													value={this.state.viva_formst_text2}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formst_text2"
												>
													<MenuItem value="ka">Keine Anzeige</MenuItem>																			
													<MenuItem value="TL">Oben Links</MenuItem>																			
													<MenuItem value="TC">Oben Mitte</MenuItem>																			
													<MenuItem value="TR">Oben Rechts</MenuItem>																			
													<MenuItem value="CL">Mitte Links</MenuItem>																			
													<MenuItem value="CC">Mitte Mitte</MenuItem>																			
													<MenuItem value="CR">Mitte Rechts</MenuItem>																			
													<MenuItem value="BL">Unten Links</MenuItem>																			
													<MenuItem value="BC">Unten Mitte</MenuItem>																			
													<MenuItem value="BR">Unten Rechts</MenuItem>																																		
												</Select>
											</FormControl>	
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">StörerText2 Form</InputLabel>
												<Select
													value={this.state.viva_formst_textForm2}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formst_textForm2"
												>
													<MenuItem value="Icon Blanco Quadrat">Blanco Quadrat</MenuItem>																			
													<MenuItem value="Icon Comfort Product">Comfort Product</MenuItem>																																																					
													<MenuItem value="Icon Designed_in_Germany">Designed_in_Germany</MenuItem>																																																					
													<MenuItem value="Icon Eco">Eco</MenuItem>																																																					
													<MenuItem value="Icon Exclusive_Product">Exclusive_Product</MenuItem>																																																					
													<MenuItem value="Icon Highlight_Product">Highlight_Product</MenuItem>																																																					
													<MenuItem value="Icon Info">Info</MenuItem>																																																					
													<MenuItem value="Icon Made_in_Europe">Made_in_Europe</MenuItem>																																																					
													<MenuItem value="Icon Made_in_Germany">Icon Made_in_Germany</MenuItem>																																																					
													<MenuItem value="Icon Neu">Neu</MenuItem>																																																					
													<MenuItem value="Icon Onlineshop">Icon Onlineshop</MenuItem>																																																					
													<MenuItem value="Icon Preis_1">Preis_1</MenuItem>																																																					
													<MenuItem value="Icon Preis_2">Preis_2</MenuItem>																																																					
													<MenuItem value="Icon Preis_Reimo">Preis_Reimo</MenuItem>																																																					
													<MenuItem value="Icon Premium_Product">Premium_Product</MenuItem>																																																					
													<MenuItem value="Icon Professional_Product">Professional_Product</MenuItem>																																																					
													<MenuItem value="Icon Rabatt">Rabatt</MenuItem>																																																					
													<MenuItem value="Icon Tip">Tip</MenuItem>																																																					
												</Select>
											</FormControl>
											</div>
											<div className="col-md-4 col-lg-4 col-xl-4 disablePaddingRight">											
											<TextField
												autoFocus
												margin="dense"
												name="viva_formst_textValue3"
												label="StörerText3"
												type="email"
												onChange={e => this.handleChangeForm(e)}
												value={this.state.viva_formst_textValue3}
												fullWidth
											/>
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">StörerText3 Position</InputLabel>
												<Select
													value={this.state.viva_formst_text3}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formst_text3"
												>
													<MenuItem value="ka">Keine Anzeige</MenuItem>																			
													<MenuItem value="TL">Oben Links</MenuItem>																			
													<MenuItem value="TC">Oben Mitte</MenuItem>																			
													<MenuItem value="TR">Oben Rechts</MenuItem>																			
													<MenuItem value="CL">Mitte Links</MenuItem>																			
													<MenuItem value="CC">Mitte Mitte</MenuItem>																			
													<MenuItem value="CR">Mitte Rechts</MenuItem>																			
													<MenuItem value="BL">Unten Links</MenuItem>																			
													<MenuItem value="BC">Unten Mitte</MenuItem>																			
													<MenuItem value="BR">Unten Rechts</MenuItem>																																		
												</Select>
											</FormControl>
											<FormControl  style={{width: "100%"}}>
												<InputLabel htmlFor="age-native-helper">StörerText3 Form</InputLabel>
												<Select
													value={this.state.viva_formst_textForm3}
													onChange={e => this.handleChangeForm(e)}
													input={<Input name="age" id="age-label-placeholder" />}
													displayEmpty
													name="viva_formst_textForm3"
												>
													<MenuItem value="Icon Blanco Quadrat">Blanco Quadrat</MenuItem>																			
													<MenuItem value="Icon Comfort Product">Comfort Product</MenuItem>																																																					
													<MenuItem value="Icon Designed_in_Germany">Designed_in_Germany</MenuItem>																																																					
													<MenuItem value="Icon Eco">Eco</MenuItem>																																																					
													<MenuItem value="Icon Exclusive_Product">Exclusive_Product</MenuItem>																																																					
													<MenuItem value="Icon Highlight_Product">Highlight_Product</MenuItem>																																																					
													<MenuItem value="Icon Info">Info</MenuItem>																																																					
													<MenuItem value="Icon Made_in_Europe">Made_in_Europe</MenuItem>																																																					
													<MenuItem value="Icon Made_in_Germany">Icon Made_in_Germany</MenuItem>																																																					
													<MenuItem value="Icon Neu">Neu</MenuItem>																																																					
													<MenuItem value="Icon Onlineshop">Icon Onlineshop</MenuItem>																																																					
													<MenuItem value="Icon Preis_1">Preis_1</MenuItem>																																																					
													<MenuItem value="Icon Preis_2">Preis_2</MenuItem>																																																					
													<MenuItem value="Icon Preis_Reimo">Preis_Reimo</MenuItem>																																																					
													<MenuItem value="Icon Premium_Product">Premium_Product</MenuItem>																																																					
													<MenuItem value="Icon Professional_Product">Professional_Product</MenuItem>																																																					
													<MenuItem value="Icon Rabatt">Rabatt</MenuItem>																																																					
													<MenuItem value="Icon Tip">Tip</MenuItem>																																																					
												</Select>
											</FormControl>											

										</div>
									</div>																																						
							</DialogContent>
							
							<DialogActions>
								<Button onClick={this.handleCloseLayout} color="primary">
									{this.props.t('Abbruch')}
								</Button>
								<Button onClick={this.handleChangeLayoutItem} color="primary">
									{this.props.t('aktualisieren')}
								</Button>								
								<Button onClick={this.handleChangeLayoutItemClose} color="primary">
									{this.props.t('OK')}
								</Button>
								</DialogActions>
					</Dialog> 
                </>
        );

    }
    }
}
export default (withTranslation()(withSnackbar(NWPFormDialog)));
